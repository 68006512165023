import { Font, parse as parseFont } from 'opentype.js'

import { svgStringToCanvas } from '@orangelv/utils-dom'

import assert from '../../../platform/assert'
import http from '../../../platform/client/http'
import getAsset from '../../../platform/getAsset'

import { Node } from '../common/typings'

const FONT_SIZE = 72 // Doesn't matter because the text is scaled anyway.

const fontCache: Record<string, Promise<{ font: Font; maxHeight: number }>> = {}

const getMaxHeight = (font: Font) => {
  const digits = Array.from({ length: 10 }, (_, i) =>
    String.fromCharCode('0'.charCodeAt(0) + i),
  )
  const uppercaseLetters = Array.from({ length: 26 }, (_, i) =>
    String.fromCharCode('A'.charCodeAt(0) + i),
  )
  const lowercaseLetters = Array.from({ length: 26 }, (_, i) =>
    String.fromCharCode('a'.charCodeAt(0) + i),
  )
  const chars = [...digits, ...uppercaseLetters, ...lowercaseLetters]

  const measurementPath = font.getPath(chars.join(''), 0, 0, FONT_SIZE)
  const measurementPathBoundingBox = measurementPath.getBoundingBox()
  const maxHeight =
    measurementPathBoundingBox.y2 - measurementPathBoundingBox.y1

  return maxHeight
}

const getVectorTextTexture = (
  fontNode: Node,
  textNode: Node,
  colorNode: Node,
  canvasSize: { width: number; height: number },
  textScale = 1,
) => ({
  getCanvas: async (canvas: HTMLCanvasElement) => {
    const context = canvas.getContext('2d')
    assert(context)

    const text = textNode.value

    if (!text) {
      context.clearRect(0, 0, canvas.width, canvas.height)
      return
    }

    const fontPath = getAsset(`fonts/${fontNode.value}.ttf`)

    let fontPromise = fontCache[fontPath]

    if (!fontPromise) {
      fontPromise = http
        .get(fontPath, {
          responseType: 'arraybuffer',
        })
        .then((response) => {
          const font = parseFont(response.data)
          const maxHeight = getMaxHeight(font)

          return { font, maxHeight }
        })

      fontCache[fontPath] = fontPromise
    }

    const { font, maxHeight } = await fontPromise

    canvas.width = canvasSize.width
    canvas.height = canvasSize.height

    const path = font.getPath(text, 0, 0, FONT_SIZE)

    const boundingBox = path.getBoundingBox()
    const textWidth = boundingBox.x2 - boundingBox.x1

    const scaleY = (canvasSize.height / maxHeight) * textScale
    const scaleX =
      canvasSize.width < textWidth * textScale ?
        Math.min(1, canvasSize.width / textWidth) * textScale
      : scaleY

    const translateX = canvasSize.width / 2 - (textWidth * scaleX) / 2
    const yAdjustment = (-font.descender / font.unitsPerEm) * FONT_SIZE
    const translateY =
      (canvasSize.height - maxHeight * scaleY) / 2 +
      maxHeight * scaleY -
      yAdjustment * scaleY

    const svgString = path.toSVG(2)

    const svgElement = document.createElement('svg')
    svgElement.setAttribute('xmlns', 'http://www.w3.org/2000/svg')
    svgElement.setAttribute('width', `${canvas.width}`)
    svgElement.setAttribute('height', `${canvas.height}`)

    const groupElement = document.createElement('g')
    groupElement.setAttribute(
      'transform',
      `translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`,
    )
    groupElement.setAttribute('fill', colorNode.object.props.hex)

    groupElement.innerHTML = svgString

    svgElement.appendChild(groupElement)

    return svgStringToCanvas(svgElement.outerHTML, { canvas })
  },
  key: [fontNode.value, textNode.value, colorNode.value],
})

export default getVectorTextTexture
