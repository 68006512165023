import * as _ from '@technically/lodash'
import React from 'react'
import cn from 'classnames'
import { connect } from 'react-redux'

import getAsset from '~p/getAsset'

import controlTree from '~c/client/controlTree'

import TextInput from './TextInput'

import './TextAndFontInput.css'

const mapStateToProps = (state) => ({
  nodes: controlTree.getNodes(state),
})

const mapDispatchToProps = {
  onChange: controlTree.change,
}

const TextAndFontInput = (props) => {
  const {
    hasMargin = true,
    nodes,
    textNodeKeyPath,
    fontNodeKeyPath,
    onChange,
  } = props

  const textNode = nodes[textNodeKeyPath]
  const fontNode = nodes[fontNodeKeyPath]

  return (
    <div className={cn('TextAndFontInput', { hasMargin })}>
      <TextInput
        value={textNode.value}
        onChange={(value) => {
          onChange(textNode.keyPath, value)
        }}
        placeholder={textNode.label}
        maxLength={textNode.maxLength}
        pattern={textNode.pattern}
      />

      <div className="select">
        {_.map(fontNode.options, (option) => (
          <div
            key={option.id}
            className={cn({
              isActive: option.id === fontNode.value,
              isDisabled: !fontNode.isAvailable,
            })}
            style={{
              backgroundImage: `url(${getAsset(`fonts/${option.id}.jpg`)})`,
            }}
            title={option.name}
            onClick={() => {
              onChange(fontNode.keyPath, option.id)
            }}
          />
        ))}
      </div>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(TextAndFontInput)
