import { type ArcRotateCamera, Vector2 } from '@babylonjs/core';
import { ok } from '@orangelv/utils';
import { inverseLerp } from '@orangelv/utils-arithmetic';

import { getState } from './state.js';
import type { Ref, State } from './types.js';

// This assumes that base angle is always the same.
const BASE_ANGLE = Math.PI / 2;

const runRenderLoop = (stateRef: Ref<State>) => (): void => {
  const state = getState(stateRef);

  const { scene } = state;

  ok(scene);

  const engine = scene.getEngine();

  engine.runRenderLoop(() => {
    if (!getState(stateRef).isReady) {
      // Do not render when bjs-renderer is not ready.
      return;
    }

    if (document.hidden) {
      // Do not render when the page is not visible to the user.
      return;
    }

    const camera = scene.activeCamera as ArcRotateCamera | null;

    if (!camera) {
      return;
    }

    const { config } = getState(stateRef).props;

    const xFactor = config.camera?.peek?.xFactor ?? 0;
    const yFactor = config.camera?.peek?.yFactor ?? 0;

    const { lowerRadiusLimit, upperRadiusLimit, radius, alpha, beta } = camera;
    const radiusFactor =
      lowerRadiusLimit !== null && upperRadiusLimit !== null ?
        1 - inverseLerp(radius, lowerRadiusLimit, upperRadiusLimit)
      : 1;

    camera.targetScreenOffset = new Vector2(
      (alpha - BASE_ANGLE) * radiusFactor * xFactor,
      (beta - BASE_ANGLE) * radiusFactor * yFactor,
    );

    scene.render();
  });
};

export default runRenderLoop;
