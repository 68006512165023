import type { AbstractMesh, AssetContainer } from '@babylonjs/core';

import getParentMeshes from './get-parent-meshes.js';

const getAllMeshes = (assetContainer: AssetContainer): AbstractMesh[] => {
  const allMeshes: Record<string, AbstractMesh> = {};
  for (const mesh of assetContainer.meshes) {
    allMeshes[mesh.id] = mesh;
  }

  // Populate with parents and parents of parents.
  for (const mesh of assetContainer.meshes) {
    for (const parentMesh of getParentMeshes(mesh)) {
      allMeshes[parentMesh.id] = parentMesh;
    }
  }

  return Object.values(allMeshes);
};

export default getAllMeshes;
