import { mapValues, ok } from '@orangelv/utils';

import { getState, setModelState } from '../state.js';
import type { Ref, RendererConfig, State } from '../types.js';
import getMaterialKey from './get-material-key.js';
import updateMaterial from './update-material.js';

const updateMaterials =
  (stateRef: Ref<State>) =>
  async (modelId: string, config: RendererConfig): Promise<void> => {
    const modelConfig = config.models[modelId];

    if (!modelConfig) {
      return;
    }

    const state = getState(stateRef);

    ok(state.scene);

    const modelState = state.models[modelId];

    ok(modelState);

    const materialKeys = mapValues(
      modelConfig.materials ?? {},
      (materialConfig) => getMaterialKey(materialConfig),
    );

    await Promise.all(
      Object.entries(modelConfig.materials ?? {}).map(
        async ([materialId, materialConfig]) => {
          const needsUpdating =
            modelState.materialKeys[materialId] !== materialKeys[materialId];

          if (!needsUpdating) return;

          return updateMaterial(stateRef)(modelId, materialId, materialConfig);
        },
      ),
    );

    // Gather the latest state after all material promises are finished.
    const stateLatest = getState(stateRef);

    const modelStateLatest = stateLatest.models[modelId];

    ok(modelStateLatest);

    setModelState(stateRef)(modelId, {
      ...modelStateLatest,
      materialKeys,
    });

    if (config.onAfterMaterialsUpdated) {
      config.onAfterMaterialsUpdated(stateLatest);
    }
  };

export default updateMaterials;
