import type {
  MaterialProperty,
  MaterialPropertyCanvas,
  MaterialPropertyFabric,
  MaterialPropertyRemove,
  MaterialPropertyUrl,
} from '../types.js';

const isMaterialPropertyRemove = (
  materialProperty: MaterialProperty,
): materialProperty is MaterialPropertyRemove =>
  typeof materialProperty === 'object' && 'remove' in materialProperty;

const isMaterialPropertyUrl = (
  materialProperty: MaterialProperty,
): materialProperty is MaterialPropertyUrl =>
  typeof materialProperty === 'object' && 'url' in materialProperty;

const isMaterialPropertyCanvas = (
  materialProperty: MaterialProperty,
): materialProperty is MaterialPropertyCanvas =>
  typeof materialProperty === 'object' && 'getCanvas' in materialProperty;

const isMaterialPropertyFabric = (
  materialProperty: MaterialProperty,
): materialProperty is MaterialPropertyFabric =>
  typeof materialProperty === 'object' && 'getFabric' in materialProperty;

export {
  isMaterialPropertyCanvas,
  isMaterialPropertyFabric,
  isMaterialPropertyRemove,
  isMaterialPropertyUrl,
};
