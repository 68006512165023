import * as React from 'react'

import { t } from '~p/i18n'

import Summary from './Summary'

import './Product.css'

const Product = (props) => (
  <div className="product-product">
    <div className="product-title">{props.title}</div>
    <div className="product-price mod-print">{props.priceWithCurrency}</div>
    <div className="product-configurationId">
      {t('_rawlings:configurationId')}: {props.configurationId}
    </div>
    <Summary sections={props.summarySections} />
    <div className="product-price">{props.priceWithCurrency}</div>
    <div className="product-controls">{props.children}</div>
  </div>
)

export default Product
