import cn from 'classnames'
import * as _ from '@technically/lodash'
import React, { Component, Fragment } from 'react'
import animate from 'amator'
import BezierEasing from 'bezier-easing'

import { t } from '~p/i18n'
import { push } from '~p/client/history'
import { navListRootIndices } from '~p/client/navigation'

import Icon from './Icon'
import Button from './Button'

import './Navigation.css'

const easing = BezierEasing(0.42, 0, 0.855, 1)

// Amount of fuzzy pixels around the edges of the scroll frame, since some
// browsers, for example, don't return 0 for scrollTop when at the top.
const SCROLL_EPSILON = 8

const TOP_LEVEL_ITEM_HEIGHT = 50

export default class Navigation extends Component {
  static defaultProps = {
    isOpen: false,
  }

  state = {
    showScroll: false,
    canScrollUp: false,
    canScrollDown: false,
  }

  componentDidMount() {
    // The timeout is necessary, unfortunately
    window.setTimeout(() => this.onResizeAndScroll(), 200)
    window.addEventListener('resize', this.onResizeAndScroll)
  }

  UNSAFE_componentWillReceiveProps() {
    window.setTimeout(() => this.onResizeAndScroll(), 200)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResizeAndScroll)
  }

  onResizeAndScroll = _.throttle(() => {
    const { itemsList: x } = this
    if (!x) return
    this.setState({
      showScroll: x.clientHeight !== x.scrollHeight,
      canScrollUp: x.scrollTop > SCROLL_EPSILON,
      canScrollDown:
        x.scrollHeight - (x.clientHeight + x.scrollTop) > SCROLL_EPSILON,
    })
  }, 200)

  itemsList = null

  scroll = (d) => {
    const { itemsList: x } = this
    if (!x) return
    const scrollTop = Math.min(Math.max(x.scrollTop + d, 0), x.scrollHeight)
    animate(x, { scrollTop }, { duration: 300, easing })
  }

  scrollUp = () => {
    if (this.itemsList) {
      this.scroll(-this.itemsList.clientHeight - TOP_LEVEL_ITEM_HEIGHT)
    }
  }

  scrollDown = () => {
    if (this.itemsList) {
      this.scroll(this.itemsList.clientHeight - TOP_LEVEL_ITEM_HEIGHT)
    }
  }

  renderItem = (itemProps) => {
    const { index, isCollapsed } = itemProps
    const { navList, currentNavItem } = this.props
    const navItem = navList[index]

    if (!navItem.isAvailable) return undefined

    const navigationFilter = this.props.navigationFilter

    if (navigationFilter && !navigationFilter(navItem.effectiveId)) {
      return undefined
    }

    const childItems = _.map(navItem.childIndices, (idx) => navList[idx])

    // Show "new" for any available menu item and its parent.
    const isNewVisible =
      navItem.isNew ||
      _.some(childItems, (item) => item.isNew && item.node.isAvailable)

    const isActive =
      currentNavItem &&
      (navItem === currentNavItem ||
        currentNavItem.ancestorIndices.includes(index))

    const isProductSelector = navItem.id === 'filter.productSelector'
    const isSportSelector =
      navItem.id === 'filter.productSelector.productSelector.sport'

    return (
      <Fragment key={index}>
        {isSportSelector && (
          <div
            className={cn(['Navigation-item', isCollapsed && 'is-collapsed'])}
          >
            <div
              className="Navigation-link"
              onClick={() => {
                this.props.resetValues()
                push({ path: '/', query: null })
              }}
            >
              {t('_rawlings:startOver')}
              <Icon name="start-over" />
            </div>
          </div>
        )}

        <div
          className={cn([
            'Navigation-item',
            isCollapsed && 'is-collapsed',
            isProductSelector && 'mod-productSelector',
          ])}
        >
          <div
            className={cn(['Navigation-link', isActive && 'is-active'])}
            onClick={() => {
              this.props.openMenu(navItem.effectiveId)
              if (!navItem.shouldShowChildren) {
                this.props.closeNavigation()
              }
            }}
          >
            {navItem.name}
            {isProductSelector && <Icon name="search" />}
            {isNewVisible && (
              <span className="Navigation-new">{t('_rawlings:new')}</span>
            )}
          </div>
          {_.map(navItem.childIndices, (x) =>
            this.renderItem({ index: x, isCollapsed: !isActive }),
          )}
        </div>
      </Fragment>
    )
  }

  renderSection = (index) => {
    const navItem = this.props.navList[index]

    const NavigationComponent =
      this.props.navigationComponents &&
      this.props.navigationComponents[navItem.id]
    if (NavigationComponent) {
      return <NavigationComponent key={navItem.id} />
    }

    if (!navItem.isAvailable) {
      return undefined
    }

    return (
      <div
        key={index}
        className={cn(['Navigation-section', `Navigation-section-${index}`])}
      >
        {this.props.showSectionLabel && (
          <div className="Navigation-sectionTitle">{navItem.name}</div>
        )}
        {_.map(navItem.childIndices, (x) =>
          this.renderItem({ index: x, isTopLevel: true }),
        )}
      </div>
    )
  }

  render() {
    const {
      closeNavigation,
      currentNavItem,
      isOpen,
      openMenu,
      openNavigation,
      product,
    } = this.props

    const prevId = currentNavItem ? currentNavItem.prevId : undefined
    const nextId = currentNavItem ? currentNavItem.nextId : undefined

    return (
      <div className={cn(['Navigation', isOpen && 'is-open'])}>
        {prevId ?
          <a
            className="Navigation-control mod-left"
            onClick={() => openMenu(prevId)}
          >
            <Icon name="arrow-back" />
          </a>
        : <div className="Navigation-control mod-left">
            <Icon name="arrow-back" />
          </div>
        }
        <div
          className="Navigation-pageTitle mod-menu"
          onClick={closeNavigation}
        >
          <Icon name="close" />
        </div>
        <div className="Navigation-pageTitle" onClick={openNavigation}>
          <div className="Navigation-pageTitleBox">
            <Icon name="bars" />
            {currentNavItem ? currentNavItem.fullName : undefined}
          </div>
        </div>
        <div
          className={cn([
            'Navigation-scrollControl',
            !this.state.showScroll && 'is-hidden',
            !this.state.canScrollUp && 'is-disabled',
          ])}
          onClick={this.state.canScrollUp ? this.scrollUp : undefined}
        >
          <Icon name="arrow-up" />
        </div>
        <div
          className="Navigation-itemsList"
          ref={(x) => {
            this.itemsList = x
          }}
          onScroll={this.onResizeAndScroll}
        >
          <div className={cn(['Navigation-section', 'mod-mobileOnly'])}>
            <div className="Navigation-productTitle">{product.title}</div>
            <div className="Navigation-productPrice">
              {product.priceWithCurrency}
            </div>
            <div className="Navigation-productControls">
              <Button classMods={['hasIcon']} onClick={this.props.onCart}>
                <Icon name="cart" />{' '}
                {window.serverConfig.orderButtonText ||
                  t('_rawlings:addToCart')}
              </Button>
            </div>
          </div>
          {_.map(navListRootIndices, this.renderSection)}
          <div className={cn(['Navigation-section', 'mod-mobileOnly'])}>
            <div
              key="summary"
              className={cn(['Navigation-item', 'mod-summary'])}
            >
              <div
                className={cn(['Navigation-link'])}
                onClick={() => {
                  this.props.onSummary()
                  this.props.closeNavigation()
                }}
              >
                <Icon name="info" /> {t('_rawlings:summary')}
              </div>
            </div>
            <div
              key="purchase"
              className={cn(['Navigation-item', 'mod-purchase'])}
            >
              <div
                className={cn(['Navigation-link'])}
                onClick={() => {
                  this.props.onCart()
                  this.props.closeNavigation()
                }}
              >
                <Icon name="cart" /> {t('_rawlings:purchase')}
              </div>
            </div>
          </div>
        </div>
        <div
          className={cn([
            'Navigation-scrollControl',
            !this.state.showScroll && 'is-hidden',
            !this.state.canScrollDown && 'is-disabled',
          ])}
          onClick={this.state.canScrollDown ? this.scrollDown : undefined}
        >
          <Icon name="arrow-down" />
        </div>
        <a
          className="Navigation-control mod-right"
          onClick={() => {
            if (nextId) {
              openMenu(nextId)
            } else {
              this.props.onSummary()
            }
          }}
        >
          <Icon name="arrow-forward" />
        </a>
      </div>
    )
  }
}
