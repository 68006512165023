import * as _ from '@technically/lodash'

import {
  GLOVE_ASSET_DICT,
  GLOVE_DICT,
  LOGO_DICT,
  WEB_DICT,
  LEATHER_DICT,
} from '../../common/sheets'
import generateSnapshots from './generate'
import controlTree from '../controlTree'

const HEIGHT = 512

const getJob = ({ filename, glove, leather, logo, viewName, web }) => {
  const controlTreeChanges = [
    ['product.leather', leather.id],
    ['product.glove', glove.id],
    ['personalization.embroidery.logo', logo.id],
  ]

  if (web) {
    controlTreeChanges.push(['colors.web.style', web.id])
  }

  const gloveAsset = GLOVE_ASSET_DICT[glove.asset.gloveAssetId]
  const sourceSize = gloveAsset.props.imageDimensions[viewName]
  const height = HEIGHT
  const width = Math.round((height / sourceSize.height) * sourceSize.width)
  const size = {
    width,
    height,
  }

  return { filename, controlTreeChanges, viewName, size }
}

const main = async (filters) => {
  const jobs = []

  const viewName = 'web'

  const state = {
    controlTree: {
      pendingChanges: { auto: {}, user: {} },
      preferredValues: {},
      repeatedNodes: {},
      values: {},
    },
  }

  let gloves = controlTree.getNodes(state)['product.glove'].visibleOptions

  if (filters && filters.gloveId) {
    gloves = [GLOVE_DICT[filters.gloveId]]
  }

  let logos =
    controlTree.getNodes(state)['personalization.embroidery.logo']
      .visibleOptions
  if (filters && filters.logoId) {
    logos = [LOGO_DICT[filters.logoId]]
  }

  const web = filters && filters.webId ? WEB_DICT[filters.webId] : undefined

  _.forEach(gloves, (glove) => {
    // First available for this glove.
    const leatherId = _.first(_.keys(_.pickBy(glove.limitations.leatherId)))

    const leather = LEATHER_DICT[leatherId]

    _.forEach(logos, (logo) => {
      const filename = [glove.id, leather.id, logo.id, viewName].join('  ')

      jobs.push(
        getJob({
          filename,
          glove,
          leather,
          logo,
          viewName,
          web,
        }),
      )
    })
  })

  return generateSnapshots(jobs)
}

export default main
