import { GeneratePreviews } from '@orangelv/bjs-renderer'

import { setPreviewGenerator } from '../../../platform/client/common/actions'

import store from '../client/store'
import controlTree from '../client/controlTree'
import getRendererConfig from './getRendererConfig'
import snapshots from './snapshots'

declare global {
  interface Window {
    snapshots: ReturnType<typeof snapshots>
  }
}

const viewIdToCameraId = {
  web: 'web_cam',
  palm: 'palm_cam',
  back: 'shellBack_cam',
}

const onGeneratePreviews = (generatePreviews: GeneratePreviews) => {
  store.dispatch(
    // TODO: Fix TypeScript thinking that viewIds is any.
    setPreviewGenerator(async (viewIds: string[]) => {
      const state = store.getState()
      const nodes = controlTree.getNodes(state)

      const previews = {}

      for (const viewId of viewIds) {
        const rendererConfig = getRendererConfig(nodes)

        if (rendererConfig.models.glove?.meshes?.ground) {
          rendererConfig.models.glove.meshes.ground.isVisible = false
        }

        const cameraId = viewIdToCameraId[viewId]

        previews[viewId] = (
          await generatePreviews({
            cameraIds: [cameraId],
            rendererConfig,
          })
        )[cameraId]
      }

      return previews
    }),
  )

  window.snapshots = snapshots(generatePreviews)
}

export default onGeneratePreviews
