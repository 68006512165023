// DO NOT EDIT, GENERATED BY "yarn sheets"

import { t } from '../../../../platform/i18n'

import type { LeatherRow } from '../typings';

const data = [
  {
    "id": "rev1x",
    "props": {
      "sku": "REV",
      "isSkuPartOfGloveSku": true,
      "forceFullHeel": false
    },
    "limitations": {
      "weltingTypes": {
        "all": false,
        "palm": true,
        "back": true
      },
      "hsw": false,
      "hasPatches": false,
      "hasLeatherOnBack": false
    },
    "name": t('rawlingsGloves:sheets.leathers.rev1x.name'),
    "description": t('rawlingsGloves:sheets.leathers.rev1x.description')
  },
  {
    "id": "kip",
    "props": {
      "sku": "PROS",
      "isSkuPartOfGloveSku": false,
      "forceFullHeel": false
    },
    "limitations": {
      "weltingTypes": {
        "all": false,
        "palm": true,
        "back": true
      },
      "hsw": true,
      "hasPatches": true,
      "hasLeatherOnBack": true
    },
    "name": t('rawlingsGloves:sheets.leathers.kip.name'),
    "description": t('rawlingsGloves:sheets.leathers.kip.description')
  },
  {
    "id": "hoh",
    "props": {
      "sku": "PRO",
      "isSkuPartOfGloveSku": false,
      "forceFullHeel": false
    },
    "limitations": {
      "weltingTypes": {
        "all": false,
        "palm": true,
        "back": true
      },
      "hsw": true,
      "hasPatches": true,
      "hasLeatherOnBack": true
    },
    "name": t('rawlingsGloves:sheets.leathers.hoh.name'),
    "description": t('rawlingsGloves:sheets.leathers.hoh.description')
  },
  {
    "id": "gxle",
    "props": {
      "sku": "G",
      "isSkuPartOfGloveSku": false,
      "forceFullHeel": true
    },
    "limitations": {
      "weltingTypes": {
        "all": true,
        "palm": false,
        "back": false
      },
      "hsw": false,
      "hasPatches": true,
      "hasLeatherOnBack": true
    },
    "name": t('rawlingsGloves:sheets.leathers.gxle.name'),
    "description": t('rawlingsGloves:sheets.leathers.gxle.description')
  },
  {
    "id": "rla",
    "props": {
      "sku": "RLA",
      "isSkuPartOfGloveSku": false,
      "forceFullHeel": true
    },
    "limitations": {
      "weltingTypes": {
        "all": true,
        "palm": false,
        "back": false
      },
      "hsw": false,
      "hasPatches": true,
      "hasLeatherOnBack": true
    },
    "name": t('rawlingsGloves:sheets.leathers.rla.name'),
    "description": t('rawlingsGloves:sheets.leathers.rla.description')
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<LeatherRow[]>> = data;

export type Leather = typeof typed[number];

export const LEATHER_INDEX_KEY = "id";
export type LeatherIndexKey = "id";
export type LeatherId = Leather["id"];

let i = 0;
export const LEATHER_DICT = {
  "rev1x": typed[i++],
  "kip": typed[i++],
  "hoh": typed[i++],
  "gxle": typed[i++],
  "rla": typed[i++]
} as const;

export { typed as LEATHERS };
