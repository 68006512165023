import { t } from '../../platform/i18n'

const defaultViewAngleId = 'web'

const viewAngles = {
  web: {
    label: t('rawlingsGloves:webView'),
    exposeForUI: true,
    exposeForRecipePreviewImages: true,
    previewSize: {
      height: 1024,
    },
  },
  palm: {
    label: t('rawlingsGloves:palmView'),
    exposeForUI: true,
    exposeForRecipePreviewImages: true,
    previewSize: {
      height: 1024,
    },
  },
  back: {
    label: t('rawlingsGloves:backView'),
    exposeForUI: true,
    exposeForRecipePreviewImages: true,
    previewSize: {
      height: 1024,
    },
  },
}

export default viewAngles
export { defaultViewAngleId }
