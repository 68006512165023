import type { AbstractMesh } from '@babylonjs/core';
import { ok } from '@orangelv/utils';

import { getState } from './state.js';
import type { Ref, State } from './types.js';

const getModelIdByMesh =
  (stateRef: Ref<State>) =>
  (targetMesh: AbstractMesh): string => {
    const { models } = getState(stateRef);

    for (const [modelId, modelState] of Object.entries(models)) {
      for (const mesh of modelState.allMeshes) {
        if (targetMesh.id === mesh.id) return modelId;
      }
    }

    ok(false, 'Could not find modelId by mesh!');
  };

export default getModelIdByMesh;
