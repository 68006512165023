// DO NOT EDIT, GENERATED BY "yarn sheets"

import { t } from '../../../../platform/i18n'

import type { FlagRow } from '../typings';

const data = [
  {
    "id": "unitedStatesOfAmerica",
    "props": {
      "abbr": "US"
    },
    "name": t('rawlingsGloves:sheets.flags.unitedStatesOfAmerica.name')
  },
  {
    "id": "alabama",
    "props": {
      "abbr": "AL"
    },
    "name": t('rawlingsGloves:sheets.flags.alabama.name')
  },
  {
    "id": "arizona",
    "props": {
      "abbr": "AZ"
    },
    "name": t('rawlingsGloves:sheets.flags.arizona.name')
  },
  {
    "id": "arkansas",
    "props": {
      "abbr": "AR"
    },
    "name": t('rawlingsGloves:sheets.flags.arkansas.name')
  },
  {
    "id": "aruba",
    "props": {
      "abbr": "AW"
    },
    "name": t('rawlingsGloves:sheets.flags.aruba.name')
  },
  {
    "id": "australia",
    "props": {
      "abbr": "AU"
    },
    "name": t('rawlingsGloves:sheets.flags.australia.name')
  },
  {
    "id": "belgium",
    "props": {
      "abbr": "BE"
    },
    "name": t('rawlingsGloves:sheets.flags.belgium.name')
  },
  {
    "id": "california",
    "props": {
      "abbr": "CA"
    },
    "name": t('rawlingsGloves:sheets.flags.california.name')
  },
  {
    "id": "canada",
    "props": {
      "abbr": "CN"
    },
    "name": t('rawlingsGloves:sheets.flags.canada.name')
  },
  {
    "id": "colombia",
    "props": {
      "abbr": "CO"
    },
    "name": t('rawlingsGloves:sheets.flags.colombia.name')
  },
  {
    "id": "colorado",
    "props": {
      "abbr": "CO"
    },
    "name": t('rawlingsGloves:sheets.flags.colorado.name')
  },
  {
    "id": "cuba",
    "props": {
      "abbr": "CU"
    },
    "name": t('rawlingsGloves:sheets.flags.cuba.name')
  },
  {
    "id": "curacao",
    "props": {
      "abbr": "CW"
    },
    "name": t('rawlingsGloves:sheets.flags.curacao.name')
  },
  {
    "id": "delaware",
    "props": {
      "abbr": "DE"
    },
    "name": t('rawlingsGloves:sheets.flags.delaware.name')
  },
  {
    "id": "denmark",
    "props": {
      "abbr": "DK"
    },
    "name": t('rawlingsGloves:sheets.flags.denmark.name')
  },
  {
    "id": "dominicanRepublic",
    "props": {
      "abbr": "DO"
    },
    "name": t('rawlingsGloves:sheets.flags.dominicanRepublic.name')
  },
  {
    "id": "dutch",
    "props": {
      "abbr": "DU"
    },
    "name": t('rawlingsGloves:sheets.flags.dutch.name')
  },
  {
    "id": "elSalvador",
    "props": {
      "abbr": "SV"
    },
    "name": t('rawlingsGloves:sheets.flags.elSalvador.name')
  },
  {
    "id": "france",
    "props": {
      "abbr": "FR"
    },
    "name": t('rawlingsGloves:sheets.flags.france.name')
  },
  {
    "id": "germany",
    "props": {
      "abbr": "DE"
    },
    "name": t('rawlingsGloves:sheets.flags.germany.name')
  },
  {
    "id": "greece",
    "props": {
      "abbr": "GR"
    },
    "name": t('rawlingsGloves:sheets.flags.greece.name')
  },
  {
    "id": "hawaii",
    "props": {
      "abbr": "HI"
    },
    "name": t('rawlingsGloves:sheets.flags.hawaii.name')
  },
  {
    "id": "holland",
    "props": {
      "abbr": "HL"
    },
    "name": t('rawlingsGloves:sheets.flags.holland.name')
  },
  {
    "id": "honduras",
    "props": {
      "abbr": "HN"
    },
    "name": t('rawlingsGloves:sheets.flags.honduras.name')
  },
  {
    "id": "india",
    "props": {
      "abbr": "IN"
    },
    "name": t('rawlingsGloves:sheets.flags.india.name')
  },
  {
    "id": "ireland",
    "props": {
      "abbr": "IE"
    },
    "name": t('rawlingsGloves:sheets.flags.ireland.name')
  },
  {
    "id": "israel",
    "props": {
      "abbr": "IL"
    },
    "name": t('rawlingsGloves:sheets.flags.israel.name')
  },
  {
    "id": "italy",
    "props": {
      "abbr": "IT"
    },
    "name": t('rawlingsGloves:sheets.flags.italy.name')
  },
  {
    "id": "jamaica",
    "props": {
      "abbr": "JM"
    },
    "name": t('rawlingsGloves:sheets.flags.jamaica.name')
  },
  {
    "id": "japan",
    "props": {
      "abbr": "JP"
    },
    "name": t('rawlingsGloves:sheets.flags.japan.name')
  },
  {
    "id": "kentucky",
    "props": {
      "abbr": "KY"
    },
    "name": t('rawlingsGloves:sheets.flags.kentucky.name')
  },
  {
    "id": "louisiana",
    "props": {
      "abbr": "LA"
    },
    "name": t('rawlingsGloves:sheets.flags.louisiana.name')
  },
  {
    "id": "massachusetts",
    "props": {
      "abbr": "MA"
    },
    "name": t('rawlingsGloves:sheets.flags.massachusetts.name')
  },
  {
    "id": "mexico",
    "props": {
      "abbr": "MX"
    },
    "name": t('rawlingsGloves:sheets.flags.mexico.name')
  },
  {
    "id": "michigan",
    "props": {
      "abbr": "MI"
    },
    "name": t('rawlingsGloves:sheets.flags.michigan.name')
  },
  {
    "id": "netherlands",
    "props": {
      "abbr": "NL"
    },
    "name": t('rawlingsGloves:sheets.flags.netherlands.name')
  },
  {
    "id": "newZealand",
    "props": {
      "abbr": "NZ"
    },
    "name": t('rawlingsGloves:sheets.flags.newZealand.name')
  },
  {
    "id": "nicaragua",
    "props": {
      "abbr": "NI"
    },
    "name": t('rawlingsGloves:sheets.flags.nicaragua.name')
  },
  {
    "id": "northCarolina",
    "props": {
      "abbr": "NC"
    },
    "name": t('rawlingsGloves:sheets.flags.northCarolina.name')
  },
  {
    "id": "ohio",
    "props": {
      "abbr": "OH"
    },
    "name": t('rawlingsGloves:sheets.flags.ohio.name')
  },
  {
    "id": "oklahoma",
    "props": {
      "abbr": "OK"
    },
    "name": t('rawlingsGloves:sheets.flags.oklahoma.name')
  },
  {
    "id": "oregon",
    "props": {
      "abbr": "OR"
    },
    "name": t('rawlingsGloves:sheets.flags.oregon.name')
  },
  {
    "id": "panama",
    "props": {
      "abbr": "PA"
    },
    "name": t('rawlingsGloves:sheets.flags.panama.name')
  },
  {
    "id": "philippines",
    "props": {
      "abbr": "PH"
    },
    "name": t('rawlingsGloves:sheets.flags.philippines.name')
  },
  {
    "id": "poland",
    "props": {
      "abbr": "PL"
    },
    "name": t('rawlingsGloves:sheets.flags.poland.name')
  },
  {
    "id": "portugal",
    "props": {
      "abbr": "PT"
    },
    "name": t('rawlingsGloves:sheets.flags.portugal.name')
  },
  {
    "id": "puertoRico",
    "props": {
      "abbr": "PR"
    },
    "name": t('rawlingsGloves:sheets.flags.puertoRico.name')
  },
  {
    "id": "quebec",
    "props": {
      "abbr": "QC"
    },
    "name": t('rawlingsGloves:sheets.flags.quebec.name')
  },
  {
    "id": "romania",
    "props": {
      "abbr": "RO"
    },
    "name": t('rawlingsGloves:sheets.flags.romania.name')
  },
  {
    "id": "scotland",
    "props": {
      "abbr": "SG"
    },
    "name": t('rawlingsGloves:sheets.flags.scotland.name')
  },
  {
    "id": "southCarolina",
    "props": {
      "abbr": "SC"
    },
    "name": t('rawlingsGloves:sheets.flags.southCarolina.name')
  },
  {
    "id": "southDakota",
    "props": {
      "abbr": "SD"
    },
    "name": t('rawlingsGloves:sheets.flags.southDakota.name')
  },
  {
    "id": "southKorea",
    "props": {
      "abbr": "KR"
    },
    "name": t('rawlingsGloves:sheets.flags.southKorea.name')
  },
  {
    "id": "spain",
    "props": {
      "abbr": "ES"
    },
    "name": t('rawlingsGloves:sheets.flags.spain.name')
  },
  {
    "id": "sweden",
    "props": {
      "abbr": "SE"
    },
    "name": t('rawlingsGloves:sheets.flags.sweden.name')
  },
  {
    "id": "switzerland",
    "props": {
      "abbr": "CH"
    },
    "name": t('rawlingsGloves:sheets.flags.switzerland.name')
  },
  {
    "id": "taiwan",
    "props": {
      "abbr": "TW"
    },
    "name": t('rawlingsGloves:sheets.flags.taiwan.name')
  },
  {
    "id": "tennessee",
    "props": {
      "abbr": "TN"
    },
    "name": t('rawlingsGloves:sheets.flags.tennessee.name')
  },
  {
    "id": "texas",
    "props": {
      "abbr": "TX"
    },
    "name": t('rawlingsGloves:sheets.flags.texas.name')
  },
  {
    "id": "utah",
    "props": {
      "abbr": "UT"
    },
    "name": t('rawlingsGloves:sheets.flags.utah.name')
  },
  {
    "id": "venezuela",
    "props": {
      "abbr": "VE"
    },
    "name": t('rawlingsGloves:sheets.flags.venezuela.name')
  },
  {
    "id": "virginIslands",
    "props": {
      "abbr": "VI"
    },
    "name": t('rawlingsGloves:sheets.flags.virginIslands.name')
  },
  {
    "id": "westVirginia",
    "props": {
      "abbr": "WV"
    },
    "name": t('rawlingsGloves:sheets.flags.westVirginia.name')
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<FlagRow[]>> = data;

export type Flag = typeof typed[number];

export const FLAG_INDEX_KEY = "id";
export type FlagIndexKey = "id";
export type FlagId = Flag["id"];

let i = 0;
export const FLAG_DICT = {
  "unitedStatesOfAmerica": typed[i++],
  "alabama": typed[i++],
  "arizona": typed[i++],
  "arkansas": typed[i++],
  "aruba": typed[i++],
  "australia": typed[i++],
  "belgium": typed[i++],
  "california": typed[i++],
  "canada": typed[i++],
  "colombia": typed[i++],
  "colorado": typed[i++],
  "cuba": typed[i++],
  "curacao": typed[i++],
  "delaware": typed[i++],
  "denmark": typed[i++],
  "dominicanRepublic": typed[i++],
  "dutch": typed[i++],
  "elSalvador": typed[i++],
  "france": typed[i++],
  "germany": typed[i++],
  "greece": typed[i++],
  "hawaii": typed[i++],
  "holland": typed[i++],
  "honduras": typed[i++],
  "india": typed[i++],
  "ireland": typed[i++],
  "israel": typed[i++],
  "italy": typed[i++],
  "jamaica": typed[i++],
  "japan": typed[i++],
  "kentucky": typed[i++],
  "louisiana": typed[i++],
  "massachusetts": typed[i++],
  "mexico": typed[i++],
  "michigan": typed[i++],
  "netherlands": typed[i++],
  "newZealand": typed[i++],
  "nicaragua": typed[i++],
  "northCarolina": typed[i++],
  "ohio": typed[i++],
  "oklahoma": typed[i++],
  "oregon": typed[i++],
  "panama": typed[i++],
  "philippines": typed[i++],
  "poland": typed[i++],
  "portugal": typed[i++],
  "puertoRico": typed[i++],
  "quebec": typed[i++],
  "romania": typed[i++],
  "scotland": typed[i++],
  "southCarolina": typed[i++],
  "southDakota": typed[i++],
  "southKorea": typed[i++],
  "spain": typed[i++],
  "sweden": typed[i++],
  "switzerland": typed[i++],
  "taiwan": typed[i++],
  "tennessee": typed[i++],
  "texas": typed[i++],
  "utah": typed[i++],
  "venezuela": typed[i++],
  "virginIslands": typed[i++],
  "westVirginia": typed[i++]
} as const;

export { typed as FLAGS };
