import type { Props, Ref, State } from '../types.js';
import getFabricMaterialIdsByModelId from './get-fabric-material-ids-by-model-id.js';

const getFabricCanvasIds =
  (stateRef: Ref<State>, props: Props) => (): string[] => {
    const { config } = props;

    const fabricMaterialIdsByModelId = getFabricMaterialIdsByModelId(
      stateRef,
      props,
    )();

    return Object.entries(config.models).flatMap(([modelId, _modelConfig]) => {
      const materialIds = fabricMaterialIdsByModelId[modelId];
      if (materialIds === undefined) {
        return [];
      }

      return materialIds.map((materialId) => `${modelId}.${materialId}`);
    });
  };

export default getFabricCanvasIds;
