import { useState } from 'react'

import { unrealRender } from '../../../../platform/client/unreal-render'

import Button from './Button'

import './UnrealRenderForm.css'

const SCENES = [
  'Graphical_01',
  'Graphical_02',
  'Graphical_03',
  'Studio_01',
  'Studio_02',
  'Stadium',
  'LockerRoom',
]

type Props = {
  hasUnrealRender: boolean
  recipeId: string
}

const UnrealRenderForm = (props: Props) => {
  const [emails, setEmails] = useState<string[]>(['customizers@olv.global'])

  const [scenes, setScenes] = useState([
    {
      scene: SCENES[0],
      animate: false,
    },
  ])

  if (!props.hasUnrealRender) {
    return undefined
  }

  return (
    <div className="UnrealRenderForm">
      <div>
        <div>
          Emails{' '}
          <a
            href="#"
            onClick={(ev) => {
              ev.preventDefault()
              setEmails((prevEmails) => prevEmails.concat(''))
            }}
          >
            [+]
          </a>
        </div>
        {emails.map((email, emailIndex) => (
          // eslint-disable-next-line @eslint-react/no-array-index-key
          <div key={emailIndex}>
            <input
              type="email"
              value={email}
              onChange={(ev) => {
                setEmails((prevEmails) => {
                  const nextEmails = [...prevEmails]
                  nextEmails[emailIndex] = ev.target.value
                  return nextEmails
                })
              }}
            />

            {emails.length > 1 && (
              <a
                href="#"
                onClick={(ev) => {
                  ev.preventDefault()

                  setEmails((prevEmails) =>
                    prevEmails.filter((_email, index) => index !== emailIndex),
                  )
                }}
              >
                [-]
              </a>
            )}
          </div>
        ))}
      </div>

      <div>
        <div>
          Scenes{' '}
          <a
            href="#"
            onClick={(ev) => {
              ev.preventDefault()

              setScenes((prevScenes) =>
                prevScenes.concat({
                  scene: SCENES[0],
                  animate: false,
                }),
              )
            }}
          >
            [+]
          </a>
        </div>
        {scenes.map((scene, sceneIndex) => (
          // eslint-disable-next-line @eslint-react/no-array-index-key
          <div key={sceneIndex}>
            <select
              value={scene.scene}
              onChange={(ev) => {
                setScenes((prevScenes) =>
                  prevScenes.map((scene, index) =>
                    sceneIndex === index ?
                      { ...scene, scene: ev.target.value }
                    : scene,
                  ),
                )
              }}
            >
              {SCENES.map((scene) => (
                <option key={scene} value={scene}>
                  {scene}
                </option>
              ))}
            </select>

            {scenes.length > 1 && (
              <a
                href="#"
                onClick={(ev) => {
                  ev.preventDefault()

                  setScenes((prevScenes) =>
                    prevScenes.filter((_scene, index) => index !== sceneIndex),
                  )
                }}
              >
                [-]
              </a>
            )}

            <div>
              <label>
                <input
                  type="checkbox"
                  checked={scene.animate}
                  onChange={() => {
                    setScenes((prevScenes) =>
                      prevScenes.map((scene, index) =>
                        sceneIndex === index ?
                          { ...scene, animate: !scene.animate }
                        : scene,
                      ),
                    )
                  }}
                />
                Animate
              </label>
            </div>
          </div>
        ))}
      </div>

      <div className="button-wrapper">
        <Button
          classMods={['hasIcon', 'gray']}
          onClick={async () => {
            const { error } = await unrealRender(props.recipeId, {
              emailTo: emails.join(', '),
              scenes,
            })

            /* eslint-disable no-alert */
            if (error) {
              alert('There was an error with unreal render!')
            } else {
              alert('Render with unreal initiated...')
            }
            /* eslint-enable no-alert */
          }}
        >
          Unreal render
        </Button>
      </div>
    </div>
  )
}

export default UnrealRenderForm
