import { Vector3 } from '@babylonjs/core';
import { ok } from '@orangelv/utils';

import { getState } from './state.js';
import type {
  Position,
  PositionAbsolute,
  PositionRelative,
  Ref,
  State,
} from './types.js';

const isPositionAbsolute = (position: Position): position is PositionAbsolute =>
  'x' in position || 'y' in position || 'z' in position;

const isPositionRelative = (position: Position): position is PositionRelative =>
  'below' in position;

const updatePositions = (stateRef: Ref<State>) => (): void => {
  const state = getState(stateRef);

  const { config } = state.props;

  for (const [modelId, modelConfig] of Object.entries(config.models)) {
    if (!modelConfig) {
      continue;
    }

    const { defaultPosition } = modelConfig;

    if (!defaultPosition) {
      continue;
    }

    const model = state.models[modelId];

    ok(model);

    const { rootMesh } = model;

    if (isPositionAbsolute(defaultPosition)) {
      rootMesh.position = new Vector3(
        defaultPosition.x ?? 0,
        defaultPosition.y ?? 0,
        defaultPosition.z ?? 0,
      );
    } else if (isPositionRelative(defaultPosition)) {
      const otherModel = state.models[defaultPosition.below];

      ok(otherModel, 'Model for relative positioning was not found!');

      const otherModelRootMesh = otherModel.rootMesh;

      const {
        max: { y: y1 },
      } = rootMesh.getHierarchyBoundingVectors();
      const {
        min: { y: y2 },
      } = otherModelRootMesh.getHierarchyBoundingVectors();
      const { y: y3 } = rootMesh.position;

      const x = 0;
      const y = (y1 - y2 - y3 + (defaultPosition.offset ?? 0)) * -1;
      const z = 0;

      rootMesh.position = new Vector3(x, y, z);
    }
  }
};

export default updatePositions;
