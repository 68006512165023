import updateCameraFovMode from './camera/update-camera-fov-mode.js';
import updateCameraProjectionMatrix from './camera/update-camera-projection-matrix.js';
import { getState } from './state.js';
import type { Props, Ref, State } from './types.js';

const resizeScene =
  (stateRef: Ref<State>, isHidden: Props['isHidden']) => (): void => {
    // https://forum.babylonjs.com/t/many-performance-issues-with-4-2-and-ios-mobile/15098/37
    if (isHidden) {
      return;
    }

    const { scene } = getState(stateRef);

    if (!scene) {
      // This can get called before the scene is created. If so, do nothing.
      return;
    }

    const engine = scene.getEngine();

    engine.resize();

    updateCameraFovMode(stateRef)();
    updateCameraProjectionMatrix(stateRef);

    const { isDigesting, isReady } = getState(stateRef);

    if (!isDigesting && isReady) {
      scene.render();
    }
  };

export default resizeScene;
