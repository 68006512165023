import FileSaver from 'file-saver'
import JSZip from 'jszip'

import { GeneratePreviews } from '@orangelv/bjs-renderer'

import store from '../../client/store'
import controlTree from '../../client/controlTree'
import getRendererConfig from '../getRendererConfig'
import { GLOVE_MODEL_DICT, Glove } from '../../common/sheets'

const getFirstAvailableId = (record: Record<string, boolean>) => {
  for (const [id, isAvailable] of Object.entries(record)) {
    if (isAvailable) {
      return id
    }
  }

  return null
}

type SnapshotterConfig = {
  generatePreviews: GeneratePreviews
  name: string
  snapshots: {
    glove: Glove
    values: Record<string, any>
  }[]
  cameraId?: string
}

const snapshotter = async ({
  name,
  generatePreviews,
  snapshots,
  cameraId = 'web_cam',
}: SnapshotterConfig) => {
  const state = store.getState()

  const zip = new JSZip()

  for (const [index, snapshot] of snapshots.entries()) {
    const { glove, values } = snapshot

    const gloveModel = GLOVE_MODEL_DICT[glove.modelId]

    if (!gloveModel.snapshots) {
      continue
    }

    console.log(
      `Generating snapshot for ${glove.id} (${gloveModel.id}) [${index + 1}/${snapshots.length}]`,
    )

    const rendererConfig = getRendererConfig(
      controlTree.getNodes({
        ...state,
        controlTree: {
          pendingChanges: { user: {}, auto: {} },
          preferredValues: {},
          repeatedNodes: {},
          values: {
            // 'product.glove': '125SB',
            'product.glove': glove.id,
            'product.leather': getFirstAvailableId(glove.limitations.leatherId),
            'colors.web.style': 'TwoPS',
            // Enable this when all gloves are done
            // 'colors.web.style': getFirstAvailableId(glove.limitations.webIds),
            ...values,
          },
        },
      }),
    )

    if (rendererConfig.models.glove?.meshes?.ground) {
      rendererConfig.models.glove.meshes.ground.isVisible = false
    }

    try {
      const preview = (
        await generatePreviews({
          rendererConfig,
          cameraIds: [cameraId],
        })
      )[cameraId]

      zip.file(`${glove.id}.png`, preview)
    } catch (e) {
      console.error(e)

      console.log(
        `Failed to generate snapshot for ${glove.id} (${gloveModel.id})`,
      )

      break
    }
  }

  FileSaver.saveAs(await zip.generateAsync({ type: 'blob' }), `${name}.zip`)
}

export { getFirstAvailableId, snapshotter }
