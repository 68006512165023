import React, { useRef } from 'react'
import { Provider, connect } from 'react-redux'
import WebFont from 'webfontloader'

import {
  createMenuSelector,
  layoutModeSelector,
} from '~p/client/common/selectors'
import { t } from '~p/i18n'
import Layout from '~rawlings/client/components/Layout'
import TextAndFontInput from '~rawlings/client/components/TextAndFontInput'

import controlTree from '../controlTree'

import {
  LEATHER_COLORS,
  LEATHER_DICT,
  PRICING_SCHEME_DICT,
  VENDOR_DICT,
} from '../../common/sheets'
import Renderer from '../containers/Renderer'
import Renderer3d from '../components/Renderer3d'
import getCameraFromMenu from '../../renderer/getCameraFromMenu'

import '~rawlings/client/components/Main.css'

WebFont.load({
  google: {
    families: ['Open Sans:300,400,600,700'],
  },
})

const customComponents = {
  TextAndFontInput,
}

const getProductInfo = (nodes, recipeId) => ({
  summarySections: [],
  title: nodes['calc.skuLabel'].value,
  priceWithCurrency: nodes['calc.priceWithCurrency'].value,
  configurationId: recipeId,
})

const menuSelector = createMenuSelector(controlTree)

const mapStateToProps = (state) => ({
  menu: menuSelector(state),
  layoutMode: layoutModeSelector(state),
})

const mapDispatchToProps = (dispatch) => ({
  change: (path, value) => dispatch(controlTree.change(path, value)),
  commitChanges: () => dispatch(controlTree.commitChanges()),
})

const { hostname } = window.serverConfig
const vendor = VENDOR_DICT[hostname]

const getTileInfo = (menu, nodes, option) => {
  if (menu === 'filter.productSelector.productSelector.leather') {
    const currencyNode = nodes['env.currency']

    const leather = LEATHER_DICT[option.id]

    const shellColors = LEATHER_COLORS.filter(
      (color) => color.subsets[leather.id].shell,
    )

    const currency = currencyNode.value.props.prefix
    const price =
      PRICING_SCHEME_DICT.regular.props.price[currencyNode.value.id][leather.id]

    return [
      [
        t('rawlingsGloves:wizardLeatherShellLeather'),
        t('rawlingsGloves:wizardLeatherShellLeatherCount', {
          count: shellColors.length,
        }),
      ],
      [t('rawlingsGloves:wizardLeatherPrice'), `${currency}${price}`],
    ]
  }

  return undefined
}

const ConnectedLayout = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props) => {
  const setCameraRef = useRef()

  const { is3d } = vendor.features

  const defaultCamera = getCameraFromMenu(props.menu)

  return (
    <Layout
      Renderer={
        is3d ?
          <Renderer3d
            onSetCamera={(setCamera) => {
              setCameraRef.current = setCamera
            }}
            setCameraRef={setCameraRef}
            defaultCamera={defaultCamera}
          />
        : <Renderer layoutMode={props.layoutMode} />
      }
      getProductInfo={getProductInfo}
      customComponents={customComponents}
      onChangePost={(path, value) => {
        const LOGO_PATH = 'personalization.embroidery.logo'
        const NUMBER_PATH = 'personalization.embroidery.number.text'
        const PINKY_PALM_TEXT_PATH = 'personalization.embroidery.pinkyPalm.text'

        props.commitChanges()

        if (path === LOGO_PATH) {
          // Reset number when logo is set.
          props.change(NUMBER_PATH, '')
        } else if (path === NUMBER_PATH) {
          // Reset logo when number is set.
          props.change(LOGO_PATH, null)
        }

        if (path === PINKY_PALM_TEXT_PATH) {
          // Force text to uppercase.
          props.change(PINKY_PALM_TEXT_PATH, value.toUpperCase())
        }
      }}
      onOpenMenuPost={(menu) => {
        const setCamera = setCameraRef.current

        if (!setCamera) {
          return
        }

        const camera = getCameraFromMenu(menu)

        if (!camera) {
          return
        }

        setCamera(camera, 'glove')
      }}
      countDown={{
        message: t('_rawlings:holidayOrderDeadline'),
        deadline: '2022-11-09 00:00-05:00',
      }}
      getTileInfo={getTileInfo}
      isScrollMenuLayout={props.layoutMode !== 'mobile'}
    />
  )
})

const Root = ({ store }) => (
  <Provider store={store}>
    <ConnectedLayout />
  </Provider>
)

export default Root
