import wildcard from 'wildcard'

import { Nodes } from '../common/typings'

const MESH_MATCHER_TO_MENU: [string, string | ((nodes: Nodes) => string)][] = [
  ['binding', 'colors.binding'],
  ['patch', 'colors.logoPatch'],
  ['embroideryOvalR_*', 'colors.logoPatch'],
  ['*_embroideryOvalR', 'colors.logoPatch'],
  ['shellBack_*', 'colors.shellBack.leatherColor'],
  ['*_back_*', 'colors.shellBack.leatherColor'],
  ['*_palm', 'colors.shellPalm'],
  ['fingerLaces_*', 'colors.laces.fingerWeb'],
  ['*_fingerLaces_*', 'colors.laces.fingerWeb'],
  ['heelLaces_*', 'colors.laces.heel'],
  ['stamping_*', 'colors.stamping'],
  ['stitching', 'colors.stitching'],
  ['*_stitching', 'colors.stitching'],
  ['*_base_*', 'colors.web.color'],
  ['trim*', 'colors.trim.color'],
  ['embroidery', 'personalization.embroidery.color'],
  ['weltingPalm', 'colors.weltingNested.palm'],
  ['weltingBack', 'colors.weltingNested.back'],
  ['*FingerPad', 'options.fingerPadHood.fingerPad'],
  ['*FingerHood', 'options.fingerPadHood.fingerHood'],
  ['sweatband', 'options.sweatband'],
  [
    'embroideryLogo',
    (nodes) =>
      nodes['personalization.flag'].value ?
        'personalization.flag'
      : 'personalization.embroidery.color',
  ],
]

const getMenuFromMeshId = (meshId: string, nodes: Nodes) => {
  const match = MESH_MATCHER_TO_MENU.find(([matcher]) =>
    wildcard(matcher, meshId),
  )

  if (!match) {
    return
  }

  const [, menuIdOrFn] = match

  const menuId =
    typeof menuIdOrFn === 'function' ? menuIdOrFn(nodes) : menuIdOrFn

  return menuId
}

export default getMenuFromMeshId
