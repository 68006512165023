import { type ArcRotateCamera, Vector2, type Vector3 } from '@babylonjs/core';

const getFrustumSlope = (camera: ArcRotateCamera): Vector2 => {
  const engine = camera.getScene().getEngine();
  const aspectRatio = engine.getAspectRatio(camera);

  // Camera FOV is the vertical field of view (top-bottom) in radians.
  // Slope of the frustum top/bottom planes in view space, relative to the forward vector.
  const frustumSlopeY = Math.tan(camera.fov / 2);

  // Slope of the frustum left/right planes in view space, relative to the forward vector.
  // Provides the amount that one side (e.g. left) of the frustum gets wider for every unit
  // along the forward vector.
  const frustumSlopeX = frustumSlopeY * aspectRatio;

  return new Vector2(frustumSlopeX, frustumSlopeY);
};

const calculateOptimalCameraRadius = (
  minWorld: Vector3,
  maxWorld: Vector3,
  camera: ArcRotateCamera,
): number => {
  const size = maxWorld.subtract(minWorld);
  const boxVectorGlobalDiagonal = size.length();
  const frustumSlope: Vector2 = getFrustumSlope(camera);

  // Formula for setting distance
  // (Good explanation: http://stackoverflow.com/questions/2866350/move-camera-to-fit-3d-scene)
  const radius = boxVectorGlobalDiagonal * 0.5;

  // Horizon distance
  const distanceForHorizontalFrustum =
    radius * Math.sqrt(1 + 1 / (frustumSlope.x * frustumSlope.x));
  const distanceForVerticalFrustum =
    radius * Math.sqrt(1 + 1 / (frustumSlope.y * frustumSlope.y));
  return Math.max(distanceForHorizontalFrustum, distanceForVerticalFrustum);
};

export default calculateOptimalCameraRadius;
