import type { DynamicTexture } from '@babylonjs/core';

import type { MaterialPropertyCanvas } from '../types.js';

const renderCanvas = async (
  propertyCanvas: MaterialPropertyCanvas,
  dynamicTexture: DynamicTexture,
): Promise<void> => {
  const { getCanvas } = propertyCanvas;

  const context = dynamicTexture.getContext();
  const { canvas } = context;

  await getCanvas(canvas as HTMLCanvasElement, true);

  context.drawImage(canvas, 0, 0);

  dynamicTexture.update(false);
};

export default renderCanvas;
