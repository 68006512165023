import {
  DirectionalLight,
  PointLight,
  Vector3,
  ArcRotateCamera,
  Matrix,
  Tools,
} from '@babylonjs/core'

import { clamp } from '@orangelv/utils'
import { State } from '@orangelv/bjs-renderer'

import assert from '../../../platform/assert'

const remapValue = (x: number, a: number, b: number, c: number, d: number) => {
  return c + ((x - a) * (d - c)) / (b - a)
}

const onAfterSceneCreated = (state: State) => {
  const { scene } = state

  assert(scene)

  // Vectors are irrelevant as they are going to be dinamically changed.
  const light1 = new DirectionalLight(
    'DirectionalLight1',
    new Vector3(0, 0, 1),
    scene,
  )
  const light2 = new DirectionalLight(
    'DirectionalLight2',
    new Vector3(-1, 0, 1),
    scene,
  )
  const light3 = new PointLight('PointLight1', new Vector3(-1, 0, 1), scene)
  light1.intensity = 1.5
  light1.shadowEnabled = false

  light2.intensity = 1.5
  light2.shadowEnabled = false

  light3.intensity = 0.1 // Will be dynamic later.
  light3.shadowEnabled = false

  scene.onBeforeRenderObservable.add(() => {
    const camera = scene.activeCamera as ArcRotateCamera

    // Normalized camera location vector.
    const camLocVector = camera.position.subtract(camera.target).normalize()

    light1.direction = Vector3.TransformCoordinates(
      camLocVector,
      Matrix.RotationY(Tools.ToRadians(60)),
    )
    light2.direction = Vector3.TransformCoordinates(
      camLocVector,
      Matrix.RotationY(Tools.ToRadians(-60)),
    )

    // Express camera verticality as float.
    const light3VertDot = Vector3.Dot(camLocVector, new Vector3(0, -1, 0))
    // Remap verticality as required distance of point light.
    const pointDist = remapValue(light3VertDot, -1, 1, 0.333, 0.2)
    light3.position = camLocVector.multiply(
      new Vector3(pointDist, pointDist, pointDist),
    )

    // Set light intensity based on view angle.
    light3.intensity = remapValue(light3VertDot, -1, 1, 0.01, 0.05)

    const groundMesh = state.models.glove.allMeshes.find(
      (x) => x.id === 'ground',
    )

    if (groundMesh && groundMesh.material) {
      // Nullify X and Z coordinates because we are only here concerned with vertical distance of item.
      const camOnlyY = camera.position.multiply(new Vector3(0, 1, 0))
      const groundOnlyY = groundMesh.position.multiply(new Vector3(0, 1, 0))

      // Remap the distance between cam and floor to use scene units.
      const remapCamFloorY = remapValue(
        Vector3.Distance(camOnlyY, groundOnlyY),
        0.05,
        0.25,
        0,
        1,
      )

      // Set floor opacity to fade away when viewing more from the side.
      groundMesh.material.alpha = clamp(remapCamFloorY, 0, 1)
    }
  })
}

export default onAfterSceneCreated
