import type { DynamicTexture, Texture } from '@babylonjs/core';

import { canvasToBlob } from '../utils.js';

const updateUrl = async (texture: Texture, url: string): Promise<void> =>
  new Promise<void>((resolve) => {
    texture.updateURL(url, undefined, () => {
      resolve();
    });
  });

const updateTexture = async (
  dynamicTexture: DynamicTexture,
  originalTexture: Texture,
): Promise<void> => {
  const context = dynamicTexture.getContext();
  const blob = await canvasToBlob(context.canvas as HTMLCanvasElement);
  const url = URL.createObjectURL(blob);
  await updateUrl(originalTexture, url);
  URL.revokeObjectURL(url);
};

export default updateTexture;
