// DO NOT EDIT, GENERATED BY "yarn sheets"

import { t } from '../../../../platform/i18n'

import type { PalmPadRow } from '../typings';

const data = [
  {
    "id": "no",
    "subsets": {
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": false,
        "rla": true,
        "rev1x": true
      }
    },
    "name": t('rawlingsGloves:sheets.palmPads.no.name')
  },
  {
    "id": "leather",
    "subsets": {
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": true,
        "rla": true,
        "rev1x": true
      }
    },
    "name": t('rawlingsGloves:sheets.palmPads.leather.name')
  },
  {
    "id": "xrd",
    "subsets": {
      "leatherId": {
        "kip": false,
        "hoh": false,
        "gxle": false,
        "rla": false,
        "rev1x": false
      }
    },
    "name": t('rawlingsGloves:sheets.palmPads.xrd.name')
  },
  {
    "id": "poronxrd",
    "subsets": {
      "leatherId": {
        "kip": false,
        "hoh": false,
        "gxle": false,
        "rla": false,
        "rev1x": false
      }
    },
    "name": t('rawlingsGloves:sheets.palmPads.poronxrd.name')
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<PalmPadRow[]>> = data;

export type PalmPad = typeof typed[number];

export const PALM_PAD_INDEX_KEY = "id";
export type PalmPadIndexKey = "id";
export type PalmPadId = PalmPad["id"];

let i = 0;
export const PALM_PAD_DICT = {
  "no": typed[i++],
  "leather": typed[i++],
  "xrd": typed[i++],
  "poronxrd": typed[i++]
} as const;

export { typed as PALM_PADS };
