import fp from 'lodash/fp.js'

import { history, stringifySearch, parseSearch, replace } from './history'
import { navigated, configureVendorShoppingCart } from './common/actions'
import Router from './Router'

const KNOWN_QUERY_PARAM_KEYS = ['menu', 'changes', 'rosterPreview']
const PRESERVED_EXTRA_PARAM_KEYS = [
  'utm_source',
  'utm_campaign',
  'utm_medium',
  'utm_term',
  'wknd_item',
]

export default function configureRouter(controlTree, store, routes) {
  const router = new Router(routes)

  const onLocation = ({ location: loc, action }) => {
    const nextLoc = { ...loc }

    if (action === 'POP') {
      nextLoc.search = decodeURI(loc.search)
    }

    const path = nextLoc.pathname
    const matches = router.match(path)

    store.dispatch(navigated(controlTree, nextLoc, matches, action))

    return { path, matches }
  }

  const initialLocation = history.location
  const initialQuery = parseSearch(initialLocation.search, false)
  const knownParams = fp.pickBy(
    (_, k) => fp.includes(k, KNOWN_QUERY_PARAM_KEYS),
    initialQuery,
  )
  const extraParams = fp.pickBy(
    (_, k) => !fp.includes(k, KNOWN_QUERY_PARAM_KEYS),
    initialQuery,
  )
  const { path, matches } = onLocation({
    location: {
      ...initialLocation,
      search: stringifySearch(knownParams),
    },
    action: 'INITIAL',
  })

  const hasExtraParams = !fp.isEmpty(extraParams)
  if (hasExtraParams) {
    store.dispatch(configureVendorShoppingCart({ extraParams }))
    replace({
      path,
      query: {
        ...knownParams,
        ...Object.fromEntries(
          Object.entries(extraParams).map(([key, value]) =>
            PRESERVED_EXTRA_PARAM_KEYS.includes(key) ?
              [key, value]
            : [key, undefined],
          ),
        ),
      },
    })
  }

  // Listen for new locations.
  history.listen(onLocation)

  return {
    initialPath: path,
    initialMatches: matches,
  }
}
