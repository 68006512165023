import * as _ from '@technically/lodash'
import JSZip from 'jszip'
import FileSaver from 'file-saver'
import Bluebird from 'bluebird'

import {
  createCanvas,
  render,
} from '../../../../platform/client/renderComposite/canvas'
import Loader from '../../../../platform/client/renderComposite/Loader'
import assert from '../../../../platform/assert'

import * as renderComposite from '../../renderComposite'
import controlTree from '../controlTree'

export const viewNames = ['web', 'palm', 'back']

const getCanvasBlob = (canvas) =>
  new Promise((resolve, reject) => {
    try {
      canvas.toBlob((blob) => {
        resolve(blob)
      })
    } catch (error) {
      reject(error)
    }
  })

const saveSnapshotsAsZip = async (snapshots) => {
  const zip = new JSZip()
  const folder = zip.folder('snapshots')

  assert(folder !== null)

  _.forEach(snapshots, ({ blob, filename }) => {
    folder.file(`${filename}.png`, blob)
  })

  const content = await zip.generateAsync({ type: 'blob' })
  return FileSaver.saveAs(content, 'snapshots.zip')
}

const generateSnapshot = async (loader, viewName, size, layers, filename) => {
  const canvas = createCanvas(size).canvas
  const assets = await loader.load(layers)
  render(canvas, assets, size, viewName)
  const blob = await getCanvasBlob(canvas)
  const result = { blob, filename }
  return result
}

export type Job = {
  filename: string
  controlTreeChanges: [string, string][]
  viewName: string
  size: { width: number; height: number }
}

const generateSnapshots = async (jobs: Job[]) => {
  const state = {
    controlTree: {
      pendingChanges: { auto: {}, user: {} },
      preferredValues: {},
      repeatedNodes: {},
      values: {},
    },
  }

  let jobsDone = 0

  const loader = new Loader()

  const snapshots = await Bluebird.map(
    jobs,
    (job) => {
      const { filename, viewName, controlTreeChanges, size } = job
      const newState = _.reduce(
        controlTreeChanges,
        (localState, change) =>
          _.set(localState, ['controlTree', 'values', change[0]], change[1]),
        state,
      )

      const expandedRecipeNested = controlTree.getExpandedRecipeNested(newState)

      const layers = renderComposite.getLayers(
        expandedRecipeNested,
        size,
        viewName,
      )

      const promise = generateSnapshot(loader, viewName, size, layers, filename)

      jobsDone += 1

      console.log(`[${jobsDone}/${jobs.length}] ${filename}`)

      return promise
    },
    { concurrency: 8 },
  )

  return saveSnapshotsAsZip(snapshots)
}

export default generateSnapshots
