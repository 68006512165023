import React from 'react'
import cn from 'classnames'

import getAsset from '~p/getAsset'

import './Logo.css'

const Logo = (props) => {
  const { onClick, logoSrc, logoAlt } = props
  return (
    <div className="logo">
      <a
        className={cn('logo-link', { 'mod-clickable': onClick })}
        href="#"
        onClick={(ev) => {
          ev.preventDefault()

          if (onClick) {
            onClick()
          }
        }}
      >
        <img className="logo-image" alt={logoAlt} src={getAsset(logoSrc)} />
      </a>
    </div>
  )
}

export default Logo
