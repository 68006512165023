import React from 'react'

import { t } from '../../../../platform/i18n'
import { uploadsEnhancer } from '../../../../platform/client/component-enhancers'

import Button from './Button'
import Spinner from './Spinner'

import './FileInput.css'

class FileInput extends React.Component {
  onChange = (ev) => {
    if (this.props.isUploading) {
      return
    }

    const [file] = ev.target.files

    if (file) {
      this.props.onAcceptFile(file)
    }
  }

  render() {
    const {
      type = 'file',
      value,
      placeholder,
      onUpload,
      label,
      isUploading,
    } = this.props

    return (
      <div className="fileInput">
        <div className="fileInput-input">
          {value ?
            <div className="fileInput-value">{value}</div>
          : <div className="fileInput-placeholder">{placeholder}</div>}
          <div className="fileInput-button">
            <Button classMods={['gray']} onClick={onUpload}>
              {isUploading ?
                <Spinner classMods={['small', 'center']} />
              : t('_rawlings:upload')}
            </Button>
          </div>
          <input
            className="fileInput-ghost"
            type={type}
            onChange={this.onChange}
            placeholder={placeholder}
            disabled={isUploading}
          />
        </div>
        <div className="fileInput-label">{label}</div>
      </div>
    )
  }
}

export default uploadsEnhancer({ targetUrl: '/api/images' })(FileInput)
