import * as _ from '@technically/lodash'

import { GLOVE_ASSET_DICT, LEATHERS, WEBS } from '../../common/sheets'
import generateSnapshots, { Job } from './generate'
import controlTree from '../controlTree'
import { getSku } from '../../common/meta'

const VIEW_NAME = 'palm'
const HEIGHT = 512

const main = async (filter) => {
  const state = {
    controlTree: {
      pendingChanges: { auto: {}, user: {} },
      preferredValues: {},
      repeatedNodes: {},
      values: {},
    },
  }

  const gloves = controlTree.getNodes(state)['product.glove'].visibleOptions
  const leathers = LEATHERS
  const webs = WEBS

  let jobs: Job[] = []
  const jobIds: string[] = []
  _.forEach(gloves, (glove) => {
    const gloveAsset = GLOVE_ASSET_DICT[glove.asset.gloveAssetId]
    _.forEach(leathers, (leather) => {
      if (!glove.limitations.leatherId[leather.id]) {
        return
      }
      _.forEach(webs, (web) => {
        if (!glove.limitations.webIds[web.id]) {
          return
        }
        const jobId = `${gloveAsset.id}-${web.id}`
        if (_.includes(jobIds, jobId)) {
          return
        }
        jobIds.push(jobId)

        const filename = `${gloveAsset.id}   ${web.id}   ${getSku(
          leather,
          glove,
        )}`

        const sourceSize = gloveAsset.props.imageDimensions[VIEW_NAME]
        const height = HEIGHT
        const width = Math.round(
          (height / sourceSize.height) * sourceSize.width,
        )
        const size = {
          width,
          height,
        }

        jobs.push({
          filename,
          controlTreeChanges: [
            ['product.leather', leather.id],
            ['product.glove', glove.id],
            ['colors.web.style', web.id],
            ['colors.laces.fingerWeb', 'columbiaBlue'],
            ['colors.laces.heel', 'scarlet'],
          ],
          viewName: VIEW_NAME,
          size,
        })
      })
    })
  })
  if (filter) {
    jobs = jobs.filter((job) => job.filename.match(filter))
  }
  console.log('Jobs:')
  console.log(jobs)
  return generateSnapshots(jobs)
}

export default main
