import * as _ from '@technically/lodash'

import { GLOVE_ASSET_DICT, GLOVE_DICT, FONTS } from '../../common/sheets'
import generateSnapshots from './generate'

const HEIGHT = 512

const getJob = ({ filename, glove, numberFont, numberText, viewName }) => {
  const controlTreeChanges = [
    ['product.leather', 'kip'],
    ['product.glove', glove.id],
    ['personalization.embroidery.color', 'scarlet'],
    ['personalization.embroidery.number.text', numberText],
    ['personalization.embroidery.number.font', numberFont],
  ]

  const gloveAsset = GLOVE_ASSET_DICT[glove.asset.gloveAssetId]
  const sourceSize = gloveAsset.props.imageDimensions[viewName]
  const height = HEIGHT
  const width = Math.round((height / sourceSize.height) * sourceSize.width)
  const size = {
    width,
    height,
  }

  return { filename, controlTreeChanges, viewName, size }
}

const main = async (filter) => {
  const fonts = FONTS
  const aList = ['', ..._.map(_.range(10), (val) => val.toString())]
  const bList = _.map(_.range(10), (val) => val.toString())

  const glove = GLOVE_DICT['2172']

  let jobs = []
  _.forEach(fonts, (font) => {
    const numberFont = font.id
    _.forEach(aList, (a) => {
      _.forEach(bList, (b) => {
        const numberText = `${a}${b}`
        const filename = `${numberFont}   ${a === '' ? '_' : a}${b}`
        const job = getJob({
          filename,
          glove,
          numberFont,
          numberText,
          viewName: 'web',
        })
        jobs.push(job)
      })
    })
  })
  if (filter) {
    jobs = _.filter(jobs, (job) => job.filename.match(filter))
  }
  console.log('Jobs:')
  console.log(jobs)
  return generateSnapshots(jobs)
}

export default main
