import { type AbstractMesh, Vector3 } from '@babylonjs/core';

const calculateCameraZoomTarget = (
  rootMeshes: AbstractMesh[],
): {
  zoomTarget: Vector3;
  minWorld: Vector3;
  maxWorld: Vector3;
  radiusWorld: Vector3;
  centerWorld: Vector3;
} => {
  const minWorld = new Vector3(
    Number.MAX_VALUE,
    Number.MAX_VALUE,
    Number.MAX_VALUE,
  );
  const maxWorld = new Vector3(
    -Number.MAX_VALUE,
    -Number.MAX_VALUE,
    -Number.MAX_VALUE,
  );

  for (const rootMesh of rootMeshes) {
    const { min, max } = rootMesh.getHierarchyBoundingVectors();

    Vector3.CheckExtends(min, minWorld, maxWorld);
    Vector3.CheckExtends(max, minWorld, maxWorld);
  }

  const bottom = minWorld.y;
  const top = maxWorld.y;
  const zoomTargetY = bottom + (top - bottom) * 0.5;
  const radiusWorld = maxWorld.subtract(minWorld).scale(0.5);

  const centerWorld = minWorld.add(radiusWorld);
  const zoomTarget = new Vector3(centerWorld.x, zoomTargetY, centerWorld.z);

  return { zoomTarget, minWorld, maxWorld, radiusWorld, centerWorld };
};

export default calculateCameraZoomTarget;
