// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { GloveSizeRow } from '../typings';

const data = [
  {
    "id": "9_50",
    "name": "9 1/2\""
  },
  {
    "id": "11_00",
    "name": "11\""
  },
  {
    "id": "11_25",
    "name": "11 1/4\""
  },
  {
    "id": "11_50",
    "name": "11 1/2\""
  },
  {
    "id": "11_75",
    "name": "11 3/4\""
  },
  {
    "id": "12_00",
    "name": "12\""
  },
  {
    "id": "12_25",
    "name": "12 1/4\""
  },
  {
    "id": "12_50",
    "name": "12 1/2\""
  },
  {
    "id": "12_75",
    "name": "12 3/4\""
  },
  {
    "id": "13_00",
    "name": "13\""
  },
  {
    "id": "28_00",
    "name": "28\""
  },
  {
    "id": "32_00",
    "name": "32\""
  },
  {
    "id": "32_50",
    "name": "32 1/2\""
  },
  {
    "id": "33_00",
    "name": "33\""
  },
  {
    "id": "34_00",
    "name": "34\""
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<GloveSizeRow[]>> = data;

export type GloveSize = typeof typed[number];

export const GLOVE_SIZE_INDEX_KEY = "id";
export type GloveSizeIndexKey = "id";
export type GloveSizeId = GloveSize["id"];

let i = 0;
export const GLOVE_SIZE_DICT = {
  "9_50": typed[i++],
  "11_00": typed[i++],
  "11_25": typed[i++],
  "11_50": typed[i++],
  "11_75": typed[i++],
  "12_00": typed[i++],
  "12_25": typed[i++],
  "12_50": typed[i++],
  "12_75": typed[i++],
  "13_00": typed[i++],
  "28_00": typed[i++],
  "32_00": typed[i++],
  "32_50": typed[i++],
  "33_00": typed[i++],
  "34_00": typed[i++]
} as const;

export { typed as GLOVE_SIZES };
