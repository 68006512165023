import { Color3, Color4 } from '@babylonjs/core';
import { ok } from '@orangelv/utils';

const normalizeColor = (color: string): Color4 => {
  ok(color.startsWith('#'), `Color "${color}" must start with a #!`);

  ok(
    color.length === 7 || color.length === 9,
    `Color "${color}" must be in format #rrggbb or #rrggbbaa!`,
  );

  return Color4.FromHexString(color);
};

const toColor3 = (color4: Color4): Color3 =>
  new Color3(color4.r, color4.g, color4.b);

export { normalizeColor, toColor3 };
