import React from 'react'

import { cn, mods } from '~p/client/components/utils'

import './Button.css'

function Button(props) {
  const { onClick, children, classMods } = props

  return (
    <button
      type="button"
      className={cn(['button', mods(classMods)])}
      onClick={onClick}
    >
      {/* https://github.com/philipwalton/flexbugs#9-some-html-elements-cant-be-flex-containers */}
      <span className="button-container">{children}</span>
    </button>
  )
}

Button.defaultProps = {
  classMods: [],
}

export default Button
