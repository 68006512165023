import axios, { AxiosError, AxiosResponse } from 'axios'

import http from './http'

export async function unrealRender(
  recipeId: string,
  formData?: { emailTo: string; scenes: { scene: string; animate: boolean }[] },
): Promise<
  | { result: AxiosResponse; error?: undefined }
  | { result?: undefined; error: AxiosError }
> {
  try {
    const result = await http.post(
      `/admin/api/unreal-render/${recipeId}`,
      formData,
    )
    return { result }
  } catch (error) {
    if (axios.isAxiosError(error)) return { error }
    throw error
  }
}
