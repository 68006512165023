import cn from 'classnames'

import { t } from '../../../../platform/i18n'

import './CountDown.css'

type DurationKey = 'd' | 'h' | 'm' | 's'

const DURATION_MAPPING: Record<DurationKey, number> = {
  d: 1000 * 60 * 60 * 24,
  h: 1000 * 60 * 60,
  m: 1000 * 60,
  s: 1000,
}

const DURATION_LABELS: Record<DurationKey, string> = {
  d: t('_rawlings:days'),
  h: t('_rawlings:hours'),
  m: t('_rawlings:mins'),
  s: t('_rawlings:secs'),
}

const humanizeDuration = (duration: number) => {
  let d = duration
  let durations: [DurationKey, number][] = []
  for (const durationKey of Object.keys(DURATION_MAPPING)) {
    const duration = DURATION_MAPPING[durationKey]
    let count = 0
    if (duration <= d) {
      count = Math.floor(d / duration)
      d -= duration * count
    }
    durations.push([durationKey as DurationKey, count])
  }

  return durations
}

type Props = {
  isCountDownVisible: boolean
  message: string
  milliseconds: number
}

const CountDown = (props: Props) => {
  const { message, milliseconds } = props

  let durations = humanizeDuration(milliseconds)
  if (milliseconds > 60 * 1000) {
    durations = durations.filter(([durationKey]) => durationKey !== 's')
  }

  const durationString = durations
    .map(([durationKey, count]) => `${count} ${DURATION_LABELS[durationKey]}`)
    .join(' ')

  return (
    <div
      className={cn('count-down', {
        isCountDownVisible: props.isCountDownVisible,
      })}
    >
      {message}: <span>{durationString}</span>
    </div>
  )
}

export default CountDown
