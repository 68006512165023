import * as _ from '@technically/lodash'
import { getSku } from '../../common/meta'

import { GLOVE_ASSET_DICT, GLOVES, LEATHERS } from '../../common/sheets'
import generateSnapshots, { Job } from './generate'

const VIEW_NAME = 'web'
const HEIGHT = 512

const main = async (filter) => {
  const gloves = GLOVES
  const leathers = LEATHERS

  let jobs: Job[] = []
  const jobIds: string[] = []
  _.forEach(gloves, (glove) => {
    const gloveAsset = GLOVE_ASSET_DICT[glove.asset.gloveAssetId]
    _.forEach(leathers, (leather) => {
      if (!glove.limitations.leatherId[leather.id]) {
        return
      }
      const jobId = gloveAsset.id
      if (_.includes(jobIds, jobId)) {
        return
      }
      jobIds.push(jobId)
      const filename = `${gloveAsset.id}   ${getSku(leather, glove)}`

      const sourceSize = gloveAsset.props.imageDimensions[VIEW_NAME]
      const height = HEIGHT
      const width = Math.round((height / sourceSize.height) * sourceSize.width)
      const size = {
        width,
        height,
      }

      jobs.push({
        filename,
        controlTreeChanges: [
          ['product.leather', leather.id],
          ['product.glove', glove.id],
          ['colors.laces.fingerWeb', 'columbiaBlue'],
          ['colors.laces.heel', 'scarlet'],
          ['personalization.embroidery.color', 'scarlet'],
          ['personalization.embroidery.number.text', '55'],
          ['personalization.embroidery.number.font', 'blockNumber'],
        ],
        viewName: VIEW_NAME,
        size,
      })
    })
  })
  if (filter) {
    jobs = jobs.filter((job) => job.filename.match(filter))
  }
  console.log('Jobs:')
  console.log(jobs)
  return generateSnapshots(jobs)
}

export default main
