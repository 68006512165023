import { connect } from 'react-redux'

import { setRenderCompositeActiveView } from '~p/client/renderComposite/actions'

import { setFocusMode } from '~rawlings/client/actions'

import Renderer from '../components/Renderer'

const mapStateToProps = (state) => ({
  activeView: state.renderComposite.activeView,
  isFocusMode: state.isFocusMode,
})

const mapDispatchToProps = {
  setView: setRenderCompositeActiveView,
  setFocusMode,
}

export default connect(mapStateToProps, mapDispatchToProps)(Renderer)
