// DO NOT EDIT, GENERATED BY "yarn sheets"

import { t } from '../../../../platform/i18n'

import type { NavTreeItemRow } from '../typings';

const data = [
  {
    "id": "filter",
    "childId": "filter",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "name": t('rawlingsGloves:sheets.navTree.filter.name'),
    "wizardTitle": t('rawlingsGloves:sheets.navTree.filter.wizardTitle')
  },
  {
    "id": "filter.productSelector",
    "parentId": "filter",
    "childId": "productSelector",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": true,
    "name": t('rawlingsGloves:sheets.navTree.filter.productSelector.name'),
    "wizardTitle": t('rawlingsGloves:sheets.navTree.filter.productSelector.wizardTitle')
  },
  {
    "id": "filter.productSelector.productSelector.sport",
    "parentId": "filter.productSelector",
    "childId": "productSelector.sport",
    "propId": "filter.sport",
    "isWizardStep": true,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "name": t('rawlingsGloves:sheets.navTree.filter.productSelector.productSelector.sport.name'),
    "wizardTitle": t('rawlingsGloves:sheets.navTree.filter.productSelector.productSelector.sport.wizardTitle')
  },
  {
    "id": "filter.productSelector.productSelector.leather",
    "parentId": "filter.productSelector",
    "childId": "productSelector.leather",
    "propId": "product.leather",
    "isWizardStep": true,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "name": t('rawlingsGloves:sheets.navTree.filter.productSelector.productSelector.leather.name'),
    "wizardTitle": t('rawlingsGloves:sheets.navTree.filter.productSelector.productSelector.leather.wizardTitle')
  },
  {
    "id": "filter.productSelector.productSelector.fit",
    "parentId": "filter.productSelector",
    "childId": "productSelector.fit",
    "propId": "product.fit",
    "isWizardStep": true,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "name": t('rawlingsGloves:sheets.navTree.filter.productSelector.productSelector.fit.name'),
    "wizardTitle": t('rawlingsGloves:sheets.navTree.filter.productSelector.productSelector.fit.wizardTitle')
  },
  {
    "id": "filter.productSelector.productSelector.position",
    "parentId": "filter.productSelector",
    "childId": "productSelector.position",
    "propId": "filter.position",
    "isWizardStep": true,
    "isWizardStepOptional": true,
    "isNew": false,
    "shouldShowChildren": false,
    "name": t('rawlingsGloves:sheets.navTree.filter.productSelector.productSelector.position.name'),
    "wizardTitle": t('rawlingsGloves:sheets.navTree.filter.productSelector.productSelector.position.wizardTitle')
  },
  {
    "id": "filter.productSelector.productSelector.size",
    "parentId": "filter.productSelector",
    "childId": "productSelector.size",
    "propId": "filter.size",
    "isWizardStep": true,
    "isWizardStepOptional": true,
    "isNew": false,
    "shouldShowChildren": false,
    "name": t('rawlingsGloves:sheets.navTree.filter.productSelector.productSelector.size.name'),
    "wizardTitle": t('rawlingsGloves:sheets.navTree.filter.productSelector.productSelector.size.wizardTitle')
  },
  {
    "id": "filter.productSelector.productSelector.glove",
    "parentId": "filter.productSelector",
    "childId": "productSelector.glove",
    "propId": "product.glove",
    "isWizardStep": true,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "name": t('rawlingsGloves:sheets.navTree.filter.productSelector.productSelector.glove.name'),
    "wizardTitle": t('rawlingsGloves:sheets.navTree.filter.productSelector.productSelector.glove.wizardTitle')
  },
  {
    "id": "product",
    "childId": "product",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "name": t('rawlingsGloves:sheets.navTree.product.name'),
    "wizardTitle": t('rawlingsGloves:sheets.navTree.product.wizardTitle')
  },
  {
    "id": "product.throwingHand",
    "parentId": "product",
    "childId": "throwingHand",
    "propId": "product.throwingHand",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "name": t('rawlingsGloves:sheets.navTree.product.throwingHand.name'),
    "wizardTitle": t('rawlingsGloves:sheets.navTree.product.throwingHand.wizardTitle')
  },
  {
    "id": "colors",
    "childId": "colors",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "name": t('rawlingsGloves:sheets.navTree.colors.name'),
    "wizardTitle": t('rawlingsGloves:sheets.navTree.colors.wizardTitle')
  },
  {
    "id": "colors.web",
    "parentId": "colors",
    "childId": "web",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "name": t('rawlingsGloves:sheets.navTree.colors.web.name'),
    "wizardTitle": t('rawlingsGloves:sheets.navTree.colors.web.wizardTitle')
  },
  {
    "id": "colors.web.style",
    "parentId": "colors.web",
    "childId": "style",
    "propId": "colors.web.style",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "name": t('rawlingsGloves:sheets.navTree.colors.web.style.name'),
    "wizardTitle": t('rawlingsGloves:sheets.navTree.colors.web.style.wizardTitle')
  },
  {
    "id": "colors.web.color",
    "parentId": "colors.web",
    "childId": "color",
    "propId": "colors.web.color",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "name": t('rawlingsGloves:sheets.navTree.colors.web.color.name'),
    "wizardTitle": t('rawlingsGloves:sheets.navTree.colors.web.color.wizardTitle')
  },
  {
    "id": "colors.logoPatch",
    "parentId": "colors",
    "childId": "logoPatch",
    "propId": "colors.logoPatch",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "name": t('rawlingsGloves:sheets.navTree.colors.logoPatch.name'),
    "wizardTitle": t('rawlingsGloves:sheets.navTree.colors.logoPatch.wizardTitle')
  },
  {
    "id": "colors.shellBack",
    "parentId": "colors",
    "childId": "shellBack",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "name": t('rawlingsGloves:sheets.navTree.colors.shellBack.name'),
    "wizardTitle": t('rawlingsGloves:sheets.navTree.colors.shellBack.wizardTitle')
  },
  {
    "id": "colors.shellBack.design",
    "parentId": "colors.shellBack",
    "childId": "design",
    "propId": "colors.shellBack.design",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "name": t('rawlingsGloves:sheets.navTree.colors.shellBack.design.name'),
    "wizardTitle": t('rawlingsGloves:sheets.navTree.colors.shellBack.design.wizardTitle')
  },
  {
    "id": "colors.shellBack.material",
    "parentId": "colors.shellBack",
    "childId": "material",
    "propId": "colors.shellBack.material",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "name": t('rawlingsGloves:sheets.navTree.colors.shellBack.material.name'),
    "wizardTitle": t('rawlingsGloves:sheets.navTree.colors.shellBack.material.wizardTitle')
  },
  {
    "id": "colors.shellBack.leatherColor",
    "parentId": "colors.shellBack",
    "childId": "leatherColor",
    "propId": "colors.shellBack.leatherColor",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "name": t('rawlingsGloves:sheets.navTree.colors.shellBack.leatherColor.name'),
    "wizardTitle": t('rawlingsGloves:sheets.navTree.colors.shellBack.leatherColor.wizardTitle')
  },
  {
    "id": "colors.shellPalm",
    "parentId": "colors",
    "childId": "shellPalm",
    "propId": "colors.shellPalm",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "name": t('rawlingsGloves:sheets.navTree.colors.shellPalm.name'),
    "wizardTitle": t('rawlingsGloves:sheets.navTree.colors.shellPalm.wizardTitle')
  },
  {
    "id": "colors.backPalm",
    "parentId": "colors",
    "childId": "backPalm",
    "propId": "colors.backPalm",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "name": t('rawlingsGloves:sheets.navTree.colors.backPalm.name'),
    "wizardTitle": t('rawlingsGloves:sheets.navTree.colors.backPalm.wizardTitle')
  },
  {
    "id": "colors.laces",
    "parentId": "colors",
    "childId": "laces",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "name": t('rawlingsGloves:sheets.navTree.colors.laces.name'),
    "wizardTitle": t('rawlingsGloves:sheets.navTree.colors.laces.wizardTitle')
  },
  {
    "id": "colors.laces.fingerWeb",
    "parentId": "colors.laces",
    "childId": "fingerWeb",
    "propId": "colors.laces.fingerWeb",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "name": t('rawlingsGloves:sheets.navTree.colors.laces.fingerWeb.name'),
    "wizardTitle": t('rawlingsGloves:sheets.navTree.colors.laces.fingerWeb.wizardTitle')
  },
  {
    "id": "colors.laces.heel",
    "parentId": "colors.laces",
    "childId": "heel",
    "propId": "colors.laces.heel",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "name": t('rawlingsGloves:sheets.navTree.colors.laces.heel.name'),
    "wizardTitle": t('rawlingsGloves:sheets.navTree.colors.laces.heel.wizardTitle')
  },
  {
    "id": "colors.laces.laceLength",
    "parentId": "colors.laces",
    "childId": "laceLength",
    "propId": "colors.laces.laceLength",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "name": t('rawlingsGloves:sheets.navTree.colors.laces.laceLength.name'),
    "wizardTitle": t('rawlingsGloves:sheets.navTree.colors.laces.laceLength.wizardTitle')
  },
  {
    "id": "colors.lining",
    "parentId": "colors",
    "childId": "lining",
    "propId": "colors.lining",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "name": t('rawlingsGloves:sheets.navTree.colors.lining.name'),
    "wizardTitle": t('rawlingsGloves:sheets.navTree.colors.lining.wizardTitle')
  },
  {
    "id": "colors.welting",
    "parentId": "colors",
    "childId": "welting",
    "propId": "colors.welting.all",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "name": t('rawlingsGloves:sheets.navTree.colors.welting.name'),
    "wizardTitle": t('rawlingsGloves:sheets.navTree.colors.welting.wizardTitle')
  },
  {
    "id": "colors.weltingNested",
    "parentId": "colors",
    "childId": "weltingNested",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "name": t('rawlingsGloves:sheets.navTree.colors.weltingNested.name'),
    "wizardTitle": t('rawlingsGloves:sheets.navTree.colors.weltingNested.wizardTitle')
  },
  {
    "id": "colors.weltingNested.palm",
    "parentId": "colors.weltingNested",
    "childId": "palm",
    "propId": "colors.welting.palm",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "name": t('rawlingsGloves:sheets.navTree.colors.weltingNested.palm.name'),
    "wizardTitle": t('rawlingsGloves:sheets.navTree.colors.weltingNested.palm.wizardTitle')
  },
  {
    "id": "colors.weltingNested.back",
    "parentId": "colors.weltingNested",
    "childId": "back",
    "propId": "colors.welting.back",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "name": t('rawlingsGloves:sheets.navTree.colors.weltingNested.back.name'),
    "wizardTitle": t('rawlingsGloves:sheets.navTree.colors.weltingNested.back.wizardTitle')
  },
  {
    "id": "colors.weltingNested.handSewn",
    "parentId": "colors.weltingNested",
    "childId": "handSewn",
    "propId": "colors.welting.handSewn",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "name": t('rawlingsGloves:sheets.navTree.colors.weltingNested.handSewn.name'),
    "wizardTitle": t('rawlingsGloves:sheets.navTree.colors.weltingNested.handSewn.wizardTitle')
  },
  {
    "id": "colors.weltingNested.handSewnStitching",
    "parentId": "colors.weltingNested",
    "childId": "handSewnStitching",
    "propId": "colors.welting.handSewnStitching",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "name": t('rawlingsGloves:sheets.navTree.colors.weltingNested.handSewnStitching.name'),
    "wizardTitle": t('rawlingsGloves:sheets.navTree.colors.weltingNested.handSewnStitching.wizardTitle')
  },
  {
    "id": "colors.binding",
    "parentId": "colors",
    "childId": "binding",
    "propId": "colors.binding",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "name": t('rawlingsGloves:sheets.navTree.colors.binding.name'),
    "wizardTitle": t('rawlingsGloves:sheets.navTree.colors.binding.wizardTitle')
  },
  {
    "id": "colors.trim",
    "parentId": "colors",
    "childId": "trim",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "name": t('rawlingsGloves:sheets.navTree.colors.trim.name'),
    "wizardTitle": t('rawlingsGloves:sheets.navTree.colors.trim.wizardTitle')
  },
  {
    "id": "colors.trim.style",
    "parentId": "colors.trim",
    "childId": "style",
    "propId": "colors.trim.style",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "name": t('rawlingsGloves:sheets.navTree.colors.trim.style.name'),
    "wizardTitle": t('rawlingsGloves:sheets.navTree.colors.trim.style.wizardTitle')
  },
  {
    "id": "colors.trim.color",
    "parentId": "colors.trim",
    "childId": "color",
    "propId": "colors.trim.color",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "name": t('rawlingsGloves:sheets.navTree.colors.trim.color.name'),
    "wizardTitle": t('rawlingsGloves:sheets.navTree.colors.trim.color.wizardTitle')
  },
  {
    "id": "colors.stitching",
    "parentId": "colors",
    "childId": "stitching",
    "propId": "colors.stitching",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "name": t('rawlingsGloves:sheets.navTree.colors.stitching.name'),
    "wizardTitle": t('rawlingsGloves:sheets.navTree.colors.stitching.wizardTitle')
  },
  {
    "id": "colors.stamping",
    "parentId": "colors",
    "childId": "stamping",
    "propId": "colors.stamping",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "name": t('rawlingsGloves:sheets.navTree.colors.stamping.name'),
    "wizardTitle": t('rawlingsGloves:sheets.navTree.colors.stamping.wizardTitle')
  },
  {
    "id": "options",
    "childId": "options",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "name": t('rawlingsGloves:sheets.navTree.options.name'),
    "wizardTitle": t('rawlingsGloves:sheets.navTree.options.wizardTitle')
  },
  {
    "id": "options.fingerPadHood",
    "parentId": "options",
    "childId": "fingerPadHood",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "name": t('rawlingsGloves:sheets.navTree.options.fingerPadHood.name'),
    "wizardTitle": t('rawlingsGloves:sheets.navTree.options.fingerPadHood.wizardTitle')
  },
  {
    "id": "options.fingerPadHood.fingerPad",
    "parentId": "options.fingerPadHood",
    "childId": "fingerPad",
    "propId": "options.fingerPadHood.fingerPad",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "name": t('rawlingsGloves:sheets.navTree.options.fingerPadHood.fingerPad.name'),
    "wizardTitle": t('rawlingsGloves:sheets.navTree.options.fingerPadHood.fingerPad.wizardTitle')
  },
  {
    "id": "options.fingerPadHood.fingerHood",
    "parentId": "options.fingerPadHood",
    "childId": "fingerHood",
    "propId": "options.fingerPadHood.fingerHood",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "name": t('rawlingsGloves:sheets.navTree.options.fingerPadHood.fingerHood.name'),
    "wizardTitle": t('rawlingsGloves:sheets.navTree.options.fingerPadHood.fingerHood.wizardTitle')
  },
  {
    "id": "options.fingerPadHood.position",
    "parentId": "options.fingerPadHood",
    "childId": "position",
    "propId": "options.fingerPadHood.position",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "name": t('rawlingsGloves:sheets.navTree.options.fingerPadHood.position.name'),
    "wizardTitle": t('rawlingsGloves:sheets.navTree.options.fingerPadHood.position.wizardTitle')
  },
  {
    "id": "options.palmPad",
    "parentId": "options",
    "childId": "palmPad",
    "propId": "options.palmPad",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "name": t('rawlingsGloves:sheets.navTree.options.palmPad.name'),
    "wizardTitle": t('rawlingsGloves:sheets.navTree.options.palmPad.wizardTitle')
  },
  {
    "id": "options.heelPad",
    "parentId": "options",
    "childId": "heelPad",
    "propId": "options.heelPad",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "name": t('rawlingsGloves:sheets.navTree.options.heelPad.name'),
    "wizardTitle": t('rawlingsGloves:sheets.navTree.options.heelPad.wizardTitle')
  },
  {
    "id": "options.wristLining",
    "parentId": "options",
    "childId": "wristLining",
    "propId": "options.wristLining",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "name": t('rawlingsGloves:sheets.navTree.options.wristLining.name'),
    "wizardTitle": t('rawlingsGloves:sheets.navTree.options.wristLining.wizardTitle')
  },
  {
    "id": "options.sweatband",
    "parentId": "options",
    "childId": "sweatband",
    "propId": "options.sweatband",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "name": t('rawlingsGloves:sheets.navTree.options.sweatband.name'),
    "wizardTitle": t('rawlingsGloves:sheets.navTree.options.sweatband.wizardTitle')
  },
  {
    "id": "options.breakIn",
    "parentId": "options",
    "childId": "breakIn",
    "propId": "options.breakIn",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "name": t('rawlingsGloves:sheets.navTree.options.breakIn.name'),
    "wizardTitle": t('rawlingsGloves:sheets.navTree.options.breakIn.wizardTitle')
  },
  {
    "id": "personalization",
    "childId": "personalization",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "name": t('rawlingsGloves:sheets.navTree.personalization.name'),
    "wizardTitle": t('rawlingsGloves:sheets.navTree.personalization.wizardTitle')
  },
  {
    "id": "personalization.embroidery",
    "parentId": "personalization",
    "childId": "embroidery",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "name": t('rawlingsGloves:sheets.navTree.personalization.embroidery.name'),
    "wizardTitle": t('rawlingsGloves:sheets.navTree.personalization.embroidery.wizardTitle')
  },
  {
    "id": "personalization.embroidery.color",
    "parentId": "personalization.embroidery",
    "childId": "color",
    "propId": "personalization.embroidery.color",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "name": t('rawlingsGloves:sheets.navTree.personalization.embroidery.color.name'),
    "wizardTitle": t('rawlingsGloves:sheets.navTree.personalization.embroidery.color.wizardTitle')
  },
  {
    "id": "personalization.embroidery.font",
    "parentId": "personalization.embroidery",
    "childId": "font",
    "propId": "personalization.embroidery.number.font",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "name": t('rawlingsGloves:sheets.navTree.personalization.embroidery.font.name'),
    "wizardTitle": t('rawlingsGloves:sheets.navTree.personalization.embroidery.font.wizardTitle')
  },
  {
    "id": "personalization.embroidery.number",
    "parentId": "personalization.embroidery",
    "childId": "number",
    "propId": "personalization.embroidery.number.text",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "name": t('rawlingsGloves:sheets.navTree.personalization.embroidery.number.name'),
    "wizardTitle": t('rawlingsGloves:sheets.navTree.personalization.embroidery.number.wizardTitle')
  },
  {
    "id": "personalization.embroidery.logo",
    "parentId": "personalization.embroidery",
    "childId": "logo",
    "propId": "personalization.embroidery.logo",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "name": t('rawlingsGloves:sheets.navTree.personalization.embroidery.logo.name'),
    "wizardTitle": t('rawlingsGloves:sheets.navTree.personalization.embroidery.logo.wizardTitle')
  },
  {
    "id": "personalization.embroidery.thumb",
    "parentId": "personalization.embroidery",
    "childId": "thumb",
    "propId": "personalization.embroidery.thumb.text",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "name": t('rawlingsGloves:sheets.navTree.personalization.embroidery.thumb.name'),
    "wizardTitle": t('rawlingsGloves:sheets.navTree.personalization.embroidery.thumb.wizardTitle')
  },
  {
    "id": "personalization.embroidery.pinky",
    "parentId": "personalization.embroidery",
    "childId": "pinky",
    "propId": "personalization.embroidery.pinky.text",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "name": t('rawlingsGloves:sheets.navTree.personalization.embroidery.pinky.name'),
    "wizardTitle": t('rawlingsGloves:sheets.navTree.personalization.embroidery.pinky.wizardTitle')
  },
  {
    "id": "personalization.embroidery.pinkyPalm",
    "parentId": "personalization.embroidery",
    "childId": "pinkyPalm",
    "propId": "personalization.embroidery.pinkyPalm.text",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "name": t('rawlingsGloves:sheets.navTree.personalization.embroidery.pinkyPalm.name'),
    "wizardTitle": t('rawlingsGloves:sheets.navTree.personalization.embroidery.pinkyPalm.wizardTitle')
  },
  {
    "id": "personalization.flag",
    "parentId": "personalization",
    "childId": "flag",
    "propId": "personalization.flag",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "name": t('rawlingsGloves:sheets.navTree.personalization.flag.name'),
    "wizardTitle": t('rawlingsGloves:sheets.navTree.personalization.flag.wizardTitle')
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<NavTreeItemRow[]>> = data;

export type NavTreeItem = typeof typed[number];

export const NAV_TREE_ITEM_INDEX_KEY = "id";
export type NavTreeItemIndexKey = "id";
export type NavTreeItemId = NavTreeItem["id"];

let i = 0;
export const NAV_TREE_ITEM_DICT = {
  "filter": typed[i++],
  "filter.productSelector": typed[i++],
  "filter.productSelector.productSelector.sport": typed[i++],
  "filter.productSelector.productSelector.leather": typed[i++],
  "filter.productSelector.productSelector.fit": typed[i++],
  "filter.productSelector.productSelector.position": typed[i++],
  "filter.productSelector.productSelector.size": typed[i++],
  "filter.productSelector.productSelector.glove": typed[i++],
  "product": typed[i++],
  "product.throwingHand": typed[i++],
  "colors": typed[i++],
  "colors.web": typed[i++],
  "colors.web.style": typed[i++],
  "colors.web.color": typed[i++],
  "colors.logoPatch": typed[i++],
  "colors.shellBack": typed[i++],
  "colors.shellBack.design": typed[i++],
  "colors.shellBack.material": typed[i++],
  "colors.shellBack.leatherColor": typed[i++],
  "colors.shellPalm": typed[i++],
  "colors.backPalm": typed[i++],
  "colors.laces": typed[i++],
  "colors.laces.fingerWeb": typed[i++],
  "colors.laces.heel": typed[i++],
  "colors.laces.laceLength": typed[i++],
  "colors.lining": typed[i++],
  "colors.welting": typed[i++],
  "colors.weltingNested": typed[i++],
  "colors.weltingNested.palm": typed[i++],
  "colors.weltingNested.back": typed[i++],
  "colors.weltingNested.handSewn": typed[i++],
  "colors.weltingNested.handSewnStitching": typed[i++],
  "colors.binding": typed[i++],
  "colors.trim": typed[i++],
  "colors.trim.style": typed[i++],
  "colors.trim.color": typed[i++],
  "colors.stitching": typed[i++],
  "colors.stamping": typed[i++],
  "options": typed[i++],
  "options.fingerPadHood": typed[i++],
  "options.fingerPadHood.fingerPad": typed[i++],
  "options.fingerPadHood.fingerHood": typed[i++],
  "options.fingerPadHood.position": typed[i++],
  "options.palmPad": typed[i++],
  "options.heelPad": typed[i++],
  "options.wristLining": typed[i++],
  "options.sweatband": typed[i++],
  "options.breakIn": typed[i++],
  "personalization": typed[i++],
  "personalization.embroidery": typed[i++],
  "personalization.embroidery.color": typed[i++],
  "personalization.embroidery.font": typed[i++],
  "personalization.embroidery.number": typed[i++],
  "personalization.embroidery.logo": typed[i++],
  "personalization.embroidery.thumb": typed[i++],
  "personalization.embroidery.pinky": typed[i++],
  "personalization.embroidery.pinkyPalm": typed[i++],
  "personalization.flag": typed[i++]
} as const;

export { typed as NAV_TREE };
