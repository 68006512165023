import i18nEnUsRawlingsCommon from '../../platform/i18n/en-US/en-US._rawlings.i18next-v4.json' with { type: 'json' }
import i18nFrCaRawlingsCommon from '../../platform/i18n/fr-CA/fr-CA._rawlings.i18next-v4.json' with { type: 'json' }

import i18nEnUsRawlingsGloves from '../../platform/i18n/en-US/en-US.rawlingsGloves.i18next-v4.json' with { type: 'json' }
import i18nFrCaRawlingsGloves from '../../platform/i18n/fr-CA/fr-CA.rawlingsGloves.i18next-v4.json' with { type: 'json' }

const DEFAULT_MENU = 'filter.sport'
const DEFAULT_MENU_WHEN_SKU = null
const SKU_MATCHES = ['calc.sku']
const SKU_OMITS = []
const I18N_RESOURCES = {
  'en-US': {
    _rawlings: i18nEnUsRawlingsCommon,
    rawlingsGloves: i18nEnUsRawlingsGloves,
  },
  'fr-CA': {
    _rawlings: i18nFrCaRawlingsCommon,
    rawlingsGloves: i18nFrCaRawlingsGloves,
  },
}

// TODO: Add types.
export {
  DEFAULT_MENU,
  DEFAULT_MENU_WHEN_SKU,
  SKU_MATCHES,
  SKU_OMITS,
  I18N_RESOURCES,
}
