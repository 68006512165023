import wildcard from 'wildcard'

const MENU_MATCHER_TO_CAMERA_ID = [
  ['colors.web.*', 'web_cam'],
  ['options.palmPad', 'web_cam'],
  ['options.shellPad', 'palm_cam'],
  ['options.fingerPadHood.*', 'shellBack_cam'],
  ['options.sweatband', 'web_cam'],
  ['colors.logoPatch', 'patch_cam'],
  ['colors.shellBack.*', 'web_cam'],
  ['colors.shellPalm.*', 'palm_cam'],
  ['colors.fingerLaces.*', 'web_cam'],
  ['colors.laces.heel.*', 'palm_cam'],
  ['colors.weltingNested.palm.*', 'palm_cam'],
  ['colors.weltingNested.back.*', 'shellBack_cam'],
  ['colors.stamping', 'palm_cam'],
  ['personalization.embroidery.number', 'web_cam'],
  ['personalization.embroidery.thumb', 'web_cam'],
  ['personalization.embroidery.pinky', 'shellBack_cam'],
  ['personalization.flag', 'web_cam'],
]

const DEFAULT_CAMERA = 'web_cam'

const getCameraFromMenu = (menu?: string): string => {
  if (!menu) {
    return DEFAULT_CAMERA
  }

  const match = MENU_MATCHER_TO_CAMERA_ID.find(([matcher]) =>
    wildcard(matcher, menu),
  ) as [string, string] | undefined

  if (!match) {
    return DEFAULT_CAMERA
  }

  const [, camera] = match

  return camera
}

export default getCameraFromMenu
