import fp from 'lodash/fp.js'
import { handleActions } from 'redux-fp'

import { currentMenuPropDefSelector } from './selectors'

const updateView = (statePath) => () => (state) => {
  const propDef = currentMenuPropDefSelector(state)

  if (!propDef) {
    return state
  }

  if (state.originValues === null) {
    return state
  }

  if (fp.includes(fp.get(statePath, state), propDef.viewNames)) {
    return state
  }

  return fp.set(statePath, propDef.viewNames[0], state)
}

export const createViewNameUpdater = (statePath) =>
  handleActions({
    MENU_CHANGED: updateView(statePath),
    SET_CHANGES: updateView(statePath),
  })

export const focusModeUpdater = handleActions({
  SET_FOCUS_MODE: ({ payload }) => fp.set('isFocusMode', payload),
})
