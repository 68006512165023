import type { AbstractMesh, Mesh } from '@babylonjs/core';

const getParentMeshes = (mesh: AbstractMesh): Mesh[] => {
  const parentMeshes: Mesh[] = [];

  let currentMesh = mesh;

  while (true) {
    const parentMesh = currentMesh.parent as Mesh | undefined;
    if (!parentMesh) {
      break;
    }

    currentMesh = parentMesh;
    parentMeshes.push(parentMesh);
  }

  return parentMeshes;
};

export default getParentMeshes;
