import React from 'react'
import cn from 'classnames'

import Spinner from './Spinner'

import './Renderer.css'

const Renderer = ({ children, isLoading = false, isScrollMenuLayout }) => (
  <div>
    {isLoading && (
      <div className="renderer-loading">
        <div className="renderer-spinner">
          <Spinner />
        </div>
      </div>
    )}
    <div
      className={cn(
        'renderer-inner',
        isScrollMenuLayout && 'mod-is-scroll-menu-layout',
      )}
    >
      {children}
    </div>
  </div>
)

export default Renderer
