// DO NOT EDIT, GENERATED BY "yarn sheets"

import { t } from '../../../../platform/i18n'

import type { WebRow } from '../typings';

const data = [
  {
    "id": "SingleP",
    "asset": {
      "logo": false,
      "detail": false,
      "binding": false,
      "hasAccent": false
    },
    "name": t('rawlingsGloves:sheets.webs.SingleP.name')
  },
  {
    "id": "SingleP1x",
    "asset": {
      "logo": false,
      "detail": false,
      "binding": false,
      "hasAccent": false
    },
    "name": t('rawlingsGloves:sheets.webs.SingleP1x.name')
  },
  {
    "id": "ProI",
    "asset": {
      "logo": true,
      "detail": false,
      "binding": false,
      "hasAccent": false
    },
    "name": t('rawlingsGloves:sheets.webs.ProI.name')
  },
  {
    "id": "ProI2x",
    "asset": {
      "logo": false,
      "detail": false,
      "binding": true,
      "hasAccent": true
    },
    "name": t('rawlingsGloves:sheets.webs.ProI2x.name')
  },
  {
    "id": "Basket",
    "asset": {
      "logo": false,
      "detail": true,
      "binding": false,
      "hasAccent": false
    },
    "name": t('rawlingsGloves:sheets.webs.Basket.name')
  },
  {
    "id": "ModTrapeze",
    "asset": {
      "logo": false,
      "detail": false,
      "binding": false,
      "hasAccent": false
    },
    "name": t('rawlingsGloves:sheets.webs.ModTrapeze.name')
  },
  {
    "id": "ModTrapeze4x",
    "asset": {
      "logo": false,
      "detail": false,
      "binding": false,
      "hasAccent": false
    },
    "name": t('rawlingsGloves:sheets.webs.ModTrapeze4x.name')
  },
  {
    "id": "SinglePDB",
    "asset": {
      "logo": false,
      "detail": false,
      "binding": false,
      "hasAccent": false
    },
    "name": t('rawlingsGloves:sheets.webs.SinglePDB.name')
  },
  {
    "id": "ProH",
    "asset": {
      "logo": false,
      "detail": false,
      "binding": false,
      "hasAccent": false
    },
    "name": t('rawlingsGloves:sheets.webs.ProH.name')
  },
  {
    "id": "ProH6x",
    "asset": {
      "logo": false,
      "detail": false,
      "binding": false,
      "hasAccent": false
    },
    "name": t('rawlingsGloves:sheets.webs.ProH6x.name')
  },
  {
    "id": "ProV",
    "asset": {
      "logo": false,
      "detail": false,
      "binding": false,
      "hasAccent": false
    },
    "name": t('rawlingsGloves:sheets.webs.ProV.name')
  },
  {
    "id": "ProV7x",
    "asset": {
      "logo": false,
      "detail": false,
      "binding": false,
      "hasAccent": false
    },
    "name": t('rawlingsGloves:sheets.webs.ProV7x.name')
  },
  {
    "id": "OnePS",
    "asset": {
      "logo": true,
      "detail": false,
      "binding": false,
      "hasAccent": false
    },
    "name": t('rawlingsGloves:sheets.webs.OnePS.name')
  },
  {
    "id": "OnePS8x",
    "asset": {
      "logo": false,
      "detail": false,
      "binding": false,
      "hasAccent": false
    },
    "name": t('rawlingsGloves:sheets.webs.OnePS8x.name')
  },
  {
    "id": "TwoPS",
    "asset": {
      "logo": true,
      "detail": false,
      "binding": false,
      "hasAccent": false
    },
    "name": t('rawlingsGloves:sheets.webs.TwoPS.name')
  },
  {
    "id": "TwoPS9x",
    "asset": {
      "logo": false,
      "detail": false,
      "binding": false,
      "hasAccent": true
    },
    "name": t('rawlingsGloves:sheets.webs.TwoPS9x.name')
  },
  {
    "id": "SinglePDBbase",
    "asset": {
      "logo": false,
      "detail": false,
      "binding": false,
      "hasAccent": false
    },
    "name": t('rawlingsGloves:sheets.webs.SinglePDBbase.name')
  },
  {
    "id": "BasketConnector",
    "asset": {
      "logo": false,
      "detail": true,
      "binding": false,
      "hasAccent": false
    },
    "name": t('rawlingsGloves:sheets.webs.BasketConnector.name')
  },
  {
    "id": "VerticalH",
    "asset": {
      "logo": false,
      "detail": false,
      "binding": false,
      "hasAccent": false
    },
    "name": t('rawlingsGloves:sheets.webs.VerticalH.name')
  },
  {
    "id": "ModSinglePost",
    "asset": {
      "logo": false,
      "detail": false,
      "binding": false,
      "hasAccent": false
    },
    "name": t('rawlingsGloves:sheets.webs.ModSinglePost.name')
  },
  {
    "id": "VerticalHB",
    "asset": {
      "logo": false,
      "detail": true,
      "binding": false,
      "hasAccent": false
    },
    "name": t('rawlingsGloves:sheets.webs.VerticalHB.name')
  },
  {
    "id": "ModTrapezeLoop",
    "asset": {
      "logo": false,
      "detail": false,
      "binding": false,
      "hasAccent": false
    },
    "name": t('rawlingsGloves:sheets.webs.ModTrapezeLoop.name')
  },
  {
    "id": "Ypost",
    "asset": {
      "logo": true,
      "detail": false,
      "binding": false,
      "hasAccent": false
    },
    "name": t('rawlingsGloves:sheets.webs.Ypost.name')
  },
  {
    "id": "ModProH",
    "asset": {
      "logo": false,
      "detail": false,
      "binding": false,
      "hasAccent": false
    },
    "name": t('rawlingsGloves:sheets.webs.ModProH.name')
  },
  {
    "id": "DoubleLacedBasket",
    "asset": {
      "logo": false,
      "detail": false,
      "binding": false,
      "hasAccent": false
    },
    "name": t('rawlingsGloves:sheets.webs.DoubleLacedBasket.name')
  },
  {
    "id": "DoubleLacedBasket18x",
    "asset": {
      "logo": false,
      "detail": false,
      "binding": false,
      "hasAccent": false
    },
    "name": t('rawlingsGloves:sheets.webs.DoubleLacedBasket18x.name')
  },
  {
    "id": "XLacedSingleP",
    "asset": {
      "logo": false,
      "detail": false,
      "binding": false,
      "hasAccent": false
    },
    "name": t('rawlingsGloves:sheets.webs.XLacedSingleP.name')
  },
  {
    "id": "SinglePwXLace",
    "asset": {
      "logo": false,
      "detail": false,
      "binding": false,
      "hasAccent": false
    },
    "name": t('rawlingsGloves:sheets.webs.SinglePwXLace.name')
  },
  {
    "id": "HorizontalXLaces",
    "asset": {
      "logo": false,
      "detail": false,
      "binding": false,
      "hasAccent": false
    },
    "name": t('rawlingsGloves:sheets.webs.HorizontalXLaces.name')
  },
  {
    "id": "Trapeze",
    "asset": {
      "logo": false,
      "detail": false,
      "binding": true,
      "hasAccent": false
    },
    "name": t('rawlingsGloves:sheets.webs.Trapeze.name')
  },
  {
    "id": "OnePSCatcher",
    "asset": {
      "logo": false,
      "detail": false,
      "binding": false,
      "hasAccent": false
    },
    "name": t('rawlingsGloves:sheets.webs.OnePSCatcher.name')
  },
  {
    "id": "ProHCatcher",
    "asset": {
      "idOverride": "ProH",
      "logo": false,
      "detail": false,
      "binding": false,
      "hasAccent": false
    },
    "name": t('rawlingsGloves:sheets.webs.ProHCatcher.name')
  },
  {
    "id": "TwoPSCatcher",
    "asset": {
      "logo": false,
      "detail": false,
      "binding": false,
      "hasAccent": false
    },
    "name": t('rawlingsGloves:sheets.webs.TwoPSCatcher.name')
  },
  {
    "id": "Laced2PS",
    "asset": {
      "logo": true,
      "detail": false,
      "binding": false,
      "hasAccent": false
    },
    "name": t('rawlingsGloves:sheets.webs.Laced2PS.name')
  },
  {
    "id": "Laced1PS",
    "asset": {
      "logo": true,
      "detail": false,
      "binding": false,
      "hasAccent": false
    },
    "name": t('rawlingsGloves:sheets.webs.Laced1PS.name')
  },
  {
    "id": "SplitSingleP32x",
    "asset": {
      "logo": false,
      "detail": false,
      "binding": false,
      "hasAccent": false
    },
    "name": t('rawlingsGloves:sheets.webs.SplitSingleP32x.name')
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<WebRow[]>> = data;

export type Web = typeof typed[number];

export const WEB_INDEX_KEY = "id";
export type WebIndexKey = "id";
export type WebId = Web["id"];

let i = 0;
export const WEB_DICT = {
  "SingleP": typed[i++],
  "SingleP1x": typed[i++],
  "ProI": typed[i++],
  "ProI2x": typed[i++],
  "Basket": typed[i++],
  "ModTrapeze": typed[i++],
  "ModTrapeze4x": typed[i++],
  "SinglePDB": typed[i++],
  "ProH": typed[i++],
  "ProH6x": typed[i++],
  "ProV": typed[i++],
  "ProV7x": typed[i++],
  "OnePS": typed[i++],
  "OnePS8x": typed[i++],
  "TwoPS": typed[i++],
  "TwoPS9x": typed[i++],
  "SinglePDBbase": typed[i++],
  "BasketConnector": typed[i++],
  "VerticalH": typed[i++],
  "ModSinglePost": typed[i++],
  "VerticalHB": typed[i++],
  "ModTrapezeLoop": typed[i++],
  "Ypost": typed[i++],
  "ModProH": typed[i++],
  "DoubleLacedBasket": typed[i++],
  "DoubleLacedBasket18x": typed[i++],
  "XLacedSingleP": typed[i++],
  "SinglePwXLace": typed[i++],
  "HorizontalXLaces": typed[i++],
  "Trapeze": typed[i++],
  "OnePSCatcher": typed[i++],
  "ProHCatcher": typed[i++],
  "TwoPSCatcher": typed[i++],
  "Laced2PS": typed[i++],
  "Laced1PS": typed[i++],
  "SplitSingleP32x": typed[i++]
} as const;

export { typed as WEBS };
