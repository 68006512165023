import { findKey } from '@technically/lodash'

import { setValues } from '~p/client/control-tree'
import { loadRecipe, setOriginValues } from '~p/client/common/actions'
import configureRouter from '~p/client/configureRouter'
import configureStore from '~p/client/configureStore'

import * as meta from '../common/meta'
import { PROP_DEF_DICT, PROP_DEFS } from '../common/sheets'
import updater from './updater'
import controlTree from './controlTree'

const store = configureStore({
  updater,
  initialState: {
    sheets: { propDefs: PROP_DEF_DICT },
    sheetLists: { propDefs: PROP_DEFS },
  },
  controlTree,
})

const routes = ['/sku/:sku', '/design/:recipeId(/:status)', '/review/:recipeId']

const { initialMatches } = configureRouter(controlTree, store, routes)

if (initialMatches) {
  const matches = initialMatches
  const { sku, recipeId } = matches

  if (recipeId) {
    store.dispatch(loadRecipe(controlTree, recipeId))
  } else if (sku) {
    const { leather, glove } = meta.getSkuValues(sku)
    store.dispatch(
      setValues({
        'product.leather': leather.id,
        'product.glove': glove.id,
        'filter.sport': glove.limitations.sportId,
        'filter.size': glove.props.sizeId,
        'product.throwingHand':
          glove.limitations.throwingHandIds.right ? 'right' : 'left',
        'product.fit': findKey(glove.limitations.fitIds, (x) => x),
      }),
    )
    store.dispatch(setOriginValues(controlTree))
  }
} else {
  store.dispatch(setOriginValues(controlTree))
}

export default store
