import { upperFirst } from '@technically/lodash'

import {
  getDualLayers,
  getFillLayer,
  getTextLayer,
} from '~p/client/renderComposite/meshLayerUtils'

import { LEATHER_COLOR_DICT, GLOVE_SIZE_DICT } from '../common/sheets'

export const getLayerInstructions = (expandedRecipeNested, viewName) => {
  const { product, colors, options, personalization, calc } =
    expandedRecipeNested
  const { glove, leather } = product
  const { web, trim, shellBack } = colors
  const { fingerPadHood, breakIn } = options
  const { embroidery, flag } = personalization
  const gloveAsset = calc.gloveAsset

  const assetProps = gloveAsset.props

  const shellBackAssetId =
    shellBack.material && shellBack.design.props.hasOwnAssets ?
      `${shellBack.design.id}Mesh`
    : null
  const shellBackMaterial =
    shellBack.material ?
      `${shellBackAssetId ? `${shellBackAssetId}-` : ''}${
        shellBack.material.props.materialIdSuffix ?? shellBack.material.id
      }`
    : null

  const hasFingerPad = !!(fingerPadHood && fingerPadHood.fingerPad)
  const hasFingerHood = !!(
    fingerPadHood &&
    fingerPadHood.fingerHood &&
    fingerPadHood.fingerHood.id === 'yes'
  )
  const hasFingerPadOrHood = hasFingerPad || hasFingerHood

  const hasLeatherPalmPad = options.palmPad?.id !== 'no'

  const hasStitching = colors.stitching.id !== 'toneOnTone'

  const hasHandSewnStitching =
    colors.welting &&
    colors.welting.handSewn &&
    colors.welting.handSewn.id === 'yes' &&
    colors.welting.handSewnStitching.id !== 'toneOnTone'

  const isStandardFit = product.fit && product.fit.id === 'standard'

  const lacesSuffix = glove.limitations.lacesSuffix || ''

  const hasEmbroideryLogo = !!embroidery.logo

  const hasMesh = (name) => {
    const meshAvailabilityMap = assetProps.deco3D[viewName]
    return meshAvailabilityMap[name] || false
  }

  const _getStamp = (meshName, imageName, colorOverride, bundleName) => {
    const stampingColor = colors.stamping

    if (stampingColor.props.assetId) {
      // isMetallic
      return [
        {
          bundleName: 'mesh-stamps',
          blend: 'shadow',
          name: `${imageName}-shadow`,
        },
        {
          bundleName: 'mesh-stamps',
          name: `${imageName}-metallic${stampingColor.props.assetId}`,
        },
      ]
    }

    const isIndent = stampingColor.id === 'indent'

    if (isIndent) {
      return getDualLayers({
        meshId: meshName,
        textureIdBase: imageName,
        color: colorOverride || colors.shellPalm,
        bundleName: bundleName || 'mesh-stamps',
      })
    }

    return getDualLayers({
      meshId: meshName,
      textureIdBase: imageName,
      color:
        colorOverride ||
        (stampingColor.props.colorId ?
          LEATHER_COLOR_DICT[stampingColor.props.colorId]
        : undefined),
      bundleName: bundleName || 'mesh-stamps',
    })
  }

  const getStamp = (meshName, imageName, indentColor, bundleName) => {
    if (!hasMesh(meshName)) {
      return null
    }

    let name = imageName

    if (meshName === 'PalmPadLeather' && options.palmPad.id === 'poronxrd') {
      name = name || 'PalmPadPoronXRD'
    }
    name = name || meshName

    return _getStamp(meshName, `stamps/${name}`, indentColor, bundleName)
  }

  const getGoldGlove = () => {
    const meshId = 'GoldGlove'

    if (!hasMesh(meshId)) {
      return null
    }
    const { text, font } = embroidery.pinky || {}

    if (text && font) {
      return getTextLayer({
        meshId,
        textData: {
          font: font.id,
          text,
        },
        color: embroidery.color,
      })
    }

    if (leather.id === 'gxle' || leather.id === 'rla') {
      return getStamp(meshId, 'GoldGlove', shellBack.leatherColor)
    }
    return getDualLayers({
      meshId,
      textureIdBase: 'other/GoldGloveEmbroidery',
      color: embroidery.color,
      bundleName: 'mesh-shadow',
    })
  }

  const getModelNameStamp = () => {
    const name = calc.skuLabel

    const { stamping } = colors

    const color = stamping.id === 'indent' ? undefined : stamping

    return [
      color && {
        bundleName: 'mesh-name',
        name: `name/${name}-fill`,
        color,
      },
      {
        bundleName: 'mesh-name',
        blend: 'shadow',
        name: `name/${name}-shadow`,
      },
    ]
  }

  const getSweatband = () =>
    options.sweatband && options.sweatband.id === 'yes' && { name: 'sweatband' }

  const getLogoPatch = () => [
    { name: 'patchLogo-dropShadow' },
    getFillLayer({
      meshId: 'Patch',
      textureId: `textures/patches/a${colors.logoPatch.asset.patchAssetId}.png`,
      // Fixes pinky loop from back layer going over patch for FM20XL/TM8SB.
      mask:
        gloveAsset.id === 'FM20XL' || gloveAsset.id === 'TM8SB' ?
          'patch-mask'
        : undefined,
    }),
  ]

  const getMLB = (useMask) => [
    {
      name: 'MLBLogo-dropShadow',
      mask: useMask ? 'MLB-mask' : undefined,
    },
    getFillLayer({
      meshId: 'MLB',
      textureId: `textures/patches/d${colors.logoPatch.asset.MLBAssetId}.png`,
      mask: useMask ? 'MLB-mask' : undefined,
    }),
  ]

  const getFlag = () => {
    if (!flag) {
      return undefined
    }
    if (!hasMesh('Flag')) {
      return null
    }
    return getFillLayer({
      meshId: 'Flag',
      textureId: `flags/${flag.id}.svg`,
      contain: true,
      // bundleName: 'mesh-fill',
    })
  }

  const getEmbroideryName = () => {
    if (!hasMesh('Name')) {
      return null
    }

    const { text, font } = embroidery.thumb
    if (!text || !font) {
      return null
    }

    return getTextLayer({
      meshId: 'Name',
      textData: {
        font: font.id,
        text,
      },
      color: embroidery.color,
      // bundleName: 'mesh-shadow',
    })
  }

  const getEmbroideryLogo = () => {
    if (!hasMesh('EmbroideryLogo')) {
      return null
    }

    if (
      shellBackAssetId === 'proMesh' &&
      glove.limitations.halfMeshEndsOnIndexFinger
    ) {
      return null
    }

    if (flag && !assetProps.uniqueFlagPlacement) {
      return null
    }

    if (embroidery.thumb.text && assetProps.embroideryNameReplacesLogo) {
      return null
    }

    return getDualLayers({
      meshId: 'EmbroideryLogo',
      textureIdBase: 'other/embroideryLogo',
      color: embroidery.color,
      bundleName: 'mesh-shadow',
    })
  }

  const getFingerPart = (fingerPartName, fingerPartColor) => [
    { name: fingerPartName, color: fingerPartColor },
    hasStitching && {
      name: `${fingerPartName}-stitching`,
      color: colors.stitching,
    },
    { name: `${fingerPartName}-shadow`, blend: 'shadow' },
  ]

  const getFingerPadAndHood = () => {
    if (!fingerPadHood || !fingerPadHood.position) {
      return undefined
    }

    const prefix = fingerPadHood.position.props.assetPrefix

    let padSuffix =
      (
        hasFingerHood &&
        fingerPadHood.position.id === 'index' &&
        glove.limitations.shellBackId === 'FB'
      ) ?
        'PadForHood'
      : 'Pad'

    // NOTE assets better should be corrected
    if (
      // PROPL31
      product.leather.props.sku === 'PRO' &&
      gloveAsset.id === 'PL31'
    ) {
      padSuffix = 'Pad'
    }

    const fingerColor =
      fingerPadHood.fingerPad && fingerPadHood.fingerPad.id === 'shell' ?
        shellBack.leatherColor
      : trim.color

    const fingerHoodColor =
      leather.id === 'rev1x' ? trim.color : shellBack.leatherColor

    return [
      hasFingerPad && getFingerPart(prefix + padSuffix, fingerColor),
      hasFingerHood && getFingerPart(`${prefix}Hood`, fingerHoodColor),
      leather.id === 'gxle' &&
        _getStamp(
          'FingerPadXRD',
          `stamps/${upperFirst(fingerPadHood.position.id)}FingerXRD`,
          shellBack.leatherColor,
          undefined,
        ),
    ]
  }

  const getMetallicLayer = ({ name, color, prefix, nameSuffix }) => {
    if (!color) {
      return undefined
    }

    // TODO Add layer order logic in usage
    // Metallic layers should be placed above shadow
    // Colorized layers should be placed below shadow
    // Currently both cases are placed below shadow
    const safePrefix = prefix ? `${prefix}-` : ''
    const safeSufix = nameSuffix || ''

    const adjustments = {
      metallicGold: {
        brightness: 0.95,
      },
      metallicRoseGold: {
        brightness: 0.95,
      },
      metallicSilver: {
        brightness: 0.9,
      },
      metallicRoyal: {
        brightness: 0.95,
      },
      metallicScarlet: {
        brightness: 0.95,
      },
    }

    if (color.props.isMetallic) {
      return {
        name: `${safePrefix}${name}`,
        filename: `${safePrefix}${name}${safeSufix}_${color.id}`,
        bundleName: 'metallic',
        ...adjustments[color.id],
      }
    }
    if (prefix) {
      return {
        name: `${safePrefix}${name}`,
        filename: `${safePrefix}${name}${safeSufix}`,
        color,
      }
    }
    // If neither prefix nor metallic is used, do nothing - covered with other
    // instructions at different level
    return undefined
  }

  const getWeb = (nameOfView, showLogo) => {
    if (!web || !web.style) {
      return null
    }
    const webStyle = web.style
    let webColor = web.color
    const webAssetId = webStyle.asset.idOverride || webStyle.id
    let layerNameBase = `${nameOfView}-${webAssetId}`
    let layerNameBaseForLaces = layerNameBase

    if (webAssetId === 'Trapeze') {
      webColor =
        nameOfView === 'palm' ? colors.shellPalm : shellBack.leatherColor
      if (nameOfView === 'web' && assetProps.palmOverlay && hasFingerPad) {
        layerNameBase += '-indexFinger'
        layerNameBaseForLaces = layerNameBase
      }
    }

    return [
      { name: `${layerNameBase}-base`, color: webColor },
      webStyle.asset.hasAccent && {
        name: `${layerNameBase}-trim1`,
        color: trim.color,
      },
      webStyle.asset.binding && {
        name: `${layerNameBase}-binding`,
        color: colors.binding,
      },
      hasStitching && {
        name: `${layerNameBase}-stitching`,
        color: colors.stitching,
      },
      webStyle.asset.detail && {
        name: `${layerNameBase}-detail`,
        color: webColor,
      },
      {
        name: `${layerNameBaseForLaces}-laces`,
        color: colors.laces.heel,
      },
      {
        name: `${layerNameBaseForLaces}-fingerLaces`,
        color: colors.laces.fingerWeb,
      },
      { name: `${layerNameBase}-shadow`, blend: 'shadow' },
      webStyle.asset.binding &&
        getMetallicLayer({
          name: `${layerNameBase}-binding`,
          color: colors.binding,
        }),
      showLogo &&
        webStyle.asset.logo &&
        getFillLayer({
          meshId: `Oval-${webAssetId}`,
          textureId: `textures/patches/b${colors.logoPatch.asset.ovalLogoBgAssetId}.png`,
          mask: `oval-${webAssetId}-mask`,
          invertMask: true,
        }),
      getMetallicLayer({
        name: `${layerNameBaseForLaces}-laces`,
        color: colors.laces.heel,
      }),
      getMetallicLayer({
        name: `${layerNameBaseForLaces}-fingerLaces`,
        color: colors.laces.fingerWeb,
      }),
    ]
  }

  const getOval = () => {
    const { color, number, logo } = embroidery
    const { logoPatch } = colors
    const hasOval = hasMesh('Oval')

    // If there's no oval, there's no logo to show.
    if (!hasOval) return undefined

    if (number?.text && number?.font) {
      return [
        getTextLayer({
          meshId: 'Oval',
          textData: {
            font: `${number.font.id}Number`,
            text: number.text,
          },
          color,
        }),
        getTextLayer({
          meshId: 'Oval',
          textData: {
            font: `${number.font.id}Number`,
            text: number.text,
          },
          blend: 'shadow',
          backgroundColor: '#808080',
        }),
      ]
    } else if (hasEmbroideryLogo) {
      return getFillLayer({
        meshId: 'Logo',
        textureId: `textures/logos/${logo.id}.png`,
        contain: true,
      })
    }

    return [
      hasStitching && { name: 'ovalLogo-stitching', color: colors.stitching },
      { name: 'ovalLogo-shadow', blend: 'shadow' },
      getFillLayer({
        meshId: 'Oval',
        textureId: `textures/patches/b${logoPatch.asset.ovalLogoBgAssetId}.png`,
        mask: 'oval-mask',
        invertMask: true,
      }),
    ]
  }

  const getLining = () => {
    if (colors.lining) {
      return {
        name: 'lining',
        color: colors.lining,
      }
    }

    // For HOH serie the LINING and the WEB color should be backPalm color
    // For Pro Preffered serie only The WEB color should be backPalm color
    if (glove.id === 'FL12TR') {
      return {
        name: 'lining',
        color: colors.backPalm,
      }
    }

    return undefined
  }

  const getTrim = () => {
    if (!trim.style) {
      return null
    }

    const isCroc = trim.color && trim.color.id === 'croc'

    const trimPrefix = trim.style.props.assetId

    const name = isCroc ? `${trimPrefix}-croc` : trimPrefix
    const color = !isCroc && trim.color

    return { name, color }
  }

  switch (viewName) {
    case 'back':
      return [
        assetProps.palmOnBackView && {
          name: 'palm',
          color: colors.shellPalm,
        },
        { name: 'back', color: shellBack.leatherColor },
        shellBackMaterial && {
          name: shellBackMaterial,
        },
        assetProps.hasBackPalm && {
          name: 'backPalm',
          color: colors.backPalm,
        },

        getTrim(),

        assetProps.liningOnBackView && getLining(),
        { name: 'binding', color: colors.binding },
        assetProps.weltingOnBackView &&
          !glove.asset.useStitchingInsteadOfWelting && [
            {
              name: 'backWelting',
              color: colors.welting.all || colors.welting.back,
            },
            {
              name: 'palmWelting',
              color: colors.welting.all || colors.welting.palm,
            },
          ],
        hasStitching && { name: 'stitching', color: colors.stitching },
        shellBackAssetId &&
          hasStitching && {
            name: `${shellBackAssetId}-stitching`,
            color: colors.stitching,
          },

        hasHandSewnStitching && {
          name: 'handSewn-stitching',
          color: colors.welting.handSewnStitching,
        },

        !hasFingerPadOrHood && getStamp('Fastback'),
        getStamp('Wing', undefined, shellBack.leatherColor),
        getGoldGlove(),

        getEmbroideryName(),
        getEmbroideryLogo(),
        getFlag(),
        getOval(),

        {
          name: 'gloveLaces',
          nameSuffix: lacesSuffix,
          color: colors.laces.heel,
        },
        {
          name: 'fingerLaces',
          nameSuffix: lacesSuffix,
          color: colors.laces.fingerWeb,
        },
        !hasFingerPad &&
          assetProps.holdsterLaces && {
            name: 'holdsterLaces',
            color: shellBack.leatherColor,
          },

        { name: 'shadow', nameSuffix: lacesSuffix, blend: 'shadow' },

        getMetallicLayer({
          name: 'binding',
          color: colors.binding,
        }),
        assetProps.weltingOnBackView &&
          !glove.asset.useStitchingInsteadOfWelting && [
            getMetallicLayer({
              prefix: shellBackAssetId,
              name: 'backWelting',
              color: colors.welting.all || colors.welting.back,
            }),
            getMetallicLayer({
              prefix: shellBackAssetId,
              name: 'palmWelting',
              color: colors.welting.all || colors.welting.palm,
            }),
          ],

        getMetallicLayer({
          name: 'gloveLaces',
          nameSuffix: lacesSuffix,
          color: colors.laces.heel,
        }),
        getMetallicLayer({
          name: 'fingerLaces',
          nameSuffix: lacesSuffix,
          color: colors.laces.fingerWeb,
        }),
        shellBackAssetId && {
          name: `${shellBackAssetId}-shadow`,
          blend: 'shadow',
        },
        assetProps.hasStandardFitShadowOnBack &&
          isStandardFit && {
            name: 'standardFit-shadow',
          },

        assetProps.insertOnBackView && { name: 'insert' },

        getFingerPadAndHood(),
        gloveAsset.props.logoPatchOnBackView && getLogoPatch(),
        glove.limitations.sportId === 'baseball' &&
          gloveAsset.props.MLBOnBackView &&
          getMLB(gloveAsset.props.MLBOnBackViewMask),
        getSweatband(),

        assetProps.webOnBackView && getWeb('back'),
      ]

    case 'web':
      return [
        assetProps.palmOnWebView && { name: 'palm', color: colors.shellPalm },
        { name: 'back', color: shellBack.leatherColor },
        assetProps.palmOnWebView &&
          shellBackAssetId === 'decoMesh' && {
            name: `${shellBackAssetId}-back`,
            color: shellBack.leatherColor,
          },
        shellBackMaterial && {
          name: shellBackMaterial,
        },
        assetProps.hasBackPalm && { name: 'backPalm', color: colors.backPalm },

        getTrim(),

        getLining(),
        { name: 'binding', color: colors.binding },
        !shellBackAssetId &&
          assetProps.welting &&
          !glove.asset.useStitchingInsteadOfWelting && [
            {
              name: 'backWelting',
              color: colors.welting.all || colors.welting.back,
            },
            {
              name: 'palmWelting',
              color: colors.welting.all || colors.welting.palm,
            },
          ],

        hasStitching && { name: 'stitching', color: colors.stitching },
        hasStitching &&
          assetProps.palmOnWebView &&
          shellBackAssetId !== 'decoMesh' && {
            name: 'palmOnWebView-stitching',
            color: colors.stitching,
          },

        hasHandSewnStitching && {
          name: 'handSewn-stitching',
          color: colors.welting.handSewnStitching,
        },

        shellBackAssetId &&
          hasStitching && {
            name: `${shellBackAssetId}-stitching`,
            color: colors.stitching,
          },
        shellBack.design.id === 'deco' &&
          shellBack.material &&
          shellBack.material.props.hasExtraStitching &&
          gloveAsset.props.extraStitching && [
            hasStitching && {
              name: 'decoMesh-extra-stitching',
              color: colors.stitching,
            },
            {
              name: 'decoMesh-extraStitching-shadow',
              blend: 'shadow',
            },
            {
              name: 'edge',
            },
          ],

        getFlag(),
        getOval(),
        getEmbroideryName(),
        getEmbroideryLogo(),
        getGoldGlove(),

        !hasFingerPad &&
          assetProps.holdsterLaces && {
            name: 'holdsterLaces',
            color: shellBack.leatherColor,
          },

        gloveAsset.props.logoPatchOnWebView && getLogoPatch(),
        assetProps.pullStrapAboveOval && {
          name: 'pullStrapAboveOval',
          color: trim.color,
        },
        assetProps.pullStrapAboveOval &&
          hasStitching && {
            name: 'pullStrapAboveOval-stitching',
            color: colors.stitching,
          },
        {
          name: 'gloveLaces',
          nameSuffix: lacesSuffix,
          color: colors.laces.heel,
        },
        {
          name: 'fingerLaces',
          nameSuffix: lacesSuffix,
          color: colors.laces.fingerWeb,
        },

        !(assetProps.palmOnWebView && shellBackAssetId === 'decoMesh') && {
          name: 'shadow',
          nameSuffix: lacesSuffix,
          blend: 'shadow',
        },

        getMetallicLayer({
          name: 'binding',
          color: colors.binding,
        }),
        assetProps.welting &&
          !glove.asset.useStitchingInsteadOfWelting && [
            getMetallicLayer({
              prefix: shellBackAssetId,
              name: 'backWelting',
              color: colors.welting.all || colors.welting.back,
            }),
            getMetallicLayer({
              prefix: shellBackAssetId,
              name: 'palmWelting',
              color: colors.welting.all || colors.welting.palm,
            }),
          ],

        getMetallicLayer({
          name: 'gloveLaces',
          nameSuffix: lacesSuffix,
          color: colors.laces.heel,
        }),
        getMetallicLayer({
          name: 'fingerLaces',
          nameSuffix: lacesSuffix,
          color: colors.laces.fingerWeb,
        }),
        shellBackAssetId && {
          name: `${shellBackAssetId}-shadow`,
          blend: 'shadow',
        },
        assetProps.hasStandardFitShadowOnWeb &&
          isStandardFit && {
            name: 'standardFit-shadow',
          },

        assetProps.insertOnWebView && { name: 'insert' },
        !hasFingerPadOrHood &&
          getStamp('Fastback', undefined, shellBack.leatherColor),
        getStamp('Wing', undefined, shellBack.leatherColor),

        hasLeatherPalmPad &&
          getStamp('PalmPadLeather', undefined, colors.lining),

        getFingerPadAndHood(),
        getWeb('web', true),
        getStamp('TrapezeWeb', undefined, shellBack.leatherColor), // only for 'PRO601-FB'
        glove.limitations.sportId === 'baseball' &&
          gloveAsset.props.MLBOnWebView &&
          getMLB(gloveAsset.props.MLBOnWebViewMask),
        getSweatband(),
      ]

    case 'palm':
      return [
        { name: 'palm', color: colors.shellPalm },
        assetProps.hasBackOnPalmView && {
          name: 'back',
          color: shellBack.leatherColor,
        },
        shellBackMaterial && {
          name: shellBackMaterial,
        },

        getTrim(),
        breakIn?.id === 'R2G' && getStamp(breakIn.id),

        assetProps.liningOnPalmView && getLining(),
        { name: 'binding', color: colors.binding },
        assetProps.welting &&
          !glove.asset.useStitchingInsteadOfWelting && [
            {
              name: 'backWelting',
              color: colors.welting.all || colors.welting.back,
            },
            {
              name: 'palmWelting',
              color: colors.welting.all || colors.welting.palm,
            },
          ],

        hasStitching && { name: 'stitching', color: colors.stitching },
        glove.asset.useStitchingInsteadOfWelting &&
          hasStitching && { name: 'stitching-extra', color: colors.stitching },
        shellBackAssetId &&
          hasStitching && {
            name: `${shellBackAssetId}-stitching`,
            color: colors.stitching,
          },

        getStamp('LeatherType', leather.id.toUpperCase()),
        getStamp('LiTeToe'),
        getStamp('Professional'),
        getStamp('RawlingsStamp'),
        getStamp('REV1XRawlingsStamp'),
        getStamp('REV1XPinky'),
        getStamp('USSteerhide', leather.id === 'rla' ? 'ProDesign' : undefined),

        leather.id === 'rev1x' ?
          getTextLayer({
            meshId: 'pinkyPalmModel',
            textData: {
              font:
                colors.stamping.id === 'indent' ?
                  'WeidemannBoldItalicStamping'
                : 'WeidemannBoldItalic',
              text: `${calc.skuLabel} • ${
                GLOVE_SIZE_DICT[glove.props.sizeId].name
              }`,
            },
            color: colors.stamping,
          })
        : getModelNameStamp(),

        assetProps.hasPinkyPalm &&
          embroidery.pinkyPalm?.text && [
            getTextLayer({
              meshId: 'pinkyPalmPersonalization',
              textData: {
                font:
                  colors.stamping.id === 'indent' ?
                    'WeidemannBoldItalicStamping'
                  : 'WeidemannBoldItalic',
                text: embroidery.pinkyPalm.text,
              },
              color: colors.stamping,
            }),
          ],

        {
          name: 'gloveLaces',
          nameSuffix: lacesSuffix,
          color: colors.laces.heel,
        },
        {
          name: 'fingerLaces',
          nameSuffix: lacesSuffix,
          color: colors.laces.fingerWeb,
        },

        {
          name: 'shadow',
          nameSuffix:
            glove.asset.useStitchingInsteadOfWelting ?
              '-stitchingExtra'
            : lacesSuffix,
          blend: 'shadow',
        },

        getMetallicLayer({
          name: 'binding',
          color: colors.binding,
        }),
        assetProps.welting &&
          !glove.asset.useStitchingInsteadOfWelting && [
            getMetallicLayer({
              name: 'backWelting',
              color: colors.welting.all || colors.welting.back,
            }),
            getMetallicLayer({
              name: 'palmWelting',
              color: colors.welting.all || colors.welting.palm,
            }),
          ],
        // meshFabricAssetId && { name: meshFabricAssetId + '-welting', color: colors.welting },
        shellBackAssetId && {
          name: `${shellBackAssetId}-shadow`,
          blend: 'shadow',
        },

        getMetallicLayer({
          name: 'gloveLaces',
          nameSuffix: lacesSuffix,
          color: colors.laces.heel,
        }),
        getMetallicLayer({
          name: 'fingerLaces',
          nameSuffix: lacesSuffix,
          color: colors.laces.fingerWeb,
        }),

        assetProps.insertOnPalmView && { name: 'insert' },

        getWeb('palm'),
      ]

    default:
      return []
  }
}
