import { getState } from './state.js';
import type { Ref, State } from './types.js';

const clearCanvas = (stateRef: Ref<State>) => (): void => {
  const { emptyScene } = getState(stateRef);

  if (!emptyScene) {
    return;
  }

  emptyScene.render();
};

export default clearCanvas;
