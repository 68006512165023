import {
  GLOVES,
  GLOVE_ASSET_DICT,
  LEATHERS,
  STAMP_COLORS,
} from '../../common/sheets'
import generateSnapshots, { Job } from './generate'
import { PartialWithUndefined } from '@orangelv/utils'

const VIEW_NAME = 'palm'
const HEIGHT = 512

const main = async ({
  gloveId,
  gloveAssetId,
  leatherId,
  stampColorId,
}: PartialWithUndefined<{
  gloveId: string
  gloveAssetId: string
  leatherId: string
  stampColorId: string
}> = {}) => {
  const filenames = new Set<string>()
  const jobs: Job[] = []

  for (const glove of GLOVES) {
    if (gloveId !== undefined && glove.id !== gloveId) continue

    const gloveAsset = GLOVE_ASSET_DICT[glove.asset.gloveAssetId]
    if (gloveAssetId !== undefined && gloveAsset.id !== gloveAssetId) continue

    for (const leather of LEATHERS) {
      if (
        (leatherId !== undefined && leather.id !== leatherId) ||
        !glove.limitations.leatherId[leather.id]
      ) {
        continue
      }

      for (const stampColor of STAMP_COLORS) {
        if (
          (stampColorId !== undefined && stampColor.id !== stampColorId) ||
          !stampColor.subsets.leatherId[leather.id]
        ) {
          continue
        }

        const filename = [gloveAsset.id, leather.id, stampColor.id].join('   ')
        if (filenames.has(filename)) continue
        filenames.add(filename)

        const sourceSize = gloveAsset.props.imageDimensions[VIEW_NAME]
        jobs.push({
          filename,
          controlTreeChanges: [
            ['product.leather', leather.id],
            ['product.glove', glove.id],
            [`colors.stamping`, stampColor.id],
          ],
          viewName: VIEW_NAME,
          size: {
            width: Math.round((HEIGHT / sourceSize.height) * sourceSize.width),
            height: HEIGHT,
          },
        })
      }
    }
  }

  console.log('Jobs:')
  console.log(jobs)

  return generateSnapshots(jobs)
}

export default main
