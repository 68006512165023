import type { Props, Ref, State } from '../types.js';
import { isMaterialPropertyFabric } from './checks.js';

type MaterialIdMapping = Record<string, string[]>;

const getFabricMaterialIdsByModelId =
  (_stateRef: Ref<State>, props: Props) => (): MaterialIdMapping => {
    const { config } = props;

    const mapping: MaterialIdMapping = {};
    // TODO: [CP-1237] Add support for other textures, not just diffuse texture.
    for (const [modelId, modelConfig] of Object.entries(config.models)) {
      if (!modelConfig) {
        continue;
      }

      const materials = modelConfig.materials ?? {};
      const materialIds = Object.keys(materials);
      mapping[modelId] = materialIds.filter((id) => {
        const mat = materials[id];
        return (
          !!mat?.diffuseTexture && isMaterialPropertyFabric(mat.diffuseTexture)
        );
      });
    }

    return mapping;
  };

export default getFabricMaterialIdsByModelId;
