import React from 'react'

import Icon from './Icon'

import './ReturnToSite.css'

type Props = {
  name?: string
  url?: string
}

const ReturnToSite = (props: Props) => {
  if (!props.name || !props.url) {
    return null
  }
  return (
    <div className="return-to-site">
      <a href={props.url} className="return-to-site-url">
        <span className="return-icon">
          <Icon name="arrow-back" />
        </span>
        <span className="return-url">{props.name}</span>
      </a>
    </div>
  )
}

export default ReturnToSite
