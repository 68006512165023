import type { DynamicTexture, PBRMaterial, Texture } from '@babylonjs/core';

import { normalizeColor, toColor3 } from '../colors.js';
import type { MaterialConfigColors, MaterialConfigTextures } from '../types.js';
import updateTexture from './update-texture.js';

const TEXTURE_WIDTH = 1024 * 2;
const TEXTURE_HEIGHT = 1024 * 2;

const COLOR_PROPERTIES: [
  keyof MaterialConfigColors,
  (material: PBRMaterial, propertyColor: string) => void,
][] = [
  [
    'diffuseColor',
    (material: PBRMaterial, propertyColor: string): void => {
      material.albedoColor = toColor3(
        normalizeColor(propertyColor),
      ).toLinearSpace();
    },
  ],
  [
    'specularColor',
    (material: PBRMaterial, propertyColor: string): void => {
      material.reflectivityColor = toColor3(
        normalizeColor(propertyColor).toLinearSpace(),
      );
    },
  ],
  [
    'emissiveColor',
    (material: PBRMaterial, propertyColor: string): void => {
      material.emissiveColor = toColor3(
        normalizeColor(propertyColor),
      ).toLinearSpace();
    },
  ],
  [
    'ambientColor',
    (material: PBRMaterial, propertyColor: string): void => {
      material.ambientColor = toColor3(
        normalizeColor(propertyColor),
      ).toLinearSpace();
    },
  ],
];

const TEXTURE_PROPERTIES: [
  keyof MaterialConfigTextures,
  (
    material: PBRMaterial,
    dynamicTexture: DynamicTexture,
  ) => Promise<void> | void,
][] = [
  [
    'diffuseTexture',
    (material, dynamicTexture): void => {
      material.albedoTexture = dynamicTexture;
    },
  ],
  [
    'specularTexture',
    (material, dynamicTexture): void => {
      material.reflectionTexture = dynamicTexture;
    },
  ],
  [
    'emissiveTexture',
    (material, dynamicTexture): void => {
      material.emissiveTexture = dynamicTexture;
    },
  ],
  [
    'normalTexture',
    async (material, dynamicTexture): Promise<void> => {
      const bumpTexture = material.bumpTexture as Texture | undefined;

      if (bumpTexture) {
        // Draw the canvas content onto the existing texture.
        // TODO: [CP-1237] It might be better to update the dynamic texture and
        // use that instead.
        await updateTexture(dynamicTexture, bumpTexture);
      } else {
        material.bumpTexture = dynamicTexture;
      }
    },
  ],
];

const TEXTURE_PROPERTY_TO_BABYLON_PROPERTY = {
  diffuseTexture: 'albedoTexture',
  specularTexture: 'reflectionTexture',
  emissiveTexture: 'emissiveTexture',
  normalTexture: 'bumpTexture',
  ambientTexture: 'ambientTexture',
} as const;

export {
  COLOR_PROPERTIES,
  TEXTURE_HEIGHT,
  TEXTURE_PROPERTIES,
  TEXTURE_PROPERTY_TO_BABYLON_PROPERTY,
  TEXTURE_WIDTH,
};
