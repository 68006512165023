import assert from '../../../platform/assert'
import http from '../../../platform/client/http'
import getAsset from '../../../platform/getAsset'

import loadImage from './loadImage'
import { Node } from '../common/typings'

type CharData = Record<string, number>
type CharsData = Record<string, CharData>

const fontCache: Record<
  string,
  Promise<{ imageElement: HTMLImageElement; charsData: CharsData }>
> = {}

const getBitmapTextTexture = (
  fontNode: Node,
  textNode: Node,
  colorNode: Node,
  canvasSize: { width: number; height: number },
) => ({
  getCanvas: async (canvas: HTMLCanvasElement) => {
    const context = canvas.getContext('2d')
    assert(context)

    const text = textNode.value

    if (!text) {
      context.clearRect(0, 0, canvas.width, canvas.height)
      return
    }

    const fontId = `${fontNode.value}Number`

    let fontPromise = fontCache[fontId]

    if (!fontPromise) {
      fontPromise = (async () => {
        const imagePromise = loadImage(getAsset(`fonts/${fontId}.png`))
        const fntPromise = http
          .get<string>(getAsset(`fonts/${fontId}.fnt`))
          .then((response) => response.data)

        const [imageElement, fntData] = await Promise.all([
          imagePromise,
          fntPromise,
        ])

        // https://www.angelcode.com/products/bmfont/doc/file_format.html
        const charsData = Object.fromEntries(
          fntData
            .split('\n')
            .filter((line) => line.startsWith('char id='))
            .map((line) => {
              const charData: CharData = {}
              line
                .trim()
                .slice('char '.length)
                .split(/\s+/)
                .forEach((x) => {
                  const [key, value] = x.split('=')
                  charData[key] = parseInt(value, 10)
                })
              return [String.fromCharCode(charData.id), charData]
            }),
        )

        return { imageElement, charsData }
      })()

      fontCache[fontId] = fontPromise
    }

    const { imageElement, charsData } = await fontPromise

    canvas.width = canvasSize.width
    canvas.height = canvasSize.height

    const maxHeight = Math.max(
      ...Object.values(charsData).map((charData) => charData.height),
    )

    // Scale based on height so that the highest char would always fill the canvas vertically.
    const scale = canvas.height / maxHeight

    const chars: string[] = text.split('')

    // Calculate the width of all chars that need to be rendered.
    const charsWidth = chars.reduce((a, char) => {
      const charData = charsData[char]
      return charData.xadvance * scale + a
    }, 0)

    // Calculate the initial position of x for centered text.
    let x = (canvas.width - charsWidth) / 2

    for (const char of chars) {
      const charData = charsData[char]

      // Render char onto canvas in correct position.
      context.drawImage(
        imageElement,
        charData.x,
        charData.y,
        charData.width,
        charData.height,
        x,
        0,
        charData.width * scale,
        charData.height * scale,
      )

      x += charData.xadvance * scale
    }

    // Create a snapshot of current canvas where we have correct characters in correct positions, just monochrome.
    const monochromeCanvas = document.createElement('canvas')
    monochromeCanvas.width = canvas.width
    monochromeCanvas.height = canvas.height

    const monochromeContext = monochromeCanvas.getContext('2d')
    assert(monochromeContext)

    monochromeContext.drawImage(canvas, 0, 0)

    // Overlay composite with color the whole canvas.
    context.fillStyle = colorNode.object.props.hex
    context.globalCompositeOperation = 'overlay'
    context.fillRect(0, 0, canvas.width, canvas.height)

    // Mask out unwanted color from background.
    context.globalCompositeOperation = 'destination-in'
    context.drawImage(monochromeCanvas, 0, 0)
  },
  key: [fontNode.value, textNode.value, colorNode.value],
})

export default getBitmapTextTexture
