// DO NOT EDIT, GENERATED BY "yarn sheets"

import { t } from '../../../../platform/i18n'

import type { PatchColorRow } from '../typings';

const data = [
  {
    "id": "eran",
    "subsets": {
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": true,
        "rla": true,
        "rev1x": false
      }
    },
    "asset": {
      "patchAssetId": "01",
      "ovalLogoBgAssetId": "01",
      "MLBAssetId": "01"
    },
    "leatherColorId": "black",
    "name": t('rawlingsGloves:sheets.patchColors.eran.name')
  },
  {
    "id": "erapbw",
    "subsets": {
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": false,
        "rla": true,
        "rev1x": false
      }
    },
    "asset": {
      "patchAssetId": "02",
      "ovalLogoBgAssetId": "02",
      "MLBAssetId": "06"
    },
    "leatherColorId": "black",
    "name": t('rawlingsGloves:sheets.patchColors.erapbw.name')
  },
  {
    "id": "erasb",
    "subsets": {
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": false,
        "rla": true,
        "rev1x": false
      }
    },
    "asset": {
      "patchAssetId": "03",
      "ovalLogoBgAssetId": "03",
      "MLBAssetId": "03"
    },
    "leatherColorId": "black",
    "name": t('rawlingsGloves:sheets.patchColors.erasb.name')
  },
  {
    "id": "erasgl",
    "subsets": {
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": false,
        "rla": true,
        "rev1x": false
      }
    },
    "asset": {
      "patchAssetId": "04",
      "ovalLogoBgAssetId": "04",
      "MLBAssetId": "03"
    },
    "leatherColorId": "black",
    "name": t('rawlingsGloves:sheets.patchColors.erasgl.name')
  },
  {
    "id": "eraob",
    "subsets": {
      "leatherId": {
        "kip": false,
        "hoh": false,
        "gxle": false,
        "rla": false,
        "rev1x": false
      }
    },
    "asset": {
      "patchAssetId": "05",
      "ovalLogoBgAssetId": "05",
      "MLBAssetId": "01"
    },
    "leatherColorId": "black",
    "name": t('rawlingsGloves:sheets.patchColors.eraob.name')
  },
  {
    "id": "erabsv",
    "subsets": {
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": false,
        "rla": true,
        "rev1x": false
      }
    },
    "asset": {
      "patchAssetId": "06",
      "ovalLogoBgAssetId": "06",
      "MLBAssetId": "02"
    },
    "leatherColorId": "black",
    "name": t('rawlingsGloves:sheets.patchColors.erabsv.name')
  },
  {
    "id": "erabr",
    "subsets": {
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": false,
        "rla": true,
        "rev1x": false
      }
    },
    "asset": {
      "patchAssetId": "07",
      "ovalLogoBgAssetId": "07",
      "MLBAssetId": "04"
    },
    "leatherColorId": "black",
    "name": t('rawlingsGloves:sheets.patchColors.erabr.name')
  },
  {
    "id": "erabo",
    "subsets": {
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": false,
        "rla": true,
        "rev1x": false
      }
    },
    "asset": {
      "patchAssetId": "08",
      "ovalLogoBgAssetId": "08",
      "MLBAssetId": "11"
    },
    "leatherColorId": "black",
    "name": t('rawlingsGloves:sheets.patchColors.erabo.name')
  },
  {
    "id": "erabgd",
    "subsets": {
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": false,
        "rla": true,
        "rev1x": false
      }
    },
    "asset": {
      "patchAssetId": "09",
      "ovalLogoBgAssetId": "09",
      "MLBAssetId": "02"
    },
    "leatherColorId": "black",
    "name": t('rawlingsGloves:sheets.patchColors.erabgd.name')
  },
  {
    "id": "erabs",
    "subsets": {
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": false,
        "rla": true,
        "rev1x": false
      }
    },
    "asset": {
      "patchAssetId": "10",
      "ovalLogoBgAssetId": "10",
      "MLBAssetId": "03"
    },
    "leatherColorId": "black",
    "name": t('rawlingsGloves:sheets.patchColors.erabs.name')
  },
  {
    "id": "erawb",
    "subsets": {
      "leatherId": {
        "kip": false,
        "hoh": false,
        "gxle": false,
        "rla": false,
        "rev1x": false
      }
    },
    "asset": {
      "patchAssetId": "11",
      "ovalLogoBgAssetId": "11",
      "MLBAssetId": "01"
    },
    "leatherColorId": "black",
    "name": t('rawlingsGloves:sheets.patchColors.erawb.name')
  },
  {
    "id": "erars",
    "subsets": {
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": false,
        "rla": true,
        "rev1x": false
      }
    },
    "asset": {
      "patchAssetId": "12",
      "ovalLogoBgAssetId": "12",
      "MLBAssetId": "09"
    },
    "leatherColorId": "black",
    "name": t('rawlingsGloves:sheets.patchColors.erars.name')
  },
  {
    "id": "erans",
    "subsets": {
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": false,
        "rla": true,
        "rev1x": false
      }
    },
    "asset": {
      "patchAssetId": "13",
      "ovalLogoBgAssetId": "13",
      "MLBAssetId": "01"
    },
    "leatherColorId": "black",
    "name": t('rawlingsGloves:sheets.patchColors.erans.name')
  },
  {
    "id": "eradgw",
    "subsets": {
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": false,
        "rla": true,
        "rev1x": false
      }
    },
    "asset": {
      "patchAssetId": "14",
      "ovalLogoBgAssetId": "14",
      "MLBAssetId": "07"
    },
    "leatherColorId": "black",
    "name": t('rawlingsGloves:sheets.patchColors.eradgw.name')
  },
  {
    "id": "eranw",
    "subsets": {
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": false,
        "rla": true,
        "rev1x": false
      }
    },
    "asset": {
      "patchAssetId": "15",
      "ovalLogoBgAssetId": "15",
      "MLBAssetId": "05"
    },
    "leatherColorId": "black",
    "name": t('rawlingsGloves:sheets.patchColors.eranw.name')
  },
  {
    "id": "erano",
    "subsets": {
      "leatherId": {
        "kip": false,
        "hoh": false,
        "gxle": false,
        "rla": false,
        "rev1x": false
      }
    },
    "asset": {
      "patchAssetId": "16",
      "ovalLogoBgAssetId": "16",
      "MLBAssetId": "01"
    },
    "leatherColorId": "black",
    "name": t('rawlingsGloves:sheets.patchColors.erano.name')
  },
  {
    "id": "erarw",
    "subsets": {
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": false,
        "rla": true,
        "rev1x": false
      }
    },
    "asset": {
      "patchAssetId": "17",
      "ovalLogoBgAssetId": "17",
      "MLBAssetId": "04"
    },
    "leatherColorId": "black",
    "name": t('rawlingsGloves:sheets.patchColors.erarw.name')
  },
  {
    "id": "erangd",
    "subsets": {
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": false,
        "rla": true,
        "rev1x": false
      }
    },
    "asset": {
      "patchAssetId": "18",
      "ovalLogoBgAssetId": "18",
      "MLBAssetId": "05"
    },
    "leatherColorId": "black",
    "name": t('rawlingsGloves:sheets.patchColors.erangd.name')
  },
  {
    "id": "erapw",
    "subsets": {
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": false,
        "rla": true,
        "rev1x": false
      }
    },
    "asset": {
      "patchAssetId": "19",
      "ovalLogoBgAssetId": "19",
      "MLBAssetId": "08"
    },
    "leatherColorId": "black",
    "name": t('rawlingsGloves:sheets.patchColors.erapw.name')
  },
  {
    "id": "erawbrwch",
    "subsets": {
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": false,
        "rla": true,
        "rev1x": false
      }
    },
    "asset": {
      "patchAssetId": "21",
      "ovalLogoBgAssetId": "21",
      "MLBAssetId": "10"
    },
    "leatherColorId": "black",
    "name": t('rawlingsGloves:sheets.patchColors.erawbrwch.name')
  },
  {
    "id": "erawcbw",
    "subsets": {
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": false,
        "rla": true,
        "rev1x": false
      }
    },
    "asset": {
      "patchAssetId": "22",
      "ovalLogoBgAssetId": "22",
      "MLBAssetId": "02"
    },
    "leatherColorId": "black",
    "name": t('rawlingsGloves:sheets.patchColors.erawcbw.name')
  },
  {
    "id": "erawgbw",
    "subsets": {
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": false,
        "rla": true,
        "rev1x": false
      }
    },
    "asset": {
      "patchAssetId": "23",
      "ovalLogoBgAssetId": "23",
      "MLBAssetId": "02"
    },
    "leatherColorId": "black",
    "name": t('rawlingsGloves:sheets.patchColors.erawgbw.name')
  },
  {
    "id": "erawrgdb",
    "subsets": {
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": false,
        "rla": true,
        "rev1x": false
      }
    },
    "asset": {
      "patchAssetId": "24",
      "ovalLogoBgAssetId": "24",
      "MLBAssetId": "04"
    },
    "leatherColorId": "black",
    "name": t('rawlingsGloves:sheets.patchColors.erawrgdb.name')
  },
  {
    "id": "erawboyw",
    "subsets": {
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": false,
        "rla": true,
        "rev1x": false
      }
    },
    "asset": {
      "patchAssetId": "25",
      "ovalLogoBgAssetId": "25",
      "MLBAssetId": "12"
    },
    "leatherColorId": "black",
    "name": t('rawlingsGloves:sheets.patchColors.erawboyw.name')
  },
  {
    "id": "erawbrgdw",
    "subsets": {
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": false,
        "rla": true,
        "rev1x": false
      }
    },
    "asset": {
      "patchAssetId": "26",
      "ovalLogoBgAssetId": "26",
      "MLBAssetId": "02"
    },
    "leatherColorId": "black",
    "name": t('rawlingsGloves:sheets.patchColors.erawbrgdw.name')
  },
  {
    "id": "erawcbwb",
    "subsets": {
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": false,
        "rla": true,
        "rev1x": false
      }
    },
    "asset": {
      "patchAssetId": "27",
      "ovalLogoBgAssetId": "27",
      "MLBAssetId": "15"
    },
    "leatherColorId": "black",
    "name": t('rawlingsGloves:sheets.patchColors.erawcbwb.name')
  },
  {
    "id": "erawhsbmw",
    "subsets": {
      "leatherId": {
        "kip": false,
        "hoh": false,
        "gxle": false,
        "rla": false,
        "rev1x": false
      }
    },
    "asset": {
      "patchAssetId": "28",
      "ovalLogoBgAssetId": "28",
      "MLBAssetId": "02"
    },
    "leatherColorId": "black",
    "name": t('rawlingsGloves:sheets.patchColors.erawhsbmw.name')
  },
  {
    "id": "erawmgwb",
    "subsets": {
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": false,
        "rla": true,
        "rev1x": false
      }
    },
    "asset": {
      "patchAssetId": "29",
      "ovalLogoBgAssetId": "29",
      "MLBAssetId": "14"
    },
    "leatherColorId": "black",
    "name": t('rawlingsGloves:sheets.patchColors.erawmgwb.name')
  },
  {
    "id": "erawsswcbb",
    "subsets": {
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": false,
        "rla": true,
        "rev1x": false
      }
    },
    "asset": {
      "patchAssetId": "30",
      "ovalLogoBgAssetId": "30",
      "MLBAssetId": "04"
    },
    "leatherColorId": "black",
    "name": t('rawlingsGloves:sheets.patchColors.erawsswcbb.name')
  },
  {
    "id": "erawwmg",
    "subsets": {
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": false,
        "rla": true,
        "rev1x": false
      }
    },
    "asset": {
      "patchAssetId": "31",
      "ovalLogoBgAssetId": "31",
      "MLBAssetId": "02"
    },
    "leatherColorId": "black",
    "name": t('rawlingsGloves:sheets.patchColors.erawwmg.name')
  },
  {
    "id": "erawwsn",
    "subsets": {
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": false,
        "rla": true,
        "rev1x": false
      }
    },
    "asset": {
      "patchAssetId": "32",
      "ovalLogoBgAssetId": "32",
      "MLBAssetId": "01"
    },
    "leatherColorId": "black",
    "name": t('rawlingsGloves:sheets.patchColors.erawwsn.name')
  },
  {
    "id": "erapkw",
    "subsets": {
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": false,
        "rla": true,
        "rev1x": false
      }
    },
    "asset": {
      "patchAssetId": "33",
      "ovalLogoBgAssetId": "33",
      "MLBAssetId": "13"
    },
    "leatherColorId": "black",
    "name": t('rawlingsGloves:sheets.patchColors.erapkw.name')
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<PatchColorRow[]>> = data;

export type PatchColor = typeof typed[number];

export const PATCH_COLOR_INDEX_KEY = "id";
export type PatchColorIndexKey = "id";
export type PatchColorId = PatchColor["id"];

let i = 0;
export const PATCH_COLOR_DICT = {
  "eran": typed[i++],
  "erapbw": typed[i++],
  "erasb": typed[i++],
  "erasgl": typed[i++],
  "eraob": typed[i++],
  "erabsv": typed[i++],
  "erabr": typed[i++],
  "erabo": typed[i++],
  "erabgd": typed[i++],
  "erabs": typed[i++],
  "erawb": typed[i++],
  "erars": typed[i++],
  "erans": typed[i++],
  "eradgw": typed[i++],
  "eranw": typed[i++],
  "erano": typed[i++],
  "erarw": typed[i++],
  "erangd": typed[i++],
  "erapw": typed[i++],
  "erawbrwch": typed[i++],
  "erawcbw": typed[i++],
  "erawgbw": typed[i++],
  "erawrgdb": typed[i++],
  "erawboyw": typed[i++],
  "erawbrgdw": typed[i++],
  "erawcbwb": typed[i++],
  "erawhsbmw": typed[i++],
  "erawmgwb": typed[i++],
  "erawsswcbb": typed[i++],
  "erawwmg": typed[i++],
  "erawwsn": typed[i++],
  "erapkw": typed[i++]
} as const;

export { typed as PATCH_COLORS };
