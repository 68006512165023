import React, { Component } from 'react'
import cn from 'classnames'
import { findIndex, keys, reverse, map, size } from '@technically/lodash'

import { Swipeable } from '~p/client/components/Swipeable'
import { defaultViewAngleId } from '~p/viewAngles'

import Icon from './Icon'

import './Gallery.css'

const getNextKey = (xs, x) => xs[findIndex(xs, (y) => y === x) + 1] || xs[0]

class Gallery extends Component {
  state = {
    activeViewName: '',
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props &&
      props.previewUrls &&
      !props.previewUrls[state.activeViewName]
    ) {
      return {
        activeViewName: keys(props.previewUrls)[0] || '',
      }
    }

    return null
  }

  setActiveView = (activeViewName) => this.setState({ activeViewName })

  showPrevView = () => {
    const { previewUrls } = this.props
    const views = keys(previewUrls)

    this.setState((state) => ({
      activeViewName: getNextKey(reverse(views), state.activeViewName),
    }))
  }

  showNextView = () => {
    const { previewUrls } = this.props
    const views = keys(previewUrls)

    this.setState((state) => ({
      activeViewName: getNextKey(views, state.activeViewName),
    }))
  }

  render() {
    const { previewUrls } = this.props
    const { activeViewName } = this.state

    return (
      <div
        className={cn('gallery', {
          'has-single-preview': size(previewUrls) === 1,
        })}
      >
        <a
          key="prev"
          className="gallery-arrow mod-prev"
          onClick={(e) => {
            e.preventDefault()
            this.showPrevView()
          }}
        >
          <Icon name="arrow-back" />
        </a>
        <Swipeable
          onSwipedRight={this.showPrevView}
          onSwipedLeft={this.showNextView}
        >
          {map(previewUrls, (url, viewName) => (
            <img
              key={viewName}
              className={cn([
                'gallery-image',
                `mod-${viewName}`,
                viewName === activeViewName && 'is-active',
                viewName === defaultViewAngleId && 'is-default',
              ])}
              src={url}
              alt=""
            />
          ))}
        </Swipeable>
        <div key="bullets" className="gallery-bullets">
          {map(previewUrls, (_, viewName) => (
            <div
              key={viewName}
              className={cn('gallery-bullet', {
                'is-active': viewName === activeViewName,
              })}
              onClick={() => {
                this.setActiveView(viewName)
              }}
            >
              {viewName}
            </div>
          ))}
        </div>
        <a
          key="next"
          className="gallery-arrow mod-next"
          onClick={(e) => {
            e.preventDefault()
            this.showNextView()
          }}
        >
          <Icon name="arrow-forward" />
        </a>
      </div>
    )
  }
}

export default Gallery
