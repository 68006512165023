import http from '../../../platform/client/http'

const loadImage = (url: string) =>
  new Promise<HTMLImageElement>(async (resolve, reject) => {
    const response = await http.get(url, { responseType: 'blob' })

    const imageElement = document.createElement('img')

    const reader = new FileReader()
    reader.readAsDataURL(response.data)
    reader.addEventListener('load', () => {
      let imageDataUrl = reader.result as string

      imageElement.addEventListener('load', () => {
        resolve(imageElement)
      })

      imageElement.setAttribute('src', imageDataUrl)
    })
    reader.addEventListener('error', reject)
  })

export default loadImage
