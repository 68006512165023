// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { GloveAssetRow } from '../typings';

const data = [
  {
    "sportId": "baseball",
    "id": "882",
    "shapeId": "fielder",
    "props": {
      "imageDimensions": {
        "back": {
          "width": 2405,
          "height": 3099
        },
        "web": {
          "width": 2772,
          "height": 3158
        },
        "palm": {
          "width": 2576,
          "height": 2773
        }
      },
      "hasHandSewnStitching": false,
      "trimWithShellBack": false,
      "liningOnBackView": true,
      "liningOnPalmView": true,
      "useBackPalmColorForLiningOnKIP": false,
      "useBackPalmColorForLiningOnHOH": false,
      "allowShellPalmColorForLining": true,
      "isLiningShellPalmColorOnly": false,
      "logoPatchOnWebView": true,
      "logoPatchOnBackView": true,
      "MLBOnWebView": false,
      "MLBOnBackView": true,
      "MLBOnWebViewMask": false,
      "MLBOnBackViewMask": false,
      "extraStitching": false,
      "hasBackPalm": false,
      "holdsterLaces": false,
      "palmOverlay": false,
      "palmOnBackView": true,
      "palmOnWebView": true,
      "insertOnBackView": false,
      "insertOnWebView": false,
      "insertOnPalmView": true,
      "webOnBackView": false,
      "sweatband": true,
      "weltingOnBackView": true,
      "welting": true,
      "xrdPad": false,
      "hasFlag": true,
      "hasFlagOnHalfMesh": false,
      "uniqueFlagPlacement": false,
      "embroideryNameReplacesLogo": false,
      "pullStrapAboveOval": false,
      "hasStandardFitShadowOnWeb": true,
      "hasStandardFitShadowOnBack": true,
      "fingerPadIsRequired": false,
      "forceHalfHeel": false,
      "forceFullHeel": false,
      "hasBackOnPalmView": true,
      "hasHeelPad": true,
      "hasWristLining": true,
      "hasPinkyPalm": false,
      "deco3D": {
        "web": {
          "Flag": true,
          "Wing": false,
          "Oval": true,
          "Name": true,
          "PalmPadLeather": true,
          "EmbroideryLogo": true,
          "GoldGlove": false,
          "Fastback": false,
          "TrapezeWeb": false,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": true
        },
        "palm": {
          "LeatherType": true,
          "USSteerhide": true,
          "Professional": true,
          "RawlingsStamp": true,
          "REV1XRawlingsStamp": false,
          "REV1XPinky": false,
          "ModelName": true,
          "LiTeToe": false,
          "R2G": true
        },
        "back": {
          "Flag": true,
          "Wing": false,
          "Name": false,
          "EmbroideryLogo": true,
          "GoldGlove": true,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": true,
          "Fastback": false,
          "Oval": false
        }
      }
    }
  },
  {
    "sportId": "baseball",
    "id": "REV204",
    "shapeId": "fielder",
    "props": {
      "imageDimensions": {
        "back": {
          "width": 2342,
          "height": 3000
        },
        "web": {
          "width": 2497,
          "height": 3000
        },
        "palm": {
          "width": 2402,
          "height": 3000
        }
      },
      "hasHandSewnStitching": false,
      "trimWithShellBack": false,
      "liningOnBackView": true,
      "liningOnPalmView": true,
      "useBackPalmColorForLiningOnKIP": false,
      "useBackPalmColorForLiningOnHOH": false,
      "allowShellPalmColorForLining": true,
      "isLiningShellPalmColorOnly": true,
      "logoPatchOnWebView": false,
      "logoPatchOnBackView": false,
      "MLBOnWebView": false,
      "MLBOnBackView": false,
      "MLBOnWebViewMask": false,
      "MLBOnBackViewMask": false,
      "extraStitching": false,
      "hasBackPalm": false,
      "holdsterLaces": false,
      "palmOverlay": false,
      "palmOnBackView": true,
      "palmOnWebView": true,
      "insertOnBackView": true,
      "insertOnWebView": true,
      "insertOnPalmView": true,
      "webOnBackView": false,
      "sweatband": false,
      "weltingOnBackView": true,
      "welting": true,
      "xrdPad": false,
      "hasFlag": false,
      "hasFlagOnHalfMesh": false,
      "uniqueFlagPlacement": false,
      "embroideryNameReplacesLogo": false,
      "pullStrapAboveOval": false,
      "hasStandardFitShadowOnWeb": true,
      "hasStandardFitShadowOnBack": true,
      "fingerPadIsRequired": false,
      "forceHalfHeel": false,
      "forceFullHeel": false,
      "hasBackOnPalmView": false,
      "hasHeelPad": false,
      "hasWristLining": false,
      "hasPinkyPalm": true,
      "deco3D": {
        "web": {
          "Flag": false,
          "Wing": false,
          "Oval": false,
          "Name": false,
          "PalmPadLeather": true,
          "EmbroideryLogo": false,
          "GoldGlove": false,
          "Fastback": false,
          "TrapezeWeb": false,
          "IndexFingerXRD": false,
          "MiddleFingerXRD": false
        },
        "palm": {
          "LeatherType": true,
          "USSteerhide": false,
          "Professional": false,
          "RawlingsStamp": false,
          "REV1XRawlingsStamp": true,
          "REV1XPinky": true,
          "ModelName": true,
          "LiTeToe": false,
          "R2G": false
        },
        "back": {
          "Flag": false,
          "Wing": false,
          "Name": false,
          "EmbroideryLogo": false,
          "GoldGlove": false,
          "IndexFingerXRD": false,
          "MiddleFingerXRD": false,
          "Fastback": false,
          "Oval": false
        }
      }
    }
  },
  {
    "sportId": "baseball",
    "id": "200CV",
    "shapeId": "fielder",
    "props": {
      "imageDimensions": {
        "back": {
          "width": 2663,
          "height": 3123
        },
        "web": {
          "width": 2638,
          "height": 3035
        },
        "palm": {
          "width": 2540,
          "height": 2878
        }
      },
      "hasHandSewnStitching": false,
      "trimWithShellBack": false,
      "liningOnBackView": true,
      "liningOnPalmView": true,
      "useBackPalmColorForLiningOnKIP": false,
      "useBackPalmColorForLiningOnHOH": false,
      "allowShellPalmColorForLining": true,
      "isLiningShellPalmColorOnly": false,
      "logoPatchOnWebView": true,
      "logoPatchOnBackView": true,
      "MLBOnWebView": false,
      "MLBOnBackView": true,
      "MLBOnWebViewMask": false,
      "MLBOnBackViewMask": false,
      "extraStitching": true,
      "hasBackPalm": false,
      "holdsterLaces": false,
      "palmOverlay": false,
      "palmOnBackView": true,
      "palmOnWebView": false,
      "insertOnBackView": false,
      "insertOnWebView": false,
      "insertOnPalmView": true,
      "webOnBackView": false,
      "sweatband": true,
      "weltingOnBackView": true,
      "welting": true,
      "xrdPad": false,
      "hasFlag": true,
      "hasFlagOnHalfMesh": false,
      "uniqueFlagPlacement": false,
      "embroideryNameReplacesLogo": false,
      "pullStrapAboveOval": false,
      "hasStandardFitShadowOnWeb": true,
      "hasStandardFitShadowOnBack": true,
      "fingerPadIsRequired": false,
      "forceHalfHeel": false,
      "forceFullHeel": false,
      "hasBackOnPalmView": true,
      "hasHeelPad": true,
      "hasWristLining": true,
      "hasPinkyPalm": false,
      "deco3D": {
        "web": {
          "Flag": true,
          "Wing": false,
          "Oval": true,
          "Name": true,
          "PalmPadLeather": true,
          "EmbroideryLogo": true,
          "GoldGlove": false,
          "Fastback": false,
          "TrapezeWeb": false,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": true
        },
        "palm": {
          "LeatherType": true,
          "USSteerhide": true,
          "Professional": true,
          "RawlingsStamp": true,
          "REV1XRawlingsStamp": false,
          "REV1XPinky": false,
          "ModelName": true,
          "LiTeToe": false,
          "R2G": true
        },
        "back": {
          "Flag": true,
          "Wing": false,
          "Name": false,
          "EmbroideryLogo": true,
          "GoldGlove": true,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": true,
          "Fastback": false,
          "Oval": false
        }
      }
    }
  },
  {
    "sportId": "baseball",
    "id": "204HSW",
    "shapeId": "fielder",
    "props": {
      "imageDimensions": {
        "back": {
          "width": 2663,
          "height": 3123
        },
        "web": {
          "width": 2638,
          "height": 3035
        },
        "palm": {
          "width": 2540,
          "height": 2878
        }
      },
      "hasHandSewnStitching": true,
      "trimWithShellBack": false,
      "liningOnBackView": true,
      "liningOnPalmView": true,
      "useBackPalmColorForLiningOnKIP": false,
      "useBackPalmColorForLiningOnHOH": false,
      "allowShellPalmColorForLining": true,
      "isLiningShellPalmColorOnly": false,
      "logoPatchOnWebView": true,
      "logoPatchOnBackView": true,
      "MLBOnWebView": false,
      "MLBOnBackView": true,
      "MLBOnWebViewMask": false,
      "MLBOnBackViewMask": false,
      "extraStitching": true,
      "hasBackPalm": false,
      "holdsterLaces": false,
      "palmOverlay": false,
      "palmOnBackView": true,
      "palmOnWebView": false,
      "insertOnBackView": false,
      "insertOnWebView": false,
      "insertOnPalmView": true,
      "webOnBackView": false,
      "sweatband": true,
      "weltingOnBackView": true,
      "welting": true,
      "xrdPad": false,
      "hasFlag": true,
      "hasFlagOnHalfMesh": false,
      "uniqueFlagPlacement": false,
      "embroideryNameReplacesLogo": false,
      "pullStrapAboveOval": false,
      "hasStandardFitShadowOnWeb": true,
      "hasStandardFitShadowOnBack": true,
      "fingerPadIsRequired": false,
      "forceHalfHeel": false,
      "forceFullHeel": false,
      "hasBackOnPalmView": true,
      "hasHeelPad": true,
      "hasWristLining": true,
      "hasPinkyPalm": false,
      "deco3D": {
        "web": {
          "Flag": true,
          "Wing": false,
          "Oval": true,
          "Name": true,
          "PalmPadLeather": true,
          "EmbroideryLogo": true,
          "GoldGlove": false,
          "Fastback": false,
          "TrapezeWeb": false,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": true
        },
        "palm": {
          "LeatherType": true,
          "USSteerhide": true,
          "Professional": true,
          "RawlingsStamp": true,
          "REV1XRawlingsStamp": false,
          "REV1XPinky": false,
          "ModelName": true,
          "LiTeToe": false,
          "R2G": true
        },
        "back": {
          "Flag": true,
          "Wing": false,
          "Name": false,
          "EmbroideryLogo": true,
          "GoldGlove": true,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": true,
          "Fastback": false,
          "Oval": false
        }
      }
    }
  },
  {
    "sportId": "baseball",
    "id": "205FS",
    "shapeId": "fielder",
    "props": {
      "imageDimensions": {
        "back": {
          "width": 2663,
          "height": 3123
        },
        "web": {
          "width": 2638,
          "height": 3035
        },
        "palm": {
          "width": 2540,
          "height": 2878
        }
      },
      "hasHandSewnStitching": false,
      "trimWithShellBack": false,
      "liningOnBackView": true,
      "liningOnPalmView": true,
      "useBackPalmColorForLiningOnKIP": false,
      "useBackPalmColorForLiningOnHOH": false,
      "allowShellPalmColorForLining": true,
      "isLiningShellPalmColorOnly": false,
      "logoPatchOnWebView": true,
      "logoPatchOnBackView": true,
      "MLBOnWebView": false,
      "MLBOnBackView": true,
      "MLBOnWebViewMask": false,
      "MLBOnBackViewMask": true,
      "extraStitching": true,
      "hasBackPalm": false,
      "holdsterLaces": false,
      "palmOverlay": false,
      "palmOnBackView": true,
      "palmOnWebView": false,
      "insertOnBackView": false,
      "insertOnWebView": false,
      "insertOnPalmView": true,
      "webOnBackView": false,
      "sweatband": true,
      "weltingOnBackView": true,
      "welting": true,
      "xrdPad": false,
      "hasFlag": true,
      "hasFlagOnHalfMesh": false,
      "uniqueFlagPlacement": false,
      "embroideryNameReplacesLogo": false,
      "pullStrapAboveOval": false,
      "hasStandardFitShadowOnWeb": true,
      "hasStandardFitShadowOnBack": true,
      "fingerPadIsRequired": false,
      "forceHalfHeel": false,
      "forceFullHeel": false,
      "hasBackOnPalmView": true,
      "hasHeelPad": true,
      "hasWristLining": true,
      "hasPinkyPalm": false,
      "deco3D": {
        "web": {
          "Flag": true,
          "Wing": false,
          "Oval": true,
          "Name": true,
          "PalmPadLeather": true,
          "EmbroideryLogo": true,
          "GoldGlove": false,
          "Fastback": false,
          "TrapezeWeb": false,
          "IndexFingerXRD": false,
          "MiddleFingerXRD": true
        },
        "palm": {
          "LeatherType": true,
          "USSteerhide": true,
          "Professional": true,
          "RawlingsStamp": true,
          "REV1XRawlingsStamp": false,
          "REV1XPinky": false,
          "ModelName": true,
          "LiTeToe": false,
          "R2G": true
        },
        "back": {
          "Flag": true,
          "Wing": false,
          "Name": false,
          "EmbroideryLogo": true,
          "GoldGlove": true,
          "IndexFingerXRD": false,
          "MiddleFingerXRD": true,
          "Fastback": false,
          "Oval": false
        }
      }
    }
  },
  {
    "sportId": "baseball",
    "id": "200WT",
    "shapeId": "fielder",
    "props": {
      "imageDimensions": {
        "back": {
          "width": 3524,
          "height": 4579
        },
        "web": {
          "width": 3692,
          "height": 4630
        },
        "palm": {
          "width": 4086,
          "height": 4763
        }
      },
      "hasHandSewnStitching": false,
      "trimWithShellBack": false,
      "liningOnBackView": true,
      "liningOnPalmView": true,
      "useBackPalmColorForLiningOnKIP": false,
      "useBackPalmColorForLiningOnHOH": false,
      "allowShellPalmColorForLining": true,
      "isLiningShellPalmColorOnly": false,
      "logoPatchOnWebView": true,
      "logoPatchOnBackView": true,
      "MLBOnWebView": false,
      "MLBOnBackView": true,
      "MLBOnWebViewMask": false,
      "MLBOnBackViewMask": false,
      "extraStitching": false,
      "hasBackPalm": false,
      "holdsterLaces": false,
      "palmOverlay": false,
      "palmOnBackView": false,
      "palmOnWebView": false,
      "insertOnBackView": false,
      "insertOnWebView": false,
      "insertOnPalmView": true,
      "webOnBackView": false,
      "sweatband": true,
      "weltingOnBackView": true,
      "welting": true,
      "xrdPad": false,
      "hasFlag": true,
      "hasFlagOnHalfMesh": false,
      "uniqueFlagPlacement": false,
      "embroideryNameReplacesLogo": false,
      "pullStrapAboveOval": false,
      "hasStandardFitShadowOnWeb": true,
      "hasStandardFitShadowOnBack": true,
      "fingerPadIsRequired": false,
      "forceHalfHeel": false,
      "forceFullHeel": false,
      "hasBackOnPalmView": true,
      "hasHeelPad": true,
      "hasWristLining": true,
      "hasPinkyPalm": false,
      "deco3D": {
        "web": {
          "Flag": true,
          "Wing": true,
          "Oval": true,
          "Name": true,
          "PalmPadLeather": true,
          "EmbroideryLogo": true,
          "GoldGlove": false,
          "Fastback": false,
          "TrapezeWeb": false,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": true
        },
        "palm": {
          "LeatherType": true,
          "USSteerhide": true,
          "Professional": true,
          "RawlingsStamp": true,
          "REV1XRawlingsStamp": false,
          "REV1XPinky": false,
          "ModelName": true,
          "LiTeToe": false,
          "R2G": true
        },
        "back": {
          "Flag": true,
          "Wing": true,
          "Name": false,
          "EmbroideryLogo": true,
          "GoldGlove": true,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": true,
          "Fastback": false,
          "Oval": false
        }
      }
    }
  },
  {
    "sportId": "baseball",
    "id": "200FB",
    "shapeId": "fielder",
    "props": {
      "imageDimensions": {
        "back": {
          "width": 2488,
          "height": 3126
        },
        "web": {
          "width": 2664,
          "height": 3230
        },
        "palm": {
          "width": 2469,
          "height": 2954
        }
      },
      "hasHandSewnStitching": false,
      "trimWithShellBack": false,
      "liningOnBackView": false,
      "liningOnPalmView": false,
      "useBackPalmColorForLiningOnKIP": false,
      "useBackPalmColorForLiningOnHOH": false,
      "allowShellPalmColorForLining": true,
      "isLiningShellPalmColorOnly": false,
      "logoPatchOnWebView": true,
      "logoPatchOnBackView": true,
      "MLBOnWebView": false,
      "MLBOnBackView": true,
      "MLBOnWebViewMask": false,
      "MLBOnBackViewMask": false,
      "extraStitching": false,
      "hasBackPalm": false,
      "holdsterLaces": true,
      "palmOverlay": false,
      "palmOnBackView": true,
      "palmOnWebView": true,
      "insertOnBackView": false,
      "insertOnWebView": true,
      "insertOnPalmView": true,
      "webOnBackView": false,
      "sweatband": false,
      "weltingOnBackView": true,
      "welting": true,
      "xrdPad": false,
      "hasFlag": true,
      "hasFlagOnHalfMesh": false,
      "uniqueFlagPlacement": false,
      "embroideryNameReplacesLogo": false,
      "pullStrapAboveOval": false,
      "hasStandardFitShadowOnWeb": true,
      "hasStandardFitShadowOnBack": true,
      "fingerPadIsRequired": false,
      "forceHalfHeel": false,
      "forceFullHeel": false,
      "hasBackOnPalmView": true,
      "hasHeelPad": true,
      "hasWristLining": true,
      "hasPinkyPalm": false,
      "deco3D": {
        "web": {
          "Flag": true,
          "Wing": false,
          "Oval": true,
          "Name": true,
          "PalmPadLeather": false,
          "EmbroideryLogo": true,
          "GoldGlove": false,
          "Fastback": true,
          "TrapezeWeb": false,
          "IndexFingerXRD": false,
          "MiddleFingerXRD": false
        },
        "palm": {
          "LeatherType": true,
          "USSteerhide": true,
          "Professional": true,
          "RawlingsStamp": true,
          "REV1XRawlingsStamp": false,
          "REV1XPinky": false,
          "ModelName": true,
          "LiTeToe": false,
          "R2G": true
        },
        "back": {
          "Flag": true,
          "Wing": false,
          "Name": false,
          "EmbroideryLogo": true,
          "GoldGlove": true,
          "IndexFingerXRD": false,
          "MiddleFingerXRD": false,
          "Fastback": true,
          "Oval": false
        }
      }
    }
  },
  {
    "sportId": "baseball",
    "id": "217",
    "shapeId": "fielder",
    "props": {
      "imageDimensions": {
        "back": {
          "width": 2461,
          "height": 3179
        },
        "web": {
          "width": 2696,
          "height": 3161
        },
        "palm": {
          "width": 2540,
          "height": 2878
        }
      },
      "hasHandSewnStitching": false,
      "trimWithShellBack": false,
      "liningOnBackView": true,
      "liningOnPalmView": true,
      "useBackPalmColorForLiningOnKIP": false,
      "useBackPalmColorForLiningOnHOH": false,
      "allowShellPalmColorForLining": true,
      "isLiningShellPalmColorOnly": false,
      "logoPatchOnWebView": true,
      "logoPatchOnBackView": true,
      "MLBOnWebView": false,
      "MLBOnBackView": true,
      "MLBOnWebViewMask": false,
      "MLBOnBackViewMask": true,
      "extraStitching": true,
      "hasBackPalm": false,
      "holdsterLaces": false,
      "palmOverlay": false,
      "palmOnBackView": false,
      "palmOnWebView": true,
      "insertOnBackView": false,
      "insertOnWebView": false,
      "insertOnPalmView": true,
      "webOnBackView": false,
      "sweatband": true,
      "weltingOnBackView": true,
      "welting": true,
      "xrdPad": false,
      "hasFlag": true,
      "hasFlagOnHalfMesh": false,
      "uniqueFlagPlacement": false,
      "embroideryNameReplacesLogo": false,
      "pullStrapAboveOval": false,
      "hasStandardFitShadowOnWeb": true,
      "hasStandardFitShadowOnBack": true,
      "fingerPadIsRequired": false,
      "forceHalfHeel": false,
      "forceFullHeel": false,
      "hasBackOnPalmView": true,
      "hasHeelPad": true,
      "hasWristLining": true,
      "hasPinkyPalm": false,
      "deco3D": {
        "web": {
          "Flag": true,
          "Wing": false,
          "Oval": true,
          "Name": true,
          "PalmPadLeather": true,
          "EmbroideryLogo": true,
          "GoldGlove": false,
          "Fastback": false,
          "TrapezeWeb": false,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": true
        },
        "palm": {
          "LeatherType": true,
          "USSteerhide": true,
          "Professional": true,
          "RawlingsStamp": true,
          "REV1XRawlingsStamp": false,
          "REV1XPinky": false,
          "ModelName": true,
          "LiTeToe": false,
          "R2G": true
        },
        "back": {
          "Flag": true,
          "Wing": false,
          "Name": false,
          "EmbroideryLogo": true,
          "GoldGlove": true,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": true,
          "Fastback": false,
          "Oval": false
        }
      }
    }
  },
  {
    "sportId": "baseball",
    "id": "302CV",
    "shapeId": "fielder",
    "props": {
      "imageDimensions": {
        "back": {
          "width": 2498,
          "height": 3492
        },
        "web": {
          "width": 2894,
          "height": 3437
        },
        "palm": {
          "width": 2460,
          "height": 3202
        }
      },
      "hasHandSewnStitching": false,
      "trimWithShellBack": false,
      "liningOnBackView": true,
      "liningOnPalmView": true,
      "useBackPalmColorForLiningOnKIP": false,
      "useBackPalmColorForLiningOnHOH": false,
      "allowShellPalmColorForLining": true,
      "isLiningShellPalmColorOnly": false,
      "logoPatchOnWebView": true,
      "logoPatchOnBackView": true,
      "MLBOnWebView": false,
      "MLBOnBackView": true,
      "MLBOnWebViewMask": false,
      "MLBOnBackViewMask": true,
      "extraStitching": false,
      "hasBackPalm": false,
      "holdsterLaces": false,
      "palmOverlay": false,
      "palmOnBackView": false,
      "palmOnWebView": false,
      "insertOnBackView": false,
      "insertOnWebView": false,
      "insertOnPalmView": true,
      "webOnBackView": false,
      "sweatband": true,
      "weltingOnBackView": true,
      "welting": true,
      "xrdPad": false,
      "hasFlag": true,
      "hasFlagOnHalfMesh": false,
      "uniqueFlagPlacement": false,
      "embroideryNameReplacesLogo": false,
      "pullStrapAboveOval": false,
      "hasStandardFitShadowOnWeb": true,
      "hasStandardFitShadowOnBack": true,
      "fingerPadIsRequired": false,
      "forceHalfHeel": false,
      "forceFullHeel": false,
      "hasBackOnPalmView": true,
      "hasHeelPad": true,
      "hasWristLining": true,
      "hasPinkyPalm": false,
      "deco3D": {
        "web": {
          "Flag": true,
          "Wing": false,
          "Oval": true,
          "Name": true,
          "PalmPadLeather": true,
          "EmbroideryLogo": true,
          "GoldGlove": false,
          "Fastback": false,
          "TrapezeWeb": false,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": true
        },
        "palm": {
          "LeatherType": true,
          "USSteerhide": true,
          "Professional": true,
          "RawlingsStamp": true,
          "REV1XRawlingsStamp": false,
          "REV1XPinky": false,
          "ModelName": true,
          "LiTeToe": false,
          "R2G": true
        },
        "back": {
          "Flag": true,
          "Wing": false,
          "Name": false,
          "EmbroideryLogo": true,
          "GoldGlove": true,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": true,
          "Fastback": false,
          "Oval": false
        }
      }
    }
  },
  {
    "sportId": "baseball",
    "id": "302FB",
    "shapeId": "fielder",
    "props": {
      "imageDimensions": {
        "back": {
          "width": 2370,
          "height": 3289
        },
        "web": {
          "width": 2940,
          "height": 3420
        },
        "palm": {
          "width": 2531,
          "height": 3086
        }
      },
      "hasHandSewnStitching": false,
      "trimWithShellBack": false,
      "liningOnBackView": false,
      "liningOnPalmView": false,
      "useBackPalmColorForLiningOnKIP": false,
      "useBackPalmColorForLiningOnHOH": false,
      "allowShellPalmColorForLining": true,
      "isLiningShellPalmColorOnly": false,
      "logoPatchOnWebView": true,
      "logoPatchOnBackView": true,
      "MLBOnWebView": false,
      "MLBOnBackView": true,
      "MLBOnWebViewMask": false,
      "MLBOnBackViewMask": false,
      "extraStitching": false,
      "hasBackPalm": false,
      "holdsterLaces": true,
      "palmOverlay": false,
      "palmOnBackView": false,
      "palmOnWebView": true,
      "insertOnBackView": false,
      "insertOnWebView": true,
      "insertOnPalmView": true,
      "webOnBackView": false,
      "sweatband": false,
      "weltingOnBackView": true,
      "welting": true,
      "xrdPad": false,
      "hasFlag": true,
      "hasFlagOnHalfMesh": false,
      "uniqueFlagPlacement": false,
      "embroideryNameReplacesLogo": false,
      "pullStrapAboveOval": false,
      "hasStandardFitShadowOnWeb": true,
      "hasStandardFitShadowOnBack": true,
      "fingerPadIsRequired": false,
      "forceHalfHeel": false,
      "forceFullHeel": false,
      "hasBackOnPalmView": true,
      "hasHeelPad": true,
      "hasWristLining": true,
      "hasPinkyPalm": false,
      "deco3D": {
        "web": {
          "Flag": true,
          "Wing": false,
          "Oval": true,
          "Name": true,
          "PalmPadLeather": false,
          "EmbroideryLogo": true,
          "GoldGlove": false,
          "Fastback": true,
          "TrapezeWeb": false,
          "IndexFingerXRD": false,
          "MiddleFingerXRD": false
        },
        "palm": {
          "LeatherType": true,
          "USSteerhide": true,
          "Professional": true,
          "RawlingsStamp": true,
          "REV1XRawlingsStamp": false,
          "REV1XPinky": false,
          "ModelName": true,
          "LiTeToe": false,
          "R2G": true
        },
        "back": {
          "Flag": true,
          "Wing": false,
          "Name": false,
          "EmbroideryLogo": true,
          "GoldGlove": true,
          "IndexFingerXRD": false,
          "MiddleFingerXRD": false,
          "Fastback": true,
          "Oval": false
        }
      }
    }
  },
  {
    "sportId": "baseball",
    "id": "303",
    "shapeId": "fielder",
    "props": {
      "imageDimensions": {
        "back": {
          "width": 2570,
          "height": 3438
        },
        "web": {
          "width": 2894,
          "height": 3400
        },
        "palm": {
          "width": 2622,
          "height": 3237
        }
      },
      "hasHandSewnStitching": false,
      "trimWithShellBack": false,
      "liningOnBackView": true,
      "liningOnPalmView": true,
      "useBackPalmColorForLiningOnKIP": false,
      "useBackPalmColorForLiningOnHOH": false,
      "allowShellPalmColorForLining": true,
      "isLiningShellPalmColorOnly": false,
      "logoPatchOnWebView": true,
      "logoPatchOnBackView": true,
      "MLBOnWebView": false,
      "MLBOnBackView": true,
      "MLBOnWebViewMask": false,
      "MLBOnBackViewMask": true,
      "extraStitching": false,
      "hasBackPalm": false,
      "holdsterLaces": false,
      "palmOverlay": true,
      "palmOnBackView": true,
      "palmOnWebView": true,
      "insertOnBackView": false,
      "insertOnWebView": false,
      "insertOnPalmView": true,
      "webOnBackView": false,
      "sweatband": true,
      "weltingOnBackView": true,
      "welting": true,
      "xrdPad": false,
      "hasFlag": true,
      "hasFlagOnHalfMesh": false,
      "uniqueFlagPlacement": false,
      "embroideryNameReplacesLogo": false,
      "pullStrapAboveOval": false,
      "hasStandardFitShadowOnWeb": true,
      "hasStandardFitShadowOnBack": true,
      "fingerPadIsRequired": false,
      "forceHalfHeel": false,
      "forceFullHeel": false,
      "hasBackOnPalmView": true,
      "hasHeelPad": true,
      "hasWristLining": true,
      "hasPinkyPalm": false,
      "deco3D": {
        "web": {
          "Flag": true,
          "Wing": false,
          "Oval": true,
          "Name": true,
          "PalmPadLeather": true,
          "EmbroideryLogo": true,
          "GoldGlove": false,
          "Fastback": false,
          "TrapezeWeb": false,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": true
        },
        "palm": {
          "LeatherType": true,
          "USSteerhide": true,
          "Professional": true,
          "RawlingsStamp": true,
          "REV1XRawlingsStamp": false,
          "REV1XPinky": false,
          "ModelName": true,
          "LiTeToe": false,
          "R2G": true
        },
        "back": {
          "Flag": true,
          "Wing": false,
          "Name": false,
          "EmbroideryLogo": true,
          "GoldGlove": true,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": true,
          "Fastback": false,
          "Oval": false
        }
      }
    }
  },
  {
    "sportId": "baseball",
    "id": "3039HSW",
    "shapeId": "fielder",
    "props": {
      "imageDimensions": {
        "back": {
          "width": 2570,
          "height": 3438
        },
        "web": {
          "width": 2894,
          "height": 3400
        },
        "palm": {
          "width": 2622,
          "height": 3237
        }
      },
      "hasHandSewnStitching": true,
      "trimWithShellBack": false,
      "liningOnBackView": true,
      "liningOnPalmView": true,
      "useBackPalmColorForLiningOnKIP": false,
      "useBackPalmColorForLiningOnHOH": false,
      "allowShellPalmColorForLining": true,
      "isLiningShellPalmColorOnly": false,
      "logoPatchOnWebView": true,
      "logoPatchOnBackView": true,
      "MLBOnWebView": false,
      "MLBOnBackView": true,
      "MLBOnWebViewMask": false,
      "MLBOnBackViewMask": true,
      "extraStitching": false,
      "hasBackPalm": false,
      "holdsterLaces": false,
      "palmOverlay": true,
      "palmOnBackView": true,
      "palmOnWebView": true,
      "insertOnBackView": false,
      "insertOnWebView": false,
      "insertOnPalmView": true,
      "webOnBackView": false,
      "sweatband": true,
      "weltingOnBackView": true,
      "welting": true,
      "xrdPad": false,
      "hasFlag": true,
      "hasFlagOnHalfMesh": false,
      "uniqueFlagPlacement": false,
      "embroideryNameReplacesLogo": false,
      "pullStrapAboveOval": false,
      "hasStandardFitShadowOnWeb": true,
      "hasStandardFitShadowOnBack": true,
      "fingerPadIsRequired": false,
      "forceHalfHeel": false,
      "forceFullHeel": false,
      "hasBackOnPalmView": true,
      "hasHeelPad": true,
      "hasWristLining": true,
      "hasPinkyPalm": false,
      "deco3D": {
        "web": {
          "Flag": true,
          "Wing": false,
          "Oval": true,
          "Name": true,
          "PalmPadLeather": true,
          "EmbroideryLogo": true,
          "GoldGlove": false,
          "Fastback": false,
          "TrapezeWeb": false,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": true
        },
        "palm": {
          "LeatherType": true,
          "USSteerhide": true,
          "Professional": true,
          "RawlingsStamp": true,
          "REV1XRawlingsStamp": false,
          "REV1XPinky": false,
          "ModelName": true,
          "LiTeToe": false,
          "R2G": true
        },
        "back": {
          "Flag": true,
          "Wing": false,
          "Name": false,
          "EmbroideryLogo": true,
          "GoldGlove": true,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": true,
          "Fastback": false,
          "Oval": false
        }
      }
    }
  },
  {
    "sportId": "baseball",
    "id": "3039FS",
    "shapeId": "fielder",
    "props": {
      "imageDimensions": {
        "back": {
          "width": 2570,
          "height": 3438
        },
        "web": {
          "width": 2894,
          "height": 3400
        },
        "palm": {
          "width": 2622,
          "height": 3237
        }
      },
      "hasHandSewnStitching": false,
      "trimWithShellBack": false,
      "liningOnBackView": true,
      "liningOnPalmView": true,
      "useBackPalmColorForLiningOnKIP": false,
      "useBackPalmColorForLiningOnHOH": false,
      "allowShellPalmColorForLining": true,
      "isLiningShellPalmColorOnly": false,
      "logoPatchOnWebView": true,
      "logoPatchOnBackView": true,
      "MLBOnWebView": false,
      "MLBOnBackView": true,
      "MLBOnWebViewMask": false,
      "MLBOnBackViewMask": false,
      "extraStitching": false,
      "hasBackPalm": false,
      "holdsterLaces": false,
      "palmOverlay": true,
      "palmOnBackView": true,
      "palmOnWebView": true,
      "insertOnBackView": false,
      "insertOnWebView": false,
      "insertOnPalmView": true,
      "webOnBackView": false,
      "sweatband": true,
      "weltingOnBackView": true,
      "welting": true,
      "xrdPad": false,
      "hasFlag": true,
      "hasFlagOnHalfMesh": false,
      "uniqueFlagPlacement": false,
      "embroideryNameReplacesLogo": false,
      "pullStrapAboveOval": false,
      "hasStandardFitShadowOnWeb": true,
      "hasStandardFitShadowOnBack": true,
      "fingerPadIsRequired": false,
      "forceHalfHeel": false,
      "forceFullHeel": false,
      "hasBackOnPalmView": true,
      "hasHeelPad": true,
      "hasWristLining": true,
      "hasPinkyPalm": false,
      "deco3D": {
        "web": {
          "Flag": true,
          "Wing": false,
          "Oval": true,
          "Name": true,
          "PalmPadLeather": true,
          "EmbroideryLogo": true,
          "GoldGlove": false,
          "Fastback": false,
          "TrapezeWeb": false,
          "IndexFingerXRD": false,
          "MiddleFingerXRD": true
        },
        "palm": {
          "LeatherType": true,
          "USSteerhide": true,
          "Professional": true,
          "RawlingsStamp": true,
          "REV1XRawlingsStamp": false,
          "REV1XPinky": false,
          "ModelName": true,
          "LiTeToe": false,
          "R2G": true
        },
        "back": {
          "Flag": true,
          "Wing": false,
          "Name": false,
          "EmbroideryLogo": true,
          "GoldGlove": true,
          "IndexFingerXRD": false,
          "MiddleFingerXRD": true,
          "Fastback": false,
          "Oval": false
        }
      }
    }
  },
  {
    "sportId": "baseball",
    "id": "REV3039",
    "shapeId": "fielder",
    "props": {
      "imageDimensions": {
        "back": {
          "width": 2182,
          "height": 3000
        },
        "web": {
          "width": 2371,
          "height": 3000
        },
        "palm": {
          "width": 2334,
          "height": 3000
        }
      },
      "hasHandSewnStitching": false,
      "trimWithShellBack": false,
      "liningOnBackView": true,
      "liningOnPalmView": true,
      "useBackPalmColorForLiningOnKIP": false,
      "useBackPalmColorForLiningOnHOH": false,
      "allowShellPalmColorForLining": true,
      "isLiningShellPalmColorOnly": true,
      "logoPatchOnWebView": false,
      "logoPatchOnBackView": false,
      "MLBOnWebView": false,
      "MLBOnBackView": false,
      "MLBOnWebViewMask": false,
      "MLBOnBackViewMask": false,
      "extraStitching": false,
      "hasBackPalm": false,
      "holdsterLaces": false,
      "palmOverlay": false,
      "palmOnBackView": true,
      "palmOnWebView": true,
      "insertOnBackView": true,
      "insertOnWebView": true,
      "insertOnPalmView": true,
      "webOnBackView": false,
      "sweatband": false,
      "weltingOnBackView": true,
      "welting": true,
      "xrdPad": false,
      "hasFlag": false,
      "hasFlagOnHalfMesh": false,
      "uniqueFlagPlacement": false,
      "embroideryNameReplacesLogo": false,
      "pullStrapAboveOval": false,
      "hasStandardFitShadowOnWeb": true,
      "hasStandardFitShadowOnBack": true,
      "fingerPadIsRequired": false,
      "forceHalfHeel": false,
      "forceFullHeel": false,
      "hasBackOnPalmView": false,
      "hasHeelPad": false,
      "hasWristLining": false,
      "hasPinkyPalm": true,
      "deco3D": {
        "web": {
          "Flag": false,
          "Wing": false,
          "Oval": false,
          "Name": false,
          "PalmPadLeather": true,
          "EmbroideryLogo": false,
          "GoldGlove": false,
          "Fastback": false,
          "TrapezeWeb": false,
          "IndexFingerXRD": false,
          "MiddleFingerXRD": false
        },
        "palm": {
          "LeatherType": true,
          "USSteerhide": false,
          "Professional": false,
          "RawlingsStamp": false,
          "REV1XRawlingsStamp": true,
          "REV1XPinky": true,
          "ModelName": true,
          "LiTeToe": false,
          "R2G": false
        },
        "back": {
          "Flag": false,
          "Wing": false,
          "Name": false,
          "EmbroideryLogo": false,
          "GoldGlove": false,
          "IndexFingerXRD": false,
          "MiddleFingerXRD": false,
          "Fastback": false,
          "Oval": false
        }
      }
    }
  },
  {
    "sportId": "baseball",
    "id": "315",
    "shapeId": "fielder",
    "props": {
      "imageDimensions": {
        "back": {
          "width": 3752,
          "height": 4925
        },
        "web": {
          "width": 2333,
          "height": 3000
        },
        "palm": {
          "width": 1920,
          "height": 2327
        }
      },
      "hasHandSewnStitching": false,
      "trimWithShellBack": true,
      "liningOnBackView": true,
      "liningOnPalmView": true,
      "useBackPalmColorForLiningOnKIP": false,
      "useBackPalmColorForLiningOnHOH": false,
      "allowShellPalmColorForLining": true,
      "isLiningShellPalmColorOnly": false,
      "logoPatchOnWebView": true,
      "logoPatchOnBackView": true,
      "MLBOnWebView": false,
      "MLBOnBackView": true,
      "MLBOnWebViewMask": false,
      "MLBOnBackViewMask": false,
      "extraStitching": false,
      "hasBackPalm": false,
      "holdsterLaces": false,
      "palmOverlay": false,
      "palmOnBackView": false,
      "palmOnWebView": false,
      "insertOnBackView": false,
      "insertOnWebView": false,
      "insertOnPalmView": true,
      "webOnBackView": false,
      "sweatband": true,
      "weltingOnBackView": true,
      "welting": true,
      "xrdPad": false,
      "hasFlag": false,
      "hasFlagOnHalfMesh": false,
      "uniqueFlagPlacement": false,
      "embroideryNameReplacesLogo": false,
      "pullStrapAboveOval": false,
      "hasStandardFitShadowOnWeb": true,
      "hasStandardFitShadowOnBack": true,
      "fingerPadIsRequired": false,
      "forceHalfHeel": true,
      "forceFullHeel": false,
      "hasBackOnPalmView": true,
      "hasHeelPad": true,
      "hasWristLining": true,
      "hasPinkyPalm": false,
      "deco3D": {
        "web": {
          "Flag": false,
          "Wing": false,
          "Oval": true,
          "Name": true,
          "PalmPadLeather": true,
          "EmbroideryLogo": true,
          "GoldGlove": false,
          "Fastback": false,
          "TrapezeWeb": false,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": true
        },
        "palm": {
          "LeatherType": true,
          "USSteerhide": true,
          "Professional": true,
          "RawlingsStamp": true,
          "REV1XRawlingsStamp": false,
          "REV1XPinky": false,
          "ModelName": true,
          "LiTeToe": false,
          "R2G": true
        },
        "back": {
          "Flag": false,
          "Wing": false,
          "Name": false,
          "EmbroideryLogo": true,
          "GoldGlove": true,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": true,
          "Fastback": false,
          "Oval": false
        }
      }
    }
  },
  {
    "sportId": "baseball",
    "id": "314HSW",
    "shapeId": "fielder",
    "props": {
      "imageDimensions": {
        "back": {
          "width": 2285,
          "height": 3000
        },
        "web": {
          "width": 2333,
          "height": 3000
        },
        "palm": {
          "width": 1920,
          "height": 2327
        }
      },
      "hasHandSewnStitching": true,
      "trimWithShellBack": true,
      "liningOnBackView": true,
      "liningOnPalmView": true,
      "useBackPalmColorForLiningOnKIP": false,
      "useBackPalmColorForLiningOnHOH": false,
      "allowShellPalmColorForLining": true,
      "isLiningShellPalmColorOnly": false,
      "logoPatchOnWebView": true,
      "logoPatchOnBackView": true,
      "MLBOnWebView": false,
      "MLBOnBackView": true,
      "MLBOnWebViewMask": false,
      "MLBOnBackViewMask": false,
      "extraStitching": false,
      "hasBackPalm": false,
      "holdsterLaces": false,
      "palmOverlay": false,
      "palmOnBackView": false,
      "palmOnWebView": false,
      "insertOnBackView": false,
      "insertOnWebView": false,
      "insertOnPalmView": true,
      "webOnBackView": false,
      "sweatband": true,
      "weltingOnBackView": true,
      "welting": true,
      "xrdPad": false,
      "hasFlag": false,
      "hasFlagOnHalfMesh": false,
      "uniqueFlagPlacement": false,
      "embroideryNameReplacesLogo": false,
      "pullStrapAboveOval": false,
      "hasStandardFitShadowOnWeb": true,
      "hasStandardFitShadowOnBack": true,
      "fingerPadIsRequired": false,
      "forceHalfHeel": true,
      "forceFullHeel": false,
      "hasBackOnPalmView": true,
      "hasHeelPad": true,
      "hasWristLining": true,
      "hasPinkyPalm": false,
      "deco3D": {
        "web": {
          "Flag": false,
          "Wing": false,
          "Oval": true,
          "Name": true,
          "PalmPadLeather": true,
          "EmbroideryLogo": true,
          "GoldGlove": false,
          "Fastback": false,
          "TrapezeWeb": false,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": true
        },
        "palm": {
          "LeatherType": true,
          "USSteerhide": true,
          "Professional": true,
          "RawlingsStamp": true,
          "REV1XRawlingsStamp": false,
          "REV1XPinky": false,
          "ModelName": true,
          "LiTeToe": false,
          "R2G": true
        },
        "back": {
          "Flag": false,
          "Wing": false,
          "Name": false,
          "EmbroideryLogo": true,
          "GoldGlove": true,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": true,
          "Fastback": false,
          "Oval": false
        }
      }
    }
  },
  {
    "sportId": "baseball",
    "id": "3319",
    "shapeId": "fielder",
    "props": {
      "imageDimensions": {
        "back": {
          "width": 2120,
          "height": 3000
        },
        "web": {
          "width": 2520,
          "height": 2973
        },
        "palm": {
          "width": 2567,
          "height": 3000
        }
      },
      "hasHandSewnStitching": false,
      "trimWithShellBack": true,
      "liningOnBackView": true,
      "liningOnPalmView": false,
      "useBackPalmColorForLiningOnKIP": false,
      "useBackPalmColorForLiningOnHOH": false,
      "allowShellPalmColorForLining": true,
      "isLiningShellPalmColorOnly": false,
      "logoPatchOnWebView": true,
      "logoPatchOnBackView": true,
      "MLBOnWebView": false,
      "MLBOnBackView": true,
      "MLBOnWebViewMask": false,
      "MLBOnBackViewMask": false,
      "extraStitching": false,
      "hasBackPalm": false,
      "holdsterLaces": false,
      "palmOverlay": false,
      "palmOnBackView": true,
      "palmOnWebView": true,
      "insertOnBackView": true,
      "insertOnWebView": true,
      "insertOnPalmView": true,
      "webOnBackView": false,
      "sweatband": true,
      "weltingOnBackView": true,
      "welting": true,
      "xrdPad": false,
      "hasFlag": false,
      "hasFlagOnHalfMesh": false,
      "uniqueFlagPlacement": false,
      "embroideryNameReplacesLogo": false,
      "pullStrapAboveOval": false,
      "hasStandardFitShadowOnWeb": true,
      "hasStandardFitShadowOnBack": true,
      "fingerPadIsRequired": false,
      "forceHalfHeel": true,
      "forceFullHeel": false,
      "hasBackOnPalmView": true,
      "hasHeelPad": true,
      "hasWristLining": true,
      "hasPinkyPalm": false,
      "deco3D": {
        "web": {
          "Flag": false,
          "Wing": false,
          "Oval": true,
          "Name": true,
          "PalmPadLeather": false,
          "EmbroideryLogo": true,
          "GoldGlove": false,
          "Fastback": false,
          "TrapezeWeb": false,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": true
        },
        "palm": {
          "LeatherType": true,
          "USSteerhide": true,
          "Professional": true,
          "RawlingsStamp": true,
          "REV1XRawlingsStamp": false,
          "REV1XPinky": false,
          "ModelName": true,
          "LiTeToe": false,
          "R2G": true
        },
        "back": {
          "Flag": false,
          "Wing": false,
          "Name": false,
          "EmbroideryLogo": true,
          "GoldGlove": true,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": true,
          "Fastback": false,
          "Oval": false
        }
      }
    }
  },
  {
    "sportId": "baseball",
    "id": "502",
    "shapeId": "fielder",
    "props": {
      "imageDimensions": {
        "back": {
          "width": 2357,
          "height": 3192
        },
        "web": {
          "width": 2940,
          "height": 3420
        },
        "palm": {
          "width": 2533,
          "height": 3078
        }
      },
      "hasHandSewnStitching": false,
      "trimWithShellBack": false,
      "liningOnBackView": false,
      "liningOnPalmView": false,
      "useBackPalmColorForLiningOnKIP": false,
      "useBackPalmColorForLiningOnHOH": false,
      "allowShellPalmColorForLining": true,
      "isLiningShellPalmColorOnly": false,
      "logoPatchOnWebView": true,
      "logoPatchOnBackView": true,
      "MLBOnWebView": false,
      "MLBOnBackView": true,
      "MLBOnWebViewMask": false,
      "MLBOnBackViewMask": false,
      "extraStitching": false,
      "hasBackPalm": false,
      "holdsterLaces": true,
      "palmOverlay": false,
      "palmOnBackView": true,
      "palmOnWebView": true,
      "insertOnBackView": false,
      "insertOnWebView": true,
      "insertOnPalmView": true,
      "webOnBackView": false,
      "sweatband": false,
      "weltingOnBackView": true,
      "welting": true,
      "xrdPad": false,
      "hasFlag": true,
      "hasFlagOnHalfMesh": false,
      "uniqueFlagPlacement": false,
      "embroideryNameReplacesLogo": false,
      "pullStrapAboveOval": false,
      "hasStandardFitShadowOnWeb": true,
      "hasStandardFitShadowOnBack": true,
      "fingerPadIsRequired": false,
      "forceHalfHeel": false,
      "forceFullHeel": false,
      "hasBackOnPalmView": true,
      "hasHeelPad": true,
      "hasWristLining": true,
      "hasPinkyPalm": false,
      "deco3D": {
        "web": {
          "Flag": true,
          "Wing": false,
          "Oval": true,
          "Name": true,
          "PalmPadLeather": false,
          "EmbroideryLogo": true,
          "GoldGlove": false,
          "Fastback": true,
          "TrapezeWeb": false,
          "IndexFingerXRD": false,
          "MiddleFingerXRD": false
        },
        "palm": {
          "LeatherType": true,
          "USSteerhide": true,
          "Professional": true,
          "RawlingsStamp": true,
          "REV1XRawlingsStamp": false,
          "REV1XPinky": false,
          "ModelName": true,
          "LiTeToe": false,
          "R2G": true
        },
        "back": {
          "Flag": true,
          "Wing": false,
          "Name": false,
          "EmbroideryLogo": true,
          "GoldGlove": true,
          "IndexFingerXRD": false,
          "MiddleFingerXRD": false,
          "Fastback": true,
          "Oval": false
        }
      }
    }
  },
  {
    "sportId": "baseball",
    "id": "504",
    "shapeId": "fielder",
    "props": {
      "imageDimensions": {
        "back": {
          "width": 2450,
          "height": 3331
        },
        "web": {
          "width": 3011,
          "height": 3367
        },
        "palm": {
          "width": 2568,
          "height": 3158
        }
      },
      "hasHandSewnStitching": false,
      "trimWithShellBack": false,
      "liningOnBackView": true,
      "liningOnPalmView": true,
      "useBackPalmColorForLiningOnKIP": false,
      "useBackPalmColorForLiningOnHOH": false,
      "allowShellPalmColorForLining": true,
      "isLiningShellPalmColorOnly": false,
      "logoPatchOnWebView": true,
      "logoPatchOnBackView": true,
      "MLBOnWebView": false,
      "MLBOnBackView": true,
      "MLBOnWebViewMask": false,
      "MLBOnBackViewMask": true,
      "extraStitching": false,
      "hasBackPalm": false,
      "holdsterLaces": false,
      "palmOverlay": false,
      "palmOnBackView": true,
      "palmOnWebView": true,
      "insertOnBackView": false,
      "insertOnWebView": false,
      "insertOnPalmView": true,
      "webOnBackView": false,
      "sweatband": true,
      "weltingOnBackView": true,
      "welting": true,
      "xrdPad": false,
      "hasFlag": true,
      "hasFlagOnHalfMesh": false,
      "uniqueFlagPlacement": false,
      "embroideryNameReplacesLogo": false,
      "pullStrapAboveOval": false,
      "hasStandardFitShadowOnWeb": true,
      "hasStandardFitShadowOnBack": true,
      "fingerPadIsRequired": false,
      "forceHalfHeel": false,
      "forceFullHeel": false,
      "hasBackOnPalmView": true,
      "hasHeelPad": true,
      "hasWristLining": true,
      "hasPinkyPalm": false,
      "deco3D": {
        "web": {
          "Flag": true,
          "Wing": false,
          "Oval": true,
          "Name": true,
          "PalmPadLeather": true,
          "EmbroideryLogo": true,
          "GoldGlove": false,
          "Fastback": false,
          "TrapezeWeb": false,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": true
        },
        "palm": {
          "LeatherType": true,
          "USSteerhide": true,
          "Professional": true,
          "RawlingsStamp": true,
          "REV1XRawlingsStamp": false,
          "REV1XPinky": false,
          "ModelName": true,
          "LiTeToe": false,
          "R2G": true
        },
        "back": {
          "Flag": true,
          "Wing": false,
          "Name": false,
          "EmbroideryLogo": true,
          "GoldGlove": true,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": true,
          "Fastback": false,
          "Oval": false
        }
      }
    }
  },
  {
    "sportId": "baseball",
    "id": "600",
    "shapeId": "fielder",
    "props": {
      "imageDimensions": {
        "back": {
          "width": 2499,
          "height": 3347
        },
        "web": {
          "width": 2373,
          "height": 2984
        },
        "palm": {
          "width": 2919,
          "height": 3270
        }
      },
      "hasHandSewnStitching": false,
      "trimWithShellBack": false,
      "liningOnBackView": false,
      "liningOnPalmView": false,
      "useBackPalmColorForLiningOnKIP": false,
      "useBackPalmColorForLiningOnHOH": false,
      "allowShellPalmColorForLining": true,
      "isLiningShellPalmColorOnly": false,
      "logoPatchOnWebView": false,
      "logoPatchOnBackView": true,
      "MLBOnWebView": false,
      "MLBOnBackView": true,
      "MLBOnWebViewMask": false,
      "MLBOnBackViewMask": false,
      "extraStitching": false,
      "hasBackPalm": false,
      "holdsterLaces": true,
      "palmOverlay": false,
      "palmOnBackView": true,
      "palmOnWebView": true,
      "insertOnBackView": false,
      "insertOnWebView": true,
      "insertOnPalmView": true,
      "webOnBackView": false,
      "sweatband": false,
      "weltingOnBackView": true,
      "welting": true,
      "xrdPad": false,
      "hasFlag": true,
      "hasFlagOnHalfMesh": true,
      "uniqueFlagPlacement": false,
      "embroideryNameReplacesLogo": false,
      "pullStrapAboveOval": false,
      "hasStandardFitShadowOnWeb": true,
      "hasStandardFitShadowOnBack": true,
      "fingerPadIsRequired": false,
      "forceHalfHeel": false,
      "forceFullHeel": false,
      "hasBackOnPalmView": true,
      "hasHeelPad": true,
      "hasWristLining": true,
      "hasPinkyPalm": false,
      "deco3D": {
        "web": {
          "Flag": false,
          "Wing": false,
          "Oval": true,
          "Name": true,
          "PalmPadLeather": false,
          "EmbroideryLogo": false,
          "GoldGlove": false,
          "Fastback": true,
          "TrapezeWeb": true,
          "IndexFingerXRD": false,
          "MiddleFingerXRD": false
        },
        "palm": {
          "LeatherType": true,
          "USSteerhide": true,
          "Professional": true,
          "RawlingsStamp": true,
          "REV1XRawlingsStamp": false,
          "REV1XPinky": false,
          "ModelName": true,
          "LiTeToe": false,
          "R2G": true
        },
        "back": {
          "Flag": true,
          "Wing": false,
          "Name": false,
          "EmbroideryLogo": true,
          "GoldGlove": true,
          "IndexFingerXRD": false,
          "MiddleFingerXRD": false,
          "Fastback": true,
          "Oval": false
        }
      }
    }
  },
  {
    "sportId": "baseball",
    "id": "6019HSW",
    "shapeId": "fielder",
    "props": {
      "imageDimensions": {
        "back": {
          "width": 2499,
          "height": 3347
        },
        "web": {
          "width": 2373,
          "height": 2984
        },
        "palm": {
          "width": 2919,
          "height": 3270
        }
      },
      "hasHandSewnStitching": true,
      "trimWithShellBack": false,
      "liningOnBackView": false,
      "liningOnPalmView": false,
      "useBackPalmColorForLiningOnKIP": false,
      "useBackPalmColorForLiningOnHOH": false,
      "allowShellPalmColorForLining": true,
      "isLiningShellPalmColorOnly": false,
      "logoPatchOnWebView": false,
      "logoPatchOnBackView": true,
      "MLBOnWebView": false,
      "MLBOnBackView": true,
      "MLBOnWebViewMask": false,
      "MLBOnBackViewMask": false,
      "extraStitching": false,
      "hasBackPalm": false,
      "holdsterLaces": true,
      "palmOverlay": false,
      "palmOnBackView": true,
      "palmOnWebView": true,
      "insertOnBackView": false,
      "insertOnWebView": true,
      "insertOnPalmView": true,
      "webOnBackView": false,
      "sweatband": false,
      "weltingOnBackView": true,
      "welting": true,
      "xrdPad": false,
      "hasFlag": true,
      "hasFlagOnHalfMesh": true,
      "uniqueFlagPlacement": false,
      "embroideryNameReplacesLogo": false,
      "pullStrapAboveOval": false,
      "hasStandardFitShadowOnWeb": true,
      "hasStandardFitShadowOnBack": true,
      "fingerPadIsRequired": false,
      "forceHalfHeel": false,
      "forceFullHeel": false,
      "hasBackOnPalmView": true,
      "hasHeelPad": true,
      "hasWristLining": true,
      "hasPinkyPalm": false,
      "deco3D": {
        "web": {
          "Flag": false,
          "Wing": false,
          "Oval": true,
          "Name": true,
          "PalmPadLeather": false,
          "EmbroideryLogo": false,
          "GoldGlove": false,
          "Fastback": true,
          "TrapezeWeb": true,
          "IndexFingerXRD": false,
          "MiddleFingerXRD": false
        },
        "palm": {
          "LeatherType": true,
          "USSteerhide": true,
          "Professional": true,
          "RawlingsStamp": true,
          "REV1XRawlingsStamp": false,
          "REV1XPinky": false,
          "ModelName": true,
          "LiTeToe": false,
          "R2G": true
        },
        "back": {
          "Flag": true,
          "Wing": false,
          "Name": false,
          "EmbroideryLogo": true,
          "GoldGlove": true,
          "IndexFingerXRD": false,
          "MiddleFingerXRD": false,
          "Fastback": true,
          "Oval": false
        }
      }
    }
  },
  {
    "sportId": "baseball",
    "id": "6019FS",
    "shapeId": "fielder",
    "props": {
      "imageDimensions": {
        "back": {
          "width": 2499,
          "height": 3347
        },
        "web": {
          "width": 2373,
          "height": 2984
        },
        "palm": {
          "width": 2919,
          "height": 3270
        }
      },
      "hasHandSewnStitching": false,
      "trimWithShellBack": false,
      "liningOnBackView": false,
      "liningOnPalmView": false,
      "useBackPalmColorForLiningOnKIP": false,
      "useBackPalmColorForLiningOnHOH": false,
      "allowShellPalmColorForLining": true,
      "isLiningShellPalmColorOnly": false,
      "logoPatchOnWebView": false,
      "logoPatchOnBackView": true,
      "MLBOnWebView": false,
      "MLBOnBackView": true,
      "MLBOnWebViewMask": false,
      "MLBOnBackViewMask": false,
      "extraStitching": false,
      "hasBackPalm": false,
      "holdsterLaces": true,
      "palmOverlay": false,
      "palmOnBackView": true,
      "palmOnWebView": true,
      "insertOnBackView": false,
      "insertOnWebView": true,
      "insertOnPalmView": true,
      "webOnBackView": false,
      "sweatband": false,
      "weltingOnBackView": true,
      "welting": true,
      "xrdPad": false,
      "hasFlag": true,
      "hasFlagOnHalfMesh": true,
      "uniqueFlagPlacement": false,
      "embroideryNameReplacesLogo": false,
      "pullStrapAboveOval": false,
      "hasStandardFitShadowOnWeb": true,
      "hasStandardFitShadowOnBack": true,
      "fingerPadIsRequired": false,
      "forceHalfHeel": false,
      "forceFullHeel": false,
      "hasBackOnPalmView": true,
      "hasHeelPad": true,
      "hasWristLining": true,
      "hasPinkyPalm": false,
      "deco3D": {
        "web": {
          "Flag": false,
          "Wing": false,
          "Oval": true,
          "Name": true,
          "PalmPadLeather": false,
          "EmbroideryLogo": false,
          "GoldGlove": false,
          "Fastback": true,
          "TrapezeWeb": true,
          "IndexFingerXRD": false,
          "MiddleFingerXRD": false
        },
        "palm": {
          "LeatherType": true,
          "USSteerhide": true,
          "Professional": true,
          "RawlingsStamp": true,
          "REV1XRawlingsStamp": false,
          "REV1XPinky": false,
          "ModelName": true,
          "LiTeToe": false,
          "R2G": true
        },
        "back": {
          "Flag": true,
          "Wing": false,
          "Name": false,
          "EmbroideryLogo": true,
          "GoldGlove": true,
          "IndexFingerXRD": false,
          "MiddleFingerXRD": false,
          "Fastback": true,
          "Oval": false
        }
      }
    }
  },
  {
    "sportId": "baseball",
    "id": "JD",
    "shapeId": "fielder",
    "props": {
      "imageDimensions": {
        "back": {
          "width": 2368,
          "height": 3312
        },
        "web": {
          "width": 2904,
          "height": 3273
        },
        "palm": {
          "width": 2639,
          "height": 2959
        }
      },
      "hasHandSewnStitching": false,
      "trimWithShellBack": false,
      "liningOnBackView": true,
      "liningOnPalmView": true,
      "useBackPalmColorForLiningOnKIP": false,
      "useBackPalmColorForLiningOnHOH": false,
      "allowShellPalmColorForLining": true,
      "isLiningShellPalmColorOnly": false,
      "logoPatchOnWebView": true,
      "logoPatchOnBackView": true,
      "MLBOnWebView": false,
      "MLBOnBackView": true,
      "MLBOnWebViewMask": false,
      "MLBOnBackViewMask": false,
      "extraStitching": false,
      "hasBackPalm": false,
      "holdsterLaces": false,
      "palmOverlay": false,
      "palmOnBackView": true,
      "palmOnWebView": true,
      "insertOnBackView": true,
      "insertOnWebView": true,
      "insertOnPalmView": true,
      "webOnBackView": false,
      "sweatband": true,
      "weltingOnBackView": true,
      "welting": true,
      "xrdPad": false,
      "hasFlag": true,
      "hasFlagOnHalfMesh": false,
      "uniqueFlagPlacement": false,
      "embroideryNameReplacesLogo": false,
      "pullStrapAboveOval": false,
      "hasStandardFitShadowOnWeb": true,
      "hasStandardFitShadowOnBack": true,
      "fingerPadIsRequired": false,
      "forceHalfHeel": false,
      "forceFullHeel": false,
      "hasBackOnPalmView": true,
      "hasHeelPad": true,
      "hasWristLining": true,
      "hasPinkyPalm": false,
      "deco3D": {
        "web": {
          "Flag": true,
          "Wing": false,
          "Oval": true,
          "Name": true,
          "PalmPadLeather": true,
          "EmbroideryLogo": true,
          "GoldGlove": false,
          "Fastback": false,
          "TrapezeWeb": false,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": true
        },
        "palm": {
          "LeatherType": true,
          "USSteerhide": true,
          "Professional": true,
          "RawlingsStamp": true,
          "REV1XRawlingsStamp": false,
          "REV1XPinky": false,
          "ModelName": true,
          "LiTeToe": false,
          "R2G": true
        },
        "back": {
          "Flag": true,
          "Wing": false,
          "Name": false,
          "EmbroideryLogo": true,
          "GoldGlove": true,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": true,
          "Fastback": false,
          "Oval": false
        }
      }
    }
  },
  {
    "sportId": "baseball",
    "id": "NP",
    "shapeId": "fielder",
    "props": {
      "imageDimensions": {
        "back": {
          "width": 2551,
          "height": 3238
        },
        "web": {
          "width": 2796,
          "height": 3232
        },
        "palm": {
          "width": 2538,
          "height": 2968
        }
      },
      "hasHandSewnStitching": false,
      "trimWithShellBack": false,
      "liningOnBackView": true,
      "liningOnPalmView": true,
      "useBackPalmColorForLiningOnKIP": false,
      "useBackPalmColorForLiningOnHOH": false,
      "allowShellPalmColorForLining": true,
      "isLiningShellPalmColorOnly": false,
      "logoPatchOnWebView": true,
      "logoPatchOnBackView": true,
      "MLBOnWebView": false,
      "MLBOnBackView": true,
      "MLBOnWebViewMask": false,
      "MLBOnBackViewMask": false,
      "extraStitching": false,
      "hasBackPalm": false,
      "holdsterLaces": false,
      "palmOverlay": false,
      "palmOnBackView": true,
      "palmOnWebView": true,
      "insertOnBackView": false,
      "insertOnWebView": false,
      "insertOnPalmView": true,
      "webOnBackView": false,
      "sweatband": true,
      "weltingOnBackView": true,
      "welting": true,
      "xrdPad": false,
      "hasFlag": true,
      "hasFlagOnHalfMesh": false,
      "uniqueFlagPlacement": false,
      "embroideryNameReplacesLogo": false,
      "pullStrapAboveOval": false,
      "hasStandardFitShadowOnWeb": true,
      "hasStandardFitShadowOnBack": true,
      "fingerPadIsRequired": false,
      "forceHalfHeel": false,
      "forceFullHeel": false,
      "hasBackOnPalmView": true,
      "hasHeelPad": true,
      "hasWristLining": true,
      "hasPinkyPalm": false,
      "deco3D": {
        "web": {
          "Flag": true,
          "Wing": false,
          "Oval": true,
          "Name": true,
          "PalmPadLeather": true,
          "EmbroideryLogo": true,
          "GoldGlove": false,
          "Fastback": false,
          "TrapezeWeb": false,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": true
        },
        "palm": {
          "LeatherType": true,
          "USSteerhide": true,
          "Professional": true,
          "RawlingsStamp": true,
          "REV1XRawlingsStamp": false,
          "REV1XPinky": false,
          "ModelName": true,
          "LiTeToe": false,
          "R2G": true
        },
        "back": {
          "Flag": true,
          "Wing": false,
          "Name": false,
          "EmbroideryLogo": true,
          "GoldGlove": true,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": true,
          "Fastback": false,
          "Oval": false
        }
      }
    }
  },
  {
    "sportId": "baseball",
    "id": "NP5HSW",
    "shapeId": "fielder",
    "props": {
      "imageDimensions": {
        "back": {
          "width": 2551,
          "height": 3238
        },
        "web": {
          "width": 2796,
          "height": 3232
        },
        "palm": {
          "width": 2538,
          "height": 2968
        }
      },
      "hasHandSewnStitching": true,
      "trimWithShellBack": false,
      "liningOnBackView": true,
      "liningOnPalmView": true,
      "useBackPalmColorForLiningOnKIP": false,
      "useBackPalmColorForLiningOnHOH": false,
      "allowShellPalmColorForLining": true,
      "isLiningShellPalmColorOnly": false,
      "logoPatchOnWebView": true,
      "logoPatchOnBackView": true,
      "MLBOnWebView": false,
      "MLBOnBackView": true,
      "MLBOnWebViewMask": false,
      "MLBOnBackViewMask": false,
      "extraStitching": false,
      "hasBackPalm": false,
      "holdsterLaces": false,
      "palmOverlay": false,
      "palmOnBackView": true,
      "palmOnWebView": true,
      "insertOnBackView": false,
      "insertOnWebView": false,
      "insertOnPalmView": true,
      "webOnBackView": false,
      "sweatband": true,
      "weltingOnBackView": true,
      "welting": true,
      "xrdPad": false,
      "hasFlag": true,
      "hasFlagOnHalfMesh": false,
      "uniqueFlagPlacement": false,
      "embroideryNameReplacesLogo": false,
      "pullStrapAboveOval": false,
      "hasStandardFitShadowOnWeb": true,
      "hasStandardFitShadowOnBack": true,
      "fingerPadIsRequired": false,
      "forceHalfHeel": false,
      "forceFullHeel": false,
      "hasBackOnPalmView": true,
      "hasHeelPad": true,
      "hasWristLining": true,
      "hasPinkyPalm": false,
      "deco3D": {
        "web": {
          "Flag": true,
          "Wing": false,
          "Oval": true,
          "Name": true,
          "PalmPadLeather": true,
          "EmbroideryLogo": true,
          "GoldGlove": false,
          "Fastback": false,
          "TrapezeWeb": false,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": true
        },
        "palm": {
          "LeatherType": true,
          "USSteerhide": true,
          "Professional": true,
          "RawlingsStamp": true,
          "REV1XRawlingsStamp": false,
          "REV1XPinky": false,
          "ModelName": true,
          "LiTeToe": false,
          "R2G": true
        },
        "back": {
          "Flag": true,
          "Wing": false,
          "Name": false,
          "EmbroideryLogo": true,
          "GoldGlove": true,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": true,
          "Fastback": false,
          "Oval": false
        }
      }
    }
  },
  {
    "sportId": "baseball",
    "id": "SC1",
    "shapeId": "fielder",
    "props": {
      "imageDimensions": {
        "back": {
          "width": 2637,
          "height": 3181
        },
        "web": {
          "width": 2615,
          "height": 3120
        },
        "palm": {
          "width": 2590,
          "height": 2819
        }
      },
      "hasHandSewnStitching": false,
      "trimWithShellBack": false,
      "liningOnBackView": true,
      "liningOnPalmView": true,
      "useBackPalmColorForLiningOnKIP": false,
      "useBackPalmColorForLiningOnHOH": false,
      "allowShellPalmColorForLining": true,
      "isLiningShellPalmColorOnly": false,
      "logoPatchOnWebView": true,
      "logoPatchOnBackView": true,
      "MLBOnWebView": false,
      "MLBOnBackView": true,
      "MLBOnWebViewMask": false,
      "MLBOnBackViewMask": false,
      "extraStitching": false,
      "hasBackPalm": false,
      "holdsterLaces": false,
      "palmOverlay": false,
      "palmOnBackView": true,
      "palmOnWebView": true,
      "insertOnBackView": false,
      "insertOnWebView": false,
      "insertOnPalmView": true,
      "webOnBackView": false,
      "sweatband": true,
      "weltingOnBackView": true,
      "welting": true,
      "xrdPad": false,
      "hasFlag": true,
      "hasFlagOnHalfMesh": false,
      "uniqueFlagPlacement": false,
      "embroideryNameReplacesLogo": false,
      "pullStrapAboveOval": false,
      "hasStandardFitShadowOnWeb": true,
      "hasStandardFitShadowOnBack": true,
      "fingerPadIsRequired": false,
      "forceHalfHeel": false,
      "forceFullHeel": false,
      "hasBackOnPalmView": true,
      "hasHeelPad": true,
      "hasWristLining": true,
      "hasPinkyPalm": false,
      "deco3D": {
        "web": {
          "Flag": true,
          "Wing": false,
          "Oval": true,
          "Name": true,
          "PalmPadLeather": true,
          "EmbroideryLogo": true,
          "GoldGlove": false,
          "Fastback": false,
          "TrapezeWeb": false,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": true
        },
        "palm": {
          "LeatherType": true,
          "USSteerhide": true,
          "Professional": true,
          "RawlingsStamp": true,
          "REV1XRawlingsStamp": false,
          "REV1XPinky": false,
          "ModelName": true,
          "LiTeToe": false,
          "R2G": true
        },
        "back": {
          "Flag": true,
          "Wing": false,
          "Name": false,
          "EmbroideryLogo": true,
          "GoldGlove": true,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": true,
          "Fastback": false,
          "Oval": false
        }
      }
    }
  },
  {
    "sportId": "baseball",
    "id": "TT2",
    "shapeId": "fielder",
    "props": {
      "imageDimensions": {
        "back": {
          "width": 3579,
          "height": 4757
        },
        "web": {
          "width": 4298,
          "height": 5059
        },
        "palm": {
          "width": 4251,
          "height": 4850
        }
      },
      "hasHandSewnStitching": false,
      "trimWithShellBack": false,
      "liningOnBackView": true,
      "liningOnPalmView": true,
      "useBackPalmColorForLiningOnKIP": false,
      "useBackPalmColorForLiningOnHOH": false,
      "allowShellPalmColorForLining": true,
      "isLiningShellPalmColorOnly": false,
      "logoPatchOnWebView": true,
      "logoPatchOnBackView": true,
      "MLBOnWebView": false,
      "MLBOnBackView": true,
      "MLBOnWebViewMask": false,
      "MLBOnBackViewMask": false,
      "extraStitching": false,
      "hasBackPalm": false,
      "holdsterLaces": false,
      "palmOverlay": false,
      "palmOnBackView": true,
      "palmOnWebView": true,
      "insertOnBackView": false,
      "insertOnWebView": false,
      "insertOnPalmView": true,
      "webOnBackView": false,
      "sweatband": true,
      "weltingOnBackView": true,
      "welting": true,
      "xrdPad": false,
      "hasFlag": true,
      "hasFlagOnHalfMesh": false,
      "uniqueFlagPlacement": false,
      "embroideryNameReplacesLogo": false,
      "pullStrapAboveOval": false,
      "hasStandardFitShadowOnWeb": true,
      "hasStandardFitShadowOnBack": true,
      "fingerPadIsRequired": false,
      "forceHalfHeel": false,
      "forceFullHeel": false,
      "hasBackOnPalmView": true,
      "hasHeelPad": true,
      "hasWristLining": true,
      "hasPinkyPalm": false,
      "deco3D": {
        "web": {
          "Flag": true,
          "Wing": false,
          "Oval": true,
          "Name": true,
          "PalmPadLeather": true,
          "EmbroideryLogo": true,
          "GoldGlove": false,
          "Fastback": false,
          "TrapezeWeb": false,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": true
        },
        "palm": {
          "LeatherType": true,
          "USSteerhide": true,
          "Professional": true,
          "RawlingsStamp": true,
          "REV1XRawlingsStamp": false,
          "REV1XPinky": false,
          "ModelName": true,
          "LiTeToe": false,
          "R2G": true
        },
        "back": {
          "Flag": true,
          "Wing": false,
          "Name": false,
          "EmbroideryLogo": true,
          "GoldGlove": true,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": true,
          "Fastback": false,
          "Oval": false
        }
      }
    }
  },
  {
    "sportId": "baseball",
    "id": "YJN1",
    "shapeId": "fielder",
    "props": {
      "imageDimensions": {
        "back": {
          "width": 3967,
          "height": 4792
        },
        "web": {
          "width": 3759,
          "height": 4000
        },
        "palm": {
          "width": 3447,
          "height": 3687
        }
      },
      "hasHandSewnStitching": false,
      "trimWithShellBack": false,
      "liningOnBackView": true,
      "liningOnPalmView": true,
      "useBackPalmColorForLiningOnKIP": false,
      "useBackPalmColorForLiningOnHOH": false,
      "allowShellPalmColorForLining": true,
      "isLiningShellPalmColorOnly": false,
      "logoPatchOnWebView": true,
      "logoPatchOnBackView": true,
      "MLBOnWebView": false,
      "MLBOnBackView": true,
      "MLBOnWebViewMask": false,
      "MLBOnBackViewMask": true,
      "extraStitching": false,
      "hasBackPalm": false,
      "holdsterLaces": false,
      "palmOverlay": false,
      "palmOnBackView": true,
      "palmOnWebView": true,
      "insertOnBackView": false,
      "insertOnWebView": false,
      "insertOnPalmView": true,
      "webOnBackView": false,
      "sweatband": true,
      "weltingOnBackView": true,
      "welting": true,
      "xrdPad": false,
      "hasFlag": true,
      "hasFlagOnHalfMesh": false,
      "uniqueFlagPlacement": false,
      "embroideryNameReplacesLogo": false,
      "pullStrapAboveOval": false,
      "hasStandardFitShadowOnWeb": true,
      "hasStandardFitShadowOnBack": true,
      "fingerPadIsRequired": false,
      "forceHalfHeel": false,
      "forceFullHeel": false,
      "hasBackOnPalmView": true,
      "hasHeelPad": true,
      "hasWristLining": true,
      "hasPinkyPalm": false,
      "deco3D": {
        "web": {
          "Flag": true,
          "Wing": false,
          "Oval": true,
          "Name": true,
          "PalmPadLeather": true,
          "EmbroideryLogo": true,
          "GoldGlove": false,
          "Fastback": false,
          "TrapezeWeb": false,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": true
        },
        "palm": {
          "LeatherType": true,
          "USSteerhide": true,
          "Professional": true,
          "RawlingsStamp": true,
          "REV1XRawlingsStamp": false,
          "REV1XPinky": false,
          "ModelName": true,
          "LiTeToe": false,
          "R2G": true
        },
        "back": {
          "Flag": true,
          "Wing": false,
          "Name": false,
          "EmbroideryLogo": true,
          "GoldGlove": true,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": true,
          "Fastback": false,
          "Oval": false
        }
      }
    }
  },
  {
    "sportId": "baseball",
    "id": "YPT110",
    "shapeId": "fielder",
    "props": {
      "imageDimensions": {
        "back": {
          "width": 1789,
          "height": 2248
        },
        "web": {
          "width": 1979,
          "height": 2232
        },
        "palm": {
          "width": 1897,
          "height": 2315
        }
      },
      "hasHandSewnStitching": false,
      "trimWithShellBack": false,
      "liningOnBackView": true,
      "liningOnPalmView": false,
      "useBackPalmColorForLiningOnKIP": false,
      "useBackPalmColorForLiningOnHOH": false,
      "allowShellPalmColorForLining": true,
      "isLiningShellPalmColorOnly": false,
      "logoPatchOnWebView": true,
      "logoPatchOnBackView": true,
      "MLBOnWebView": false,
      "MLBOnBackView": true,
      "MLBOnWebViewMask": false,
      "MLBOnBackViewMask": false,
      "extraStitching": false,
      "hasBackPalm": false,
      "holdsterLaces": false,
      "palmOverlay": false,
      "palmOnBackView": true,
      "palmOnWebView": true,
      "insertOnBackView": false,
      "insertOnWebView": false,
      "insertOnPalmView": true,
      "webOnBackView": false,
      "sweatband": true,
      "weltingOnBackView": true,
      "welting": true,
      "xrdPad": false,
      "hasFlag": true,
      "hasFlagOnHalfMesh": false,
      "uniqueFlagPlacement": false,
      "embroideryNameReplacesLogo": false,
      "pullStrapAboveOval": false,
      "hasStandardFitShadowOnWeb": true,
      "hasStandardFitShadowOnBack": true,
      "fingerPadIsRequired": false,
      "forceHalfHeel": false,
      "forceFullHeel": false,
      "hasBackOnPalmView": true,
      "hasHeelPad": true,
      "hasWristLining": true,
      "hasPinkyPalm": false,
      "deco3D": {
        "web": {
          "Flag": true,
          "Wing": false,
          "Oval": true,
          "Name": true,
          "PalmPadLeather": false,
          "EmbroideryLogo": true,
          "GoldGlove": false,
          "Fastback": false,
          "TrapezeWeb": false,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": true
        },
        "palm": {
          "LeatherType": true,
          "USSteerhide": true,
          "Professional": true,
          "RawlingsStamp": true,
          "REV1XRawlingsStamp": false,
          "REV1XPinky": false,
          "ModelName": true,
          "LiTeToe": false,
          "R2G": true
        },
        "back": {
          "Flag": true,
          "Wing": false,
          "Name": false,
          "EmbroideryLogo": true,
          "GoldGlove": true,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": true,
          "Fastback": false,
          "Oval": false
        }
      }
    }
  },
  {
    "sportId": "softball",
    "id": "125KR",
    "shapeId": "fielder",
    "props": {
      "imageDimensions": {
        "back": {
          "width": 3674,
          "height": 4866
        },
        "web": {
          "width": 2721,
          "height": 3000
        },
        "palm": {
          "width": 2515,
          "height": 3000
        }
      },
      "hasHandSewnStitching": false,
      "trimWithShellBack": false,
      "liningOnBackView": true,
      "liningOnPalmView": true,
      "useBackPalmColorForLiningOnKIP": false,
      "useBackPalmColorForLiningOnHOH": false,
      "allowShellPalmColorForLining": true,
      "isLiningShellPalmColorOnly": false,
      "logoPatchOnWebView": false,
      "logoPatchOnBackView": true,
      "MLBOnWebView": false,
      "MLBOnBackView": false,
      "MLBOnWebViewMask": false,
      "MLBOnBackViewMask": false,
      "extraStitching": false,
      "hasBackPalm": false,
      "holdsterLaces": false,
      "palmOverlay": false,
      "palmOnBackView": true,
      "palmOnWebView": true,
      "insertOnBackView": true,
      "insertOnWebView": false,
      "insertOnPalmView": true,
      "webOnBackView": false,
      "sweatband": false,
      "weltingOnBackView": true,
      "welting": true,
      "xrdPad": false,
      "hasFlag": true,
      "hasFlagOnHalfMesh": false,
      "uniqueFlagPlacement": false,
      "embroideryNameReplacesLogo": false,
      "pullStrapAboveOval": true,
      "hasStandardFitShadowOnWeb": true,
      "hasStandardFitShadowOnBack": true,
      "fingerPadIsRequired": false,
      "forceHalfHeel": false,
      "forceFullHeel": false,
      "hasBackOnPalmView": true,
      "hasHeelPad": true,
      "hasWristLining": true,
      "hasPinkyPalm": false,
      "deco3D": {
        "web": {
          "Flag": true,
          "Wing": false,
          "Oval": true,
          "Name": true,
          "PalmPadLeather": true,
          "EmbroideryLogo": true,
          "GoldGlove": false,
          "Fastback": false,
          "TrapezeWeb": false,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": true
        },
        "palm": {
          "LeatherType": true,
          "USSteerhide": true,
          "Professional": true,
          "RawlingsStamp": true,
          "REV1XRawlingsStamp": false,
          "REV1XPinky": false,
          "ModelName": true,
          "LiTeToe": false,
          "R2G": true
        },
        "back": {
          "Flag": true,
          "Wing": false,
          "Name": false,
          "EmbroideryLogo": true,
          "GoldGlove": true,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": true,
          "Fastback": false,
          "Oval": false
        }
      }
    }
  },
  {
    "sportId": "softball",
    "id": "125SB",
    "shapeId": "fielder",
    "props": {
      "imageDimensions": {
        "back": {
          "width": 3842,
          "height": 4861
        },
        "web": {
          "width": 3766,
          "height": 4832
        },
        "palm": {
          "width": 4094,
          "height": 4704
        }
      },
      "hasHandSewnStitching": false,
      "trimWithShellBack": false,
      "liningOnBackView": true,
      "liningOnPalmView": true,
      "useBackPalmColorForLiningOnKIP": false,
      "useBackPalmColorForLiningOnHOH": false,
      "allowShellPalmColorForLining": true,
      "isLiningShellPalmColorOnly": false,
      "logoPatchOnWebView": false,
      "logoPatchOnBackView": true,
      "MLBOnWebView": false,
      "MLBOnBackView": false,
      "MLBOnWebViewMask": false,
      "MLBOnBackViewMask": false,
      "extraStitching": false,
      "hasBackPalm": false,
      "holdsterLaces": false,
      "palmOverlay": false,
      "palmOnBackView": false,
      "palmOnWebView": false,
      "insertOnBackView": true,
      "insertOnWebView": true,
      "insertOnPalmView": true,
      "webOnBackView": false,
      "sweatband": false,
      "weltingOnBackView": true,
      "welting": true,
      "xrdPad": false,
      "hasFlag": true,
      "hasFlagOnHalfMesh": false,
      "uniqueFlagPlacement": false,
      "embroideryNameReplacesLogo": false,
      "pullStrapAboveOval": true,
      "hasStandardFitShadowOnWeb": true,
      "hasStandardFitShadowOnBack": true,
      "fingerPadIsRequired": false,
      "forceHalfHeel": false,
      "forceFullHeel": false,
      "hasBackOnPalmView": true,
      "hasHeelPad": true,
      "hasWristLining": true,
      "hasPinkyPalm": false,
      "deco3D": {
        "web": {
          "Flag": true,
          "Wing": false,
          "Oval": true,
          "Name": true,
          "PalmPadLeather": true,
          "EmbroideryLogo": true,
          "GoldGlove": false,
          "Fastback": false,
          "TrapezeWeb": false,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": true
        },
        "palm": {
          "LeatherType": true,
          "USSteerhide": true,
          "Professional": true,
          "RawlingsStamp": true,
          "REV1XRawlingsStamp": false,
          "REV1XPinky": false,
          "ModelName": true,
          "LiTeToe": false,
          "R2G": true
        },
        "back": {
          "Flag": true,
          "Wing": false,
          "Name": false,
          "EmbroideryLogo": true,
          "GoldGlove": true,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": true,
          "Fastback": false,
          "Oval": false
        }
      }
    }
  },
  {
    "sportId": "softball",
    "id": "125FS",
    "shapeId": "fielder",
    "props": {
      "imageDimensions": {
        "back": {
          "width": 3740,
          "height": 4927
        },
        "web": {
          "width": 2456,
          "height": 3000
        },
        "palm": {
          "width": 2379,
          "height": 3000
        }
      },
      "hasHandSewnStitching": false,
      "trimWithShellBack": false,
      "liningOnBackView": true,
      "liningOnPalmView": true,
      "useBackPalmColorForLiningOnKIP": false,
      "useBackPalmColorForLiningOnHOH": false,
      "allowShellPalmColorForLining": true,
      "isLiningShellPalmColorOnly": false,
      "logoPatchOnWebView": false,
      "logoPatchOnBackView": true,
      "MLBOnWebView": false,
      "MLBOnBackView": false,
      "MLBOnWebViewMask": false,
      "MLBOnBackViewMask": false,
      "extraStitching": false,
      "hasBackPalm": false,
      "holdsterLaces": false,
      "palmOverlay": false,
      "palmOnBackView": true,
      "palmOnWebView": false,
      "insertOnBackView": false,
      "insertOnWebView": false,
      "insertOnPalmView": true,
      "webOnBackView": false,
      "sweatband": false,
      "weltingOnBackView": true,
      "welting": true,
      "xrdPad": false,
      "hasFlag": true,
      "hasFlagOnHalfMesh": false,
      "uniqueFlagPlacement": false,
      "embroideryNameReplacesLogo": false,
      "pullStrapAboveOval": true,
      "hasStandardFitShadowOnWeb": true,
      "hasStandardFitShadowOnBack": true,
      "fingerPadIsRequired": false,
      "forceHalfHeel": false,
      "forceFullHeel": false,
      "hasBackOnPalmView": true,
      "hasHeelPad": true,
      "hasWristLining": true,
      "hasPinkyPalm": false,
      "deco3D": {
        "web": {
          "Flag": true,
          "Wing": false,
          "Oval": true,
          "Name": true,
          "PalmPadLeather": true,
          "EmbroideryLogo": true,
          "GoldGlove": false,
          "Fastback": false,
          "TrapezeWeb": false,
          "IndexFingerXRD": false,
          "MiddleFingerXRD": false
        },
        "palm": {
          "LeatherType": true,
          "USSteerhide": true,
          "Professional": true,
          "RawlingsStamp": true,
          "REV1XRawlingsStamp": false,
          "REV1XPinky": false,
          "ModelName": true,
          "LiTeToe": false,
          "R2G": true
        },
        "back": {
          "Flag": true,
          "Wing": false,
          "Name": false,
          "EmbroideryLogo": true,
          "GoldGlove": true,
          "IndexFingerXRD": false,
          "MiddleFingerXRD": false,
          "Fastback": false,
          "Oval": false
        }
      }
    }
  },
  {
    "sportId": "softball",
    "id": "560",
    "shapeId": "fielder",
    "props": {
      "imageDimensions": {
        "back": {
          "width": 3722,
          "height": 4990
        },
        "web": {
          "width": 2323,
          "height": 3000
        },
        "palm": {
          "width": 2356,
          "height": 3000
        }
      },
      "hasHandSewnStitching": false,
      "trimWithShellBack": false,
      "liningOnBackView": true,
      "liningOnPalmView": true,
      "useBackPalmColorForLiningOnKIP": false,
      "useBackPalmColorForLiningOnHOH": false,
      "allowShellPalmColorForLining": true,
      "isLiningShellPalmColorOnly": false,
      "logoPatchOnWebView": true,
      "logoPatchOnBackView": true,
      "MLBOnWebView": false,
      "MLBOnBackView": false,
      "MLBOnWebViewMask": false,
      "MLBOnBackViewMask": false,
      "extraStitching": false,
      "hasBackPalm": false,
      "holdsterLaces": false,
      "palmOverlay": false,
      "palmOnBackView": true,
      "palmOnWebView": true,
      "insertOnBackView": false,
      "insertOnWebView": false,
      "insertOnPalmView": true,
      "webOnBackView": false,
      "sweatband": true,
      "weltingOnBackView": true,
      "welting": true,
      "xrdPad": false,
      "hasFlag": true,
      "hasFlagOnHalfMesh": false,
      "uniqueFlagPlacement": false,
      "embroideryNameReplacesLogo": false,
      "pullStrapAboveOval": false,
      "hasStandardFitShadowOnWeb": true,
      "hasStandardFitShadowOnBack": true,
      "fingerPadIsRequired": false,
      "forceHalfHeel": false,
      "forceFullHeel": false,
      "hasBackOnPalmView": true,
      "hasHeelPad": true,
      "hasWristLining": true,
      "hasPinkyPalm": false,
      "deco3D": {
        "web": {
          "Flag": true,
          "Wing": false,
          "Oval": true,
          "Name": true,
          "PalmPadLeather": true,
          "EmbroideryLogo": true,
          "GoldGlove": false,
          "Fastback": false,
          "TrapezeWeb": false,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": true
        },
        "palm": {
          "LeatherType": true,
          "USSteerhide": true,
          "Professional": true,
          "RawlingsStamp": true,
          "REV1XRawlingsStamp": false,
          "REV1XPinky": false,
          "ModelName": true,
          "LiTeToe": false,
          "R2G": true
        },
        "back": {
          "Flag": true,
          "Wing": false,
          "Name": false,
          "EmbroideryLogo": true,
          "GoldGlove": true,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": true,
          "Fastback": false,
          "Oval": false
        }
      }
    }
  },
  {
    "sportId": "softball",
    "id": "715SB",
    "shapeId": "fielder",
    "props": {
      "imageDimensions": {
        "back": {
          "width": 3752,
          "height": 4925
        },
        "web": {
          "width": 4064,
          "height": 5226
        },
        "palm": {
          "width": 1920,
          "height": 2327
        }
      },
      "hasHandSewnStitching": false,
      "trimWithShellBack": true,
      "liningOnBackView": true,
      "liningOnPalmView": true,
      "useBackPalmColorForLiningOnKIP": false,
      "useBackPalmColorForLiningOnHOH": false,
      "allowShellPalmColorForLining": true,
      "isLiningShellPalmColorOnly": false,
      "logoPatchOnWebView": true,
      "logoPatchOnBackView": true,
      "MLBOnWebView": false,
      "MLBOnBackView": false,
      "MLBOnWebViewMask": false,
      "MLBOnBackViewMask": false,
      "extraStitching": false,
      "hasBackPalm": false,
      "holdsterLaces": false,
      "palmOverlay": false,
      "palmOnBackView": false,
      "palmOnWebView": false,
      "insertOnBackView": false,
      "insertOnWebView": false,
      "insertOnPalmView": true,
      "webOnBackView": false,
      "sweatband": true,
      "weltingOnBackView": true,
      "welting": true,
      "xrdPad": false,
      "hasFlag": false,
      "hasFlagOnHalfMesh": false,
      "uniqueFlagPlacement": false,
      "embroideryNameReplacesLogo": false,
      "pullStrapAboveOval": false,
      "hasStandardFitShadowOnWeb": true,
      "hasStandardFitShadowOnBack": true,
      "fingerPadIsRequired": false,
      "forceHalfHeel": true,
      "forceFullHeel": false,
      "hasBackOnPalmView": true,
      "hasHeelPad": true,
      "hasWristLining": true,
      "hasPinkyPalm": false,
      "deco3D": {
        "web": {
          "Flag": false,
          "Wing": false,
          "Oval": true,
          "Name": true,
          "PalmPadLeather": true,
          "EmbroideryLogo": true,
          "GoldGlove": false,
          "Fastback": false,
          "TrapezeWeb": false,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": true
        },
        "palm": {
          "LeatherType": true,
          "USSteerhide": true,
          "Professional": true,
          "RawlingsStamp": true,
          "REV1XRawlingsStamp": false,
          "REV1XPinky": false,
          "ModelName": true,
          "LiTeToe": false,
          "R2G": true
        },
        "back": {
          "Flag": false,
          "Wing": false,
          "Name": false,
          "EmbroideryLogo": true,
          "GoldGlove": true,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": true,
          "Fastback": false,
          "Oval": false
        }
      }
    }
  },
  {
    "sportId": "baseball",
    "id": "CMHCB2",
    "shapeId": "firstBase",
    "props": {
      "imageDimensions": {
        "back": {
          "width": 2578,
          "height": 3669
        },
        "web": {
          "width": 3016,
          "height": 3520
        },
        "palm": {
          "width": 2713,
          "height": 3204
        }
      },
      "hasHandSewnStitching": false,
      "trimWithShellBack": false,
      "liningOnBackView": true,
      "liningOnPalmView": true,
      "useBackPalmColorForLiningOnKIP": false,
      "useBackPalmColorForLiningOnHOH": false,
      "allowShellPalmColorForLining": true,
      "isLiningShellPalmColorOnly": false,
      "logoPatchOnWebView": true,
      "logoPatchOnBackView": true,
      "MLBOnWebView": false,
      "MLBOnBackView": true,
      "MLBOnWebViewMask": false,
      "MLBOnBackViewMask": false,
      "extraStitching": false,
      "hasBackPalm": false,
      "holdsterLaces": false,
      "palmOverlay": false,
      "palmOnBackView": true,
      "palmOnWebView": true,
      "insertOnBackView": true,
      "insertOnWebView": false,
      "insertOnPalmView": true,
      "webOnBackView": true,
      "sweatband": true,
      "weltingOnBackView": false,
      "welting": false,
      "xrdPad": true,
      "hasFlag": true,
      "hasFlagOnHalfMesh": false,
      "uniqueFlagPlacement": false,
      "embroideryNameReplacesLogo": false,
      "pullStrapAboveOval": false,
      "hasStandardFitShadowOnWeb": true,
      "hasStandardFitShadowOnBack": true,
      "fingerPadIsRequired": false,
      "forceHalfHeel": false,
      "forceFullHeel": false,
      "hasBackOnPalmView": true,
      "hasHeelPad": true,
      "hasWristLining": true,
      "hasPinkyPalm": false,
      "deco3D": {
        "web": {
          "Flag": false,
          "Wing": false,
          "Oval": true,
          "Name": true,
          "PalmPadLeather": false,
          "EmbroideryLogo": false,
          "GoldGlove": false,
          "Fastback": false,
          "TrapezeWeb": false,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": false
        },
        "palm": {
          "LeatherType": true,
          "USSteerhide": true,
          "Professional": true,
          "RawlingsStamp": true,
          "REV1XRawlingsStamp": false,
          "REV1XPinky": false,
          "ModelName": true,
          "LiTeToe": false,
          "R2G": true
        },
        "back": {
          "Flag": true,
          "Wing": false,
          "Name": false,
          "EmbroideryLogo": true,
          "GoldGlove": true,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": false,
          "Fastback": false,
          "Oval": false
        }
      }
    }
  },
  {
    "sportId": "baseball",
    "id": "DCT",
    "shapeId": "firstBase",
    "props": {
      "imageDimensions": {
        "back": {
          "width": 2505,
          "height": 3544
        },
        "web": {
          "width": 2994,
          "height": 3578
        },
        "palm": {
          "width": 2739,
          "height": 3217
        }
      },
      "hasHandSewnStitching": false,
      "trimWithShellBack": false,
      "liningOnBackView": true,
      "liningOnPalmView": true,
      "useBackPalmColorForLiningOnKIP": false,
      "useBackPalmColorForLiningOnHOH": false,
      "allowShellPalmColorForLining": true,
      "isLiningShellPalmColorOnly": false,
      "logoPatchOnWebView": true,
      "logoPatchOnBackView": true,
      "MLBOnWebView": false,
      "MLBOnBackView": true,
      "MLBOnWebViewMask": false,
      "MLBOnBackViewMask": true,
      "extraStitching": false,
      "hasBackPalm": false,
      "holdsterLaces": false,
      "palmOverlay": false,
      "palmOnBackView": true,
      "palmOnWebView": true,
      "insertOnBackView": true,
      "insertOnWebView": false,
      "insertOnPalmView": true,
      "webOnBackView": true,
      "sweatband": true,
      "weltingOnBackView": false,
      "welting": false,
      "xrdPad": true,
      "hasFlag": true,
      "hasFlagOnHalfMesh": false,
      "uniqueFlagPlacement": false,
      "embroideryNameReplacesLogo": false,
      "pullStrapAboveOval": false,
      "hasStandardFitShadowOnWeb": true,
      "hasStandardFitShadowOnBack": true,
      "fingerPadIsRequired": false,
      "forceHalfHeel": false,
      "forceFullHeel": false,
      "hasBackOnPalmView": true,
      "hasHeelPad": true,
      "hasWristLining": true,
      "hasPinkyPalm": false,
      "deco3D": {
        "web": {
          "Flag": true,
          "Wing": false,
          "Oval": true,
          "Name": false,
          "PalmPadLeather": false,
          "EmbroideryLogo": true,
          "GoldGlove": false,
          "Fastback": false,
          "TrapezeWeb": false,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": false
        },
        "palm": {
          "LeatherType": true,
          "USSteerhide": true,
          "Professional": true,
          "RawlingsStamp": false,
          "REV1XRawlingsStamp": false,
          "REV1XPinky": false,
          "ModelName": true,
          "LiTeToe": false,
          "R2G": true
        },
        "back": {
          "Flag": true,
          "Wing": false,
          "Name": false,
          "EmbroideryLogo": true,
          "GoldGlove": true,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": false,
          "Fastback": false,
          "Oval": false
        }
      }
    }
  },
  {
    "sportId": "baseball",
    "id": "DCTHSW",
    "shapeId": "firstBase",
    "props": {
      "imageDimensions": {
        "back": {
          "width": 2505,
          "height": 3544
        },
        "web": {
          "width": 2994,
          "height": 3578
        },
        "palm": {
          "width": 2739,
          "height": 3217
        }
      },
      "hasHandSewnStitching": true,
      "trimWithShellBack": false,
      "liningOnBackView": true,
      "liningOnPalmView": true,
      "useBackPalmColorForLiningOnKIP": false,
      "useBackPalmColorForLiningOnHOH": false,
      "allowShellPalmColorForLining": true,
      "isLiningShellPalmColorOnly": false,
      "logoPatchOnWebView": true,
      "logoPatchOnBackView": true,
      "MLBOnWebView": false,
      "MLBOnBackView": true,
      "MLBOnWebViewMask": false,
      "MLBOnBackViewMask": true,
      "extraStitching": false,
      "hasBackPalm": false,
      "holdsterLaces": false,
      "palmOverlay": false,
      "palmOnBackView": true,
      "palmOnWebView": true,
      "insertOnBackView": true,
      "insertOnWebView": false,
      "insertOnPalmView": true,
      "webOnBackView": true,
      "sweatband": true,
      "weltingOnBackView": false,
      "welting": false,
      "xrdPad": true,
      "hasFlag": true,
      "hasFlagOnHalfMesh": false,
      "uniqueFlagPlacement": false,
      "embroideryNameReplacesLogo": false,
      "pullStrapAboveOval": false,
      "hasStandardFitShadowOnWeb": true,
      "hasStandardFitShadowOnBack": true,
      "fingerPadIsRequired": false,
      "forceHalfHeel": false,
      "forceFullHeel": false,
      "hasBackOnPalmView": true,
      "hasHeelPad": true,
      "hasWristLining": true,
      "hasPinkyPalm": false,
      "deco3D": {
        "web": {
          "Flag": true,
          "Wing": false,
          "Oval": true,
          "Name": false,
          "PalmPadLeather": false,
          "EmbroideryLogo": true,
          "GoldGlove": false,
          "Fastback": false,
          "TrapezeWeb": false,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": false
        },
        "palm": {
          "LeatherType": true,
          "USSteerhide": true,
          "Professional": true,
          "RawlingsStamp": false,
          "REV1XRawlingsStamp": false,
          "REV1XPinky": false,
          "ModelName": true,
          "LiTeToe": false,
          "R2G": true
        },
        "back": {
          "Flag": true,
          "Wing": false,
          "Name": false,
          "EmbroideryLogo": true,
          "GoldGlove": true,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": false,
          "Fastback": false,
          "Oval": false
        }
      }
    }
  },
  {
    "sportId": "baseball",
    "id": "FM16",
    "shapeId": "firstBase",
    "props": {
      "imageDimensions": {
        "back": {
          "width": 1932,
          "height": 2631
        },
        "web": {
          "width": 1941,
          "height": 2441
        },
        "palm": {
          "width": 2072,
          "height": 2489
        }
      },
      "hasHandSewnStitching": false,
      "trimWithShellBack": false,
      "liningOnBackView": true,
      "liningOnPalmView": false,
      "useBackPalmColorForLiningOnKIP": false,
      "useBackPalmColorForLiningOnHOH": false,
      "allowShellPalmColorForLining": true,
      "isLiningShellPalmColorOnly": false,
      "logoPatchOnWebView": true,
      "logoPatchOnBackView": true,
      "MLBOnWebView": false,
      "MLBOnBackView": true,
      "MLBOnWebViewMask": false,
      "MLBOnBackViewMask": true,
      "extraStitching": false,
      "hasBackPalm": false,
      "holdsterLaces": false,
      "palmOverlay": false,
      "palmOnBackView": true,
      "palmOnWebView": true,
      "insertOnBackView": false,
      "insertOnWebView": false,
      "insertOnPalmView": true,
      "webOnBackView": true,
      "sweatband": true,
      "weltingOnBackView": true,
      "welting": true,
      "xrdPad": true,
      "hasFlag": true,
      "hasFlagOnHalfMesh": false,
      "uniqueFlagPlacement": true,
      "embroideryNameReplacesLogo": false,
      "pullStrapAboveOval": false,
      "hasStandardFitShadowOnWeb": true,
      "hasStandardFitShadowOnBack": true,
      "fingerPadIsRequired": false,
      "forceHalfHeel": false,
      "forceFullHeel": false,
      "hasBackOnPalmView": true,
      "hasHeelPad": true,
      "hasWristLining": true,
      "hasPinkyPalm": false,
      "deco3D": {
        "web": {
          "Flag": false,
          "Wing": false,
          "Oval": true,
          "Name": true,
          "PalmPadLeather": false,
          "EmbroideryLogo": false,
          "GoldGlove": false,
          "Fastback": false,
          "TrapezeWeb": false,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": false
        },
        "palm": {
          "LeatherType": true,
          "USSteerhide": true,
          "Professional": true,
          "RawlingsStamp": true,
          "REV1XRawlingsStamp": false,
          "REV1XPinky": false,
          "ModelName": true,
          "LiTeToe": false,
          "R2G": true
        },
        "back": {
          "Flag": true,
          "Wing": false,
          "Name": false,
          "EmbroideryLogo": true,
          "GoldGlove": true,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": false,
          "Fastback": false,
          "Oval": false
        }
      }
    }
  },
  {
    "sportId": "baseball",
    "id": "FM18",
    "shapeId": "firstBase",
    "props": {
      "imageDimensions": {
        "back": {
          "width": 2721,
          "height": 3568
        },
        "web": {
          "width": 2763,
          "height": 3514
        },
        "palm": {
          "width": 2688,
          "height": 3143
        }
      },
      "hasHandSewnStitching": false,
      "trimWithShellBack": false,
      "liningOnBackView": true,
      "liningOnPalmView": false,
      "useBackPalmColorForLiningOnKIP": false,
      "useBackPalmColorForLiningOnHOH": false,
      "allowShellPalmColorForLining": true,
      "isLiningShellPalmColorOnly": false,
      "logoPatchOnWebView": true,
      "logoPatchOnBackView": true,
      "MLBOnWebView": false,
      "MLBOnBackView": true,
      "MLBOnWebViewMask": false,
      "MLBOnBackViewMask": true,
      "extraStitching": false,
      "hasBackPalm": false,
      "holdsterLaces": false,
      "palmOverlay": false,
      "palmOnBackView": false,
      "palmOnWebView": true,
      "insertOnBackView": false,
      "insertOnWebView": false,
      "insertOnPalmView": true,
      "webOnBackView": true,
      "sweatband": true,
      "weltingOnBackView": false,
      "welting": true,
      "xrdPad": true,
      "hasFlag": false,
      "hasFlagOnHalfMesh": false,
      "uniqueFlagPlacement": false,
      "embroideryNameReplacesLogo": false,
      "pullStrapAboveOval": false,
      "hasStandardFitShadowOnWeb": true,
      "hasStandardFitShadowOnBack": true,
      "fingerPadIsRequired": false,
      "forceHalfHeel": false,
      "forceFullHeel": false,
      "hasBackOnPalmView": true,
      "hasHeelPad": true,
      "hasWristLining": true,
      "hasPinkyPalm": false,
      "deco3D": {
        "web": {
          "Flag": false,
          "Wing": false,
          "Oval": true,
          "Name": true,
          "PalmPadLeather": false,
          "EmbroideryLogo": true,
          "GoldGlove": false,
          "Fastback": false,
          "TrapezeWeb": false,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": false
        },
        "palm": {
          "LeatherType": true,
          "USSteerhide": true,
          "Professional": true,
          "RawlingsStamp": true,
          "REV1XRawlingsStamp": false,
          "REV1XPinky": false,
          "ModelName": true,
          "LiTeToe": false,
          "R2G": true
        },
        "back": {
          "Flag": false,
          "Wing": false,
          "Name": false,
          "EmbroideryLogo": true,
          "GoldGlove": true,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": false,
          "Fastback": false,
          "Oval": false
        }
      }
    }
  },
  {
    "sportId": "baseball",
    "id": "FM19",
    "shapeId": "firstBase",
    "props": {
      "imageDimensions": {
        "back": {
          "width": 2721,
          "height": 3568
        },
        "web": {
          "width": 2763,
          "height": 3505
        },
        "palm": {
          "width": 2688,
          "height": 3143
        }
      },
      "hasHandSewnStitching": false,
      "trimWithShellBack": false,
      "liningOnBackView": true,
      "liningOnPalmView": false,
      "useBackPalmColorForLiningOnKIP": false,
      "useBackPalmColorForLiningOnHOH": false,
      "allowShellPalmColorForLining": true,
      "isLiningShellPalmColorOnly": false,
      "logoPatchOnWebView": true,
      "logoPatchOnBackView": true,
      "MLBOnWebView": false,
      "MLBOnBackView": true,
      "MLBOnWebViewMask": false,
      "MLBOnBackViewMask": true,
      "extraStitching": false,
      "hasBackPalm": false,
      "holdsterLaces": false,
      "palmOverlay": false,
      "palmOnBackView": false,
      "palmOnWebView": true,
      "insertOnBackView": false,
      "insertOnWebView": false,
      "insertOnPalmView": true,
      "webOnBackView": true,
      "sweatband": true,
      "weltingOnBackView": false,
      "welting": true,
      "xrdPad": true,
      "hasFlag": false,
      "hasFlagOnHalfMesh": false,
      "uniqueFlagPlacement": false,
      "embroideryNameReplacesLogo": false,
      "pullStrapAboveOval": false,
      "hasStandardFitShadowOnWeb": true,
      "hasStandardFitShadowOnBack": true,
      "fingerPadIsRequired": false,
      "forceHalfHeel": false,
      "forceFullHeel": false,
      "hasBackOnPalmView": true,
      "hasHeelPad": true,
      "hasWristLining": true,
      "hasPinkyPalm": false,
      "deco3D": {
        "web": {
          "Flag": false,
          "Wing": false,
          "Oval": true,
          "Name": true,
          "PalmPadLeather": false,
          "EmbroideryLogo": true,
          "GoldGlove": false,
          "Fastback": false,
          "TrapezeWeb": false,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": false
        },
        "palm": {
          "LeatherType": true,
          "USSteerhide": true,
          "Professional": true,
          "RawlingsStamp": true,
          "REV1XRawlingsStamp": false,
          "REV1XPinky": false,
          "ModelName": true,
          "LiTeToe": false,
          "R2G": true
        },
        "back": {
          "Flag": false,
          "Wing": false,
          "Name": false,
          "EmbroideryLogo": true,
          "GoldGlove": true,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": false,
          "Fastback": false,
          "Oval": false
        }
      }
    }
  },
  {
    "sportId": "baseball",
    "id": "FM20XL",
    "shapeId": "firstBase",
    "props": {
      "imageDimensions": {
        "back": {
          "width": 2590,
          "height": 3504
        },
        "web": {
          "width": 2993,
          "height": 3324
        },
        "palm": {
          "width": 2676,
          "height": 3066
        }
      },
      "hasHandSewnStitching": false,
      "trimWithShellBack": false,
      "liningOnBackView": true,
      "liningOnPalmView": true,
      "useBackPalmColorForLiningOnKIP": false,
      "useBackPalmColorForLiningOnHOH": false,
      "allowShellPalmColorForLining": true,
      "isLiningShellPalmColorOnly": false,
      "logoPatchOnWebView": false,
      "logoPatchOnBackView": true,
      "MLBOnWebView": false,
      "MLBOnBackView": true,
      "MLBOnWebViewMask": false,
      "MLBOnBackViewMask": true,
      "extraStitching": false,
      "hasBackPalm": false,
      "holdsterLaces": false,
      "palmOverlay": false,
      "palmOnBackView": true,
      "palmOnWebView": true,
      "insertOnBackView": false,
      "insertOnWebView": false,
      "insertOnPalmView": true,
      "webOnBackView": true,
      "sweatband": false,
      "weltingOnBackView": true,
      "welting": true,
      "xrdPad": true,
      "hasFlag": true,
      "hasFlagOnHalfMesh": false,
      "uniqueFlagPlacement": false,
      "embroideryNameReplacesLogo": false,
      "pullStrapAboveOval": false,
      "hasStandardFitShadowOnWeb": true,
      "hasStandardFitShadowOnBack": true,
      "fingerPadIsRequired": false,
      "forceHalfHeel": false,
      "forceFullHeel": false,
      "hasBackOnPalmView": true,
      "hasHeelPad": true,
      "hasWristLining": true,
      "hasPinkyPalm": false,
      "deco3D": {
        "web": {
          "Flag": false,
          "Wing": false,
          "Oval": true,
          "Name": true,
          "PalmPadLeather": false,
          "EmbroideryLogo": false,
          "GoldGlove": false,
          "Fastback": false,
          "TrapezeWeb": false,
          "IndexFingerXRD": false,
          "MiddleFingerXRD": false
        },
        "palm": {
          "LeatherType": true,
          "USSteerhide": true,
          "Professional": true,
          "RawlingsStamp": true,
          "REV1XRawlingsStamp": false,
          "REV1XPinky": false,
          "ModelName": true,
          "LiTeToe": false,
          "R2G": true
        },
        "back": {
          "Flag": true,
          "Wing": false,
          "Name": false,
          "EmbroideryLogo": true,
          "GoldGlove": true,
          "IndexFingerXRD": false,
          "MiddleFingerXRD": false,
          "Fastback": false,
          "Oval": false
        }
      }
    }
  },
  {
    "sportId": "baseball",
    "id": "SC5",
    "shapeId": "firstBase",
    "props": {
      "imageDimensions": {
        "back": {
          "width": 3515,
          "height": 5072
        },
        "web": {
          "width": 2860,
          "height": 3412
        },
        "palm": {
          "width": 4580,
          "height": 5252
        }
      },
      "hasHandSewnStitching": false,
      "trimWithShellBack": false,
      "liningOnBackView": true,
      "liningOnPalmView": false,
      "useBackPalmColorForLiningOnKIP": false,
      "useBackPalmColorForLiningOnHOH": false,
      "allowShellPalmColorForLining": true,
      "isLiningShellPalmColorOnly": false,
      "logoPatchOnWebView": true,
      "logoPatchOnBackView": true,
      "MLBOnWebView": false,
      "MLBOnBackView": true,
      "MLBOnWebViewMask": false,
      "MLBOnBackViewMask": true,
      "extraStitching": false,
      "hasBackPalm": false,
      "holdsterLaces": false,
      "palmOverlay": false,
      "palmOnBackView": false,
      "palmOnWebView": false,
      "insertOnBackView": false,
      "insertOnWebView": false,
      "insertOnPalmView": true,
      "webOnBackView": true,
      "sweatband": false,
      "weltingOnBackView": true,
      "welting": true,
      "xrdPad": true,
      "hasFlag": false,
      "hasFlagOnHalfMesh": false,
      "uniqueFlagPlacement": false,
      "embroideryNameReplacesLogo": false,
      "pullStrapAboveOval": false,
      "hasStandardFitShadowOnWeb": true,
      "hasStandardFitShadowOnBack": true,
      "fingerPadIsRequired": false,
      "forceHalfHeel": false,
      "forceFullHeel": false,
      "hasBackOnPalmView": true,
      "hasHeelPad": true,
      "hasWristLining": true,
      "hasPinkyPalm": false,
      "deco3D": {
        "web": {
          "Flag": false,
          "Wing": false,
          "Oval": true,
          "Name": false,
          "PalmPadLeather": false,
          "EmbroideryLogo": true,
          "GoldGlove": false,
          "Fastback": false,
          "TrapezeWeb": false,
          "IndexFingerXRD": false,
          "MiddleFingerXRD": false
        },
        "palm": {
          "LeatherType": true,
          "USSteerhide": true,
          "Professional": true,
          "RawlingsStamp": true,
          "REV1XRawlingsStamp": false,
          "REV1XPinky": false,
          "ModelName": true,
          "LiTeToe": false,
          "R2G": true
        },
        "back": {
          "Flag": false,
          "Wing": false,
          "Name": false,
          "EmbroideryLogo": false,
          "GoldGlove": true,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": false,
          "Fastback": false,
          "Oval": false
        }
      }
    }
  },
  {
    "sportId": "softball",
    "id": "FM19SB",
    "shapeId": "firstBase",
    "props": {
      "imageDimensions": {
        "back": {
          "width": 2397,
          "height": 3224
        },
        "web": {
          "width": 2574,
          "height": 3398
        },
        "palm": {
          "width": 2650,
          "height": 3309
        }
      },
      "hasHandSewnStitching": false,
      "trimWithShellBack": false,
      "liningOnBackView": true,
      "liningOnPalmView": true,
      "useBackPalmColorForLiningOnKIP": false,
      "useBackPalmColorForLiningOnHOH": false,
      "allowShellPalmColorForLining": true,
      "isLiningShellPalmColorOnly": false,
      "logoPatchOnWebView": false,
      "logoPatchOnBackView": false,
      "MLBOnWebView": false,
      "MLBOnBackView": false,
      "MLBOnWebViewMask": false,
      "MLBOnBackViewMask": false,
      "extraStitching": false,
      "hasBackPalm": false,
      "holdsterLaces": false,
      "palmOverlay": false,
      "palmOnBackView": false,
      "palmOnWebView": true,
      "insertOnBackView": false,
      "insertOnWebView": false,
      "insertOnPalmView": true,
      "webOnBackView": true,
      "sweatband": false,
      "weltingOnBackView": false,
      "welting": true,
      "xrdPad": true,
      "hasFlag": false,
      "hasFlagOnHalfMesh": false,
      "uniqueFlagPlacement": false,
      "embroideryNameReplacesLogo": false,
      "pullStrapAboveOval": true,
      "hasStandardFitShadowOnWeb": true,
      "hasStandardFitShadowOnBack": true,
      "fingerPadIsRequired": false,
      "forceHalfHeel": false,
      "forceFullHeel": false,
      "hasBackOnPalmView": true,
      "hasHeelPad": true,
      "hasWristLining": true,
      "hasPinkyPalm": false,
      "deco3D": {
        "web": {
          "Flag": false,
          "Wing": false,
          "Oval": true,
          "Name": true,
          "PalmPadLeather": false,
          "EmbroideryLogo": true,
          "GoldGlove": false,
          "Fastback": false,
          "TrapezeWeb": false,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": false
        },
        "palm": {
          "LeatherType": true,
          "USSteerhide": true,
          "Professional": true,
          "RawlingsStamp": true,
          "REV1XRawlingsStamp": false,
          "REV1XPinky": false,
          "ModelName": true,
          "LiTeToe": false,
          "R2G": true
        },
        "back": {
          "Flag": false,
          "Wing": false,
          "Name": false,
          "EmbroideryLogo": true,
          "GoldGlove": true,
          "IndexFingerXRD": false,
          "MiddleFingerXRD": false,
          "Fastback": false,
          "Oval": false
        }
      }
    }
  },
  {
    "sportId": "softball",
    "id": "TM8SB",
    "shapeId": "firstBase",
    "props": {
      "imageDimensions": {
        "back": {
          "width": 2531,
          "height": 3317
        },
        "web": {
          "width": 4007,
          "height": 4870
        },
        "palm": {
          "width": 2833,
          "height": 3193
        }
      },
      "hasHandSewnStitching": false,
      "trimWithShellBack": false,
      "liningOnBackView": true,
      "liningOnPalmView": true,
      "useBackPalmColorForLiningOnKIP": false,
      "useBackPalmColorForLiningOnHOH": false,
      "allowShellPalmColorForLining": true,
      "isLiningShellPalmColorOnly": false,
      "logoPatchOnWebView": true,
      "logoPatchOnBackView": true,
      "MLBOnWebView": false,
      "MLBOnBackView": false,
      "MLBOnWebViewMask": false,
      "MLBOnBackViewMask": false,
      "extraStitching": false,
      "hasBackPalm": false,
      "holdsterLaces": false,
      "palmOverlay": false,
      "palmOnBackView": false,
      "palmOnWebView": true,
      "insertOnBackView": false,
      "insertOnWebView": false,
      "insertOnPalmView": true,
      "webOnBackView": true,
      "sweatband": false,
      "weltingOnBackView": false,
      "welting": true,
      "xrdPad": true,
      "hasFlag": true,
      "hasFlagOnHalfMesh": false,
      "uniqueFlagPlacement": false,
      "embroideryNameReplacesLogo": false,
      "pullStrapAboveOval": false,
      "hasStandardFitShadowOnWeb": true,
      "hasStandardFitShadowOnBack": true,
      "fingerPadIsRequired": false,
      "forceHalfHeel": false,
      "forceFullHeel": false,
      "hasBackOnPalmView": true,
      "hasHeelPad": true,
      "hasWristLining": true,
      "hasPinkyPalm": false,
      "deco3D": {
        "web": {
          "Flag": true,
          "Wing": false,
          "Oval": true,
          "Name": true,
          "PalmPadLeather": true,
          "EmbroideryLogo": true,
          "GoldGlove": true,
          "Fastback": false,
          "TrapezeWeb": false,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": false
        },
        "palm": {
          "LeatherType": true,
          "USSteerhide": true,
          "Professional": true,
          "RawlingsStamp": true,
          "REV1XRawlingsStamp": false,
          "REV1XPinky": false,
          "ModelName": true,
          "LiTeToe": false,
          "R2G": true
        },
        "back": {
          "Flag": true,
          "Wing": false,
          "Name": false,
          "EmbroideryLogo": true,
          "GoldGlove": true,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": false,
          "Fastback": false,
          "Oval": false
        }
      }
    }
  },
  {
    "sportId": "baseball",
    "id": "CM33",
    "shapeId": "catcher",
    "props": {
      "imageDimensions": {
        "back": {
          "width": 3023,
          "height": 3081
        },
        "web": {
          "width": 2802,
          "height": 3010
        },
        "palm": {
          "width": 3563,
          "height": 3352
        }
      },
      "hasHandSewnStitching": false,
      "trimWithShellBack": false,
      "liningOnBackView": true,
      "liningOnPalmView": false,
      "useBackPalmColorForLiningOnKIP": false,
      "useBackPalmColorForLiningOnHOH": true,
      "allowShellPalmColorForLining": false,
      "isLiningShellPalmColorOnly": false,
      "logoPatchOnWebView": true,
      "logoPatchOnBackView": true,
      "MLBOnWebView": true,
      "MLBOnBackView": true,
      "MLBOnWebViewMask": false,
      "MLBOnBackViewMask": true,
      "extraStitching": false,
      "hasBackPalm": true,
      "holdsterLaces": false,
      "palmOverlay": false,
      "palmOnBackView": true,
      "palmOnWebView": true,
      "insertOnBackView": false,
      "insertOnWebView": false,
      "insertOnPalmView": false,
      "webOnBackView": true,
      "sweatband": true,
      "weltingOnBackView": false,
      "welting": true,
      "xrdPad": true,
      "hasFlag": true,
      "hasFlagOnHalfMesh": false,
      "uniqueFlagPlacement": false,
      "embroideryNameReplacesLogo": false,
      "pullStrapAboveOval": false,
      "hasStandardFitShadowOnWeb": true,
      "hasStandardFitShadowOnBack": true,
      "fingerPadIsRequired": false,
      "forceHalfHeel": false,
      "forceFullHeel": false,
      "hasBackOnPalmView": true,
      "hasHeelPad": true,
      "hasWristLining": true,
      "hasPinkyPalm": false,
      "deco3D": {
        "web": {
          "Flag": true,
          "Wing": false,
          "Oval": true,
          "Name": true,
          "PalmPadLeather": false,
          "EmbroideryLogo": false,
          "GoldGlove": true,
          "Fastback": false,
          "TrapezeWeb": false,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": false
        },
        "palm": {
          "LeatherType": true,
          "USSteerhide": true,
          "Professional": true,
          "RawlingsStamp": true,
          "REV1XRawlingsStamp": false,
          "REV1XPinky": false,
          "ModelName": true,
          "LiTeToe": true,
          "R2G": true
        },
        "back": {
          "Flag": false,
          "Wing": false,
          "Name": false,
          "EmbroideryLogo": false,
          "GoldGlove": true,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": false,
          "Fastback": false,
          "Oval": true
        }
      }
    }
  },
  {
    "sportId": "baseball",
    "id": "CM43HSW",
    "shapeId": "catcher",
    "props": {
      "imageDimensions": {
        "back": {
          "width": 3023,
          "height": 3081
        },
        "web": {
          "width": 2802,
          "height": 3010
        },
        "palm": {
          "width": 3563,
          "height": 3352
        }
      },
      "hasHandSewnStitching": true,
      "trimWithShellBack": false,
      "liningOnBackView": true,
      "liningOnPalmView": false,
      "useBackPalmColorForLiningOnKIP": false,
      "useBackPalmColorForLiningOnHOH": true,
      "allowShellPalmColorForLining": false,
      "isLiningShellPalmColorOnly": false,
      "logoPatchOnWebView": true,
      "logoPatchOnBackView": true,
      "MLBOnWebView": true,
      "MLBOnBackView": true,
      "MLBOnWebViewMask": false,
      "MLBOnBackViewMask": true,
      "extraStitching": false,
      "hasBackPalm": true,
      "holdsterLaces": false,
      "palmOverlay": false,
      "palmOnBackView": true,
      "palmOnWebView": true,
      "insertOnBackView": false,
      "insertOnWebView": false,
      "insertOnPalmView": false,
      "webOnBackView": true,
      "sweatband": true,
      "weltingOnBackView": false,
      "welting": true,
      "xrdPad": true,
      "hasFlag": true,
      "hasFlagOnHalfMesh": false,
      "uniqueFlagPlacement": false,
      "embroideryNameReplacesLogo": false,
      "pullStrapAboveOval": false,
      "hasStandardFitShadowOnWeb": true,
      "hasStandardFitShadowOnBack": true,
      "fingerPadIsRequired": false,
      "forceHalfHeel": false,
      "forceFullHeel": false,
      "hasBackOnPalmView": true,
      "hasHeelPad": true,
      "hasWristLining": true,
      "hasPinkyPalm": false,
      "deco3D": {
        "web": {
          "Flag": true,
          "Wing": false,
          "Oval": true,
          "Name": true,
          "PalmPadLeather": false,
          "EmbroideryLogo": true,
          "GoldGlove": true,
          "Fastback": false,
          "TrapezeWeb": false,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": false
        },
        "palm": {
          "LeatherType": true,
          "USSteerhide": true,
          "Professional": true,
          "RawlingsStamp": true,
          "REV1XRawlingsStamp": false,
          "REV1XPinky": false,
          "ModelName": true,
          "LiTeToe": true,
          "R2G": true
        },
        "back": {
          "Flag": false,
          "Wing": false,
          "Name": false,
          "EmbroideryLogo": false,
          "GoldGlove": true,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": false,
          "Fastback": false,
          "Oval": true
        }
      }
    }
  },
  {
    "sportId": "baseball",
    "id": "CM41",
    "shapeId": "catcher",
    "props": {
      "imageDimensions": {
        "back": {
          "width": 3197,
          "height": 3213
        },
        "web": {
          "width": 3161,
          "height": 3060
        },
        "palm": {
          "width": 3408,
          "height": 3436
        }
      },
      "hasHandSewnStitching": false,
      "trimWithShellBack": false,
      "liningOnBackView": true,
      "liningOnPalmView": false,
      "useBackPalmColorForLiningOnKIP": false,
      "useBackPalmColorForLiningOnHOH": true,
      "allowShellPalmColorForLining": false,
      "isLiningShellPalmColorOnly": false,
      "logoPatchOnWebView": true,
      "logoPatchOnBackView": true,
      "MLBOnWebView": true,
      "MLBOnBackView": true,
      "MLBOnWebViewMask": false,
      "MLBOnBackViewMask": false,
      "extraStitching": false,
      "hasBackPalm": true,
      "holdsterLaces": false,
      "palmOverlay": false,
      "palmOnBackView": true,
      "palmOnWebView": true,
      "insertOnBackView": true,
      "insertOnWebView": false,
      "insertOnPalmView": false,
      "webOnBackView": true,
      "sweatband": true,
      "weltingOnBackView": false,
      "welting": false,
      "xrdPad": true,
      "hasFlag": false,
      "hasFlagOnHalfMesh": false,
      "uniqueFlagPlacement": false,
      "embroideryNameReplacesLogo": false,
      "pullStrapAboveOval": false,
      "hasStandardFitShadowOnWeb": true,
      "hasStandardFitShadowOnBack": true,
      "fingerPadIsRequired": false,
      "forceHalfHeel": false,
      "forceFullHeel": false,
      "hasBackOnPalmView": true,
      "hasHeelPad": true,
      "hasWristLining": true,
      "hasPinkyPalm": false,
      "deco3D": {
        "web": {
          "Flag": false,
          "Wing": false,
          "Oval": true,
          "Name": true,
          "PalmPadLeather": false,
          "EmbroideryLogo": false,
          "GoldGlove": true,
          "Fastback": false,
          "TrapezeWeb": false,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": false
        },
        "palm": {
          "LeatherType": true,
          "USSteerhide": true,
          "Professional": true,
          "RawlingsStamp": true,
          "REV1XRawlingsStamp": false,
          "REV1XPinky": false,
          "ModelName": true,
          "LiTeToe": true,
          "R2G": true
        },
        "back": {
          "Flag": false,
          "Wing": false,
          "Name": false,
          "EmbroideryLogo": false,
          "GoldGlove": true,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": false,
          "Fastback": false,
          "Oval": false
        }
      }
    }
  },
  {
    "sportId": "baseball",
    "id": "JP20",
    "shapeId": "catcher",
    "props": {
      "imageDimensions": {
        "back": {
          "width": 3472,
          "height": 3041
        },
        "web": {
          "width": 3092,
          "height": 2850
        },
        "palm": {
          "width": 3430,
          "height": 3371
        }
      },
      "hasHandSewnStitching": false,
      "trimWithShellBack": false,
      "liningOnBackView": true,
      "liningOnPalmView": false,
      "useBackPalmColorForLiningOnKIP": false,
      "useBackPalmColorForLiningOnHOH": true,
      "allowShellPalmColorForLining": false,
      "isLiningShellPalmColorOnly": false,
      "logoPatchOnWebView": true,
      "logoPatchOnBackView": true,
      "MLBOnWebView": false,
      "MLBOnBackView": true,
      "MLBOnWebViewMask": false,
      "MLBOnBackViewMask": false,
      "extraStitching": false,
      "hasBackPalm": true,
      "holdsterLaces": false,
      "palmOverlay": false,
      "palmOnBackView": true,
      "palmOnWebView": true,
      "insertOnBackView": true,
      "insertOnWebView": false,
      "insertOnPalmView": false,
      "webOnBackView": true,
      "sweatband": true,
      "weltingOnBackView": false,
      "welting": false,
      "xrdPad": true,
      "hasFlag": false,
      "hasFlagOnHalfMesh": false,
      "uniqueFlagPlacement": false,
      "embroideryNameReplacesLogo": false,
      "pullStrapAboveOval": false,
      "hasStandardFitShadowOnWeb": true,
      "hasStandardFitShadowOnBack": true,
      "fingerPadIsRequired": false,
      "forceHalfHeel": false,
      "forceFullHeel": false,
      "hasBackOnPalmView": true,
      "hasHeelPad": true,
      "hasWristLining": true,
      "hasPinkyPalm": false,
      "deco3D": {
        "web": {
          "Flag": false,
          "Wing": false,
          "Oval": true,
          "Name": true,
          "PalmPadLeather": false,
          "EmbroideryLogo": false,
          "GoldGlove": true,
          "Fastback": false,
          "TrapezeWeb": false,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": false
        },
        "palm": {
          "LeatherType": true,
          "USSteerhide": true,
          "Professional": true,
          "RawlingsStamp": true,
          "REV1XRawlingsStamp": false,
          "REV1XPinky": false,
          "ModelName": true,
          "LiTeToe": true,
          "R2G": true
        },
        "back": {
          "Flag": false,
          "Wing": false,
          "Name": false,
          "EmbroideryLogo": false,
          "GoldGlove": true,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": false,
          "Fastback": false,
          "Oval": false
        }
      }
    }
  },
  {
    "sportId": "baseball",
    "id": "PL31",
    "shapeId": "catcher",
    "props": {
      "imageDimensions": {
        "back": {
          "width": 2977,
          "height": 2946
        },
        "web": {
          "width": 3250,
          "height": 2832
        },
        "palm": {
          "width": 3582,
          "height": 3371
        }
      },
      "hasHandSewnStitching": false,
      "trimWithShellBack": false,
      "liningOnBackView": true,
      "liningOnPalmView": false,
      "useBackPalmColorForLiningOnKIP": false,
      "useBackPalmColorForLiningOnHOH": true,
      "allowShellPalmColorForLining": false,
      "isLiningShellPalmColorOnly": false,
      "logoPatchOnWebView": false,
      "logoPatchOnBackView": true,
      "MLBOnWebView": false,
      "MLBOnBackView": true,
      "MLBOnWebViewMask": false,
      "MLBOnBackViewMask": false,
      "extraStitching": false,
      "hasBackPalm": true,
      "holdsterLaces": true,
      "palmOverlay": false,
      "palmOnBackView": true,
      "palmOnWebView": true,
      "insertOnBackView": true,
      "insertOnWebView": true,
      "insertOnPalmView": false,
      "webOnBackView": true,
      "sweatband": false,
      "weltingOnBackView": false,
      "welting": false,
      "xrdPad": true,
      "hasFlag": false,
      "hasFlagOnHalfMesh": false,
      "uniqueFlagPlacement": false,
      "embroideryNameReplacesLogo": false,
      "pullStrapAboveOval": false,
      "hasStandardFitShadowOnWeb": true,
      "hasStandardFitShadowOnBack": true,
      "fingerPadIsRequired": false,
      "forceHalfHeel": false,
      "forceFullHeel": false,
      "hasBackOnPalmView": true,
      "hasHeelPad": true,
      "hasWristLining": true,
      "hasPinkyPalm": false,
      "deco3D": {
        "web": {
          "Flag": false,
          "Wing": false,
          "Oval": true,
          "Name": true,
          "PalmPadLeather": false,
          "EmbroideryLogo": false,
          "GoldGlove": false,
          "Fastback": false,
          "TrapezeWeb": false,
          "IndexFingerXRD": false,
          "MiddleFingerXRD": false
        },
        "palm": {
          "LeatherType": true,
          "USSteerhide": true,
          "Professional": true,
          "RawlingsStamp": true,
          "REV1XRawlingsStamp": false,
          "REV1XPinky": false,
          "ModelName": true,
          "LiTeToe": true,
          "R2G": true
        },
        "back": {
          "Flag": false,
          "Wing": false,
          "Name": false,
          "EmbroideryLogo": false,
          "GoldGlove": true,
          "IndexFingerXRD": false,
          "MiddleFingerXRD": false,
          "Fastback": false,
          "Oval": false
        }
      }
    }
  },
  {
    "sportId": "baseball",
    "id": "SP13",
    "shapeId": "catcher",
    "props": {
      "imageDimensions": {
        "back": {
          "width": 4472,
          "height": 4810
        },
        "web": {
          "width": 4618,
          "height": 4538
        },
        "palm": {
          "width": 5103,
          "height": 4920
        }
      },
      "hasHandSewnStitching": false,
      "trimWithShellBack": false,
      "liningOnBackView": true,
      "liningOnPalmView": true,
      "useBackPalmColorForLiningOnKIP": false,
      "useBackPalmColorForLiningOnHOH": true,
      "allowShellPalmColorForLining": false,
      "isLiningShellPalmColorOnly": false,
      "logoPatchOnWebView": true,
      "logoPatchOnBackView": true,
      "MLBOnWebView": false,
      "MLBOnBackView": true,
      "MLBOnWebViewMask": false,
      "MLBOnBackViewMask": false,
      "extraStitching": false,
      "hasBackPalm": true,
      "holdsterLaces": false,
      "palmOverlay": false,
      "palmOnBackView": true,
      "palmOnWebView": true,
      "insertOnBackView": true,
      "insertOnWebView": true,
      "insertOnPalmView": true,
      "webOnBackView": true,
      "sweatband": true,
      "weltingOnBackView": false,
      "welting": false,
      "xrdPad": true,
      "hasFlag": false,
      "hasFlagOnHalfMesh": false,
      "uniqueFlagPlacement": false,
      "embroideryNameReplacesLogo": false,
      "pullStrapAboveOval": false,
      "hasStandardFitShadowOnWeb": true,
      "hasStandardFitShadowOnBack": true,
      "fingerPadIsRequired": false,
      "forceHalfHeel": false,
      "forceFullHeel": false,
      "hasBackOnPalmView": true,
      "hasHeelPad": true,
      "hasWristLining": true,
      "hasPinkyPalm": false,
      "deco3D": {
        "web": {
          "Flag": true,
          "Wing": false,
          "Oval": true,
          "Name": true,
          "PalmPadLeather": false,
          "EmbroideryLogo": false,
          "GoldGlove": false,
          "Fastback": false,
          "TrapezeWeb": false,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": false
        },
        "palm": {
          "LeatherType": true,
          "USSteerhide": true,
          "Professional": true,
          "RawlingsStamp": true,
          "REV1XRawlingsStamp": false,
          "REV1XPinky": false,
          "ModelName": true,
          "LiTeToe": true,
          "R2G": true
        },
        "back": {
          "Flag": true,
          "Wing": false,
          "Name": false,
          "EmbroideryLogo": false,
          "GoldGlove": true,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": false,
          "Fastback": false,
          "Oval": false
        }
      }
    }
  },
  {
    "sportId": "softball",
    "id": "CM33FP",
    "shapeId": "catcher",
    "props": {
      "imageDimensions": {
        "back": {
          "width": 3516,
          "height": 4845
        },
        "web": {
          "width": 4440,
          "height": 4807
        },
        "palm": {
          "width": 5049,
          "height": 5214
        }
      },
      "hasHandSewnStitching": false,
      "trimWithShellBack": false,
      "liningOnBackView": true,
      "liningOnPalmView": true,
      "useBackPalmColorForLiningOnKIP": false,
      "useBackPalmColorForLiningOnHOH": true,
      "allowShellPalmColorForLining": false,
      "isLiningShellPalmColorOnly": false,
      "logoPatchOnWebView": true,
      "logoPatchOnBackView": true,
      "MLBOnWebView": false,
      "MLBOnBackView": false,
      "MLBOnWebViewMask": false,
      "MLBOnBackViewMask": false,
      "extraStitching": false,
      "hasBackPalm": true,
      "holdsterLaces": false,
      "palmOverlay": false,
      "palmOnBackView": false,
      "palmOnWebView": true,
      "insertOnBackView": true,
      "insertOnWebView": false,
      "insertOnPalmView": true,
      "webOnBackView": true,
      "sweatband": false,
      "weltingOnBackView": false,
      "welting": true,
      "xrdPad": true,
      "hasFlag": false,
      "hasFlagOnHalfMesh": false,
      "uniqueFlagPlacement": false,
      "embroideryNameReplacesLogo": false,
      "pullStrapAboveOval": true,
      "hasStandardFitShadowOnWeb": true,
      "hasStandardFitShadowOnBack": true,
      "fingerPadIsRequired": false,
      "forceHalfHeel": false,
      "forceFullHeel": false,
      "hasBackOnPalmView": true,
      "hasHeelPad": true,
      "hasWristLining": true,
      "hasPinkyPalm": false,
      "deco3D": {
        "web": {
          "Flag": false,
          "Wing": false,
          "Oval": true,
          "Name": true,
          "PalmPadLeather": true,
          "EmbroideryLogo": false,
          "GoldGlove": true,
          "Fastback": false,
          "TrapezeWeb": false,
          "IndexFingerXRD": false,
          "MiddleFingerXRD": false
        },
        "palm": {
          "LeatherType": true,
          "USSteerhide": true,
          "Professional": true,
          "RawlingsStamp": true,
          "REV1XRawlingsStamp": false,
          "REV1XPinky": false,
          "ModelName": true,
          "LiTeToe": true,
          "R2G": true
        },
        "back": {
          "Flag": false,
          "Wing": false,
          "Name": false,
          "EmbroideryLogo": false,
          "GoldGlove": true,
          "IndexFingerXRD": false,
          "MiddleFingerXRD": false,
          "Fastback": false,
          "Oval": false
        }
      }
    }
  },
  {
    "sportId": "baseball",
    "id": "200TR",
    "shapeId": "fielder",
    "props": {
      "imageDimensions": {
        "back": {
          "width": 3305,
          "height": 4145
        },
        "web": {
          "width": 3703,
          "height": 3937
        },
        "palm": {
          "width": 4062,
          "height": 4061
        }
      },
      "hasHandSewnStitching": false,
      "trimWithShellBack": false,
      "liningOnBackView": true,
      "liningOnPalmView": true,
      "useBackPalmColorForLiningOnKIP": false,
      "useBackPalmColorForLiningOnHOH": false,
      "allowShellPalmColorForLining": true,
      "isLiningShellPalmColorOnly": false,
      "logoPatchOnWebView": true,
      "logoPatchOnBackView": true,
      "MLBOnWebView": false,
      "MLBOnBackView": true,
      "MLBOnWebViewMask": false,
      "MLBOnBackViewMask": true,
      "extraStitching": false,
      "hasBackPalm": false,
      "holdsterLaces": false,
      "palmOverlay": false,
      "palmOnBackView": true,
      "palmOnWebView": true,
      "insertOnBackView": true,
      "insertOnWebView": true,
      "insertOnPalmView": true,
      "webOnBackView": false,
      "sweatband": true,
      "weltingOnBackView": true,
      "welting": true,
      "xrdPad": false,
      "hasFlag": true,
      "hasFlagOnHalfMesh": false,
      "uniqueFlagPlacement": false,
      "embroideryNameReplacesLogo": false,
      "pullStrapAboveOval": false,
      "hasStandardFitShadowOnWeb": true,
      "hasStandardFitShadowOnBack": true,
      "fingerPadIsRequired": false,
      "forceHalfHeel": false,
      "forceFullHeel": true,
      "hasBackOnPalmView": true,
      "hasHeelPad": true,
      "hasWristLining": true,
      "hasPinkyPalm": false,
      "deco3D": {
        "web": {
          "Flag": true,
          "Wing": false,
          "Oval": true,
          "Name": true,
          "PalmPadLeather": true,
          "EmbroideryLogo": true,
          "GoldGlove": false,
          "Fastback": false,
          "TrapezeWeb": false,
          "IndexFingerXRD": false,
          "MiddleFingerXRD": false
        },
        "palm": {
          "LeatherType": true,
          "USSteerhide": true,
          "Professional": true,
          "RawlingsStamp": true,
          "REV1XRawlingsStamp": false,
          "REV1XPinky": false,
          "ModelName": true,
          "LiTeToe": false,
          "R2G": true
        },
        "back": {
          "Flag": true,
          "Wing": false,
          "Name": false,
          "EmbroideryLogo": true,
          "GoldGlove": true,
          "IndexFingerXRD": false,
          "MiddleFingerXRD": false,
          "Fastback": false,
          "Oval": false
        }
      }
    }
  },
  {
    "sportId": "baseball",
    "id": "FL12TR",
    "shapeId": "pancake",
    "props": {
      "imageDimensions": {
        "back": {
          "width": 3488,
          "height": 4604
        },
        "web": {
          "width": 4810,
          "height": 4821
        },
        "palm": {
          "width": 5721,
          "height": 4875
        }
      },
      "hasHandSewnStitching": false,
      "trimWithShellBack": false,
      "liningOnBackView": true,
      "liningOnPalmView": false,
      "useBackPalmColorForLiningOnKIP": false,
      "useBackPalmColorForLiningOnHOH": true,
      "allowShellPalmColorForLining": true,
      "isLiningShellPalmColorOnly": false,
      "logoPatchOnWebView": true,
      "logoPatchOnBackView": true,
      "MLBOnWebView": false,
      "MLBOnBackView": true,
      "MLBOnWebViewMask": false,
      "MLBOnBackViewMask": false,
      "extraStitching": false,
      "hasBackPalm": true,
      "holdsterLaces": false,
      "palmOverlay": false,
      "palmOnBackView": false,
      "palmOnWebView": false,
      "insertOnBackView": true,
      "insertOnWebView": true,
      "insertOnPalmView": false,
      "webOnBackView": false,
      "sweatband": true,
      "weltingOnBackView": false,
      "welting": false,
      "xrdPad": false,
      "hasFlag": false,
      "hasFlagOnHalfMesh": false,
      "uniqueFlagPlacement": false,
      "embroideryNameReplacesLogo": true,
      "pullStrapAboveOval": false,
      "hasStandardFitShadowOnWeb": true,
      "hasStandardFitShadowOnBack": true,
      "fingerPadIsRequired": true,
      "forceHalfHeel": false,
      "forceFullHeel": true,
      "hasBackOnPalmView": true,
      "hasHeelPad": true,
      "hasWristLining": true,
      "hasPinkyPalm": false,
      "deco3D": {
        "web": {
          "Flag": false,
          "Wing": false,
          "Oval": true,
          "Name": true,
          "PalmPadLeather": true,
          "EmbroideryLogo": true,
          "GoldGlove": true,
          "Fastback": false,
          "TrapezeWeb": false,
          "IndexFingerXRD": false,
          "MiddleFingerXRD": false
        },
        "palm": {
          "LeatherType": true,
          "USSteerhide": true,
          "Professional": true,
          "RawlingsStamp": true,
          "REV1XRawlingsStamp": false,
          "REV1XPinky": false,
          "ModelName": true,
          "LiTeToe": false,
          "R2G": true
        },
        "back": {
          "Flag": false,
          "Wing": false,
          "Name": true,
          "EmbroideryLogo": true,
          "GoldGlove": true,
          "IndexFingerXRD": false,
          "MiddleFingerXRD": false,
          "Fastback": false,
          "Oval": false
        }
      }
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<GloveAssetRow[]>> = data;

export type GloveAsset = typeof typed[number];

export const GLOVE_ASSET_INDEX_KEY = "id";
export type GloveAssetIndexKey = "id";
export type GloveAssetId = GloveAsset["id"];

let i = 0;
export const GLOVE_ASSET_DICT = {
  "882": typed[i++],
  "REV204": typed[i++],
  "200CV": typed[i++],
  "204HSW": typed[i++],
  "205FS": typed[i++],
  "200WT": typed[i++],
  "200FB": typed[i++],
  "217": typed[i++],
  "302CV": typed[i++],
  "302FB": typed[i++],
  "303": typed[i++],
  "3039HSW": typed[i++],
  "3039FS": typed[i++],
  "REV3039": typed[i++],
  "315": typed[i++],
  "314HSW": typed[i++],
  "3319": typed[i++],
  "502": typed[i++],
  "504": typed[i++],
  "600": typed[i++],
  "6019HSW": typed[i++],
  "6019FS": typed[i++],
  "JD": typed[i++],
  "NP": typed[i++],
  "NP5HSW": typed[i++],
  "SC1": typed[i++],
  "TT2": typed[i++],
  "YJN1": typed[i++],
  "YPT110": typed[i++],
  "125KR": typed[i++],
  "125SB": typed[i++],
  "125FS": typed[i++],
  "560": typed[i++],
  "715SB": typed[i++],
  "CMHCB2": typed[i++],
  "DCT": typed[i++],
  "DCTHSW": typed[i++],
  "FM16": typed[i++],
  "FM18": typed[i++],
  "FM19": typed[i++],
  "FM20XL": typed[i++],
  "SC5": typed[i++],
  "FM19SB": typed[i++],
  "TM8SB": typed[i++],
  "CM33": typed[i++],
  "CM43HSW": typed[i++],
  "CM41": typed[i++],
  "JP20": typed[i++],
  "PL31": typed[i++],
  "SP13": typed[i++],
  "CM33FP": typed[i++],
  "200TR": typed[i++],
  "FL12TR": typed[i++]
} as const;

export { typed as GLOVE_ASSETS };
