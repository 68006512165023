import { connect } from 'react-redux'

import { t } from '../../../../platform/i18n'
import getAsset from '../../../../platform/getAsset'
import {
  getPreviewUrlsSelector,
  recipeIdSelector,
} from '../../../../platform/client/common/selectors'
import getSummary from '../../../../platform/client/getSummary'
import {
  modifyRecipe,
  orderRecipe,
} from '../../../../platform/client/common/actions'
import { getShareUrl } from '../../../../platform/client/share'
import { sendToFactory } from '../../../../platform/client/factory'
import { useAppDispatch } from '../../../../platform/client/configureStore'

import viewAngles, { defaultViewAngleId } from '../../../../platform/viewAngles'
import controlTree from '~c/client/controlTree'

import Product from './Product'
import Summary from './Summary'
import Share from './Share'
import Button from './Button'
import Icon from './Icon'
import Gallery from './Gallery'
import UnrealRenderForm from './UnrealRenderForm'

import './Finalize.css'

const mapStateToProps = (state, ownProps) => {
  const allSummary = getSummary(ownProps.nodes)
  return {
    product: {
      ...ownProps.product,
      summarySections: allSummary.filter((x) => x.id === 'product'),
    },
    summary: allSummary.filter((x) => x.id !== 'product'),
    previewUrls: getPreviewUrlsSelector(viewAngles, defaultViewAngleId)(state),
    isReview: ownProps.isReview,
    shareTwitterUrl: getShareUrl('twitter', state),
    shareFacebookUrl: getShareUrl('facebook', state),
    sharePinterestUrl: getShareUrl('pinterest', state),
    shareEmailUrl: getShareUrl('email', state),
    recipeId: recipeIdSelector(state),
  }
}

const Finalize = (props) => {
  const dispatch = useAppDispatch()
  return (
    <div className="finalize">
      <div className="finalize-preview">
        <Gallery previewUrls={props.previewUrls} />
      </div>
      <div className="finalize-sidebar">
        <div className="finalize-purchase">
          <Product {...props.product}>
            {!props.isReview && (
              <>
                <Button
                  classMods={['white', 'hasIcon']}
                  onClick={() => dispatch(modifyRecipe())}
                >
                  <img
                    src={getAsset('customize.svg')}
                    className="finalize-customizeIcon"
                    alt=""
                  />
                  {t('_rawlings:customize')}
                </Button>
                <Button
                  classMods={['hasIcon']}
                  onClick={() => dispatch(orderRecipe(controlTree))}
                >
                  <Icon name="cart" />
                  {window.serverConfig?.orderButtonText ||
                    t('_rawlings:addToCart')}
                </Button>
              </>
            )}
          </Product>
          <div className="finalize-summary">
            <Summary sections={props.summary} />
            <div className="finalize-printPrice">
              <div className="finalize-printPriceLabel">Price</div>
              <div className="finalize-printPriceValue">
                {props.product.priceWithCurrency}
              </div>
            </div>
          </div>
          {props.hasFactory && (
            <Button
              classMods={['hasIcon']}
              onClick={async () => {
                const orderId = Math.floor(Math.random() * 10000).toString()

                // eslint-disable-next-line no-alert
                const isConfirmed = window.confirm(
                  `Are you sure? Sales ID: "${orderId}"`,
                )
                if (!isConfirmed) {
                  return
                }

                const { error } = await sendToFactory(
                  props.recipeId,
                  orderId,
                  process.env.NODE_ENV === 'development',
                )

                if (error) {
                  console.error(error)
                  // eslint-disable-next-line no-alert
                  alert(
                    `There was an issue with sending the order to the factory!`,
                  )
                } else {
                  // eslint-disable-next-line no-alert
                  alert(
                    `Order with ID of "${orderId}" was sent to the factory!`,
                  )
                }
              }}
            >
              Send to factory
            </Button>
          )}
          <UnrealRenderForm
            recipeId={props.recipeId}
            hasUnrealRender={props.hasUnrealRender}
          />
        </div>
      </div>
      {!props.isReview && (
        <div className="finalize-share">
          <Share
            items={[
              {
                icon: 'mail',
                label: t('_rawlings:shareViaEmail'),
                href: props.shareEmailUrl,
              },
              {
                icon: 'print',
                label: t('_rawlings:print'),
                onClick: () => window.print(),
              },
              {
                icon: 'twitter',
                label: t('_rawlings:shareOnTwitter'),
                href: props.shareTwitterUrl,
              },
              {
                icon: 'facebook',
                label: t('_rawlings:shareOnFacebook'),
                href: props.shareFacebookUrl,
              },
              {
                icon: 'pinterest',
                label: t('_rawlings:shareOnPinterest'),
                href: props.sharePinterestUrl,
              },
            ]}
          />
        </div>
      )}
    </div>
  )
}

export default connect(mapStateToProps)(Finalize)
