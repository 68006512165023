import { filter, includes, map, toLower } from '@technically/lodash'
import React, { Fragment, useEffect, useState } from 'react'

import { t } from '../../../../platform/i18n'
import getAsset from '../../../../platform/getAsset'
import { cn, mods } from '../../../../platform/client/components/utils'

import TextInput from './TextInput'
import Tile from './Tile'
import TileCard from './TileCard'
import Icon from './Icon'

import './OptionsBar.css'
import './Wizard.css'

// TODO: Import Nodes from typings.
type Nodes = any

type Option = {
  id: string
  name: string
  [key: string]: any
}

type Props = {
  description?: string | null
  options?: Option[]
  value: string | null
  tileType:
    | 'square'
    | 'squareWithText'
    | 'wide'
    | 'wideWithText'
    | 'wideWithPadding'
  tileImageTemplate?: (item: Option) => string
  isDisabled?: boolean
  title: string
  onChange: (value: string | null) => void
  layoutMode: 'desktop' | 'mobile'
  toggleSearch: boolean
  isGrid?: boolean
  isSearch?: boolean
  isRequired?: boolean
  shouldAutoFocusSearch: boolean
  scrollToTop: () => void
  getTileInfo?: (
    menu: string,
    nodes: Node,
    option: Option,
  ) => [string, string][] | undefined
  getTileExtras?: (option: Option) => any
  nodes: Nodes
  menu: string
  tileCardChildren
}

type Sizes = {
  intTitleWidth: number
  tileWidth: number
  tilesPerSet: number
  containerWidth: number
}

type SearchState = {
  searchQuery: string
  hasSearchResults: boolean
}

const getInitialSearchState = (): SearchState => {
  return {
    searchQuery: '',
    hasSearchResults: false,
  }
}

const getTileSize = (tileSize: string) => {
  if (tileSize === 'large') {
    return 280 + 20
  } else {
    return window.innerWidth < 768 ? 74 : 84
  }
}

const getSizes = (tileSize: string): Sizes => {
  const width = window.innerWidth
  let availableWidth = width - 82 * 2

  const intTitleWidth = getTileSize('default')
  let tileWidth = getTileSize(tileSize)
  let tilesPerSet = Math.floor(availableWidth / tileWidth) || 1
  let containerWidth = tilesPerSet * tileWidth

  // Landscape mode for tiles.
  if (window.innerHeight <= 500) {
    tileWidth = Math.min(availableWidth, 600)
    tilesPerSet = Math.floor(availableWidth / tileWidth) || 1
    containerWidth = tilesPerSet * tileWidth
  }

  return {
    intTitleWidth,
    tileWidth,
    tilesPerSet,
    containerWidth,
  }
}

const areOptionsScrollable = () => {
  const wizardOptions = document.getElementById('wizard-options')

  if (!wizardOptions) {
    return false
  }

  return wizardOptions.scrollHeight > wizardOptions.clientHeight
}

const onResize = (
  tileSize: string,
  setSizes: (value: Sizes) => void,
  setScrollable: (value: boolean) => void,
) => {
  setSizes(getSizes(tileSize))
  setScrollable(areOptionsScrollable())
}

const onScroll = (
  scrollContainer: HTMLElement,
  setScrollable: (value: boolean) => void,
) => {
  const { scrollHeight, scrollTop, clientHeight } = scrollContainer
  const atBottom = scrollHeight - scrollTop === clientHeight
  setScrollable(!atBottom)
}

const getGridWidth = (itemsCount: number, tileWidth: number) => {
  const windowWidth = window.innerWidth
  let perRow = 4

  if (windowWidth > 400) {
    perRow = 5
  }

  return tileWidth * Math.min(itemsCount, perRow)
}

const imagePath = (x: Option, tileImageTemplate?: (item: Option) => string) => {
  if (!tileImageTemplate) {
    return null
  }

  const imagePath = tileImageTemplate(x)
  return getAsset(imagePath, { has2x: true })
}

export default (props: Props) => {
  const {
    description,
    isGrid,
    isSearch,
    isRequired,
    options,
    value,
    title,
    toggleSearch,
    shouldAutoFocusSearch,
    tileImageTemplate,
    getTileInfo,
    getTileExtras,
    nodes,
    menu,
  } = props

  const tileSize = 'large'
  const [searchOptions, setSearchOptions] = useState<Option[]>([])
  const [sizes, setSizes] = useState<Sizes>(getSizes(tileSize))
  const [isScrollable, setScrollable] = useState<boolean>(false)
  const [searchState, setSearchState] = useState<SearchState>(
    getInitialSearchState(),
  )

  const { searchQuery, hasSearchResults } = searchState

  useEffect(() => {
    if (isSearch) {
      const foundOptions = filter(options, (option) =>
        includes(toLower(option.name), toLower(searchQuery)),
      )
      const hasResults = !!foundOptions.length

      setSearchOptions(foundOptions)

      setSearchState((state) => ({ ...state, hasSearchResults: hasResults }))
    }
  }, [options, isSearch, setSearchOptions, searchQuery, setSearchState])

  const wizardOptions =
    isSearch && searchOptions.length ? searchOptions : options

  useEffect(() => {
    if (options) {
      onResize(tileSize, setSizes, setScrollable)
    }

    window.addEventListener('resize', () => {
      onResize(tileSize, setSizes, setScrollable)
    })

    return () =>
      window.removeEventListener('resize', () => {
        onResize(tileSize, setSizes, setScrollable)
      })
  }, [options, toggleSearch, tileSize, setSizes, setScrollable])

  useEffect(() => {
    const wizardOptionsDiv = document.getElementById('wizard-options')

    if (wizardOptions && wizardOptionsDiv) {
      setScrollable(areOptionsScrollable())
      wizardOptionsDiv.addEventListener('scroll', () => {
        onScroll(wizardOptionsDiv, setScrollable)
      })

      return () =>
        wizardOptionsDiv.removeEventListener('scroll', () =>
          onScroll(wizardOptionsDiv, setScrollable),
        )
    }
  }, [wizardOptions, setScrollable])

  const hasAction = toggleSearch || !isRequired

  const onOptionChange = (item: string | null) => {
    props.onChange(item)
    setSearchState(getInitialSearchState())
    props.scrollToTop()
  }

  return (
    <div className={cn(['optionsBar', ['isWizard'], mods([tileSize])])}>
      <Fragment>
        <div
          className={cn(['optionsBar-header', mods(isGrid ? ['grid'] : [])])}
        >
          {title && (
            <div
              className="wizard-title"
              dangerouslySetInnerHTML={{ __html: title }}
            />
          )}
          {description && (
            <div className="optionsBar-description">{description}</div>
          )}
        </div>
        {isGrid && (
          <div className="wizard-grid-container">
            <div
              className="wizard-grid"
              style={{
                width: getGridWidth(
                  wizardOptions ? wizardOptions.length : 0,
                  sizes.intTitleWidth,
                ),
              }}
            >
              {map(wizardOptions, (option) => (
                <Tile
                  key={option.id}
                  tileType="squareWithText"
                  text={option.name}
                  isSelected={option.id === value}
                  onClick={() => {
                    onOptionChange(option.id)
                  }}
                />
              ))}
            </div>
          </div>
        )}
        {!isGrid && wizardOptions && wizardOptions.length > 0 && (
          <div id="wizard-options" className="wizard-options-container">
            {map(wizardOptions, (option) => {
              const isSelected = option.id === value
              const tileInfo = getTileInfo && getTileInfo(menu, nodes, option)
              const tileExtras = getTileExtras && getTileExtras(option)

              return (
                <div key={option.id} className="wizard-scrollItem">
                  <TileCard
                    imageSrc={imagePath(option, tileImageTemplate)}
                    name={option.name}
                    text={option.description}
                    tileInfo={tileInfo}
                    textImportant={option.descriptionImportant}
                    isSelected={isSelected}
                    onClick={() => {
                      onOptionChange(option.id)
                    }}
                    style={{ width: sizes.tileWidth }}
                    extras={tileExtras}
                  />
                </div>
              )
            })}
          </div>
        )}
        {hasAction && (
          <div className="wizard-actions">
            {isSearch ?
              <div className="wizard-search">
                <TextInput
                  label={
                    hasSearchResults ?
                      t('_rawlings:modelSearch')
                    : t('_rawlings:noModelFound')
                  }
                  hasError={!hasSearchResults}
                  value={searchQuery}
                  onChange={(value: string) => {
                    setSearchState((state) => ({
                      ...state,
                      searchQuery: value,
                    }))
                  }}
                  shouldAutoFocus={shouldAutoFocusSearch}
                />
              </div>
            : <div>
                <div
                  className="wizard-noPreference"
                  onClick={() => {
                    if (!isRequired) {
                      onOptionChange(null)
                    }
                  }}
                >
                  {!isRequired && t('_rawlings:noPreference')}
                </div>
              </div>
            }
          </div>
        )}
        {isScrollable && (
          <div
            className="scroll-down-button"
            onClick={() => {
              const wizardOptionsContainer =
                document.getElementById('wizard-options')
              if (wizardOptionsContainer) {
                wizardOptionsContainer.scrollBy(
                  0,
                  wizardOptionsContainer.offsetHeight,
                )
              }
            }}
          >
            <Icon name="arrow-down" />
          </div>
        )}
      </Fragment>
    </div>
  )
}
