import jsonStringify from 'fast-json-stable-stringify';

import type { MaterialConfig } from '../types.js';
import {
  isMaterialPropertyCanvas,
  isMaterialPropertyFabric,
  isMaterialPropertyUrl,
} from './checks.js';
import { COLOR_PROPERTIES, TEXTURE_PROPERTIES } from './index.js';

const getMaterialKey = (materialConfig: MaterialConfig): string => {
  if (!materialConfig) {
    return '';
  }

  const keys: Record<string, unknown> = {};

  for (const [propertyKey] of COLOR_PROPERTIES) {
    const property = materialConfig[propertyKey];

    if (property === undefined) {
      continue;
    }

    keys[propertyKey] = property;
  }

  for (const [propertyKey] of TEXTURE_PROPERTIES) {
    const property = materialConfig[propertyKey];

    if (!property) {
      continue;
    }

    if (isMaterialPropertyUrl(property)) {
      keys[propertyKey] = property;
    } else if (isMaterialPropertyCanvas(property)) {
      keys[propertyKey] = property.key;
    } else if (isMaterialPropertyFabric(property)) {
      keys[propertyKey] = property.key;
    }
  }

  if ('flipX' in materialConfig) {
    keys['flipX'] = materialConfig.flipX;
  }

  if ('flipY' in materialConfig) {
    keys['flipY'] = materialConfig.flipY;
  }

  return jsonStringify(keys);
};

export default getMaterialKey;
