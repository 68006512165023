import React from 'react'

import { cn, mods } from '~p/client/components/utils'

import './Spinner.css'

const Spinner = ({ classMods = [] }) => (
  <div className={cn(['spinner', mods(classMods)])} />
)

export default Spinner
