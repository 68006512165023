// DO NOT EDIT, GENERATED BY "yarn sheets"

import { t } from '../../../../platform/i18n'

import type { SummaryTreeItemRow } from '../typings';

const data = [
  {
    "childId": "product",
    "id": "product",
    "name": t('rawlingsGloves:sheets.summaryTree.product.name')
  },
  {
    "parentId": "product",
    "childId": "glove",
    "propId": "product.glove",
    "id": "product.glove",
    "name": t('rawlingsGloves:sheets.summaryTree.product.glove.name')
  },
  {
    "parentId": "product",
    "childId": "sport",
    "propId": "calc.sport",
    "id": "product.sport",
    "name": t('rawlingsGloves:sheets.summaryTree.product.sport.name')
  },
  {
    "parentId": "product",
    "childId": "leather",
    "propId": "product.leather",
    "id": "product.leather",
    "name": t('rawlingsGloves:sheets.summaryTree.product.leather.name')
  },
  {
    "parentId": "product",
    "childId": "fit",
    "propId": "product.fit",
    "id": "product.fit",
    "name": t('rawlingsGloves:sheets.summaryTree.product.fit.name')
  },
  {
    "parentId": "product",
    "childId": "throwingHand",
    "propId": "product.throwingHand",
    "id": "product.throwingHand",
    "name": t('rawlingsGloves:sheets.summaryTree.product.throwingHand.name')
  },
  {
    "parentId": "product",
    "childId": "position",
    "propId": "calc.position",
    "id": "product.position",
    "name": t('rawlingsGloves:sheets.summaryTree.product.position.name')
  },
  {
    "parentId": "product",
    "childId": "size",
    "propId": "calc.size",
    "id": "product.size",
    "name": t('rawlingsGloves:sheets.summaryTree.product.size.name')
  },
  {
    "childId": "colors",
    "id": "colors",
    "name": t('rawlingsGloves:sheets.summaryTree.colors.name')
  },
  {
    "parentId": "colors",
    "childId": "web",
    "id": "colors.web",
    "name": t('rawlingsGloves:sheets.summaryTree.colors.web.name')
  },
  {
    "parentId": "colors.web",
    "childId": "style",
    "propId": "colors.web.style",
    "id": "colors.web.style",
    "name": t('rawlingsGloves:sheets.summaryTree.colors.web.style.name')
  },
  {
    "parentId": "colors.web",
    "childId": "color",
    "propId": "colors.web.color",
    "id": "colors.web.color",
    "name": t('rawlingsGloves:sheets.summaryTree.colors.web.color.name')
  },
  {
    "parentId": "colors",
    "childId": "logoPatch",
    "propId": "colors.logoPatch",
    "id": "colors.logoPatch",
    "name": t('rawlingsGloves:sheets.summaryTree.colors.logoPatch.name')
  },
  {
    "parentId": "colors",
    "childId": "shellBack",
    "id": "colors.shellBack",
    "name": t('rawlingsGloves:sheets.summaryTree.colors.shellBack.name')
  },
  {
    "parentId": "colors.shellBack",
    "childId": "design",
    "propId": "colors.shellBack.design",
    "id": "colors.shellBack.design",
    "name": t('rawlingsGloves:sheets.summaryTree.colors.shellBack.design.name')
  },
  {
    "parentId": "colors.shellBack",
    "childId": "material",
    "propId": "colors.shellBack.material",
    "id": "colors.shellBack.material",
    "name": t('rawlingsGloves:sheets.summaryTree.colors.shellBack.material.name')
  },
  {
    "parentId": "colors.shellBack",
    "childId": "leatherColor",
    "propId": "colors.shellBack.leatherColor",
    "id": "colors.shellBack.leatherColor",
    "name": t('rawlingsGloves:sheets.summaryTree.colors.shellBack.leatherColor.name')
  },
  {
    "parentId": "colors",
    "childId": "shellPalm",
    "propId": "colors.shellPalm",
    "id": "colors.shellPalm",
    "name": t('rawlingsGloves:sheets.summaryTree.colors.shellPalm.name')
  },
  {
    "parentId": "colors",
    "childId": "backPalm",
    "propId": "colors.backPalm",
    "id": "colors.backPalm",
    "name": t('rawlingsGloves:sheets.summaryTree.colors.backPalm.name')
  },
  {
    "parentId": "colors",
    "childId": "laces",
    "id": "colors.laces",
    "name": t('rawlingsGloves:sheets.summaryTree.colors.laces.name')
  },
  {
    "parentId": "colors.laces",
    "childId": "fingerWeb",
    "propId": "colors.laces.fingerWeb",
    "id": "colors.laces.fingerWeb",
    "name": t('rawlingsGloves:sheets.summaryTree.colors.laces.fingerWeb.name')
  },
  {
    "parentId": "colors.laces",
    "childId": "heel",
    "propId": "colors.laces.heel",
    "id": "colors.laces.heel",
    "name": t('rawlingsGloves:sheets.summaryTree.colors.laces.heel.name')
  },
  {
    "parentId": "colors.laces",
    "childId": "laceLength",
    "propId": "colors.laces.laceLength",
    "id": "colors.laces.laceLength",
    "name": t('rawlingsGloves:sheets.summaryTree.colors.laces.laceLength.name')
  },
  {
    "parentId": "colors",
    "childId": "lining",
    "propId": "colors.lining",
    "id": "colors.lining",
    "name": t('rawlingsGloves:sheets.summaryTree.colors.lining.name')
  },
  {
    "parentId": "colors",
    "childId": "welting",
    "propId": "colors.welting.all",
    "id": "colors.welting",
    "name": t('rawlingsGloves:sheets.summaryTree.colors.welting.name')
  },
  {
    "parentId": "colors",
    "childId": "weltingNested",
    "id": "colors.weltingNested",
    "name": t('rawlingsGloves:sheets.summaryTree.colors.weltingNested.name')
  },
  {
    "parentId": "colors",
    "childId": "handSewn",
    "propId": "colors.welting.handSewn",
    "id": "colors.handSewn",
    "name": t('rawlingsGloves:sheets.summaryTree.colors.handSewn.name')
  },
  {
    "parentId": "colors",
    "childId": "handSewnStitching",
    "propId": "colors.welting.handSewnStitching",
    "id": "colors.handSewnStitching",
    "name": t('rawlingsGloves:sheets.summaryTree.colors.handSewnStitching.name')
  },
  {
    "parentId": "colors.weltingNested",
    "childId": "palm",
    "propId": "colors.welting.palm",
    "id": "colors.weltingNested.palm",
    "name": t('rawlingsGloves:sheets.summaryTree.colors.weltingNested.palm.name')
  },
  {
    "parentId": "colors.weltingNested",
    "childId": "back",
    "propId": "colors.welting.back",
    "id": "colors.weltingNested.back",
    "name": t('rawlingsGloves:sheets.summaryTree.colors.weltingNested.back.name')
  },
  {
    "parentId": "colors",
    "childId": "binding",
    "propId": "colors.binding",
    "id": "colors.binding",
    "name": t('rawlingsGloves:sheets.summaryTree.colors.binding.name')
  },
  {
    "parentId": "colors",
    "childId": "trim",
    "id": "colors.trim",
    "name": t('rawlingsGloves:sheets.summaryTree.colors.trim.name')
  },
  {
    "parentId": "colors.trim",
    "childId": "style",
    "propId": "colors.trim.style",
    "id": "colors.trim.style",
    "name": t('rawlingsGloves:sheets.summaryTree.colors.trim.style.name')
  },
  {
    "parentId": "colors.trim",
    "childId": "color",
    "propId": "colors.trim.color",
    "id": "colors.trim.color",
    "name": t('rawlingsGloves:sheets.summaryTree.colors.trim.color.name')
  },
  {
    "parentId": "colors",
    "childId": "stitching",
    "propId": "colors.stitching",
    "id": "colors.stitching",
    "name": t('rawlingsGloves:sheets.summaryTree.colors.stitching.name')
  },
  {
    "parentId": "colors",
    "childId": "stamping",
    "propId": "colors.stamping",
    "id": "colors.stamping",
    "name": t('rawlingsGloves:sheets.summaryTree.colors.stamping.name')
  },
  {
    "childId": "options",
    "id": "options",
    "name": t('rawlingsGloves:sheets.summaryTree.options.name')
  },
  {
    "parentId": "options",
    "childId": "fingerPadHood",
    "id": "options.fingerPadHood",
    "name": t('rawlingsGloves:sheets.summaryTree.options.fingerPadHood.name')
  },
  {
    "parentId": "options.fingerPadHood",
    "childId": "fingerPad",
    "propId": "options.fingerPadHood.fingerPad",
    "id": "options.fingerPadHood.fingerPad",
    "name": t('rawlingsGloves:sheets.summaryTree.options.fingerPadHood.fingerPad.name')
  },
  {
    "parentId": "options.fingerPadHood",
    "childId": "fingerHood",
    "propId": "options.fingerPadHood.fingerHood",
    "id": "options.fingerPadHood.fingerHood",
    "name": t('rawlingsGloves:sheets.summaryTree.options.fingerPadHood.fingerHood.name')
  },
  {
    "parentId": "options.fingerPadHood",
    "childId": "position",
    "propId": "options.fingerPadHood.position",
    "id": "options.fingerPadHood.position",
    "name": t('rawlingsGloves:sheets.summaryTree.options.fingerPadHood.position.name')
  },
  {
    "parentId": "options",
    "childId": "palmPad",
    "propId": "options.palmPad",
    "id": "options.palmPad",
    "name": t('rawlingsGloves:sheets.summaryTree.options.palmPad.name')
  },
  {
    "parentId": "options",
    "childId": "heelPad",
    "propId": "options.heelPad",
    "id": "options.heelPad",
    "name": t('rawlingsGloves:sheets.summaryTree.options.heelPad.name')
  },
  {
    "parentId": "options",
    "childId": "wristLining",
    "propId": "options.wristLining",
    "id": "options.wristLining",
    "name": t('rawlingsGloves:sheets.summaryTree.options.wristLining.name')
  },
  {
    "parentId": "options",
    "childId": "sweatband",
    "propId": "options.sweatband",
    "id": "options.sweatband",
    "name": t('rawlingsGloves:sheets.summaryTree.options.sweatband.name')
  },
  {
    "parentId": "options",
    "childId": "breakIn",
    "propId": "options.breakIn",
    "id": "options.breakIn",
    "name": t('rawlingsGloves:sheets.summaryTree.options.breakIn.name')
  },
  {
    "childId": "personalization",
    "id": "personalization",
    "name": t('rawlingsGloves:sheets.summaryTree.personalization.name')
  },
  {
    "parentId": "personalization",
    "childId": "embroidery",
    "id": "personalization.embroidery",
    "name": t('rawlingsGloves:sheets.summaryTree.personalization.embroidery.name')
  },
  {
    "parentId": "personalization.embroidery",
    "childId": "color",
    "propId": "personalization.embroidery.color",
    "id": "personalization.embroidery.color",
    "name": t('rawlingsGloves:sheets.summaryTree.personalization.embroidery.color.name')
  },
  {
    "parentId": "personalization.embroidery",
    "childId": "number",
    "id": "personalization.embroidery.number",
    "name": t('rawlingsGloves:sheets.summaryTree.personalization.embroidery.number.name')
  },
  {
    "parentId": "personalization.embroidery.number",
    "childId": "text",
    "propId": "personalization.embroidery.number.text",
    "id": "personalization.embroidery.number.text",
    "name": t('rawlingsGloves:sheets.summaryTree.personalization.embroidery.number.text.name')
  },
  {
    "parentId": "personalization.embroidery.number",
    "childId": "font",
    "propId": "personalization.embroidery.number.font",
    "id": "personalization.embroidery.number.font",
    "name": t('rawlingsGloves:sheets.summaryTree.personalization.embroidery.number.font.name')
  },
  {
    "parentId": "personalization.embroidery",
    "childId": "logo",
    "propId": "personalization.embroidery.logo",
    "id": "personalization.embroidery.logo",
    "name": t('rawlingsGloves:sheets.summaryTree.personalization.embroidery.logo.name')
  },
  {
    "parentId": "personalization.embroidery",
    "childId": "thumb",
    "id": "personalization.embroidery.thumb",
    "name": t('rawlingsGloves:sheets.summaryTree.personalization.embroidery.thumb.name')
  },
  {
    "parentId": "personalization.embroidery.thumb",
    "childId": "text",
    "propId": "personalization.embroidery.thumb.text",
    "id": "personalization.embroidery.thumb.text",
    "name": t('rawlingsGloves:sheets.summaryTree.personalization.embroidery.thumb.text.name')
  },
  {
    "parentId": "personalization.embroidery.thumb",
    "childId": "font",
    "propId": "personalization.embroidery.thumb.font",
    "id": "personalization.embroidery.thumb.font",
    "name": t('rawlingsGloves:sheets.summaryTree.personalization.embroidery.thumb.font.name')
  },
  {
    "parentId": "personalization.embroidery",
    "childId": "pinky",
    "id": "personalization.embroidery.pinky",
    "name": t('rawlingsGloves:sheets.summaryTree.personalization.embroidery.pinky.name')
  },
  {
    "parentId": "personalization.embroidery.pinky",
    "childId": "text",
    "propId": "personalization.embroidery.pinky.text",
    "id": "personalization.embroidery.pinky.text",
    "name": t('rawlingsGloves:sheets.summaryTree.personalization.embroidery.pinky.text.name')
  },
  {
    "parentId": "personalization.embroidery.pinky",
    "childId": "font",
    "propId": "personalization.embroidery.pinky.font",
    "id": "personalization.embroidery.pinky.font",
    "name": t('rawlingsGloves:sheets.summaryTree.personalization.embroidery.pinky.font.name')
  },
  {
    "parentId": "personalization.embroidery",
    "childId": "pinkyPalm",
    "id": "personalization.embroidery.pinkyPalm",
    "name": t('rawlingsGloves:sheets.summaryTree.personalization.embroidery.pinkyPalm.name')
  },
  {
    "parentId": "personalization.embroidery.pinkyPalm",
    "childId": "text",
    "propId": "personalization.embroidery.pinkyPalm.text",
    "id": "personalization.embroidery.pinkyPalm.text",
    "name": t('rawlingsGloves:sheets.summaryTree.personalization.embroidery.pinkyPalm.text.name')
  },
  {
    "parentId": "personalization",
    "childId": "flag",
    "propId": "personalization.flag",
    "id": "personalization.flag",
    "name": t('rawlingsGloves:sheets.summaryTree.personalization.flag.name')
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<SummaryTreeItemRow[]>> = data;

export type SummaryTreeItem = typeof typed[number];

export const SUMMARY_TREE_ITEM_INDEX_KEY = "id";
export type SummaryTreeItemIndexKey = "id";
export type SummaryTreeItemId = SummaryTreeItem["id"];

let i = 0;
export const SUMMARY_TREE_ITEM_DICT = {
  "product": typed[i++],
  "product.glove": typed[i++],
  "product.sport": typed[i++],
  "product.leather": typed[i++],
  "product.fit": typed[i++],
  "product.throwingHand": typed[i++],
  "product.position": typed[i++],
  "product.size": typed[i++],
  "colors": typed[i++],
  "colors.web": typed[i++],
  "colors.web.style": typed[i++],
  "colors.web.color": typed[i++],
  "colors.logoPatch": typed[i++],
  "colors.shellBack": typed[i++],
  "colors.shellBack.design": typed[i++],
  "colors.shellBack.material": typed[i++],
  "colors.shellBack.leatherColor": typed[i++],
  "colors.shellPalm": typed[i++],
  "colors.backPalm": typed[i++],
  "colors.laces": typed[i++],
  "colors.laces.fingerWeb": typed[i++],
  "colors.laces.heel": typed[i++],
  "colors.laces.laceLength": typed[i++],
  "colors.lining": typed[i++],
  "colors.welting": typed[i++],
  "colors.weltingNested": typed[i++],
  "colors.handSewn": typed[i++],
  "colors.handSewnStitching": typed[i++],
  "colors.weltingNested.palm": typed[i++],
  "colors.weltingNested.back": typed[i++],
  "colors.binding": typed[i++],
  "colors.trim": typed[i++],
  "colors.trim.style": typed[i++],
  "colors.trim.color": typed[i++],
  "colors.stitching": typed[i++],
  "colors.stamping": typed[i++],
  "options": typed[i++],
  "options.fingerPadHood": typed[i++],
  "options.fingerPadHood.fingerPad": typed[i++],
  "options.fingerPadHood.fingerHood": typed[i++],
  "options.fingerPadHood.position": typed[i++],
  "options.palmPad": typed[i++],
  "options.heelPad": typed[i++],
  "options.wristLining": typed[i++],
  "options.sweatband": typed[i++],
  "options.breakIn": typed[i++],
  "personalization": typed[i++],
  "personalization.embroidery": typed[i++],
  "personalization.embroidery.color": typed[i++],
  "personalization.embroidery.number": typed[i++],
  "personalization.embroidery.number.text": typed[i++],
  "personalization.embroidery.number.font": typed[i++],
  "personalization.embroidery.logo": typed[i++],
  "personalization.embroidery.thumb": typed[i++],
  "personalization.embroidery.thumb.text": typed[i++],
  "personalization.embroidery.thumb.font": typed[i++],
  "personalization.embroidery.pinky": typed[i++],
  "personalization.embroidery.pinky.text": typed[i++],
  "personalization.embroidery.pinky.font": typed[i++],
  "personalization.embroidery.pinkyPalm": typed[i++],
  "personalization.embroidery.pinkyPalm.text": typed[i++],
  "personalization.flag": typed[i++]
} as const;

export { typed as SUMMARY_TREE };
