import { map, keys, findIndex, reverse, flow } from '@technically/lodash'
import React, { Component, Fragment } from 'react'
import cn from 'classnames'

import { Swipeable } from '~p/client/components/Swipeable'
import Icon from '~rawlings/client/components/Icon'

import './ViewSwitcher.css'

const getKey = (xs, x) => {
  const ys = xs.concat(xs)
  return ys[findIndex(ys, (a) => a === x) + 1]
}

const getNextKey = (xs, x) => getKey(keys(xs), x)

const getPrevKey = (xs, x) => {
  const ys = flow(keys, reverse)(xs)
  return getKey(ys, x)
}

class ViewSwitcher extends Component {
  componentDidMount() {
    window.addEventListener('keyup', this.keyupListener)
  }

  componentWillUnmount() {
    window.removeEventListener('keyup', this.keyupListener)
  }

  keyupListener = ({ key, target }) => {
    if (
      target instanceof HTMLInputElement ||
      target instanceof HTMLTextAreaElement
    ) {
      return null
    }

    switch (key) {
      case 'Escape':
        return this.props.setFocusMode(false)

      case 'ArrowLeft':
        return this.showPrevView()

      case 'ArrowRight':
        return this.showNextView()

      default:
        return null
    }
  }

  showPrevView = () => {
    const { viewAngles, activeView, setView } = this.props
    const prevView = getPrevKey(viewAngles, activeView)
    if (!setView) {
      return undefined
    }
    return setView(prevView)
  }

  showNextView = () => {
    const { viewAngles, activeView, setView } = this.props
    const nextView = getNextKey(viewAngles, activeView)
    if (!setView) {
      return undefined
    }
    return setView(nextView)
  }

  render() {
    const {
      viewAngles,
      setView,
      activeView,
      setFocusMode,
      isFocusMode,
      children,
    } = this.props

    return (
      <div className="viewSwitcher-container">
        {isFocusMode && (
          <Fragment>
            <a
              key="close"
              className="viewSwitcher-close"
              onClick={(e) => {
                e.preventDefault()
                setFocusMode(false)
              }}
            >
              <Icon name="close" />
            </a>
            {setView && (
              <a
                key="prev"
                className="viewSwitcher-arrow mod-prev"
                onClick={(e) => {
                  e.preventDefault()
                  this.showPrevView()
                }}
              >
                <Icon name="arrow-back" />
              </a>
            )}
          </Fragment>
        )}

        <Swipeable
          onSwipedRight={this.showPrevView}
          onSwipedLeft={this.showNextView}
        >
          {children}
        </Swipeable>

        {setView && isFocusMode && (
          <Fragment>
            <div key="bullets" className="viewSwitcher-bullets">
              {map(viewAngles, ({ label }, id) => (
                <div
                  key={id}
                  className={cn('viewSwitcher-bullet', {
                    'is-active': activeView === id,
                  })}
                  onClick={() => {
                    setView(id)
                  }}
                >
                  {label}
                </div>
              ))}
            </div>
            <a
              key="next"
              className="viewSwitcher-arrow mod-next"
              onClick={(e) => {
                e.preventDefault()
                this.showNextView()
              }}
            >
              <Icon name="arrow-forward" />
            </a>
          </Fragment>
        )}
      </div>
    )
  }
}

export default ViewSwitcher
