
import embroideryLogo from './embroidery-logo.json'
import meshName from './mesh-name.json'
import meshShadow from './mesh-shadow.json'
import meshStamps from './mesh-stamps.json'
import metallic from './metallic.json'
import photoshopLayers from './photoshop-layers.json'

export const revManifests = {
  'embroidery-logo': embroideryLogo,
  'mesh-name': meshName,
  'mesh-shadow': meshShadow,
  'mesh-stamps': meshStamps,
  'metallic': metallic,
  'photoshop-layers': photoshopLayers,
}
