// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { VendorRow } from '../typings';

const data = [
  {
    "hostname": "customgloves.rawlings.com",
    "environment": "production",
    "sportId": "bbsb",
    "vendorId": "rawlings",
    "isDisabled": false,
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyFlat",
    "addToCartUrl": "https://www.rawlings.com/customglove",
    "currency": "USD",
    "locale": "en-US",
    "twitterHandle": "rawlingssports",
    "returnToSiteUrl": "https://www.rawlings.com",
    "returnToSiteName": "rawlings.com",
    "features": {
      "is3d": false,
      "disableGxle": false,
      "disableRev1x": false,
      "disableTraining": false,
      "disableLogo": false
    }
  },
  {
    "hostname": "rawlings-bbg.cdev.orangelv.com",
    "environment": "development",
    "sportId": "bbsb",
    "vendorId": "rawlings",
    "isDisabled": false,
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyFlat",
    "addToCartUrl": "https://development.rawlings.com/customglove",
    "currency": "USD",
    "locale": "fr-CA",
    "twitterHandle": "rawlingssports",
    "returnToSiteUrl": "https://development-jts-teamsports.demandware.net/",
    "returnToSiteName": "rawlings.com",
    "features": {
      "is3d": false,
      "disableGxle": false,
      "disableRev1x": false,
      "disableTraining": false,
      "disableLogo": false
    }
  },
  {
    "hostname": "development.customgloves.rawlings.com",
    "environment": "development",
    "sportId": "bbsb",
    "vendorId": "rawlings",
    "isDisabled": false,
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyFlat",
    "addToCartUrl": "https://development.rawlings.com/customglove",
    "currency": "USD",
    "locale": "en-US",
    "twitterHandle": "rawlingssports",
    "returnToSiteUrl": "https://development-jts-teamsports.demandware.net/",
    "returnToSiteName": "rawlings.com",
    "features": {
      "is3d": false,
      "disableGxle": false,
      "disableRev1x": false,
      "disableTraining": false,
      "disableLogo": false
    }
  },
  {
    "hostname": "development.gloves.custom.rawlings.com",
    "environment": "staging",
    "sportId": "bbsb",
    "vendorId": "rawlings",
    "isDisabled": false,
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyFlat",
    "addToCartUrl": "https://development.rawlings.com/customglove",
    "currency": "USD",
    "locale": "en-US",
    "twitterHandle": "rawlingssports",
    "returnToSiteUrl": "https://development-jts-teamsports.demandware.net/",
    "returnToSiteName": "rawlings.com",
    "features": {
      "is3d": false,
      "disableGxle": false,
      "disableRev1x": false,
      "disableTraining": false,
      "disableLogo": false
    }
  },
  {
    "hostname": "sandbox01.gloves.custom.rawlings.com",
    "environment": "development",
    "sportId": "bbsb",
    "vendorId": "rawlings",
    "isDisabled": false,
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyFlat",
    "addToCartUrl": "https://dev01-jts-teamsports.demandware.net/s/rawlings/customglove",
    "currency": "USD",
    "locale": "en-US",
    "twitterHandle": "rawlingssports",
    "returnToSiteUrl": "https://development-jts-teamsports.demandware.net/",
    "returnToSiteName": "rawlings.com",
    "features": {
      "is3d": false,
      "disableGxle": false,
      "disableRev1x": false,
      "disableTraining": false,
      "disableLogo": false
    }
  },
  {
    "hostname": "sandbox02.gloves.custom.rawlings.com",
    "environment": "development",
    "sportId": "bbsb",
    "vendorId": "rawlings",
    "isDisabled": false,
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyFlat",
    "addToCartUrl": "https://dev02-jts-teamsports.demandware.net/s/rawlings/customglove",
    "currency": "USD",
    "locale": "en-US",
    "twitterHandle": "rawlingssports",
    "returnToSiteUrl": "https://development-jts-teamsports.demandware.net/",
    "returnToSiteName": "rawlings.com",
    "features": {
      "is3d": false,
      "disableGxle": false,
      "disableRev1x": false,
      "disableTraining": false,
      "disableLogo": false
    }
  },
  {
    "hostname": "sandbox03.gloves.custom.rawlings.com",
    "environment": "development",
    "sportId": "bbsb",
    "vendorId": "rawlings",
    "isDisabled": false,
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyFlat",
    "addToCartUrl": "https://dev03-jts-teamsports.demandware.net/s/rawlings/customglove",
    "currency": "USD",
    "locale": "en-US",
    "twitterHandle": "rawlingssports",
    "returnToSiteUrl": "https://development-jts-teamsports.demandware.net/",
    "returnToSiteName": "rawlings.com",
    "features": {
      "is3d": false,
      "disableGxle": false,
      "disableRev1x": false,
      "disableTraining": false,
      "disableLogo": false
    }
  },
  {
    "hostname": "sandbox04.gloves.custom.rawlings.com",
    "environment": "development",
    "sportId": "bbsb",
    "vendorId": "rawlings",
    "isDisabled": false,
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyFlat",
    "addToCartUrl": "https://dev04-jts-teamsports.demandware.net/s/rawlings/customglove",
    "currency": "USD",
    "locale": "en-US",
    "twitterHandle": "rawlingssports",
    "returnToSiteUrl": "https://development-jts-teamsports.demandware.net/",
    "returnToSiteName": "rawlings.com",
    "features": {
      "is3d": false,
      "disableGxle": false,
      "disableRev1x": false,
      "disableTraining": false,
      "disableLogo": false
    }
  },
  {
    "hostname": "sandbox05.gloves.custom.rawlings.com",
    "environment": "development",
    "sportId": "bbsb",
    "vendorId": "rawlings05",
    "isDisabled": false,
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyFlat",
    "addToCartUrl": "https://dev05-jts-teamsports.demandware.net/s/rawlings/customglove",
    "currency": "CAD",
    "locale": "en-US",
    "twitterHandle": "rawlingssports",
    "returnToSiteUrl": "https://development-jts-teamsports.demandware.net/",
    "returnToSiteName": "rawlings.com",
    "features": {
      "is3d": false,
      "disableGxle": false,
      "disableRev1x": true,
      "disableTraining": false,
      "disableLogo": false
    }
  },
  {
    "hostname": "development.mylocker.gloves.custom.rawlings.com",
    "environment": "development",
    "sportId": "bbsb",
    "vendorId": "rawlings",
    "isDisabled": false,
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyNested",
    "addToCartUrl": "https://mylocker.rawlings.com/save.aspx?category=1",
    "currency": "USD",
    "locale": "en-US",
    "twitterHandle": "rawlingssports",
    "orderButtonText": "Save",
    "returnToSiteUrl": "https://mylocker.rawlings.com",
    "returnToSiteName": "mylocker.rawlings.com",
    "features": {
      "is3d": false,
      "disableGxle": true,
      "disableRev1x": false,
      "disableTraining": false,
      "disableLogo": false
    }
  },
  {
    "hostname": "gloves.custom.rawlings.com",
    "environment": "production",
    "sportId": "bbsb",
    "vendorId": "rawlings",
    "isDisabled": false,
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyFlat",
    "addToCartUrl": "https://www.rawlings.com/customglove",
    "currency": "USD",
    "locale": "en-US",
    "twitterHandle": "rawlingssports",
    "returnToSiteUrl": "https://www.rawlings.com",
    "returnToSiteName": "rawlings.com",
    "features": {
      "is3d": false,
      "disableGxle": false,
      "disableRev1x": false,
      "disableTraining": false,
      "disableLogo": false
    }
  },
  {
    "hostname": "mylocker.gloves.custom.rawlings.com",
    "environment": "production",
    "sportId": "bbsb",
    "vendorId": "rawlings",
    "isDisabled": false,
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyNested",
    "addToCartUrl": "https://mylocker.rawlings.com/save.aspx?category=1",
    "currency": "USD",
    "locale": "en-US",
    "twitterHandle": "rawlingssports",
    "orderButtonText": "Save",
    "returnToSiteUrl": "https://mylocker.rawlings.com",
    "returnToSiteName": "mylocker.rawlings.com",
    "features": {
      "is3d": false,
      "disableGxle": true,
      "disableRev1x": false,
      "disableTraining": false,
      "disableLogo": false
    }
  },
  {
    "hostname": "teamlocker.gloves.custom.rawlings.com",
    "environment": "production",
    "sportId": "bbsb",
    "vendorId": "rawlings",
    "isDisabled": false,
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyNested",
    "addToCartUrl": "https://teamlockerapi.rawlings.com/api/product/custom/glove",
    "currency": "USD",
    "locale": "en-US",
    "twitterHandle": "rawlingssports",
    "orderButtonText": "Save",
    "features": {
      "is3d": false,
      "disableGxle": true,
      "disableRev1x": false,
      "disableTraining": true,
      "disableLogo": false
    }
  },
  {
    "hostname": "justballgloves.gloves.custom.rawlings.com",
    "environment": "production",
    "sportId": "bbsb",
    "vendorId": "justballgloves",
    "isDisabled": false,
    "vendorName": "JustBallGloves",
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyFlat",
    "addToCartUrl": "https://www.justballgloves.com/cart/RawlingsCustomGlove/",
    "currency": "USD",
    "locale": "en-US",
    "twitterHandle": "JustBallGloves",
    "features": {
      "is3d": false,
      "disableGxle": false,
      "disableRev1x": true,
      "disableTraining": true,
      "disableLogo": false
    }
  },
  {
    "hostname": "baseballexpress.gloves.custom.rawlings.com",
    "environment": "production",
    "sportId": "bbsb",
    "vendorId": "baseballexpress",
    "isDisabled": false,
    "vendorName": "Baseball Express",
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyFlat",
    "addToCartUrl": "https://www.baseballexpress.com/product_customizer/buyrequest/add",
    "currency": "USD",
    "locale": "en-US",
    "twitterHandle": "BB_Express",
    "features": {
      "is3d": false,
      "disableGxle": true,
      "disableRev1x": true,
      "disableTraining": true,
      "disableLogo": false
    }
  },
  {
    "hostname": "softballcom.gloves.custom.rawlings.com",
    "environment": "production",
    "sportId": "bbsb",
    "vendorId": "baseballexpress",
    "isDisabled": false,
    "vendorName": "Baseball Express",
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyFlat",
    "addToCartUrl": "https://www.softball.com/product_customizer/buyrequest/add",
    "currency": "USD",
    "locale": "en-US",
    "twitterHandle": "Softball_com",
    "features": {
      "is3d": false,
      "disableGxle": true,
      "disableRev1x": true,
      "disableTraining": true,
      "disableLogo": false
    }
  },
  {
    "hostname": "teamexpress.gloves.custom.rawlings.com",
    "environment": "production",
    "sportId": "bbsb",
    "vendorId": "baseballexpress",
    "isDisabled": false,
    "vendorName": "Baseball Express",
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyFlat",
    "addToCartUrl": "https://www.teamexpress.com/product_customizer/buyrequest/add",
    "currency": "USD",
    "locale": "en-US",
    "twitterHandle": "Team_Express",
    "features": {
      "is3d": false,
      "disableGxle": true,
      "disableRev1x": true,
      "disableTraining": true,
      "disableLogo": false
    }
  },
  {
    "hostname": "baseballmonkey.gloves.custom.rawlings.com",
    "environment": "production",
    "sportId": "bbsb",
    "vendorId": "baseballmonkey",
    "isDisabled": false,
    "vendorName": "Baseball Monkey",
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyFlat",
    "addToCartUrl": "https://www.baseballmonkey.com/shell/addtocart.php",
    "currency": "USD",
    "locale": "en-US",
    "twitterHandle": "baseballmonkey",
    "features": {
      "is3d": false,
      "disableGxle": false,
      "disableRev1x": true,
      "disableTraining": true,
      "disableLogo": false
    }
  },
  {
    "hostname": "customglove.gloves.custom.rawlings.com",
    "environment": "production",
    "sportId": "bbsb",
    "vendorId": "customglove",
    "isDisabled": false,
    "vendorName": "CustomGlove",
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyFlat",
    "addToCartUrl": "http://www.customglove.com/cgi-bin/commerce.cgi",
    "currency": "USD",
    "locale": "en-US",
    "features": {
      "is3d": false,
      "disableGxle": true,
      "disableRev1x": true,
      "disableTraining": true,
      "disableLogo": false
    }
  },
  {
    "hostname": "hq4sports.gloves.custom.rawlings.com",
    "environment": "production",
    "sportId": "bbsb",
    "vendorId": "hq4sports",
    "isDisabled": false,
    "vendorName": "HQ4Sports",
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyFlat",
    "addToCartUrl": "https://www.kingwebtools.com/hq_4_sports/rawlings_custom_glove/new_return.php",
    "currency": "USD",
    "locale": "en-US",
    "features": {
      "is3d": false,
      "disableGxle": true,
      "disableRev1x": true,
      "disableTraining": true,
      "disableLogo": false
    }
  },
  {
    "hostname": "smashitsports.gloves.custom.rawlings.com",
    "environment": "production",
    "sportId": "bbsb",
    "vendorId": "smashitsports",
    "isDisabled": false,
    "vendorName": "Smash It Sports",
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyNested",
    "addToCartUrl": "https://rawlingscustom.smashitsports.com/",
    "currency": "USD",
    "locale": "en-US",
    "features": {
      "is3d": false,
      "disableGxle": true,
      "disableRev1x": true,
      "disableTraining": true,
      "disableLogo": false
    }
  },
  {
    "hostname": "fieldrep.smashitsports.gloves.custom.rawlings.com",
    "environment": "production",
    "sportId": "bbsb",
    "vendorId": "smashitsports",
    "isDisabled": false,
    "vendorName": "Smash It Sports",
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyFlat",
    "addToCartUrl": "https://www.smashitsports.com/rawlings-custom-glove-builder/rep-order.php",
    "currency": "USD",
    "locale": "en-US",
    "features": {
      "is3d": false,
      "disableGxle": true,
      "disableRev1x": true,
      "disableTraining": true,
      "disableLogo": false
    }
  },
  {
    "hostname": "canada.smashitsports.gloves.custom.rawlings.com",
    "environment": "production",
    "sportId": "bbsb",
    "vendorId": "smashitsports",
    "isDisabled": false,
    "vendorName": "Smash It Sports",
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyNested",
    "addToCartUrl": "https://rawlingscustom.smashitsports.com/canada",
    "currency": "CAD",
    "locale": "en-US",
    "features": {
      "is3d": false,
      "disableGxle": true,
      "disableRev1x": true,
      "disableTraining": true,
      "disableLogo": false
    }
  },
  {
    "hostname": "fieldrep.canada.smashitsports.gloves.custom.rawlings.com",
    "environment": "production",
    "sportId": "bbsb",
    "vendorId": "smashitsports",
    "isDisabled": false,
    "vendorName": "Smash It Sports",
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyFlat",
    "addToCartUrl": "https://canada.smashitsports.com/rawlings-custom-glove-builder/rep-order.php",
    "currency": "CAD",
    "locale": "en-US",
    "features": {
      "is3d": false,
      "disableGxle": true,
      "disableRev1x": true,
      "disableTraining": true,
      "disableLogo": false
    }
  },
  {
    "hostname": "hardballfans.gloves.custom.rawlings.com",
    "environment": "production",
    "sportId": "bbsb",
    "vendorId": "hardballfans",
    "isDisabled": false,
    "vendorName": "Hardball Fans",
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyFlat",
    "addToCartUrl": "https://www.hardballfans.com/rawlingsglovebuilder/cart/",
    "currency": "USD",
    "locale": "en-US",
    "features": {
      "is3d": false,
      "disableGxle": true,
      "disableRev1x": true,
      "disableTraining": true,
      "disableLogo": false
    }
  },
  {
    "hostname": "softballfans.gloves.custom.rawlings.com",
    "environment": "production",
    "sportId": "bbsb",
    "vendorId": "hardballfans",
    "isDisabled": false,
    "vendorName": "Hardball Fans",
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyFlat",
    "addToCartUrl": "https://www.softballfans.com/rawlingsglovebuilder/cart/",
    "currency": "USD",
    "locale": "en-US",
    "twitterHandle": "softballfans",
    "features": {
      "is3d": false,
      "disableGxle": true,
      "disableRev1x": true,
      "disableTraining": true,
      "disableLogo": false
    }
  },
  {
    "hostname": "basesloaded.gloves.custom.rawlings.com",
    "environment": "production",
    "sportId": "bbsb",
    "vendorId": "basesloaded",
    "isDisabled": false,
    "vendorName": "Bases Loaded",
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyFlat",
    "addToCartUrl": "https://p8.secure.hostingprod.com/@site.ebasesloaded.com/ssl/glove-builder/process.php",
    "currency": "USD",
    "locale": "en-US",
    "twitterHandle": "ebasesloaded",
    "features": {
      "is3d": false,
      "disableGxle": true,
      "disableRev1x": true,
      "disableTraining": true,
      "disableLogo": false
    }
  },
  {
    "hostname": "ebasesloaded.gloves.custom.rawlings.com",
    "environment": "production",
    "sportId": "bbsb",
    "vendorId": "basesloaded",
    "isDisabled": false,
    "vendorName": "Bases Loaded",
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyFlat",
    "addToCartUrl": "https://bc.ebasesloaded.com/glove-builder/process.php",
    "currency": "USD",
    "locale": "en-US",
    "twitterHandle": "ebasesloaded",
    "features": {
      "is3d": false,
      "disableGxle": true,
      "disableRev1x": true,
      "disableTraining": true,
      "disableLogo": false
    }
  },
  {
    "hostname": "apollosports.gloves.custom.rawlings.com",
    "environment": "production",
    "sportId": "bbsb",
    "vendorId": "apollosports",
    "isDisabled": false,
    "vendorName": "Apollo Sports",
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyFlat",
    "addToCartUrl": "https://apollosports.com/cart",
    "currency": "USD",
    "locale": "en-US",
    "features": {
      "is3d": false,
      "disableGxle": true,
      "disableRev1x": true,
      "disableTraining": true,
      "disableLogo": false
    }
  },
  {
    "hostname": "forelle.gloves.custom.rawlings.com",
    "environment": "production",
    "sportId": "bbsb",
    "vendorId": "forelle",
    "isDisabled": false,
    "vendorName": "Forelle",
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyNested",
    "addToCartUrl": "https://www.forelle.com/basket/external-configurator/",
    "currency": "EUR",
    "locale": "en-US",
    "features": {
      "is3d": false,
      "disableGxle": true,
      "disableRev1x": true,
      "disableTraining": true,
      "disableLogo": false
    }
  },
  {
    "hostname": "dealer.forelle.gloves.custom.rawlings.com",
    "environment": "production",
    "sportId": "bbsb",
    "vendorId": "forelle",
    "isDisabled": false,
    "vendorName": "Forelle",
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyNested",
    "addToCartUrl": "https://dealer.forelle.com/basket/external-configurator/",
    "currency": "EUR",
    "locale": "en-US",
    "features": {
      "is3d": false,
      "disableGxle": true,
      "disableRev1x": true,
      "disableTraining": true,
      "disableLogo": false
    }
  },
  {
    "hostname": "baseballsavings.gloves.custom.rawlings.com",
    "environment": "production",
    "sportId": "bbsb",
    "vendorId": "baseballsavings",
    "isDisabled": false,
    "vendorName": "Baseball Savings",
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyFlat",
    "addToCartUrl": "https://www.baseballsavings.com/RawlingsWorthOrderItemAdd?catalogId=13052&langId=-1&storeId=11152&widgetType=Rawlings",
    "currency": "USD",
    "locale": "en-US",
    "features": {
      "is3d": false,
      "disableGxle": true,
      "disableRev1x": true,
      "disableTraining": true,
      "disableLogo": false
    }
  },
  {
    "hostname": "closeoutbats.gloves.custom.rawlings.com",
    "environment": "production",
    "sportId": "bbsb",
    "vendorId": "closeoutbats",
    "isDisabled": false,
    "vendorName": "Closeout Bats",
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyFlat",
    "addToCartUrl": "https://www.ytimes.net/closeoutbats/glove-builder/process.php",
    "currency": "USD",
    "locale": "en-US",
    "features": {
      "is3d": false,
      "disableGxle": true,
      "disableRev1x": true,
      "disableTraining": true,
      "disableLogo": false
    }
  },
  {
    "hostname": "scheels.gloves.custom.rawlings.com",
    "environment": "production",
    "sportId": "bbsb",
    "vendorId": "scheels",
    "isDisabled": false,
    "vendorName": "Scheels",
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyNested",
    "addToCartUrl": "https://www.scheels.com/glove-builder",
    "currency": "USD",
    "locale": "en-US",
    "features": {
      "is3d": false,
      "disableGxle": true,
      "disableRev1x": true,
      "disableTraining": true,
      "disableLogo": false
    }
  },
  {
    "hostname": "kahunaverse.gloves.custom.rawlings.com",
    "environment": "production",
    "sportId": "bbsb",
    "vendorId": "kahunaverse",
    "isDisabled": false,
    "vendorName": "Kahunaverse",
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyNested",
    "addToCartUrl": "https://www.kahunaverse.com/rawlings/cart/add",
    "currency": "CAD",
    "locale": "en-US",
    "features": {
      "is3d": false,
      "disableGxle": true,
      "disableRev1x": true,
      "disableTraining": true,
      "disableLogo": false
    }
  },
  {
    "hostname": "homerunsports.gloves.custom.rawlings.com",
    "environment": "production",
    "sportId": "bbsb",
    "vendorId": "homerunsports",
    "isDisabled": false,
    "vendorName": "Home Run Sports",
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyNested",
    "addToCartUrl": "https://apps.oneteamsports.com/rawlings-custom-glove-builder/rawlings-glove-customizer.php",
    "currency": "CAD",
    "locale": "en-US",
    "features": {
      "is3d": false,
      "disableGxle": true,
      "disableRev1x": true,
      "disableTraining": true,
      "disableLogo": false
    }
  },
  {
    "hostname": "evolutionsports.gloves.custom.rawlings.com",
    "environment": "production",
    "sportId": "bbsb",
    "vendorId": "evolutionsports",
    "isDisabled": false,
    "vendorName": "Evolution Sports",
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyFlat",
    "addToCartUrl": "https://phpstack-606044-2550571.cloudwaysapps.com/glove-customizer.php",
    "currency": "CAD",
    "locale": "en-US",
    "features": {
      "is3d": false,
      "disableGxle": true,
      "disableRev1x": true,
      "disableTraining": true,
      "disableLogo": false
    }
  },
  {
    "hostname": "mylocker360.gloves.custom.rawlings.com",
    "environment": "production",
    "sportId": "bbsb",
    "vendorId": "rawlings",
    "isDisabled": false,
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyNested",
    "addToCartUrl": "https://api.mylocker360.rawlings.com/rawlingsmylocker/capturedesign",
    "currency": "USD",
    "locale": "en-US",
    "twitterHandle": "rawlingssports",
    "orderButtonText": "Save",
    "returnToSiteUrl": "https://mylocker360.rawlings.com",
    "returnToSiteName": "mylocker360.rawlings.com",
    "features": {
      "is3d": false,
      "disableGxle": true,
      "disableRev1x": false,
      "disableTraining": false,
      "disableLogo": false
    }
  },
  {
    "hostname": "staging.gloves.custom.rawlings.com",
    "environment": "staging",
    "sportId": "bbsb",
    "vendorId": "rawlings",
    "isDisabled": false,
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyFlat",
    "addToCartUrl": "https://staging-jts-teamsports.demandware.net/s/rawlings-consolidated/customglove",
    "currency": "USD",
    "locale": "en-US",
    "twitterHandle": "rawlingssports",
    "returnToSiteUrl": "https://staging-jts-teamsports.demandware.net/",
    "returnToSiteName": "rawlings.com",
    "features": {
      "is3d": false,
      "disableGxle": false,
      "disableRev1x": false,
      "disableTraining": false,
      "disableLogo": false
    }
  },
  {
    "hostname": "staging.mylocker.gloves.custom.rawlings.com",
    "environment": "staging",
    "sportId": "bbsb",
    "vendorId": "rawlings",
    "isDisabled": false,
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyNested",
    "addToCartUrl": "https://mylocker.rawlings.com/save.aspx?category=1",
    "currency": "USD",
    "locale": "en-US",
    "twitterHandle": "rawlingssports",
    "orderButtonText": "Save",
    "returnToSiteUrl": "https://mylocker.rawlings.com",
    "returnToSiteName": "mylocker.rawlings.com",
    "features": {
      "is3d": false,
      "disableGxle": true,
      "disableRev1x": false,
      "disableTraining": false,
      "disableLogo": false
    }
  },
  {
    "hostname": "staging.teamlocker.gloves.custom.rawlings.com",
    "environment": "staging",
    "sportId": "bbsb",
    "vendorId": "rawlings",
    "isDisabled": false,
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyNested",
    "addToCartUrl": "https://devteamlockerapi.rawlings.com/api/product/custom/glove",
    "currency": "USD",
    "locale": "en-US",
    "twitterHandle": "rawlingssports",
    "orderButtonText": "Save",
    "features": {
      "is3d": false,
      "disableGxle": true,
      "disableRev1x": false,
      "disableTraining": true,
      "disableLogo": false
    }
  },
  {
    "hostname": "staging.justballgloves.gloves.custom.rawlings.com",
    "environment": "staging",
    "sportId": "bbsb",
    "vendorId": "justballgloves",
    "isDisabled": false,
    "vendorName": "JustBallGloves",
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyFlat",
    "addToCartUrl": "https://newjbg.theitwing.com/cart/RawlingsCustomGlove/",
    "currency": "USD",
    "locale": "en-US",
    "twitterHandle": "JustBallGloves",
    "features": {
      "is3d": false,
      "disableGxle": false,
      "disableRev1x": true,
      "disableTraining": true,
      "disableLogo": false
    }
  },
  {
    "hostname": "staging.baseballexpress.gloves.custom.rawlings.com",
    "environment": "staging",
    "sportId": "bbsb",
    "vendorId": "baseballexpress",
    "isDisabled": false,
    "vendorName": "Baseball Express",
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyFlat",
    "addToCartUrl": "https://m2www.teamexpress.com/product_customizer/buyrequest/add",
    "currency": "USD",
    "locale": "en-US",
    "twitterHandle": "BB_Express",
    "features": {
      "is3d": false,
      "disableGxle": true,
      "disableRev1x": true,
      "disableTraining": true,
      "disableLogo": false
    }
  },
  {
    "hostname": "preview.baseballexpress.gloves.custom.rawlings.com",
    "environment": "staging",
    "sportId": "bbsb",
    "vendorId": "baseballexpress",
    "isDisabled": false,
    "vendorName": "Baseball Express",
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyFlat",
    "addToCartUrl": "https://tesp-customizer.preview2.creatuity.com/product_customizer/buyrequest/add",
    "currency": "USD",
    "locale": "en-US",
    "twitterHandle": "BB_Express",
    "features": {
      "is3d": false,
      "disableGxle": true,
      "disableRev1x": true,
      "disableTraining": true,
      "disableLogo": false
    }
  },
  {
    "hostname": "staging.baseballmonkey.gloves.custom.rawlings.com",
    "environment": "staging",
    "sportId": "bbsb",
    "vendorId": "baseballmonkey",
    "isDisabled": false,
    "vendorName": "Baseball Monkey",
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyFlat",
    "addToCartUrl": "https://m24qa1.baseballmonkey.com/shell/addtocart.php",
    "currency": "USD",
    "locale": "en-US",
    "twitterHandle": "baseballmonkey",
    "features": {
      "is3d": false,
      "disableGxle": false,
      "disableRev1x": true,
      "disableTraining": true,
      "disableLogo": false
    }
  },
  {
    "hostname": "staging.customglove.gloves.custom.rawlings.com",
    "environment": "staging",
    "sportId": "bbsb",
    "vendorId": "customglove",
    "isDisabled": false,
    "vendorName": "CustomGlove",
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyFlat",
    "addToCartUrl": "http://www.customglove.com/cgi-bin/commerce.cgi",
    "currency": "USD",
    "locale": "en-US",
    "features": {
      "is3d": false,
      "disableGxle": true,
      "disableRev1x": true,
      "disableTraining": true,
      "disableLogo": false
    }
  },
  {
    "hostname": "staging.hq4sports.gloves.custom.rawlings.com",
    "environment": "staging",
    "sportId": "bbsb",
    "vendorId": "hq4sports",
    "isDisabled": false,
    "vendorName": "HQ4Sports",
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyFlat",
    "addToCartUrl": "https://www.kingwebtools.com/hq_4_sports/rawlings_custom_glove/new_return.php",
    "currency": "USD",
    "locale": "en-US",
    "features": {
      "is3d": false,
      "disableGxle": true,
      "disableRev1x": true,
      "disableTraining": true,
      "disableLogo": false
    }
  },
  {
    "hostname": "staging.smashitsports.gloves.custom.rawlings.com",
    "environment": "staging",
    "sportId": "bbsb",
    "vendorId": "smashitsports",
    "isDisabled": false,
    "vendorName": "Smash It Sports",
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyNested",
    "addToCartUrl": "https://rawlingscustom.smashitsports.com/",
    "currency": "USD",
    "locale": "en-US",
    "features": {
      "is3d": false,
      "disableGxle": true,
      "disableRev1x": true,
      "disableTraining": true,
      "disableLogo": false
    }
  },
  {
    "hostname": "staging.canada.smashitsports.gloves.custom.rawlings.com",
    "environment": "staging",
    "sportId": "bbsb",
    "vendorId": "smashitsports",
    "isDisabled": false,
    "vendorName": "Smash It Sports",
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyFlat",
    "addToCartUrl": "https://canada.smashitsports.com/?add-to-cart=11296",
    "currency": "CAD",
    "locale": "en-US",
    "features": {
      "is3d": false,
      "disableGxle": true,
      "disableRev1x": true,
      "disableTraining": true,
      "disableLogo": false
    }
  },
  {
    "hostname": "staging.hardballfans.gloves.custom.rawlings.com",
    "environment": "staging",
    "sportId": "bbsb",
    "vendorId": "hardballfans",
    "isDisabled": false,
    "vendorName": "Hardball Fans",
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyFlat",
    "addToCartUrl": "https://www.hardballfans.com/rawlingsglovebuilder/cart/",
    "currency": "USD",
    "locale": "en-US",
    "features": {
      "is3d": false,
      "disableGxle": true,
      "disableRev1x": true,
      "disableTraining": true,
      "disableLogo": false
    }
  },
  {
    "hostname": "staging.basesloaded.gloves.custom.rawlings.com",
    "environment": "staging",
    "sportId": "bbsb",
    "vendorId": "basesloaded",
    "isDisabled": false,
    "vendorName": "Bases Loaded",
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyFlat",
    "addToCartUrl": "https://p8.secure.hostingprod.com/@site.ebasesloaded.com/ssl-bc/glove-builder/process.php",
    "currency": "USD",
    "locale": "en-US",
    "twitterHandle": "ebasesloaded",
    "features": {
      "is3d": false,
      "disableGxle": true,
      "disableRev1x": true,
      "disableTraining": true,
      "disableLogo": false
    }
  },
  {
    "hostname": "staging.ebasesloaded.gloves.custom.rawlings.com",
    "environment": "staging",
    "sportId": "bbsb",
    "vendorId": "basesloaded",
    "isDisabled": false,
    "vendorName": "Bases Loaded",
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyFlat",
    "addToCartUrl": "https://bc.ebasesloaded.com/glove-builder/process-4.3.php",
    "currency": "USD",
    "locale": "en-US",
    "twitterHandle": "ebasesloaded",
    "features": {
      "is3d": false,
      "disableGxle": true,
      "disableRev1x": true,
      "disableTraining": true,
      "disableLogo": false
    }
  },
  {
    "hostname": "staging.apollosports.gloves.custom.rawlings.com",
    "environment": "staging",
    "sportId": "bbsb",
    "vendorId": "apollosports",
    "isDisabled": false,
    "vendorName": "Apollo Sports",
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyFlat",
    "addToCartUrl": "https://apollosports.com/cart",
    "currency": "USD",
    "locale": "en-US",
    "features": {
      "is3d": false,
      "disableGxle": true,
      "disableRev1x": true,
      "disableTraining": true,
      "disableLogo": false
    }
  },
  {
    "hostname": "staging.forelle.gloves.custom.rawlings.com",
    "environment": "staging",
    "sportId": "bbsb",
    "vendorId": "forelle",
    "isDisabled": false,
    "vendorName": "Forelle",
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyNested",
    "addToCartUrl": "https://test-forelle.cloudsuite.com/basket/external-configurator/",
    "currency": "EUR",
    "locale": "en-US",
    "features": {
      "is3d": false,
      "disableGxle": true,
      "disableRev1x": true,
      "disableTraining": true,
      "disableLogo": false
    }
  },
  {
    "hostname": "staging.dealer.forelle.gloves.custom.rawlings.com",
    "environment": "staging",
    "sportId": "bbsb",
    "vendorId": "forelle",
    "isDisabled": false,
    "vendorName": "Forelle",
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyNested",
    "addToCartUrl": "https://test-forelleb2b.cloudsuite.com/basket/external-configurator/",
    "currency": "EUR",
    "locale": "en-US",
    "features": {
      "is3d": false,
      "disableGxle": true,
      "disableRev1x": true,
      "disableTraining": true,
      "disableLogo": false
    }
  },
  {
    "hostname": "testauth.baseballsavings.gloves.custom.rawlings.com",
    "environment": "staging",
    "sportId": "bbsb",
    "vendorId": "baseballsavings",
    "isDisabled": false,
    "vendorName": "Baseball Savings",
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyFlat",
    "addToCartUrl": "https://testauth.baseballsavings.com/RawlingsWorthOrderItemAdd?catalogId=13052&langId=-1&storeId=11152",
    "currency": "USD",
    "locale": "en-US",
    "features": {
      "is3d": false,
      "disableGxle": true,
      "disableRev1x": true,
      "disableTraining": true,
      "disableLogo": false
    }
  },
  {
    "hostname": "testlive.baseballsavings.gloves.custom.rawlings.com",
    "environment": "staging",
    "sportId": "bbsb",
    "vendorId": "baseballsavings",
    "isDisabled": false,
    "vendorName": "Baseball Savings",
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyFlat",
    "addToCartUrl": "https://testlive.baseballsavings.com/RawlingsWorthOrderItemAdd?catalogId=13052&langId=-1&storeId=11152",
    "currency": "USD",
    "locale": "en-US",
    "features": {
      "is3d": false,
      "disableGxle": true,
      "disableRev1x": true,
      "disableTraining": true,
      "disableLogo": false
    }
  },
  {
    "hostname": "prodauth.baseballsavings.gloves.custom.rawlings.com",
    "environment": "staging",
    "sportId": "bbsb",
    "vendorId": "baseballsavings",
    "isDisabled": false,
    "vendorName": "Baseball Savings",
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyFlat",
    "addToCartUrl": "https://prodauth.baseballsavings.com/RawlingsWorthOrderItemAdd?catalogId=13052&langId=-1&storeId=11152&widgetType=Rawlings",
    "currency": "USD",
    "locale": "en-US",
    "features": {
      "is3d": false,
      "disableGxle": true,
      "disableRev1x": true,
      "disableTraining": true,
      "disableLogo": false
    }
  },
  {
    "hostname": "local.baseballsavings.gloves.custom.rawlings.com",
    "environment": "staging",
    "sportId": "bbsb",
    "vendorId": "baseballsavings",
    "isDisabled": false,
    "vendorName": "Baseball Savings",
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyFlat",
    "addToCartUrl": "https://local.baseballsavings.com/RawlingsWorthOrderItemAdd?catalogId=13052&langId=-1&storeId=11152&widgetType=Rawlings",
    "currency": "USD",
    "locale": "en-US",
    "features": {
      "is3d": false,
      "disableGxle": true,
      "disableRev1x": true,
      "disableTraining": true,
      "disableLogo": false
    }
  },
  {
    "hostname": "staging.closeoutbats.gloves.custom.rawlings.com",
    "environment": "staging",
    "sportId": "bbsb",
    "vendorId": "closeoutbats",
    "isDisabled": false,
    "vendorName": "Closeout Bats",
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyFlat",
    "addToCartUrl": "https://www.ytimes.net/closeoutbats/glove-builder/process.php",
    "currency": "USD",
    "locale": "en-US",
    "features": {
      "is3d": false,
      "disableGxle": true,
      "disableRev1x": true,
      "disableTraining": true,
      "disableLogo": false
    }
  },
  {
    "hostname": "staging.scheels.gloves.custom.rawlings.com",
    "environment": "staging",
    "sportId": "bbsb",
    "vendorId": "scheels",
    "isDisabled": false,
    "vendorName": "Scheels",
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyNested",
    "addToCartUrl": "https://dev03-web-scheels.demandware.net/glove-builder",
    "currency": "USD",
    "locale": "en-US",
    "features": {
      "is3d": false,
      "disableGxle": true,
      "disableRev1x": true,
      "disableTraining": true,
      "disableLogo": false
    }
  },
  {
    "hostname": "staging.kahunaverse.gloves.custom.rawlings.com",
    "environment": "staging",
    "sportId": "bbsb",
    "vendorId": "kahunaverse",
    "isDisabled": false,
    "vendorName": "Kahunaverse",
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyNested",
    "addToCartUrl": "https://kahunaverse.staging.nort.ca/rawlings/cart/add",
    "currency": "CAD",
    "locale": "en-US",
    "orderButtonText": "- ",
    "features": {
      "is3d": false,
      "disableGxle": true,
      "disableRev1x": true,
      "disableTraining": true,
      "disableLogo": false
    }
  },
  {
    "hostname": "development.kahunaverse.gloves.custom.rawlings.com",
    "environment": "staging",
    "sportId": "bbsb",
    "vendorId": "kahunaverse",
    "isDisabled": false,
    "vendorName": "Kahunaverse",
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyNested",
    "addToCartUrl": "https://kahunaverse.ipark.dev.nort.ca/rawlings/cart/add",
    "currency": "CAD",
    "locale": "en-US",
    "features": {
      "is3d": false,
      "disableGxle": true,
      "disableRev1x": true,
      "disableTraining": true,
      "disableLogo": false
    }
  },
  {
    "hostname": "staging.homerunsports.gloves.custom.rawlings.com",
    "environment": "staging",
    "sportId": "bbsb",
    "vendorId": "homerunsports",
    "isDisabled": false,
    "vendorName": "Home Run Sports",
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyNested",
    "addToCartUrl": "https://kahunaverse.iamotacloud.com/rawlings-glove-customizer.php",
    "currency": "CAD",
    "locale": "en-US",
    "features": {
      "is3d": false,
      "disableGxle": true,
      "disableRev1x": true,
      "disableTraining": true,
      "disableLogo": false
    }
  },
  {
    "hostname": "staging.evolutionsports.gloves.custom.rawlings.com",
    "environment": "staging",
    "sportId": "bbsb",
    "vendorId": "evolutionsports",
    "isDisabled": false,
    "vendorName": "Evolution Sports",
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyFlat",
    "addToCartUrl": "https://phpstack-606044-2550571.cloudwaysapps.com/glove-customizer.php",
    "currency": "CAD",
    "locale": "en-US",
    "features": {
      "is3d": false,
      "disableGxle": true,
      "disableRev1x": true,
      "disableTraining": true,
      "disableLogo": false
    }
  },
  {
    "hostname": "rawlings-gloves-3d-staging.cdev.orangelv.com",
    "environment": "staging",
    "sportId": "bbsb",
    "vendorId": "rawlings",
    "isDisabled": false,
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyFlat",
    "addToCartUrl": "https://development.rawlings.com/customglove",
    "currency": "USD",
    "locale": "en-US",
    "features": {
      "is3d": true,
      "disableGxle": false,
      "disableRev1x": false,
      "disableTraining": false,
      "disableLogo": false
    }
  },
  {
    "hostname": "development.mylocker360.gloves.custom.rawlings.com",
    "environment": "staging",
    "sportId": "bbsb",
    "vendorId": "rawlings",
    "isDisabled": false,
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyNested",
    "addToCartUrl": "https://localhost:44375/rawlingsmylocker/capturedesign",
    "currency": "USD",
    "locale": "en-US",
    "twitterHandle": "rawlingssports",
    "orderButtonText": "Save",
    "returnToSiteUrl": "https://localhost:3001",
    "returnToSiteName": "mylocker360.rawlings.com",
    "features": {
      "is3d": false,
      "disableGxle": true,
      "disableRev1x": false,
      "disableTraining": false,
      "disableLogo": false
    }
  },
  {
    "hostname": "test.mylocker360.gloves.custom.rawlings.com",
    "environment": "staging",
    "sportId": "bbsb",
    "vendorId": "rawlings",
    "isDisabled": false,
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyNested",
    "addToCartUrl": "https://api-rwls-qa-np.amla.io/rawlingsmylocker/capturedesign",
    "currency": "USD",
    "locale": "en-US",
    "twitterHandle": "rawlingssports",
    "orderButtonText": "Save",
    "returnToSiteUrl": "https://mylocker-rwls-qa-np.amla.io",
    "returnToSiteName": "mylocker360.rawlings.com",
    "features": {
      "is3d": false,
      "disableGxle": true,
      "disableRev1x": false,
      "disableTraining": false,
      "disableLogo": false
    }
  },
  {
    "hostname": "staging.mylocker360.gloves.custom.rawlings.com",
    "environment": "staging",
    "sportId": "bbsb",
    "vendorId": "rawlings",
    "isDisabled": false,
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyNested",
    "addToCartUrl": "https://api-rwls-np.amla.io/rawlingsmylocker/capturedesign",
    "currency": "USD",
    "locale": "en-US",
    "twitterHandle": "rawlingssports",
    "orderButtonText": "Save",
    "returnToSiteUrl": "https://mylocker-rwls-qa-np.amla.io",
    "returnToSiteName": "mylocker360.rawlings.com",
    "features": {
      "is3d": false,
      "disableGxle": true,
      "disableRev1x": false,
      "disableTraining": false,
      "disableLogo": false
    }
  },
  {
    "hostname": "development.en.rawlingscanada.gloves.custom.rawlings.com",
    "environment": "staging",
    "sportId": "bbsb",
    "vendorId": "rawlings",
    "isDisabled": false,
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyFlat",
    "addToCartUrl": "https://staging-jts-teamsports.demandware.net/on/demandware.store/Sites-rawlings-canada-Site/customglove",
    "currency": "CAD",
    "locale": "en-US",
    "returnToSiteUrl": "https://staging-jts-teamsports.demandware.net/on/demandware.store/Sites-rawlings-canada-Site",
    "returnToSiteName": "canada.rawlings.com",
    "features": {
      "is3d": false,
      "disableGxle": false,
      "disableRev1x": false,
      "disableTraining": false,
      "disableLogo": false
    }
  },
  {
    "hostname": "development.fr.rawlingscanada.gloves.custom.rawlings.com",
    "environment": "staging",
    "sportId": "bbsb",
    "vendorId": "rawlings",
    "isDisabled": false,
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyFlat",
    "addToCartUrl": "https://staging-jts-teamsports.demandware.net/on/demandware.store/Sites-rawlings-canada-Site/customglove",
    "currency": "CAD",
    "locale": "fr-CA",
    "returnToSiteUrl": "https://staging-jts-teamsports.demandware.net/on/demandware.store/Sites-rawlings-canada-Site",
    "returnToSiteName": "canada.rawlings.com",
    "features": {
      "is3d": false,
      "disableGxle": false,
      "disableRev1x": false,
      "disableTraining": false,
      "disableLogo": false
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<VendorRow[]>> = data;

export type Vendor = typeof typed[number];

export const VENDOR_INDEX_KEY = "hostname";
export type VendorIndexKey = "hostname";
export type VendorHostname = Vendor["hostname"];

let i = 0;
export const VENDOR_DICT = {
  "customgloves.rawlings.com": typed[i++],
  "rawlings-bbg.cdev.orangelv.com": typed[i++],
  "development.customgloves.rawlings.com": typed[i++],
  "development.gloves.custom.rawlings.com": typed[i++],
  "sandbox01.gloves.custom.rawlings.com": typed[i++],
  "sandbox02.gloves.custom.rawlings.com": typed[i++],
  "sandbox03.gloves.custom.rawlings.com": typed[i++],
  "sandbox04.gloves.custom.rawlings.com": typed[i++],
  "sandbox05.gloves.custom.rawlings.com": typed[i++],
  "development.mylocker.gloves.custom.rawlings.com": typed[i++],
  "gloves.custom.rawlings.com": typed[i++],
  "mylocker.gloves.custom.rawlings.com": typed[i++],
  "teamlocker.gloves.custom.rawlings.com": typed[i++],
  "justballgloves.gloves.custom.rawlings.com": typed[i++],
  "baseballexpress.gloves.custom.rawlings.com": typed[i++],
  "softballcom.gloves.custom.rawlings.com": typed[i++],
  "teamexpress.gloves.custom.rawlings.com": typed[i++],
  "baseballmonkey.gloves.custom.rawlings.com": typed[i++],
  "customglove.gloves.custom.rawlings.com": typed[i++],
  "hq4sports.gloves.custom.rawlings.com": typed[i++],
  "smashitsports.gloves.custom.rawlings.com": typed[i++],
  "fieldrep.smashitsports.gloves.custom.rawlings.com": typed[i++],
  "canada.smashitsports.gloves.custom.rawlings.com": typed[i++],
  "fieldrep.canada.smashitsports.gloves.custom.rawlings.com": typed[i++],
  "hardballfans.gloves.custom.rawlings.com": typed[i++],
  "softballfans.gloves.custom.rawlings.com": typed[i++],
  "basesloaded.gloves.custom.rawlings.com": typed[i++],
  "ebasesloaded.gloves.custom.rawlings.com": typed[i++],
  "apollosports.gloves.custom.rawlings.com": typed[i++],
  "forelle.gloves.custom.rawlings.com": typed[i++],
  "dealer.forelle.gloves.custom.rawlings.com": typed[i++],
  "baseballsavings.gloves.custom.rawlings.com": typed[i++],
  "closeoutbats.gloves.custom.rawlings.com": typed[i++],
  "scheels.gloves.custom.rawlings.com": typed[i++],
  "kahunaverse.gloves.custom.rawlings.com": typed[i++],
  "homerunsports.gloves.custom.rawlings.com": typed[i++],
  "evolutionsports.gloves.custom.rawlings.com": typed[i++],
  "mylocker360.gloves.custom.rawlings.com": typed[i++],
  "staging.gloves.custom.rawlings.com": typed[i++],
  "staging.mylocker.gloves.custom.rawlings.com": typed[i++],
  "staging.teamlocker.gloves.custom.rawlings.com": typed[i++],
  "staging.justballgloves.gloves.custom.rawlings.com": typed[i++],
  "staging.baseballexpress.gloves.custom.rawlings.com": typed[i++],
  "preview.baseballexpress.gloves.custom.rawlings.com": typed[i++],
  "staging.baseballmonkey.gloves.custom.rawlings.com": typed[i++],
  "staging.customglove.gloves.custom.rawlings.com": typed[i++],
  "staging.hq4sports.gloves.custom.rawlings.com": typed[i++],
  "staging.smashitsports.gloves.custom.rawlings.com": typed[i++],
  "staging.canada.smashitsports.gloves.custom.rawlings.com": typed[i++],
  "staging.hardballfans.gloves.custom.rawlings.com": typed[i++],
  "staging.basesloaded.gloves.custom.rawlings.com": typed[i++],
  "staging.ebasesloaded.gloves.custom.rawlings.com": typed[i++],
  "staging.apollosports.gloves.custom.rawlings.com": typed[i++],
  "staging.forelle.gloves.custom.rawlings.com": typed[i++],
  "staging.dealer.forelle.gloves.custom.rawlings.com": typed[i++],
  "testauth.baseballsavings.gloves.custom.rawlings.com": typed[i++],
  "testlive.baseballsavings.gloves.custom.rawlings.com": typed[i++],
  "prodauth.baseballsavings.gloves.custom.rawlings.com": typed[i++],
  "local.baseballsavings.gloves.custom.rawlings.com": typed[i++],
  "staging.closeoutbats.gloves.custom.rawlings.com": typed[i++],
  "staging.scheels.gloves.custom.rawlings.com": typed[i++],
  "staging.kahunaverse.gloves.custom.rawlings.com": typed[i++],
  "development.kahunaverse.gloves.custom.rawlings.com": typed[i++],
  "staging.homerunsports.gloves.custom.rawlings.com": typed[i++],
  "staging.evolutionsports.gloves.custom.rawlings.com": typed[i++],
  "rawlings-gloves-3d-staging.cdev.orangelv.com": typed[i++],
  "development.mylocker360.gloves.custom.rawlings.com": typed[i++],
  "test.mylocker360.gloves.custom.rawlings.com": typed[i++],
  "staging.mylocker360.gloves.custom.rawlings.com": typed[i++],
  "development.en.rawlingscanada.gloves.custom.rawlings.com": typed[i++],
  "development.fr.rawlingscanada.gloves.custom.rawlings.com": typed[i++]
} as const;

export { typed as VENDORS };
