import Button from './Button'
import Icon from './Icon'

import './Share.css'

const Share = (props) => (
  <div className="share">
    {props.items.map((x) => (
      <div key={x.label} className="share-icon">
        <a href={x.href} target="_blank" rel="noopener noreferrer">
          <Button classMods={['onlyIcon', 'gray']} onClick={x.onClick}>
            <Icon name={x.icon} title={x.label} />
          </Button>
        </a>
      </div>
    ))}
  </div>
)

export default Share
