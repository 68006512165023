import cn from 'classnames'
import React from 'react'

import Icon from './Icon'

import './TileCard.css'

function TileCard({
  imageSrc,
  name,
  text,
  tileInfo,
  textImportant,
  onClick,
  isSelected,
  style = {},
  extras,
}) {
  return (
    <div
      className={cn('tileCard', { isSelected })}
      onClick={onClick}
      style={style}
    >
      <div className="tileCard-content">
        <div className="tileCard-image">
          <div className="tileCard-imageWrapper">
            {imageSrc && <img src={imageSrc} alt="" />}

            {isSelected && (
              <div className="tileCard-checkmark">
                <Icon name="checkmark" />
              </div>
            )}
          </div>
        </div>

        <div className="tileCard-texts">
          <div className="tileCard-name">{name}</div>
          <div
            className="tileCard-text"
            dangerouslySetInnerHTML={{ __html: text }}
          />
          <div className="tileCard-tileInfo">
            <div>
              {tileInfo?.map(([title, value]) => (
                <div key={title}>
                  <p className="tileCard-noMargin">
                    <strong>{title}</strong> {value}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className="tileCard-textImportant">{textImportant}</div>
        </div>

        {extras && <div className="tileCard-extras">{extras}</div>}
      </div>
    </div>
  )
}

TileCard.defaultProps = {
  isSelected: false,
}

export default TileCard
