import fp from 'lodash/fp.js'
import { concat } from 'redux-fp'

import renderCompositeUpdater from '~p/client/renderComposite/updater'

import {
  createViewNameUpdater,
  focusModeUpdater,
} from '~rawlings/client/updaters'

import controlTree from './controlTree'
import { defaultViewAngleId } from '../viewAngles'

const initialState = {
  renderComposite: { activeView: defaultViewAngleId },
}

const getDefaultState = () => (state) => fp.assign(initialState, state)

export default concat(
  getDefaultState,
  controlTree.updater,
  (action) => fp.update('renderComposite', renderCompositeUpdater(action)),
  createViewNameUpdater('renderComposite.activeView'),
  focusModeUpdater,
)
