import React from 'react'

import { cn } from '~p/client/components/utils'

import './Icon.css'

function Icon({ name, inverted = false }) {
  return (
    <svg className={cn(['icon', `mod-${name}`, inverted && 'mod-inverted'])}>
      <use xlinkHref={`#rw-icon-${name}`} />
    </svg>
  )
}

export default Icon
