import { isObject } from '@technically/lodash'
import React from 'react'

import './Summary.css'

const Row = ({ id, label, value, details }) => (
  <div key={id} className="summary-row">
    <div className="summary-details">
      <div className="summary-label">{label}</div>
      <div className="summary-value">
        {isObject(value) && value.filename ?
          <a
            href={`/api/images/${value.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {value.filename}
          </a>
        : value}
      </div>
    </div>
    {details && details.map(Row)}
  </div>
)

const Section = ({ id, label, details }) => (
  <div key={id} className="summary-section">
    <div className="summary-sectionTitle">{label}</div>
    {details.map(Row)}
  </div>
)

const Summary = ({ sections }) => (
  <div className="summary">{sections.map(Section)}</div>
)

export default Summary
