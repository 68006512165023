import {
  type DynamicTexture,
  type IMouseEvent,
  PointerEventTypes,
  type PointerInfo,
  type Vector2,
} from '@babylonjs/core';
import { ok } from '@orangelv/utils';
import type { fabric as fabricLibrary } from 'fabric';

import getModelIdByMesh from './get-model-id-by-mesh.js';
import { getState } from './state.js';
import type { Ref, State } from './types.js';

let previouslyDispatchedToFabric:
  | {
      fabric: fabricLibrary.Canvas;
      event: MouseEvent;
      dynamicTexture: DynamicTexture;
      textureCoordinates: Vector2;
    }
  | undefined;

const isFabricValid = (
  fabric: fabricLibrary.Canvas,
): fabric is fabricLibrary.Canvas &
  Required<Pick<fabricLibrary.Canvas, 'upperCanvasEl' | 'contextTop'>> =>
  !!fabric.upperCanvasEl && !!fabric.contextTop;

const cancelFabricUserSelection = (): void => {
  if (!previouslyDispatchedToFabric) return;

  const { fabric, event, dynamicTexture, textureCoordinates } =
    previouslyDispatchedToFabric;

  if (!isFabricValid(fabric)) return;

  previouslyDispatchedToFabric = undefined;

  const activeObject = fabric.getActiveObject();
  if (activeObject) {
    fabric.discardActiveObject();
    fabric.renderAll();
  }

  if (event.type === 'mousemove') {
    const nextEvent = new MouseEvent('mouseup', event);

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    dispatchFabricMouseEvent(
      fabric,
      nextEvent,
      dynamicTexture,
      textureCoordinates,
    );
  }
};

const dispatchFabricMouseEvent = (
  fabric: fabricLibrary.Canvas,
  originalEvent: IMouseEvent,
  dynamicTexture: DynamicTexture,
  textureCoordinates: Vector2,
): void => {
  if (!isFabricValid(fabric)) return;

  const canvasWidth = fabric.width;
  const canvasHeight = fabric.height;

  ok(typeof canvasWidth === 'number' && typeof canvasHeight === 'number');

  const offset = fabric._offset;

  const clientX = Math.round(textureCoordinates.x * canvasWidth + offset.left);
  const clientY = Math.round(textureCoordinates.y * canvasHeight + offset.top);

  const eventName = originalEvent.type.replace('pointer', 'mouse');

  const event = new MouseEvent(eventName, {
    screenX: 0,
    screenY: 0,
    clientX,
    clientY,
    ctrlKey: originalEvent.ctrlKey,
    shiftKey: originalEvent.shiftKey,
    altKey: originalEvent.altKey,
    metaKey: originalEvent.metaKey,
    button: originalEvent.button,
    buttons: originalEvent.buttons,
    bubbles: true, // The canvas freaks out without this
  });

  // Move the object from one fabric to another.
  if (event.buttons === 1) {
    const previousTexture = previouslyDispatchedToFabric?.dynamicTexture;

    if (previousTexture?.name !== dynamicTexture.name) {
      cancelFabricUserSelection();

      // Select the object on the next fabric.
      const downEvent = new MouseEvent('mousedown', event);
      fabric.upperCanvasEl.dispatchEvent(downEvent);
    }
  }

  previouslyDispatchedToFabric = {
    fabric,
    event,
    dynamicTexture,
    textureCoordinates,
  };

  fabric.upperCanvasEl.dispatchEvent(event);
};

const addPointer = (stateRef: Ref<State>) => (): void => {
  const { scene } = getState(stateRef);

  ok(scene);

  const pointerListener = (pointerInfo: PointerInfo): void => {
    const { props } = getState(stateRef);

    const isPointerDown = pointerInfo.type === PointerEventTypes.POINTERDOWN;
    const isPointerUp = pointerInfo.type === PointerEventTypes.POINTERUP;
    const isPointerDoubleTap =
      pointerInfo.type === PointerEventTypes.POINTERDOUBLETAP;
    const isPointerMove = pointerInfo.type === PointerEventTypes.POINTERMOVE;
    const isPointerWheel = pointerInfo.type === PointerEventTypes.POINTERWHEEL;

    if (props.onCameraReset && (isPointerDown || isPointerWheel)) {
      props.onCameraReset();
    }

    const originalEvent = pointerInfo.event;

    const state = getState(stateRef);

    ok(state.scene);

    const pickResult = state.scene.pick(
      state.scene.pointerX,
      state.scene.pointerY,
    );

    const { pickedMesh } = pickResult;

    if (!pickedMesh) {
      cancelFabricUserSelection();

      return;
    }

    const modelId = getModelIdByMesh(stateRef)(pickedMesh);

    if (isPointerDown || isPointerUp || isPointerMove) {
      const modelState = modelId === '' ? undefined : state.models[modelId];

      ok(modelState);

      const { material } = pickedMesh;

      ok(material, 'Picked mesh has no material!');

      const materialId = material.id;

      const fabric = modelState.fabrics[materialId];

      if (!fabric) {
        return;
      }

      const dynamicTexture =
        modelState.dynamicTextures[materialId]?.diffuseTexture;

      ok(dynamicTexture, 'Picked mesh has no dynamic texture!');

      const textureCoordinates = pickResult.getTextureCoordinates();

      if (!textureCoordinates) {
        return;
      }

      dispatchFabricMouseEvent(
        fabric,
        originalEvent,
        dynamicTexture,
        textureCoordinates,
      );
    }

    if (isPointerDoubleTap) {
      if (modelId === '') {
        return;
      }

      if (props.onMeshPicked) {
        props.onMeshPicked(pickedMesh.id, modelId);
      }
    }
  };

  scene.onPointerObservable.add(pointerListener);
};

export default addPointer;
