import { Immutable, isDefined } from '@orangelv/utils'
import {
  RendererConfig,
  MaterialConfig,
  ModelConfig,
} from '@orangelv/bjs-renderer'

import getAsset from '../../../platform/getAsset'
import assert from '../../../platform/assert'

import {
  Web,
  WebId,
  LeatherColor,
  PatchColor,
  Glove,
  GloveModel,
  GLOVE_MODEL_DICT,
  GLOVE_ASSET_DICT,
  TrimId,
} from '../common/sheets'
import { Nodes } from '../common/typings'
import loadImage from './loadImage'
import getVectorTextTexture from './getVectorTextTexture'
import getBitmapTextTexture from './getBitmapTextTexture'
import getFlagTexture from './getFlagTexture'
import onAfterSceneCreated from './onAfterSceneCreated'
import onAfterMaterialsUpdated from './onAfterMaterialsUpdated'

type Model = 'glove' | 'web'

const getMaterialFromColor = (
  color: LeatherColor,
  replaceMaterial = true,
): MaterialConfig => {
  if (!color) {
    return undefined
  }

  const diffuseColor = color.props.hex

  if (color.props.isMetallic) {
    return {
      materialId: replaceMaterial ? 'metallic_mat' : undefined,
      metallic: 1,
      diffuseColor,
    }
  }

  return {
    diffuseColor,
  }
}

const getOvalLocation = (gloveModel: GloveModel) => {
  if (gloveModel.id === 'CM33') {
    return 'glove.trim'
  } else if (gloveModel.id === 'FL12TR') {
    return 'glove.shellBack'
  }
  return 'web.back'
}

const getRendererConfig = (
  nodes: Nodes,
  defaultCamera?: string,
): RendererConfig => {
  const gloveNode = nodes['product.glove']

  const throwingHandNode = nodes['product.throwingHand']
  // Right hand is the default, without scaling and flipping.
  const isLeftHand = throwingHandNode.value === 'left'

  const fitNode = nodes['product.fit']
  const isNarrowFit = fitNode.value === 'narrow'

  const leatherNode = nodes['product.leather']

  const shellBackColorNode = nodes['colors.shellBack.leatherColor']
  const shellBackDesignNode = nodes['colors.shellBack.design']
  const shellBackDesignValue: 'leather' | 'pro' | 'deco' =
    shellBackDesignNode.value
  const shellBackMaterialNode = nodes['colors.shellBack.material']

  const backPalmColorNode = nodes['colors.backPalm']
  const shellPalmColorNode = nodes['colors.shellPalm']
  const fingerLacesColorNode = nodes['colors.laces.fingerWeb']
  const heelLacesColorNode = nodes['colors.laces.heel']
  const weltingBackColorNode = nodes['colors.welting.back']
  const weltingPalmColorNode = nodes['colors.welting.palm']
  const bindingColorNode = nodes['colors.binding']
  const trimStyleNode = nodes['colors.trim.style']
  const trimColorNode = nodes['colors.trim.color']
  const stampingColorNode = nodes['colors.stamping']
  const stitchingColorNode = nodes['colors.stitching']
  const liningColorNode = nodes['colors.lining']
  const logoPatchNode = nodes['colors.logoPatch']
  const flagNode = nodes['personalization.flag']
  const embroideryColorNode = nodes['personalization.embroidery.color']
  const embroideryLogoNode = nodes['personalization.embroidery.logo']
  const embroideryNumberFontNode =
    nodes['personalization.embroidery.number.font']
  const embroideryNumberTextNode =
    nodes['personalization.embroidery.number.text']
  const embroideryPinkyFontNode = nodes['personalization.embroidery.pinky.font']
  const embroideryPinkyTextNode = nodes['personalization.embroidery.pinky.text']
  const embroideryThumbFontNode = nodes['personalization.embroidery.thumb.font']
  const embroideryThumbTextNode = nodes['personalization.embroidery.thumb.text']

  const trimStyle: TrimId = trimStyleNode.value

  const webColorNode = nodes['colors.web.color']
  const webStyleNode = nodes['colors.web.style']

  const patchColor =
    logoPatchNode.isAvailable ? (logoPatchNode.object as PatchColor) : undefined

  const glove = gloveNode.object as Glove
  const gloveModel = GLOVE_MODEL_DICT[glove.modelId]
  const gloveAsset = GLOVE_ASSET_DICT[glove.asset.gloveAssetId]
  const web = webStyleNode.object as Web

  const hasShellBackMaterial =
    glove.limitations.shellBackDesignIds.pro ||
    glove.limitations.shellBackDesignIds.deco

  const ovalLocation = getOvalLocation(gloveModel)

  const hasEmbroideryLogo = !!embroideryLogoNode.value
  const hasEmbroideryNumber = !!embroideryNumberTextNode.value
  const hasEmbroideryOvalR = !hasEmbroideryLogo && !hasEmbroideryNumber
  const hasEmroideryPinkyText = !!embroideryPinkyTextNode.value
  const hasEmroideryThumbText = !!embroideryThumbTextNode.value
  const hasExtraStitching = !!(
    shellBackMaterialNode.value &&
    shellBackMaterialNode.object.props.hasExtraStitching
  )

  const fingerPadNode = nodes['options.fingerPadHood.fingerPad']
  const fingerHoodNode = nodes['options.fingerPadHood.fingerHood']
  const fingerPadHoodPositionNode = nodes['options.fingerPadHood.position']
  const hasFingerHood = fingerHoodNode.value === 'yes'
  const hasFingerPad =
    !!fingerPadNode.value &&
    // There's logic where we want to hide the finger pad if the finger hood is selected.
    // TODO: This hood replaces pad logic should be moved to control tree so renderers don't have to deal with it.
    !hasFingerHood
  const fingerPadHoodPosition = fingerPadHoodPositionNode.value

  const breakInNode = nodes['options.breakIn']
  const palmPadNode = nodes['options.palmPad']
  const sweatbandNode = nodes['options.sweatband']

  // TODO: The missing webs should be added to assets and isWebMissing logic should be removed.
  const webIdsMissing: WebId[] = [
    // 'ProH',
    // 'VerticalHB',
    'ModTrapezeLoop',
    'DoubleLacedBasket',
    // 'Laced1PS',
    // 'Laced2PS',
    // 'ProV',
    // 'ModSinglePost',
    // 'SinglePwXLace',
  ]
  const isWebMissing = !!web && webIdsMissing.includes(web.id)
  // There are gloves like FL12TR that don't have any web at all.
  const hasWeb = !!web && !isWebMissing

  const hasNarrowFit = glove.limitations.fitIds.narrow
  const hasTrimOnWeb = gloveModel.hasTrimOnWeb

  const getGloveFingerPadHoodMeshes = (): ModelConfig['meshes'] => {
    if (!gloveModel.hasFingerPadHood) {
      return
    }

    return {
      ...(glove.limitations.fingerIds.index ?
        {
          iFingerPad: {
            isVisible: hasFingerPad && fingerPadHoodPosition === 'index',
          },
        }
      : {}),
      ...(glove.limitations.fingerIds.middle ?
        {
          mFingerPad: {
            isVisible: hasFingerPad && fingerPadHoodPosition === 'middle',
          },
        }
      : {}),
      ...(glove.limitations.fingerHood && glove.limitations.fingerIds.index ?
        {
          iFingerHood: {
            isVisible: hasFingerHood && fingerPadHoodPosition === 'index',
          },
        }
      : {}),
      ...(glove.limitations.fingerHood && glove.limitations.fingerIds.middle ?
        {
          mFingerHood: {
            isVisible: hasFingerHood && fingerPadHoodPosition === 'middle',
          },
        }
      : {}),
    }
  }

  const getGloveFingerPadHoodMaterials = (): ModelConfig['materials'] => {
    if (!gloveModel.hasFingerPadHood) {
      return
    }

    const fingerPadMat = getMaterialFromColor(
      fingerPadNode.value === 'shell' ?
        shellBackColorNode.object
      : trimColorNode.object,
    )

    const fingerHoodMat = getMaterialFromColor(
      // TODO: This rev1x coloring logic should be moved to control tree so renderers don't have to deal with it.
      leatherNode.value === 'rev1x' ?
        trimColorNode.object
      : shellBackColorNode.object,
    )

    return {
      iFingerPad_mat:
        glove.limitations.fingerIds.index ? fingerPadMat : undefined,
      mFingerPad_mat:
        glove.limitations.fingerIds.middle ? fingerPadMat : undefined,
      iFingerHood_mat:
        glove.limitations.fingerHood && glove.limitations.fingerIds.index ?
          fingerHoodMat
        : undefined,
      mFingerHood_mat:
        glove.limitations.fingerHood && glove.limitations.fingerIds.middle ?
          fingerHoodMat
        : undefined,
      iFingerPadWithHood_mat:
        glove.limitations.fingerIds.index && gloveModel.hasShellBackLaces ?
          fingerPadMat
        : undefined,
    }
  }

  const getGloveTrimMeshes = (): ModelConfig['meshes'] => {
    if (!hasNarrowFit) {
      return {}
    }

    return {
      trim: { isVisible: !isNarrowFit },
      trim_narrowFit: { isVisible: isNarrowFit },
    }
  }

  const getGloveWeltingMeshes = (): ModelConfig['meshes'] => {
    const { shellBack } = gloveModel
    const weltingMeshes: Record<string, { ids: string[]; isVisible: boolean }> =
      {}

    // Collect all welting meshes in a single pass
    for (const [id, { glove }] of Object.entries(shellBack ?? {})) {
      for (const mesh of glove ?? []) {
        if (typeof mesh === 'string' && mesh.startsWith('welting')) {
          if (!weltingMeshes[mesh]) {
            weltingMeshes[mesh] = {
              ids: [],
              isVisible: false,
            }
          }
          weltingMeshes[mesh].ids.push(id)
          weltingMeshes[mesh].isVisible =
            weltingMeshes[mesh].isVisible || id === shellBackDesignValue
        }
      }
    }

    return Object.fromEntries(
      Object.entries(weltingMeshes).map(([meshId, { isVisible }]) => [
        meshId,
        {
          isVisible,
        },
      ]),
    )
  }

  const getTrimMaterials = (model: Model): ModelConfig['materials'] => {
    if (!gloveModel.trim) {
      return {}
    }

    // All materials in gloveModels sheet under allOnGlove and allOnWeb will have the shellBack color.
    // Based on selected trimStyle, trim materials will have the trim color.
    const allMaterials = Object.fromEntries(
      (
        gloveModel.trim[model === 'glove' ? 'allOnGlove' : 'allOnWeb'] ?? []
      ).map((materialId: string) => [
        model === 'glove' ? materialId : `${web.id}_${materialId}`,
        getMaterialFromColor(shellBackColorNode.object),
      ]),
    )
    const trimMaterials = Object.fromEntries(
      (gloveModel.trim[trimStyle]?.[model] ?? []).map((materialId: string) => [
        model === 'glove' ? materialId : `${web.id}_${materialId}`,
        getMaterialFromColor(trimColorNode.object),
      ]),
    )

    return { ...allMaterials, ...trimMaterials }
  }

  const getTrimFingerIds = (trimStyle?: TrimId) => {
    const materialIds: Immutable<string[]> =
      (trimStyle ?
        gloveModel.trim?.[trimStyle]?.glove
      : gloveModel.trim?.allOnGlove) ?? []

    return (
      materialIds
        .filter((materialId) => materialId.match(/finger\d{1,2}_mat/) !== null)
        .map((materialId) =>
          parseInt(materialId.slice('finger'.length, -'_mat'.length), 10),
        ) ?? []
    )
  }

  const isMaterialInTrim = (materialId: string) => {
    if (!trimStyle || !gloveModel.trim) {
      return false
    }
    const materialIds: Immutable<string[]> =
      gloveModel.trim[trimStyle]?.glove ?? []
    return !!materialIds.includes(materialId)
  }

  const getGloveColorMaterials = (): ModelConfig['materials'] => {
    return {
      backPalm_mat: getMaterialFromColor(backPalmColorNode.object),
      fingerLaces_mat: getMaterialFromColor(fingerLacesColorNode.object),
      fingerLacesInside_mat: getMaterialFromColor(fingerLacesColorNode.object),
      heelLaces_mat: getMaterialFromColor(heelLacesColorNode.object),
      heelLacesInside_mat: getMaterialFromColor(heelLacesColorNode.object),
      binding_mat: getMaterialFromColor(bindingColorNode.object),

      // Usually shellPalm is on the web, but if there isn't a web, like for FL12TR, it's on the glove and we must colorize that instead.
      ...(hasWeb || isWebMissing ?
        {}
      : {
          shellPalm_mat: getMaterialFromColor(shellPalmColorNode.object),
        }),
      // Condition to show decoMesh for webs on gloves with decoMesh
      ...((
        glove.limitations.shellBackDesignIds.deco && gloveModel.hasDecoProOnWeb
      ) ?
        {
          [`${web.id}_decoMesh_mat`]: getMaterialFromColor(
            shellBackColorNode.object,
          ),
        }
      : {}),
    }
  }

  const getGloveWeltingMaterials = (): ModelConfig['materials'] => {
    const weltingBackMat = getMaterialFromColor(weltingBackColorNode.object)
    const weltingPalmMat = getMaterialFromColor(weltingPalmColorNode.object)
    const weltingMeshes = getGloveWeltingMeshes()

    const initialMaterials = {
      weltingBack_mat: weltingBackMat,
      weltingPalm_mat: weltingPalmMat,
      weltingPalm_decoMesh_mat: weltingPalmMat,
      weltingPalm_proMesh_mat: weltingPalmMat,
      weltingBack_proMesh_mat: weltingBackMat,
      weltingBack_decoMesh_mat: weltingBackMat,
    }

    // Filter out materials that don't have matching meshes
    return Object.fromEntries(
      Object.entries(initialMaterials).map(([key, value]) => {
        const meshName = key.replace('_mat', '')
        const mesh = weltingMeshes?.[meshName]
        const finalValue = mesh?.isVisible ? value : undefined

        return [key, finalValue]
      }),
    )
  }

  const getStitchingMaterial = (
    stitchingParams: {
      materialId?: string
      area?: string
    } = {},
  ): MaterialConfig => {
    if (stitchingColorNode.value !== 'toneOnTone') {
      return getMaterialFromColor(stitchingColorNode.object)
    }

    const areaColorMap: Record<string, { object: any }> = {
      shellPalm: shellPalmColorNode,
      lining: liningColorNode.value ? liningColorNode : shellBackColorNode,
      backPalm: backPalmColorNode,
      web: webColorNode,
      trim: trimColorNode,
    }

    if (stitchingParams.area && areaColorMap[stitchingParams.area]) {
      return getMaterialFromColor(areaColorMap[stitchingParams.area].object)
    }

    if (
      stitchingParams.materialId &&
      isMaterialInTrim(stitchingParams.materialId)
    ) {
      return getMaterialFromColor(trimColorNode.object)
    }

    return getMaterialFromColor(shellBackColorNode.object)
  }

  const getGloveStitchingMaterials = (): ModelConfig['materials'] => {
    const { hasSeparatedStitching, shellBack } = gloveModel

    if (!hasSeparatedStitching) {
      return {
        stitching_mat: getStitchingMaterial({ area: 'stitching_mat' }),
      }
    }

    return {
      embroideryOvalR_stitching_mat: getStitchingMaterial({
        materialId: 'embroideryOvalR_stitching_mat',
      }),
      stitching_mat: getStitchingMaterial({ materialId: 'stitching_mat' }),
      shellBack_stitching_mat: getStitchingMaterial({
        materialId: 'stitching_mat',
      }),

      lining_stitching_mat: getStitchingMaterial({ area: 'lining' }),
      shellPalm_stitching_mat: getStitchingMaterial({ area: 'shellPalm' }),

      ...Object.fromEntries(
        getTrimFingerIds().map((fingerId) => [
          `finger${fingerId}_stitching_mat`,
          getStitchingMaterial(),
        ]),
      ),
      ...Object.fromEntries(
        getTrimFingerIds(trimStyle).map((fingerId) => [
          `finger${fingerId}_stitching_mat`,
          getStitchingMaterial({ area: 'trim' }),
        ]),
      ),

      ...(gloveModel.hasBackPalmStitching ?
        {
          backPalm_stitching_mat: getStitchingMaterial({ area: 'backPalm' }),
        }
      : {}),

      ...(gloveModel.hasBackLeftStitching && {
        back_left_stitching_mat: getStitchingMaterial({ area: 'back_left' }),
        back_stitching_mat: getStitchingMaterial({
          materialId: 'back_stitching_mat',
        }),
      }),
      ...(gloveModel.hasTrimStitching ?
        isNarrowFit ?
          {
            trim_narrowFit_stitching_mat: getStitchingMaterial({
              materialId: 'trim_narrowFit_stitching_mat',
            }),
          }
        : {
            trim_stitching_mat: getStitchingMaterial({
              materialId: 'trim_stitching_mat',
            }),
          }
      : {}),

      ...(gloveModel.hasFingerPadHood ?
        {
          iFingerPad_stitching_mat:
            glove.limitations.fingerIds.index ?
              getStitchingMaterial({ area: fingerPadNode.value })
            : undefined,
          mFingerPad_stitching_mat:
            glove.limitations.fingerIds.middle ?
              getStitchingMaterial({ area: fingerPadNode.value })
            : undefined,
          iFingerHood_stitching_mat:
            glove.limitations.fingerHood && glove.limitations.fingerIds.index ?
              getStitchingMaterial()
            : undefined,
          mFingerHood_stitching_mat:
            glove.limitations.fingerHood && glove.limitations.fingerIds.middle ?
              getStitchingMaterial()
            : undefined,
          iFingerPadWithHood_stitching_mat:
            (
              glove.limitations.fingerHood &&
              glove.limitations.fingerIds.index &&
              gloveModel.hasShellBackLaces
            ) ?
              getStitchingMaterial({
                materialId: 'iFingerPadWithHood_stitching_mat',
              })
            : undefined,
        }
      : {}),

      shellBack_proMesh_stitching_mat:
        shellBack?.pro ? getStitchingMaterial() : undefined,

      shellBack_decoMesh_stitching_mat:
        shellBack?.deco ? getStitchingMaterial() : undefined,

      shellBack_decoMesh_extra_stitching_mat:
        shellBack?.deco && hasExtraStitching ?
          getStitchingMaterial()
        : undefined,

      shellBack_decoMesh_OvalR_extra_stitching_mat:
        shellBack?.deco && hasExtraStitching ?
          getStitchingMaterial()
        : undefined,
    }
  }

  const getWebStitchingMaterials = (): ModelConfig['materials'] => {
    const { shellBack } = gloveModel

    return {
      [`${web.id}_stitching_mat`]: getStitchingMaterial({ area: 'web' }),

      [`${web.id}_decoMesh_stitching_mat`]:
        shellBack?.deco ? getStitchingMaterial() : undefined,
    }
  }

  const getGloveStampingMeshes = (): ModelConfig['meshes'] => {
    return {
      ...(gloveModel.hasR2G ?
        {
          stamping_R2G: {
            isVisible: breakInNode.value === 'R2G',
          },
        }
      : {}),

      ...(gloveModel.hasPalmPadLeather ?
        {
          stamping_PalmPadLeather: {
            isVisible: palmPadNode.value === 'leather',
          },
        }
      : {}),
      ...(gloveModel.hasFastback ?
        { stamping_Fastback: { isVisible: !(hasFingerHood || hasFingerPad) } }
      : {}),
    }
  }

  const getGloveStampingMaterials = (): ModelConfig['materials'] => {
    return {
      stamping_Leather_mat: {
        metallic: 1,
        roughness: 0.5,
        normalTexture: {
          url: getAsset(
            `textures/stamps/leathers/${leatherNode.value}_normal.png`,
          ),
        },
        diffuseTexture: {
          getCanvas: async (canvas) => {
            const imageElement = await loadImage(
              getAsset(
                `textures/stamps/leathers/${leatherNode.value}_diffuse.png`,
              ),
            )

            canvas.width = imageElement.width
            canvas.height = imageElement.height

            const context = canvas.getContext('2d')
            assert(context)

            context.drawImage(imageElement, 0, 0, canvas.width, canvas.height)

            context.globalCompositeOperation = 'source-in'
            context.fillStyle = stampingColorNode.object.props.hex
            context.fillRect(0, 0, canvas.width, canvas.height)
          },
          key: stampingColorNode.value,
        },
        flipX: isLeftHand,
      },

      stamping_RawlingsLogo_mat: {
        ...getMaterialFromColor(stampingColorNode.object, false),
        diffuseTexture: {
          url: getAsset(`models/${glove.modelId}/stamping_RawlingsLogo.png`),
        },
        flipX: isLeftHand,
      },

      stamping_Professional_mat: {
        ...getMaterialFromColor(stampingColorNode.object, false),
        diffuseTexture: {
          url: getAsset(
            `models/${glove.modelId}/stamping_Professional_mat.png`,
          ),
        },
        flipX: isLeftHand,
      },

      stamping_USSteerhide_mat: {
        ...getMaterialFromColor(stampingColorNode.object, false),
        diffuseTexture: {
          url: getAsset(`models/${glove.modelId}/stamping_USSteerhide_mat.png`),
        },
        flipX: isLeftHand,
      },

      stamping_Name_mat: {
        ...getMaterialFromColor(stampingColorNode.object, false),
        flipX: isLeftHand,
      },

      stamping_Fastback_mat:
        gloveModel.hasFastback ?
          {
            ...getMaterialFromColor(stampingColorNode.object, false),
            diffuseTexture: {
              url: getAsset(`models/${glove.modelId}/stamping_Fastback.png`),
            },
            flipX: isLeftHand,
          }
        : undefined,

      stamping_LiteToe_mat:
        gloveModel.hasLiteToe ?
          {
            ...getMaterialFromColor(stampingColorNode.object, false),
            diffuseTexture: {
              url: getAsset(`models/${glove.modelId}/stamping_LiTeToe.png`),
            },
            flipX: isLeftHand,
          }
        : undefined,

      stamping_R2G_mat:
        gloveModel.hasR2G ?
          {
            ...getMaterialFromColor(stampingColorNode.object, false),
            diffuseTexture: {
              url: getAsset(`models/${glove.modelId}/stamping_R2G.png`),
            },
            flipX: isLeftHand,
          }
        : undefined,

      stamping_PalmPadLeather_mat:
        gloveModel.hasPalmPadLeather ?
          {
            ...getMaterialFromColor(stampingColorNode.object, false),
            flipX: isLeftHand,
            diffuseTexture: {
              url: getAsset(
                `models/${glove.modelId}/stamping_PalmPadLether.png`,
              ),
            },
          }
        : undefined,
    }
  }

  const getGloveLiningMaterials = (): ModelConfig['materials'] => {
    let liningMat: MaterialConfig
    if (!liningColorNode.isAvailable) {
      liningMat = getMaterialFromColor(shellBackColorNode.object)
    } else if (liningColorNode.value === 'shellPalmColor') {
      liningMat = getMaterialFromColor(shellPalmColorNode.object)
    } else {
      liningMat = getMaterialFromColor(liningColorNode.object)
    }

    return {
      lining_mat: liningMat,
    }
  }

  const getGloveSweatbandMeshes = (): ModelConfig['meshes'] => {
    if (!gloveModel.hasSweatband) {
      return
    }

    const isSweatband = sweatbandNode.value === 'yes'

    return {
      sweatband: {
        isVisible: isSweatband,
      },
      sweatband_fur: {
        isVisible: isSweatband,
      },
      fur: {
        isVisible: !isSweatband,
      },
    }
  }

  const getShellBackMeshes = (model: Model): ModelConfig['meshes'] => {
    const meshIdsToHide = Object.entries(gloveModel.shellBack ?? {})
      .flatMap(([, { glove, web }]) => (model === 'glove' ? glove : web))
      .filter(isDefined)
    const meshIdsToShow = Object.entries(gloveModel.shellBack ?? {})
      .filter(([id]) => id === shellBackDesignValue)
      .flatMap(([, { glove, web }]) => (model === 'glove' ? glove : web))
      .filter(isDefined)
      .filter((meshId) =>
        // Logic for filtering out regular or OvalR mesh.
        hasEmbroideryOvalR ?
          meshId !== 'back' &&
          meshId !== 'proMesh' &&
          meshId !== 'shellBack_decoMesh' &&
          meshId !== 'shellBack_decoMesh_extra_mesh' &&
          meshId !== 'shellBack_decoMesh_extra_stitching' &&
          meshId !== 'shellBack_decoMesh_extra_backEdge'
        : meshId !== 'back_OvalR' &&
          meshId !== 'proMesh_OvalR' &&
          meshId !== 'shellBack_decoMesh_OvalR' &&
          meshId !== 'shellBack_decoMesh_OvalR_backEdge' &&
          meshId !== 'shellBack_decoMesh_OvalR_extra_backEdge' &&
          meshId !== 'shellBack_decoMesh_OvalR_extra_mesh' &&
          meshId !== 'shellBack_decoMesh_OvalR_extra_stitching',
      )
      .filter((meshId) =>
        shellBackMaterialNode.value === 'carbonFiberBlack' ?
          meshId !== 'shellBack_decoMesh' &&
          meshId !== 'shellBack_decoMesh_OvalR'
        : meshId !== 'shellBack_decoMesh_extra_mesh' &&
          meshId !== 'shellBack_decoMesh_OvalR_extra_mesh',
      )

    type MaterialIds = typeof meshIdsToShow | typeof meshIdsToHide

    const materialId = `shellBack_${shellBackMaterialNode.value}_mat`
    const meshIdsNeedingMaterial: MaterialIds = [
      'shellBack_proMesh',
      'shellBack_decoMesh',
      'shellBack_decoMesh_extra_mesh',
      'shellBack_decoMesh_OvalR',
      'shellBack_decoMesh_OvalR_extra_mesh',
    ]
    const meshIdsForExtraStitching: MaterialIds = [
      'shellBack_decoMesh_extra_backEdge',
      'shellBack_decoMesh_extra_stitching',
      'shellBack_decoMesh_OvalR_extra_backEdge',
      'shellBack_decoMesh_OvalR_extra_mesh',
      'shellBack_decoMesh_OvalR_extra_stitching',
    ]

    const meshConfig = {
      ...Object.fromEntries(
        meshIdsToHide.map((meshId) => [
          model === 'glove' ? meshId : `${web.id}_${meshId}`,
          {
            isVisible: false,
          },
        ]),
      ),

      ...Object.fromEntries(
        meshIdsToShow.map((meshId) => [
          model === 'glove' ? meshId : `${web.id}_${meshId}`,
          {
            isVisible:
              meshIdsForExtraStitching.includes(meshId) ? hasExtraStitching : (
                true
              ),
            materialId:
              meshIdsNeedingMaterial.includes(meshId) ? materialId : undefined,
          },
        ]),
      ),
    }

    console.log(`shellBack meshConfig for ${model}`, meshConfig)

    return meshConfig
  }

  const getGloveShellBackMaterials = (): ModelConfig['materials'] => {
    const shellBackMat = getMaterialFromColor(shellBackColorNode.object)

    const materialIds = gloveModel.shellBack?.materials ?? []

    return Object.fromEntries(
      materialIds.map((materialId) => [materialId, shellBackMat]),
    )
  }

  const getGloveMeshes = () => {
    return {
      __root__: {
        scaling: { x: isLeftHand ? -1 : 1 },
      },

      ground: {
        isPickable: false,
      },

      ...(hasShellBackMaterial ?
        {
          shellBackDesigns: {
            isVisible: false,
          },
        }
      : {}),

      ...(ovalLocation === 'glove.trim' ?
        {
          trim: {
            isVisible: !hasEmbroideryOvalR,
          },
          trim_OvalR: {
            isVisible: hasEmbroideryOvalR,
          },
        }
      : {}),

      ...(ovalLocation === 'glove.shellBack' ?
        {
          shellBack: {
            isVisible: !hasEmbroideryOvalR,
          },
          shellBack_OvalR: {
            isVisible: hasEmbroideryOvalR,
          },
        }
      : {}),

      ...(gloveModel.hasEmbroideryLogo ?
        {
          embroideryLogo: {
            // Hide the embroideryLogo if we have thumb text and it replaces the logo.
            isVisible:
              !gloveAsset.props.embroideryNameReplacesLogo ||
              !hasEmroideryThumbText,
          },
        }
      : {}),

      ...(gloveModel.hasShellBackLaces ?
        {
          shellBackLaces: {
            isVisible: !hasFingerPad,
          },
        }
      : {}),

      ...(gloveModel.hasTrimStitching ?
        hasNarrowFit ?
          {
            trim_stitching: { isVisible: !isNarrowFit },
            trim_narrowFit_stitching: { isVisible: isNarrowFit },
          }
        : {
            trim_stitching: { isVisible: true },
          }
      : {}),

      embroideryOvalR: {
        isVisible: hasEmbroideryOvalR,
      },

      embroideryText: {
        isVisible: hasEmroideryThumbText,
      },

      ...getGloveTrimMeshes(),

      ...getGloveStampingMeshes(),

      ...getGloveFingerPadHoodMeshes(),

      ...getGloveSweatbandMeshes(),

      ...getShellBackMeshes('glove'),

      ...getGloveWeltingMeshes(),
    }
  }

  const getGloveMaterials = (): ModelConfig['materials'] => {
    return {
      ...getGloveColorMaterials(),

      ...getGloveWeltingMaterials(),

      ...getTrimMaterials('glove'),

      ...getGloveStampingMaterials(),

      ...getGloveLiningMaterials(),

      ...getGloveFingerPadHoodMaterials(),

      ...getGloveStitchingMaterials(),

      ...getGloveShellBackMaterials(),

      patch_mat:
        gloveModel.hasPatch ?
          {
            diffuseTexture: patchColor && {
              url: getAsset(
                `textures/patches/a${patchColor.asset.patchAssetId}.png`,
              ),
            },
            flipX: isLeftHand,
          }
        : undefined,

      patch_MLB:
        patchColor && gloveModel.hasMLB ?
          {
            diffuseTexture: {
              url: getAsset(
                `textures/patches/d${logoPatchNode.object.asset.MLBAssetId}.png`,
              ),
            },
            flipX: isLeftHand,
          }
        : undefined,

      embroideryLogo_mat:
        gloveModel.hasEmbroideryLogo ?
          {
            ...(flagNode.value ?
              {
                diffuseTexture: getFlagTexture(flagNode, {
                  width: 1211,
                  height: 485,
                }),
                normalTexture: { remove: true },
                flipX: isLeftHand,
              }
            : {
                ...getMaterialFromColor(embroideryColorNode.object, false),
                flipX: isLeftHand,
              }),
          }
        : undefined,

      embroideryOvalR_mat: {
        diffuseTexture: patchColor && {
          url: getAsset(
            `textures/patches/b${patchColor.asset.patchAssetId}.png`,
          ),
        },
        flipX: isLeftHand,
      },

      embroideryGoldGlove_mat:
        hasEmroideryPinkyText ?
          {
            diffuseTexture: getVectorTextTexture(
              embroideryPinkyFontNode,
              embroideryPinkyTextNode,
              embroideryColorNode,
              { width: 463, height: 85 },
              0.9,
            ),
            flipX: isLeftHand,
          }
        : {
            ...getMaterialFromColor(embroideryColorNode.object),
            flipX: isLeftHand,
          },

      embroideryText_mat: {
        diffuseTexture:
          hasEmroideryThumbText ?
            getVectorTextTexture(
              embroideryThumbFontNode,
              embroideryThumbTextNode,
              embroideryColorNode,
              { width: 425, height: 57 },
              0.7,
            )
          : undefined,
        flipX: isLeftHand,
      },

      embroideryNumbers_mat: {
        diffuseTexture:
          hasEmbroideryLogo ?
            {
              url: getAsset(`textures/logo/${embroideryLogoNode.value}.png`),
            }
          : getBitmapTextTexture(
              embroideryNumberFontNode,
              embroideryNumberTextNode,
              embroideryColorNode,
              { width: 1323, height: 816 },
            ),
      },
    }
  }

  const getWebMeshes = () => {
    return {
      __root__: {
        scaling: { x: isLeftHand ? -1 : 1 },
      },

      ...(ovalLocation === 'web.back' ?
        {
          [`${web.id}_back`]: {
            isVisible: !hasEmbroideryOvalR,
          },
          [`${web.id}_back_OvalR`]: {
            isVisible: hasEmbroideryOvalR,
          },
        }
      : {}),

      ...getShellBackMeshes('web'),

      ...(hasNarrowFit && hasTrimOnWeb ?
        {
          [`${web.id}_trim`]: { isVisible: !isNarrowFit },
          [`${web.id}_trim_narrowFit`]: { isVisible: isNarrowFit },
        }
      : {}),
    }
  }

  const getWebMaterials = (): ModelConfig['materials'] => {
    // TODO: Enable this when needed.
    const hasHeelLaces = false

    const heelLacesMaterials: ModelConfig['materials'] =
      hasHeelLaces ?
        {
          [`${web.id}_heelLaces_mat`]: getMaterialFromColor(
            heelLacesColorNode.object,
          ),
        }
      : {}

    const webOvalMaterials: ModelConfig['materials'] =
      web.asset.logo ?
        {
          [`${web.id}_embroideryOvalR_mat`]: {
            diffuseTexture: patchColor && {
              url: getAsset(
                `textures/patches/b${patchColor.asset.patchAssetId}.png`,
              ),
            },
            flipX: isLeftHand,
          },
        }
      : {}

    // Add an option to load weltingPalm from the webs (covers 200CV and maybe other gloves with Trapeze web, where there are different weltingPalm meshes depending on the web)
    const weltingPalmOnWeb: ModelConfig['materials'] =
      gloveModel.hasWeltingOnWeb ?
        {
          [`${web.id}_weltingPalm_mat`]: getMaterialFromColor(
            weltingPalmColorNode.object,
          ),
        }
      : {}

    return {
      [`${web.id}_base_mat`]: getMaterialFromColor(webColorNode.object),
      [`${web.id}_fingerLaces_mat`]: getMaterialFromColor(
        fingerLacesColorNode.object,
      ),
      [`${web.id}_fingerLacesInside_mat`]: getMaterialFromColor(
        fingerLacesColorNode.object,
      ),
      [`${web.id}_palm_mat`]: getMaterialFromColor(shellPalmColorNode.object),
      [`${web.id}_palmEdge_mat`]: getMaterialFromColor(
        shellPalmColorNode.object,
      ),
      ...(web.asset.binding ?
        {
          [`${web.id}_binding_mat`]: {
            ...getMaterialFromColor(bindingColorNode.object),
          },
        }
      : {}),

      stamping_TrapezeWeb_mat:
        gloveModel.hasTrapezeStamping && web.id === 'Trapeze' ?
          {
            ...getMaterialFromColor(stampingColorNode.object, false),
            diffuseTexture: {
              url: getAsset(
                `models/${glove.modelId}/stamping_TrapezeWeb_mat.png`,
              ),
            },
            flipX: isLeftHand,
          }
        : undefined,

      ...heelLacesMaterials,

      ...webOvalMaterials,

      ...weltingPalmOnWeb,

      ...getTrimMaterials('web'),

      ...getWebStitchingMaterials(),
    }
  }

  // Write out gloveMain materials, meshes and Web materials required by Customizer to cross-check with blender
  console.log(
    JSON.stringify({
      main: Object.keys(getGloveMaterials() || {}),
      meshes: Object.keys(getGloveMeshes() || {}),
      [`${web.id}`]: Object.keys(getWebMaterials() || {}),
    }),
  )

  const prepareExternalAssetFilenameGetter =
    (modelId: string) => (filename: string) =>
      getAsset(`models/${modelId}/${filename}`).replace(/^.*\//, '')
  return {
    scene: {
      backgroundColor: '#efefefff',
      environmentTexture: getAsset('models/white.env'),
      environmentRotation: Math.PI,
      environmentIntensity: 1,
      toneMapping: 'khr',
      materialContrast: 1.2,
      materialExposure: 2.5,
      antiAliasing: {
        fxaa: true,
      },
      edgeBlur: 1.5,
      darkenOutOfFocus: 0.25,
    },
    camera: {
      defaultCamera: defaultCamera ? [defaultCamera, 'glove'] : undefined,
      defaultRadius: 1.3,
      lowerRadius: 0.5,
      upperRadius: 1,
      lowerBeta: 0.5,
      upperBeta: 2.6,
    },
    models: {
      common: {
        url: getAsset('models/common/common.gltf'),
        getExternalAssetFilename: prepareExternalAssetFilenameGetter('common'),
        meshes: {
          __root__: {
            isVisible: false,
          },
        },
      },
      glove: {
        url: getAsset(`models/${glove.modelId}/${glove.modelId}.gltf`),
        dependsOn: 'common',
        getExternalAssetFilename: prepareExternalAssetFilenameGetter(
          glove.modelId,
        ),
        meshes: getGloveMeshes(),
        materials: getGloveMaterials(),
      },
      web:
        !hasWeb ? undefined : (
          {
            url: getAsset(`models/${glove.modelId}/web_${web.id}.gltf`),
            dependsOn: 'common',
            getExternalAssetFilename: prepareExternalAssetFilenameGetter(
              glove.modelId,
            ),
            meshes: getWebMeshes(),
            materials: getWebMaterials(),
          }
        ),
    },
    onAfterSceneCreated,
    onAfterMaterialsUpdated: onAfterMaterialsUpdated(
      shellBackMaterialNode.value,
      hasWeb ? web.id : undefined,
    ),
  }
}

export default getRendererConfig
