import { GeneratePreviews } from '@orangelv/bjs-renderer'

import { GLOVES, WEB_DICT } from '../../common/sheets'
import { snapshotter } from './utils'

export default (generatePreviews: GeneratePreviews) => ({
  goldGloves: async (gloveId?: string) => {
    const gloves =
      gloveId ? GLOVES.filter((glove) => glove.id === gloveId) : GLOVES

    return snapshotter({
      generatePreviews,
      name: 'goldGloves',
      snapshots: gloves.map((glove) => ({
        glove,
        values: {
          'colors.binding': 'metallicGold',
          'colors.laces.fingerWeb': 'metallicGold',
          'colors.laces.heel': 'metallicGold',
          'colors.logoPatch': 'eran',
          'colors.shellBack.leatherColor': 'camel',
          'colors.shellPalm': 'camel',
          'colors.trim.color': 'camel',
          'colors.web.color': 'camel',
          'personalization.embroidery.color': 'metallicGold',
        },
      })),
    })
  },

  glovesWithParticularWeb: async (webId: string) => {
    const web = WEB_DICT[webId]

    if (!web) {
      throw new Error(`No web with ID ${webId}!`)
    }

    const gloves = GLOVES.filter((glove) => glove.limitations.webIds[webId])

    return snapshotter({
      generatePreviews,
      name: `glovesWith${webId}`,
      snapshots: gloves.map((glove) => ({
        glove,
        values: {
          'colors.web.style': webId,
        },
      })),
    })
  },
})
