import {
  BackgroundMaterial,
  CreateBox,
  type Mesh,
  type Scene,
  Texture,
} from '@babylonjs/core';
import { ok } from '@orangelv/utils';

import { getState } from './state.js';
import type { Ref, SceneConfig, State } from './types.js';
import { loadEnvironmentTexture } from './utils.js';

const MESH_ID = 'skybox';
const MATERIAL_ID = 'skybox_mat';

const getSkybox = (
  scene: Scene,
): { mesh: Mesh; material: BackgroundMaterial } => {
  let mesh = scene.meshes.find((x) => x.id === MESH_ID) as Mesh | undefined;

  if (mesh) {
    const material = mesh.material as BackgroundMaterial;
    return { mesh, material };
  }

  mesh = CreateBox(MESH_ID, { size: 1000, updatable: true }, scene);
  const material = new BackgroundMaterial(MATERIAL_ID, scene);
  material.backFaceCulling = false;
  mesh.material = material;
  mesh.isPickable = false;
  mesh.infiniteDistance = true;
  // Setting ignoreCameraMaxZ to true (which is the default behavior when creating a skybox)
  // triggers a branch in mesh rendering code that messes with the transform matrix:
  //
  // https://github.com/BabylonJS/Babylon.js/blob/78854ae7b2b8ebc36d1813c66d4be4b41b954bf0/packages/dev/core/src/Meshes/mesh.ts#L2295-L2300
  //
  // We make our own modifications to it when an offset is needed, and it
  // isn't immediately clear how to prevent Babylon from overriding them.
  // ignoreCameraMaxZ is used to avoid clipping, as per
  // https://forum.babylonjs.com/t/mesh-ignore-camera-maxz/40867/4, but
  // none is observed when we disable it.
  //
  // This is a hack, of course, so if something is off with some skybox,
  // try re-enabling this and see if it helps.
  mesh.ignoreCameraMaxZ = false;

  return { mesh, material };
};

const updateSkybox =
  (stateRef: Ref<State>) =>
  async (sceneConfig: SceneConfig | undefined): Promise<void> => {
    if (!sceneConfig) {
      return;
    }

    const { scene, props } = getState(stateRef);

    ok(scene);

    const { mesh, material } = getSkybox(scene);

    if (sceneConfig.skyboxTexture === undefined) {
      mesh.setEnabled(false);

      if (material.reflectionTexture) {
        material.reflectionTexture.dispose();
      }
    } else {
      // At this point we know that there will need to be a loader shown so we manually do just that.
      if (props.onLoading) {
        props.onLoading(true);
      }

      mesh.setEnabled(true);

      const texture = await loadEnvironmentTexture(
        scene,
        sceneConfig.skyboxTexture,
        sceneConfig.hdrSize,
      );

      const reflectionTexture = texture.clone();
      reflectionTexture.coordinatesMode = Texture.SKYBOX_MODE;

      material.reflectionTexture = reflectionTexture;
      material.reflectionBlur = sceneConfig.skyboxBlur ?? 0;

      if (sceneConfig.environmentTexture === undefined) {
        scene.environmentTexture = texture.clone();
      }
    }
  };

export default updateSkybox;
