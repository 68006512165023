import { forEach } from '@technically/lodash'
import { createRoot } from 'react-dom/client'
import viewportUnitsBuggyfill from 'viewport-units-buggyfill'

import handleErrors from '../../../platform/client/handleErrors'
import * as controlTreeDebugUtils from '../../../platform/client/control-tree/debugUtils'

import Root from './components/Root'
import store from './store'
import controlTree from './controlTree'

import { LEATHER_COLOR_DICT } from '../common/sheets'

import snapshots from './snapshots'

handleErrors(store)

// For debugging.
window.store = store
window.snapshots = snapshots

// One should be using control-tree/debugUtils via window like window.getNodes().
window.getControlTree = () => controlTree
forEach(controlTreeDebugUtils.createUtils(store, controlTree), (fn, name) => {
  window[name] = fn
})

// Test different colors on glove
window.setDebugColorHex = (colorId, hex) => {
  if (!LEATHER_COLOR_DICT[colorId]) {
    console.log('Color id does not exist!')
    return
  }
  LEATHER_COLOR_DICT[colorId].props.hex = hex
  // RELOAD_STATE doesn't exist, used to force rerender.
  store.dispatch({ type: 'RELOAD_STATE' })
}

viewportUnitsBuggyfill.init()

createRoot(document.getElementById('root')).render(<Root store={store} />)
