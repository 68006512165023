import React, { Fragment } from 'react'
import {
  filter,
  map,
  findIndex,
  isEqual,
  endsWith,
  startsWith,
} from '@technically/lodash'

import { t } from '~p/i18n'
import getAsset from '~p/getAsset'
import { cn, mods } from '~p/client/components/utils'
import { Swipeable } from '~p/client/components/Swipeable'

import Button from './Button'
import FileInput from './FileInput'
import Icon from './Icon'
import Tile from './Tile'
import TextInput from './TextInput'
import TileCard from './TileCard'

import './OptionsBar.css'

const getTileSize = (tileSize) => {
  switch (tileSize) {
    case 'large':
      return 280 + 20
    case 'wide':
      return 184
    default:
      return window.innerWidth < 768 ? 74 : 84
  }
}

const getSizes = (props, width) => {
  const { tileSize } = props

  let availableWidth = width - 82 * 2

  if (tileSize !== 'large' && width >= 768) {
    availableWidth -= 175 + 20
  }

  const intTitleWidth = getTileSize('default')
  const tileWidth = getTileSize(tileSize)
  const tilesPerSet = Math.floor(availableWidth / tileWidth) || 1
  const containerWidth = tilesPerSet * tileWidth

  return {
    intTitleWidth,
    tileWidth,
    tilesPerSet,
    containerWidth,
  }
}

const getGridWidth = (itemsCount, tileWidth) => {
  const windowWidth = window.innerWidth
  let perRow = 4

  if (windowWidth > 560) {
    perRow = 7
  } else if (windowWidth > 500) {
    perRow = 6
  } else if (windowWidth > 400) {
    perRow = 5
  }

  return tileWidth * Math.min(itemsCount, perRow)
}

const getNewState = (state, props) => {
  const width = window.innerWidth

  const isHovering = state.isHovering || false
  const hoverLabel = state.hoverLabel || ''
  const hoverDescription = state.hoverDescription || ''

  let options = props.options || []

  if (props.hideNone) {
    options = options.filter((option) => option.id)
  }

  const tilesCount = options.length

  const sizes = getSizes(props, width)
  const { tilesPerSet } = sizes

  const maxOffsetCount = tilesCount - tilesPerSet

  const allFits = tilesPerSet >= tilesCount

  const index = findIndex(options, { id: props.value })
  const offsetCount =
    index === -1 ? 0 : (
      Math.min(
        Math.max(index - Math.ceil(tilesPerSet / 2) + 1, 0),
        maxOffsetCount,
      )
    )

  const shouldAnimate = false

  return {
    sizes,
    options,
    offsetCount,
    maxOffsetCount,
    width,
    allFits,
    shouldAnimate,
    isHovering,
    hoverLabel,
    hoverDescription,
  }
}

const getScrollContainerStyle = (props, width) => {
  if (width < 769 && props.tileSize !== 'large') {
    return {}
  }

  const { containerWidth } = getSizes(props, width)

  return {
    width: containerWidth,
  }
}

const getScrollBodyStyle = (props, offsetCount, tilesCount, width) => {
  const { tileWidth } = getSizes(props, width)

  let offset = offsetCount > 0 ? `-${tileWidth * offsetCount}px` : null
  let scrollBodyWidth = `${tileWidth * tilesCount}px`

  if (width < 769 && props.tileSize !== 'large') {
    offset = 0
    scrollBodyWidth = null
  }

  return {
    marginLeft: offset,
    width: scrollBodyWidth,
  }
}

const Input = (props) => (
  <div className={cn(['optionsBar-input', mods([props.type])])}>
    {props.type === 'file' ?
      <FileInput {...props} change={props.onChange} />
    : <TextInput {...props} />}
  </div>
)

export default class OptionsBar extends React.Component {
  static defaultProps = { options: [], tileSize: 'normal' }

  constructor(props) {
    super(props)

    this.state = getNewState({}, props)
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize)
    this.scrollIntoViewOnSmallScreens()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      !this.props.options ||
      !nextProps.options ||
      !isEqual(this.props.options, nextProps.options)
    ) {
      this.setState((state) => getNewState(state, nextProps))
    }
  }

  componentDidUpdate() {
    this.scrollIntoViewOnSmallScreens()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize)
  }

  onResize = () => {
    this.setState(getNewState)
    this.scrollIntoViewOnSmallScreens()
  }

  onSwipedLeft = () => {
    if (this.props.tileSize === 'large') {
      this.scrollRight()
    }
  }

  onSwipedRight = () => {
    if (this.props.tileSize === 'large') {
      this.scrollLeft()
    }
  }

  activeItem

  scrollBody

  scrollIntoViewOnSmallScreens() {
    if (window.innerWidth < 769) {
      requestAnimationFrame(() => {
        if (!this.activeItem || !this.scrollBody) {
          return
        }

        const delta =
          this.activeItem.offsetLeft -
          this.scrollBody.scrollLeft -
          window.innerWidth +
          this.activeItem.offsetWidth

        if (delta > 0) {
          this.scrollBody.scrollLeft += delta
        } else if (this.activeItem.offsetLeft < this.scrollBody.scrollLeft) {
          this.scrollBody.scrollLeft = this.activeItem.offsetLeft
        }
      })
    } else if (this.scrollBody) {
      this.scrollBody.scrollLeft = 0
    }
  }

  atFirstTile = () => this.state.offsetCount === 0

  atLastTile = () => this.state.offsetCount === this.state.maxOffsetCount

  scrollLeft = () => {
    const { tilesPerSet } = getSizes(this.props, window.innerWidth)
    const tilesToScroll = Math.max(tilesPerSet - 1, 1)
    this.setState((state) => ({
      offsetCount: Math.max(0, state.offsetCount - tilesToScroll),
      shouldAnimate: true,
    }))
  }

  scrollRight = () => {
    const { tilesPerSet } = getSizes(this.props, window.innerWidth)
    const tilesToScroll = Math.max(tilesPerSet - 1, 1)
    this.setState((state) => ({
      offsetCount: Math.min(
        state.offsetCount + tilesToScroll,
        state.maxOffsetCount,
      ),
      shouldAnimate: true,
    }))
  }

  imagePath = (x) => {
    if (!this.props.tileImageTemplate) {
      return null
    }

    const imagePath = this.props.tileImageTemplate(x)
    return getAsset(imagePath, { has2x: true })
  }

  renderTile = (option, commonProps) => {
    if (!option.id || option.id === 'no') {
      return (
        <Tile
          {...commonProps}
          tileType={this.props.tileType}
          text={!option.id ? t('_rawlings:none') : option.name}
          background="transparent"
          dashed
        />
      )
    }
    const optionProps = option.props || {}

    const isText =
      endsWith(this.props.tileType, 'WithText') &&
      (!optionProps.hex || optionProps.tileText)
    const isWide = startsWith(this.props.tileType, 'wide')

    return (
      <Tile
        {...commonProps}
        tileType={this.props.tileType}
        text={isText ? optionProps.tileText || option.name : undefined}
        background={
          isText ? 'midGray'
          : isWide ?
            'transparent'
          : 'lighterGray'
        }
        customColor={!isText && optionProps.hex ? optionProps.hex : undefined}
        imageSrc={this.imagePath(option)}
        secondaryImageSrc={
          optionProps.tileImageSrc && getAsset(optionProps.tileImageSrc)
        }
        glossy={!!optionProps.isCoated}
      />
    )
  }

  render() {
    const props = this.props
    const { CustomComponent, componentProps } = props
    const { options, sizes } = this.state

    if (props.isPrivate) {
      return null
    }

    const label = this.state.isHovering ? this.state.hoverLabel : props.label
    const description =
      this.state.isHovering ? this.state.hoverDescription : props.description

    return (
      <div
        className={cn([
          'optionsBar',
          mods([props.tileSize]),
          // mods([props.tileSize, 'dev-outline']),
        ])}
      >
        {CustomComponent ?
          <CustomComponent {...(componentProps || {})} />
        : <Fragment>
            <div
              className={cn([
                'optionsBar-header',
                mods(props.isGrid ? ['grid'] : []),
              ])}
            >
              {label && <div className="optionsBar-title">{label}</div>}
              {description && (
                <div className="optionsBar-description">{description}</div>
              )}
            </div>
            {props.input && <Input {...props.input} />}
            {props.isGrid && (
              <div
                className="optionsBar-grid"
                style={{
                  width: getGridWidth(options.length, sizes.intTitleWidth),
                }}
              >
                {map(options, (option) => (
                  <Tile
                    key={option.id}
                    tileType="squareWithText"
                    text={option.name}
                    isSelected={option.id === props.value}
                    onClick={() => {
                      props.onChange(option.id)
                    }}
                  />
                ))}
              </div>
            )}
            {props.isGrid === false && options.length > 0 && (
              <Swipeable
                className="optionsBar-chrome"
                onSwipedRight={this.onSwipedRight}
                onSwipedLeft={this.onSwipedLeft}
              >
                <div
                  className={cn([
                    'optionsBar-scrollContainer',
                    mods(this.state.allFits ? ['allFits'] : []),
                  ])}
                  style={getScrollContainerStyle(props, this.state.width)}
                >
                  <div className="optionsBar-control mod-left">
                    <Button
                      classMods={filter([
                        'chrome',
                        'control',
                        this.atFirstTile() && 'disabled',
                      ])}
                      onClick={this.scrollLeft}
                    >
                      <Icon name="arrow-back" />
                    </Button>
                  </div>
                  <div className="optionsBar-scrollBodyContainer">
                    <div
                      ref={(x) => {
                        this.scrollBody = x
                      }}
                      className={cn([
                        'optionsBar-scrollBody',
                        this.state.shouldAnimate ? 'withAnimation' : '',
                        mods([`items-${options.length}`]),
                        mods([props.tileSize]),
                      ])}
                      style={getScrollBodyStyle(
                        props,
                        this.state.offsetCount,
                        options.length,
                        this.state.width,
                      )}
                    >
                      {map(options, (option) => {
                        const isSelected = option.id === props.value

                        const commonProps = {
                          tileSize: props.tileSize,
                          ...(props.tileSize !== 'large' ?
                            {
                              isSelected,
                              isDisabled: props.isDisabled,
                              onClick: () => {
                                if (!isSelected) {
                                  props.onChange(option.id)
                                }
                              },
                            }
                          : {}),
                        }
                        return props.tileSize === 'large' ?
                            <div
                              ref={
                                isSelected ?
                                  (x) => {
                                    this.activeItem = x
                                  }
                                : () => {}
                              }
                              key={option.id}
                            >
                              <div className="optionsBar-scrollItem">
                                <TileCard
                                  imageSrc={this.imagePath(option)}
                                  name={option.name}
                                  text={option.description}
                                  isSelected={isSelected}
                                  onClick={() => props.onChange(option.id)}
                                  style={{ width: sizes.tileWidth }}
                                />
                              </div>
                            </div>
                          : <div
                              ref={(x) => {
                                if (isSelected) {
                                  this.activeItem = x
                                }
                              }}
                              key={option.id}
                              className="optionsBar-scrollItem"
                              onMouseEnter={
                                props.layoutMode === 'mobile' ?
                                  undefined
                                : () => {
                                    this.setState({
                                      isHovering: true,
                                      hoverLabel:
                                        option.id === null ? '' : option.name,
                                      hoverDescription: option.description,
                                    })
                                  }
                              }
                              onMouseLeave={
                                props.layoutMode === 'mobile' ?
                                  undefined
                                : () => {
                                    this.setState({
                                      isHovering: false,
                                      hoverLabel: '',
                                      hoverDescription: '',
                                    })
                                  }
                              }
                            >
                              {this.renderTile(option, commonProps)}
                            </div>
                      })}
                    </div>
                  </div>
                  <div className="optionsBar-control mod-right">
                    <Button
                      classMods={filter([
                        'chrome',
                        'control',
                        this.atLastTile() && 'disabled',
                      ])}
                      onClick={this.scrollRight}
                    >
                      <Icon name="arrow-forward" />
                    </Button>
                  </div>
                </div>
              </Swipeable>
            )}
          </Fragment>
        }
      </div>
    )
  }
}
