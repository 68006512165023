import { Camera } from '@babylonjs/core';
import { ok } from '@orangelv/utils';

import { getState } from '../state.js';
import type { Ref, State } from '../types.js';

const updateCameraFovMode = (stateRef: Ref<State>) => (): void => {
  const { scene } = getState(stateRef);

  ok(scene);

  const engine = scene.getEngine();

  const { activeCamera } = scene;

  if (!activeCamera) {
    return;
  }

  activeCamera.fovMode =
    engine.getRenderWidth() > engine.getRenderHeight() ?
      Camera.FOVMODE_VERTICAL_FIXED
    : Camera.FOVMODE_HORIZONTAL_FIXED;
};

export default updateCameraFovMode;
