import fp from 'lodash/fp.js'
import { createSelector } from 'reselect'

import { t } from '../../../platform/i18n'
import { sheetSelector } from '../../../platform/client/common/selectors'
import { currentNavItemSelector } from '../../../platform/client/navigation'

export const currentMenuPropDefSelector = createSelector(
  [sheetSelector, currentNavItemSelector],
  (sheets, menu) => {
    let { propId } = menu

    if (!propId) {
      return undefined
    }

    propId = propId.replace(/\./g, '_')

    return sheets.propDefs[propId]
  },
)

export const isFocusModeSelector = fp.get('isFocusMode')

export const loadingTextSelector = (state) => {
  if (state.isPreviewsUploading) {
    return t('_rawlings:uploadingPreviews')
  }
  if (state.isPreviewsGenerating) {
    return t('_rawlings:generatingPreviews')
  }
  if (state.isRecipeSaving) {
    return t('_rawlings:savingRecipe')
  }
  return undefined
}
