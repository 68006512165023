import getAsset from '../../../platform/getAsset'

import { Node } from '../common/typings'
import { urlToCanvas } from '@orangelv/utils-dom'

const getFlagTexture = (
  flagNode: Node,
  canvasSize: { width: number; height: number },
) => ({
  getCanvas: async (canvas: HTMLCanvasElement) =>
    urlToCanvas(getAsset(`textures/flags/${flagNode.value}.svg`), {
      canvas,
      ...canvasSize,
      fit: 'contain',
    }),
  key: [flagNode.value],
})

export default getFlagTexture
