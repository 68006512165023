// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { TrimRow } from '../typings';

const data = [
  {
    "name": "1",
    "id": "1",
    "props": {
      "assetId": "trim1"
    }
  },
  {
    "name": "2",
    "id": "2",
    "props": {
      "assetId": "trim2"
    }
  },
  {
    "name": "3",
    "id": "3",
    "props": {
      "assetId": "trim3"
    }
  },
  {
    "name": "4",
    "id": "4",
    "props": {
      "assetId": "trim4"
    }
  },
  {
    "name": "5",
    "id": "5",
    "props": {
      "assetId": "trim5"
    }
  },
  {
    "name": "6",
    "id": "6",
    "props": {
      "assetId": "trim6"
    }
  },
  {
    "name": "7",
    "id": "7",
    "props": {
      "assetId": "trim7"
    }
  },
  {
    "name": "8",
    "id": "8",
    "props": {
      "assetId": "trim8"
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<TrimRow[]>> = data;

export type Trim = typeof typed[number];

export const TRIM_INDEX_KEY = "id";
export type TrimIndexKey = "id";
export type TrimId = Trim["id"];

let i = 0;
export const TRIM_DICT = {
  "1": typed[i++],
  "2": typed[i++],
  "3": typed[i++],
  "4": typed[i++],
  "5": typed[i++],
  "6": typed[i++],
  "7": typed[i++],
  "8": typed[i++]
} as const;

export { typed as TRIMS };
