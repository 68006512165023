import React from 'react'

import Spinner from './Spinner'

import './Waiting.css'

/* Covers the full page. */
const Waiting = (props) => (
  <div className="waiting">
    <div className="waiting-spinner">
      <Spinner />
      {props.text && <div className="text">{props.text}</div>}
    </div>
  </div>
)

export default Waiting
