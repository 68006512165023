import { mapValues, ok } from '@orangelv/utils';
import FileSaver from 'file-saver';
import JSZip from 'jszip';
import ky from 'ky';

import { getState } from './state.js';
import type { Ref, State } from './types.js';
import { canvasToBlob, debugCanvas } from './utils.js';

const addDebugProperties = (stateRef: Ref<State>): void => {
  const { scene } = getState(stateRef);

  ok(scene);

  globalThis.bjsRenderer ||= {};

  globalThis.bjsRenderer.debug = async (): Promise<boolean> => {
    await import('@babylonjs/core/Debug/debugLayer');
    await import('@babylonjs/inspector');

    const isVisible = scene.debugLayer.isVisible();

    if (isVisible) {
      scene.debugLayer.hide();
    } else {
      const bodyElement = document.querySelector('body');

      ok(bodyElement, 'DOM does not have body element!');

      await scene.debugLayer.show({
        overlay: true,
        enableClose: false,
        globalRoot: bodyElement,
      });

      const sceneExplorerHostElement = document.querySelector<HTMLElement>(
        '#scene-explorer-host',
      );
      const inspectorHostElement =
        document.querySelector<HTMLElement>('#inspector-host');

      ok(
        sceneExplorerHostElement && inspectorHostElement,
        'Debug layer did not update the DOM!',
      );

      sceneExplorerHostElement.style.zIndex = '999999';
      inspectorHostElement.style.zIndex = '999999';
    }

    scene.render();

    return !isVisible;
  };

  globalThis.bjsRenderer.debugZip = async (): Promise<void> => {
    const { props, models } = getState(stateRef);

    const { config } = props;

    const zip = new JSZip();
    zip.file('config.json', JSON.stringify(config, undefined, 2));

    const promises: Promise<void>[] = [];
    for (const [modelId, modelState] of Object.entries(models)) {
      promises.push(
        (async (): Promise<void> => {
          const response = await ky(modelState.url);
          const modelBlob = await response.blob();
          zip.file(`${modelId}/${modelId}.gltf`, modelBlob);
        })(),
      );

      for (const [materialId, dynamicTextures] of Object.entries(
        modelState.dynamicTextures,
      )) {
        for (const [textureKey, dynamicTexture] of Object.entries(
          dynamicTextures,
        )) {
          if (!dynamicTexture) continue;

          const context = dynamicTexture.getContext();

          promises.push(
            (async (): Promise<void> => {
              zip.file(
                `${modelId}/${materialId}/${textureKey}.png`,
                await canvasToBlob(context.canvas as HTMLCanvasElement),
              );
            })(),
          );
        }
      }
    }

    await Promise.all(promises);

    FileSaver.saveAs(
      await zip.generateAsync({ type: 'blob' }),
      'bjs-debug.zip',
    );
  };

  Object.defineProperty(globalThis.bjsRenderer, 'debugTexture', {
    configurable: true,
    get() {
      const { models } = getState(stateRef);

      return mapValues(models, (modelState) =>
        mapValues(modelState.dynamicTextures, (dynamicTextures) =>
          mapValues(dynamicTextures, (dynamicTexture) => (): void => {
            ok(dynamicTexture, 'Dynamic texture not found!');

            const context = dynamicTexture.getContext();

            void debugCanvas(context.canvas as HTMLCanvasElement);
          }),
        ),
      );
    },
  });
};

export default addDebugProperties;
