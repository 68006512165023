import type { ArcRotateCamera, DynamicTexture } from '@babylonjs/core';
import { ok } from '@orangelv/utils';
import { fabric as fabricLibrary } from 'fabric';

import { getState, setModelState } from '../state.js';
import type {
  MaterialPropertyFabric,
  ModelFabrics,
  Ref,
  State,
} from '../types.js';
import { TEXTURE_HEIGHT, TEXTURE_WIDTH } from './index.js';

const renderFabric =
  (stateRef: Ref<State>) =>
  async (
    propertyFabric: MaterialPropertyFabric,
    dynamicTexture: DynamicTexture,
    modelId: string,
    materialId: string,
  ): Promise<void> => {
    const state = getState(stateRef);
    const { scene, fabricCanvasesRef, models } = state;

    ok(scene);

    const activeCamera = scene.activeCamera as ArcRotateCamera;

    const { getFabric } = propertyFabric;

    // It would be better to not add Fabric.js canvas to the DOM at all,
    // but then Fabric.js itself calculates wrong positions for our mouse events.
    const fabricCanvas = fabricCanvasesRef.current[`${modelId}.${materialId}`];

    ok(fabricCanvas, 'Could not find fabric canvas!');

    const modelState = models[modelId];

    ok(modelState);

    let fabric = modelState.fabrics[materialId];

    let isInit = false;

    if (fabric === undefined) {
      isInit = true;
      fabricCanvas.width = TEXTURE_WIDTH;
      fabricCanvas.height = TEXTURE_HEIGHT;

      const newFabric = new fabricLibrary.Canvas(fabricCanvas, {
        selection: false, // Disables the _group_ selection.
        renderOnAddRemove: false,
        enableRetinaScaling: false,
      });

      setModelState(stateRef)(modelId, {
        ...modelState,
        fabrics: {
          ...modelState.fabrics,
          [materialId]: newFabric,
        },
      });

      // Attach/detach camera pointers input when Fabric.js object is deselected/selected.
      // This prevents camera rotation, but still allows for zooming in on 3D model.
      newFabric.on('selection:created', () => {
        ok(activeCamera.inputs.attached['pointers']);
        activeCamera.inputs.attached['pointers'].detachControl();
      });
      newFabric.on('selection:cleared', () => {
        ok(activeCamera.inputs.attached['pointers']);
        activeCamera.inputs.attached['pointers'].detachControl();
        activeCamera.inputs.attachInput(
          activeCamera.inputs.attached['pointers'],
        );
      });

      newFabric.on('after:render', () => {
        const textureContext = dynamicTexture.getContext();
        const textureCanvas = textureContext.canvas;

        if (newFabric.width === undefined || newFabric.height === undefined) {
          throw new Error('Logic error');
        }

        textureCanvas.width = newFabric.width;
        textureCanvas.height = newFabric.height;

        textureContext.clearRect(
          0,
          0,
          textureCanvas.width,
          textureCanvas.height,
        );
        textureContext.drawImage(
          fabricCanvas,
          0,
          0,
          textureCanvas.width,
          textureCanvas.height,
        );

        dynamicTexture.update(false);

        scene.render();
      });

      fabric = newFabric;
    } else {
      // Remove everything before calling getFabric every time except when it's init.
      fabric.remove(...fabric.getObjects());
    }

    const getFabrics = (): ModelFabrics => {
      const model = getState(stateRef).models[modelId];
      if (!model) throw new Error('Logic error');
      return model.fabrics;
    };

    await getFabric(fabric, isInit, getFabrics);

    fabric.renderAll();
  };

export default renderFabric;
