import * as _ from '@technically/lodash'

import { LEATHERS, WEBS, GLOVE_ASSET_DICT } from '../../common/sheets'
import generateSnapshots, { viewNames } from './generate'
import controlTree from '../controlTree'

const HEIGHT = 1024

const getJob = ({ filename, leather, glove, viewName, webId, trimId }) => {
  const controlTreeChanges = [
    ['product.leather', leather.id],
    ['product.glove', glove.id],
    ['colors.laces.fingerWeb', 'camel'],
    ['colors.laces.heel', 'camel'],
    ['colors.trim.color', 'oceanMint'],
    ['colors.shellBack.leatherColor', 'scarlet'],
    ['colors.trim.style', trimId],
    ['colors.web.style', webId],
  ]

  const gloveAsset = GLOVE_ASSET_DICT[glove.asset.gloveAssetId]
  const sourceSize = gloveAsset.props.imageDimensions[viewName]
  const height = HEIGHT
  const width = Math.round((height / sourceSize.height) * sourceSize.width)
  const size = {
    width,
    height,
  }

  return { filename, controlTreeChanges, viewName, size }
}

const main = async () => {
  const state = {
    controlTree: {
      pendingChanges: { auto: {}, user: {} },
      preferredValues: {},
      repeatedNodes: {},
      values: {},
    },
  }

  const gloves = controlTree.getNodes(state)['product.glove'].visibleOptions
  const leathers = LEATHERS

  let jobs = []
  _.forEach(leathers, (leather) => {
    _.forEach(viewNames, (viewName) => {
      _.forEach(gloves, (glove) => {
        if (!glove.limitations.leatherId[leather.id]) {
          return
        }

        _.forEach(WEBS, (web) => {
          const webId = web.id

          if (!glove.limitations.webIds[web.id]) {
            return
          }

          _.forEach(glove.limitations.trims, (trimId) => {
            const filename = `${glove.id}   ${viewName}   ${leather.id}   ${webId}   ${trimId}`
            const job = getJob({
              filename,
              leather,
              glove,
              viewName,
              trimId,
              webId,
            })
            jobs.push(job)
          })
        })
      })
    })
  })

  console.log('Jobs:')
  console.log(jobs)
  return generateSnapshots(jobs)
}

export default main
