import fp from 'lodash/fp.js'
import cn from 'classnames'
import React from 'react'
import { renameProp } from 'recompose'

import { debounceChange, validatePattern } from '~p/client/component-enhancers'

import './TextInput.css'

const TextInput = ({
  type = 'text',
  maxLength = null,
  pattern = null,
  hasError = false,
  value,
  onChange,
  label,
  placeholder,
  shouldAutoFocus,
}) => (
  <div className={cn('textInput', { hasError })}>
    <input
      title={
        pattern &&
        pattern.source.replace('^[', 'Allowed characters: ').replace(']+$', '')
      }
      className="textInput-input"
      type={type}
      value={value}
      maxLength={maxLength}
      pattern={pattern && pattern.source}
      onChange={(ev) => onChange(ev.target.value)}
      placeholder={placeholder}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={shouldAutoFocus}
    />
    <div className="textInput-label">
      {!maxLength ?
        label
      : `${maxLength} Characters Max${label ? ` | ${label}` : ''}`}
    </div>
  </div>
)

export default fp.compose(
  // TODO: Remove this when onChange/change are called the same everywhere.
  renameProp('onChange', 'change'),
  debounceChange,
  validatePattern,
  renameProp('change', 'onChange'),
)(TextInput)
